// Dependencies
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
//import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Components
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "center"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  dialogTxt: {
    //textAlign: "left",
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  },
  button: { textTransform: "none" }
}));

export default function FeedbackValidationModal({
  feedback,
  showValidationDialog,
  setShowValidationDialog,
  submitFeedback,
  validationResult,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Redux State
  const rtl = useSelector(state => state.user.userProfile.rtl);

  // Ephemeral State

  // Behavior

  const closeModal = () => {
    setShowValidationDialog(false);
  };

  function generateMessage() {
    if (validationResult) {
      const comment = validationResult.comment;

      let output = "";

      if (comment.length > 0) {
        output += `You didn't add a comment on the peer review task`;
      }

      if (output !== "") {
        output +=
          ".<br/>" +
          intl.formatMessage({
            id: "task.feedback.confirmation",
            defaultMessage: "Do you want to proceed anyway?"
          });
      }
      return output.charAt(0).toUpperCase() + output.slice(1);
    }
  }

  return (
    <>
      <div>
        <Dialog
          open={showValidationDialog}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: classes.modal,
            style: {
              direction: rtl ? "rtl" : "ltr"
            }
          }}>
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="task.feedback.submit"
              defaultMessage="Send Feedback"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogTxt}
              dangerouslySetInnerHTML={{
                __html: generateMessage()
              }}></DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={closeModal}
              className={clsx(classes.buttonDiscard, classes.button)}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                setShowValidationDialog(false);
                submitFeedback();
              }}
              color="primary"
              // autoFocus
            >
              <FormattedMessage
                id="button.next"
                defaultMessage="Proceed anyway"
              />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
