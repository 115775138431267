import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import {
  
Chip,
  } from "@material-ui/core";

const useStyles = makeStyles((theme) => 
    (
    {
        chip:{
            marginTop:'8px',
            marginBottom:'8px',
            marginLeft:'8px',
            marginRight:'8px',
            '&:first-child':{
                marginLeft:'16px',
            },
        },
        incorrect:{
          textTransform:'none',
          backgroundColor:'#f9413e',
          '& .MuiChip-label':{
            paddingLeft:'8px',
            paddingRight:'8px',
          },
        },
             correct:{
          textTransform:'none',
          backgroundColor:'#0eac72',
          '& .MuiChip-label':{
            paddingLeft:'8px',
            paddingRight:'8px',
          },
        },
         
    })
    );
export default function ConceptChip(props) {

    const { concept,found} = props;
    const classes = useStyles();
    
//Concepts score stuff


    return (
                  <Chip label={concept} className={clsx(classes.chip, 
                    found && classes.correct, !found && classes.incorrect)}/>
   )
}