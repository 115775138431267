import React from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CoursesSelectorSidebar from "./components/SharedComponents/courseSelectorSidebar/CourseSelectorSidebar";
import ReaderSidebar from "./components/poc/ReaderSidebar";
import { useGetTheme } from "./hooks";

export default function Sidebar({ ...props }) {
  const theme = useGetTheme({ alwase: "dark" });

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        {/* Exclude sidebar from create new task */}
        <Route path="/tasks/:id/new" />

        <Route exact path="/" component={CoursesSelectorSidebar} />
        <Route path="/tasks" component={CoursesSelectorSidebar} />
        <Route path="/library" component={CoursesSelectorSidebar} />

        <Route path="/reader/task/:task_id">
          <ReaderSidebar />
        </Route>
        <Route path="/reader">
          <ReaderSidebar />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}
