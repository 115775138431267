// Dependencies
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { dequeueFlashMessage, flashClosed ,undo } from "./redux/userSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarContent, Snackbar, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

//Styles
const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  listItem: {
    listStyle: "none",
    textAlign: "left",
  },
  undo: {
    textTransform: "uppercase",
  },
}));

export default function FlashMessages() {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Variables

  // Redux State
  const rtl = useSelector((state) => state.user.userProfile.rtl);
  const flashMessages = useSelector((state) => state.user.flashMessages);

  // Ephemeral State
  // const [isOpen, setIsOpen] = useState(false);

  //Behavior
  useEffect(() => {
    if (flashMessages) {
      // setIsOpen(true);
      dispatch(flashClosed(false));
      const timer = setTimeout(() => {
        dispatch(flashClosed(true));
        dispatch(undo(false));
        dispatch(dequeueFlashMessage());
      }, flashMessages.duration || 5000);
      return () => clearTimeout(timer);
    }
  }, [flashMessages, dispatch]);

  function parseMessage(message) {
    if (Array.isArray(message)) {
      return (
        <ul className={classes.list}>
          {message.map((line) => {
            return <li className={classes.listItem}>{line}</li>;
          })}
        </ul>
      );
    } else {
      return message;
    }
  }
  //Render
  if (flashMessages) {
    return (
      
      <Snackbar
        open={flashMessages}
        // autoHideDuration is overwriten in a side effect
        autoHideDuration={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: rtl ? "left" : "right",
        }}
        ClickAwayListenerProps={{
          mouseEvent: "false",
          touchEvent: "false",
        }}
      >
        {flashMessages.severity ? (
          <Alert
            severity={flashMessages.severity}
            variant="filled"
            icon={false}
          >
            {parseMessage(flashMessages.message)}
          </Alert>
        ) : (
          <SnackbarContent
            // message={flashMessages.message}
            message={flashMessages.message}
            action={
              flashMessages.undoButton &&
              flashMessages.undoButton === true && (
                <Button
                  onClick={() => {
                    dispatch(undo(true));
                    dispatch(dequeueFlashMessage());
                  }}
                  className={clsx(classes.undo)}
                  color="secondary"
                  size="small"
                >
                  {intl.formatMessage({ id: "undo", defaultMessage: "undo" })}
                </Button>
              )
            }
          />
        )}
      </Snackbar>
    );
  } else return "";
}
