import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { setClickedMatch } from "../../../redux/taskSlice";
import CitationDialog from "../CreateTask/CitationDialog";
import {
  Divider,
  Box,
  Card,
  List,
  ListItem,
  Typography,
  Chip
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    padding: "8px",
    //  maxHeight:'184px',
    textTransform: "none",
    paddingBottom: "24px",
    // background: "#e0e0e0",
    "& button": {
      visibility: "hidden",
      color: "black",
      fontSize: "16px",
      position: "absolute",
      bottom: "2px"
    },
    "&:hover": {
      // background: "#f5f5f5",
      //  maxHeight:'initial',
      " & button": {
        visibility: "visible"
      }
    }
  },
  radio: {
    "&.Mui-checked": {
      color: "#168fee"
    },
    //  display:'inline-block',
    color: "#fafafa"
  },

  divider: {
    backgroundColor: "#616161",
    marginBottom: "12px"
  },
  divider2: {
    backgroundColor: "#616161",
    marginBottom: "16px",
    marginTop: "16px"
  },
  expand: {
    color: "#bdbdbd"
  },
  panelHeader: {
    color: "white",
    width: "100%",
    textTransform: "uppercase",
    marginTop: "16px"
  },

  questionLtr: {
    paddingRight: "16px",
    paddingLeft: "16px"
  },
  questionRtl: {
    paddingRight: "16px",
    paddingLeft: "16px"
  },
  panelHeaderLtr: {
    textAlign: "left"
  },
  panelHeaderRtl: {
    textAlign: "right"
  },
  white: {
    "&.Mui-disabled": {
      color: "white"
    },
    color: "white"
  },
  points: {
    display: "inlint-block",
    border: "1px solid #168fee",
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "64px"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    marginTop: "8px",
    display: "block",
    lineHeight: "32px",
    textTransform: "uppercase"
  },
  left: {
    textAlign: "left"
  },

  right: {
    textAlign: "right"
  },
  nextButtonRtl: {
    left: "40px"
  },
  nextButtonLtr: {
    right: "40px"
  },
  choiceContainer: {
    width: "100%",
    position: "relative"
  },
  correct: {
    textTransform: "none",
    position: "absolute",
    right: "0px",
    backgroundColor: "#0eac72",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  },
  quoteRef: {
    marginTop: "16px",
    fontSize: "12px",
    color: "#168fee",
    cursor: "pointer"
  },
  match: {
    backgroundColor: "#0eac72",
    textTransform: "none",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    "&:focus": {
      backgroundColor: "#0eac72"
    },
    "&:hover": {
      backgroundColor: "#0eac72"
    }
  },
  correctInfo: {
    textTransform: "none",
    position: "absolute",
    right: "0px",
    backgroundColor: "transparent",

    color: "#0eac72",
    border: "1px solid #0eac72",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  },
  incorrect: {
    textTransform: "none",
    position: "absolute",
    right: "0px",
    backgroundColor: "#f9413e",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  },
  chip: {
    position: "fixed",
    left: "40%"
  },
  nextButton: {
    marginTop: "16px",

    // textTransform: "none",
    position: "absolute",

    fontSize: "16px",
    lineHeight: "32px"
  },
  buttonContainerLtr: {
    right: "0px"
  },
  buttonContainerRtl: {
    left: "0px"
  },
  eval: {
    color: "#168fee",
    "&::placeholder": {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#bdbdbd"
    }
  },
  li: {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  feedbackCard: {
    fontSize: "16px",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    paddingLeft: "16px",
    paddingTop: "16px",
    paddingRight: "16px",
    paddingBottom: "16px"
    // background: "white",
  },
  answerCard: {
    // background: "#e0e0e0",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    fontSize: "16px",
    paddingLeft: "16px",
    paddingTop: "16px",
    paddingRight: "16px",
    paddingBottom: "16px"
  },
  panelDetails: {},
  panelDetailsRtl: {
    paddingRight: "16px",
    marginLeft: "16px"
  },
  panelDetailsLtr: {
    paddingLeft: "16px",
    marginRight: "16px"
  },
  questionNumTitle: {
    color: "#BCD9F1",
    textTransform: "none"
  },
  question: {
    fontSize: "16px",
    marginTop: "8px",
    marginBottom: "16px",
    textTransform: "none",
    whiteSpace: "pre-wrap"
  },
  panelStageName: {
    //    marginTop:'8px',
  },
  label: {
    textTransform: "none",
    fonrSize: "16px",
    width: "100%"
  },
  instructions: {
    marginTop: "16px",
    fontSize: "16px",
    color: "#e0e0e0"
  },
  fontStyle: {
    fontSize: "16px"
  },
  quoteText: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"'
  },
  answerText: {
    textTransform: "none"
  },
  btn: {
    marginLeft: "8px",
    marginRight: "8px"
  },
  sideComment: {
    position: "absolute",
    left: "calc(40% + 15px)"
  },
  multiChoice: {
    maxWidth: "calc(100% - 66px)"
  },
  commentText: {
    fontSize: "20px",
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    width: "100%",
    resize: "none",
    border: "none"
  },
  buttonContainer2: {
    marginTop: "16px",
    position: "absolute",
    width: "80px",
    marginBottom: "16px"
  },
  questionTitle: {
    fontSize: "0.75rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "20px 0",
    textTransform: "uppercase",
    color: "#BCD9F1"
  }
}));

export default function FeedbackQuestionPanel(props) {
  //export default function TabPanel(props) {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();
  const { index, value, isTeacher, question, answer, score, showMatch ,  teacherAnswer} = props;

  const text = useSelector(state => state.texts.selectedText);
  const [selectedQuote, setSelectedQuote] = useState(false);

  const renderAnswer = () => {
    // console.log("renderAnsewr",props);
    let matchScore = score ? score.toFixed(1).toString() : 0;

    return (
      <Box style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        {isTeacher &&
          showMatch &&
          teacherAnswer?.quotes && teacherAnswer?.quotes.length &&
          (question.includeCitation || question.type === 0) && (
            <Chip
              onClick={() => {
                dispatch(setClickedMatch(true));
              }}
              className={classes.match}
              label={
                matchScore +
                "% " +
                intl.formatMessage({
                  defaultMessage: "match",
                  id: "task.feedback.match"
                })
              }
            />
          )}
        <List>
          {answer.quotes &&
            answer.quotes.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  ContainerComponent="li"
                  className={classes.li}
                >
                  <Card
                    className={clsx(classes.card, classes.cardLtr)}
                    elevation={1}
                  >
                    <Typography className={classes.quoteText}>
                      {" "}
                      {item.text}
                    </Typography>
                    <Typography
                      onClick={() => {
                        setSelectedQuote(item);
                      }}
                      className={clsx(classes.quoteRef, classes.left)}
                    >
                      <FormattedMessage
                        id="show.text"
                        defaultMessage="Show in text"
                      />
                    </Typography>
                  </Card>
                </ListItem>
              );
            })}
        </List>
      </Box>
    );
  };

  const getQuestionTypeText = () => {
    let validType = questionTypes.filter(el => el.id === question.type);
    if (validType && validType.length) {
      //TODO : Add intl for include citations
      let includeCitationMsg = question.includeCitation
        ? `, ${intl.formatMessage({
            id: "task.includeCitation",
            defaultMessage: "Include citations"
          })}`
        : "";
      return validType[0].txt + includeCitationMsg;
    } else return "";
  };

  const questionTypes = [
    {
      id: 0,
      txt: intl.formatMessage({
        id: "task.type.find",
        defaultMessage: "Find in text"
      })
    },
    {
      id: 1,
      txt: intl.formatMessage({
        id: "task.type.open",
        defaultMessage: "In your own words"
      })
    },
    {
      id: 2,
      txt: intl.formatMessage({
        id: "task.type.multiple",
        defaultMessage: "Multiple choice"
      })
    }
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          <Box
            className={clsx(classes.panelHeader, classes.panelHeaderLtr)}
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              className={clsx(classes.questionTitle, classes.questionLtr)}
            >
              {/* <FormattedMessage id="task.question" defaultMessage="Question"/> {index+1} */}
              {getQuestionTypeText()}
            </Typography>

            <Box className={classes.panelStageName}>
              <Typography
                variant="body1"
                className={clsx(classes.question, classes.questionLtr)}
              >
                {question.question}
              </Typography>
              <Divider className={classes.divider2} />
              {renderAnswer()}
            </Box>
          </Box>
        </Box>
      )}

      {selectedQuote && (
        <CitationDialog
          setOpenDialog={setSelectedQuote}
          url={text.link || text.url}
          location={selectedQuote.cfi}
          highlights={[selectedQuote]}
          openDialog={!!selectedQuote}
        />
      )}
    </div>
  );
}
