// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";
import clsx from "clsx";
import { useParams, Redirect } from "react-router-dom";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { validateFeedback } from "../../../redux/taskSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
// Components
import RichTextEditor from "../../SharedComponents/RichTextEditor";
import PeerReviewMissingReviewModal from "./PeerReviewMissingReviewModal";
import PeerReviewFeedbackValidationModal from "./PeerReviewFeedbackValidationModal";
import PeerReviewTabs from "./PeerReviewTabs";
import PeerReviewReply from "./PeerReviewReply";
import PeerReviewTabPanels from "./PeerReviewTabPanels";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import ScrollBox from "../../SharedComponents/ScrollBox";
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  tabs: {
    top: "0px",
    position: "sticky",
    zIndex: 2
  },
  grid: {
    height: "calc(100% - 50px)", //subtract the tabs
    width: `calc(100% - ${theme.spacing(3)}px)`, //subtract the margins
    marginInlineStart: theme.spacing(3),
    flexWrap: "nowrap"
  },
  textContainer: {
    top: "0px",
    height: "100%",
    fontSize: "22px"
  },
  feedbackContainer: {
    background: "rgba(0,0,0, 0.08)",
    height: "100%"
  },
  responseContainer: {
    height: "100%",
    "& $header": { color: theme.palette.text.blue }
  },
  feedback: {
    marginBlockStart: theme.spacing(4.5),
    color: "#1179CA",
    "& $header": { paddingInlineStart: theme.spacing(2.5) }
  },

  response: {
    marginBlockStart: theme.spacing(4.5),
    paddingInlineStart: theme.spacing(2)
  },
  outlinedOnHover: {
    "&:hover": {
      paddingInline: "23px",
      paddingBlock: "15px",
      border: `1px solid ${theme.palette.divider}`
    }
  },

  header: {
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px"
  },
  userInput: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    fontSize: 16,
    lineHeight: "24px"
  }
}));

export default function PeerReviewFeedback({
  index,
  feedbackComment,
  submitFeedback,
  feedback,
  updateFeedback,
  fetchedFeedback,
  reflection,
  response,
  setResponse,
  reply,
  setReply,
  readText,
  currentTab,
  setCurrentTab,
  zenMode,
  setZenMode,
  rendition,
  onRenditionCreated,
  saveCurrentTabLocation,
  textBookmark,
  answerBookmark,
  answerRef,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const editorRef = useRef();
  const { submission_id } = useParams();

  // Redux state
  const task = useSelector(state => state.task.taskRow);
  const currentUser = useSelector(state => state.user.auth);
  const feedbackTask = useSelector(state => state.task.feedbackTask);
  const readerActions = useSelector(state => state.readerActions);

  const userRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );

  const submissionStatus = useSelector(
    state => state.task.taskRow.submission_status
  );

  const showValidateFeedback = useSelector(
    state => state.task.validateFeedback
  );

  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );

  // Ephemeral state
  const [modalVisible, setModalVisible] = useState(false); // Modal for Missing Review
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [validationResult, setValidationResult] = useState({
    comment: []
  });

  // Variables
  let formattedAcceptDate =
    feedbackTask?.accept_date &&
    getDate(feedbackTask?.accept_date).setHours(24);

  const today = new Date();

  function getDate(str) {
    let b = str.split(/\D/);
    let d = new Date(b[0], b[1] - 1, b[2]);
    return d;
  }
  // Behavior
  // set breadcrumbs
  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        ...readerActions,
        blue: true,
        showTextMenu: true,
        showSendFeedback:
          submissionStatus === "Submitted" ||
          submissionStatus === "Graded" ||
          (submissionStatus === "pendingResponse" &&
            formattedAcceptDate < today),

        showSubmitTask:
          (submissionStatus !== "Graded" &&
            selectedCourse.course_role.toLowerCase() === "student") ||
          (submissionStatus === "pendingResponse" &&
            selectedCourse.course_role === "Teacher" &&
            formattedAcceptDate > today)
      })
    );
  }, [submissionStatus, selectedCourse, formattedAcceptDate, today]);

  const validateForm = useCallback(() => {
    // Return and object with arrays of invalid answers and citations
    const comment = [];

    if (feedback) {
      feedback.questions.forEach((question, index) => {
        // the rich text editor returns an array of text lines
        // checkling to see if at least one line isn't empty
        let commentIsValid = question.comment?.blocks?.some(
          element => element.text !== ""
        );

        if (!commentIsValid && (!question.title || question.title === "")) {
          comment.push(index);
        }
      });
      setValidationResult({ comment });

      // if something failed validation - open the dialog
      if (comment.length > 0) {
        setShowValidationDialog(true);
      } else {
        submitFeedback();
      }
    }
  }, [feedback, submitFeedback]);

  useEffect(() => {
    if (showValidateFeedback) {
      validateForm();
      dispatch(validateFeedback(false));
    }
  }, [showValidateFeedback, dispatch, validateForm]);

  function updateEditor(comment, index) {
    let newFeedbackComment = comment;
    let newFeedback = {
      ...feedback,
      questions: feedback.questions.map((q, i) => {
        if (i === index) {
          return { ...q, comment: newFeedbackComment };
        } else return q;
      })
    };
    updateFeedback(newFeedback, index);
  }

  useEffect(() => {
    if (
      submission_id == task.submission_id &&
      selectedCourse.course_role === "Teacher" &&
      submissionStatus === "pendingResponse" &&
      formattedAcceptDate > today
    )
      setModalVisible(true);
  }, [
    selectedCourse.course_role,
    submissionStatus,
    submission_id,
    task.submission_id
  ]);

  if (!reflection && !response && !feedbackComment) {
    return <PangeaSpinner />;
  } else {
    return (
      <>
        <PeerReviewMissingReviewModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
        <PeerReviewFeedbackValidationModal
          feedback={feedback}
          showValidationDialog={showValidationDialog}
          setShowValidationDialog={setShowValidationDialog}
          submitFeedback={submitFeedback}
          validationResult={validationResult}
        />

        <Box className={classes.tabs}>
          <PeerReviewTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            answerBookmark={answerBookmark}
            zenMode={zenMode}
            saveCurrentTabLocation={saveCurrentTabLocation}
            classes={{
              item: classes.tabPanels
            }}
          />
        </Box>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          classes={{ container: classes.grid }}>
          <Grid item xs={6} className={classes.textContainer}>
            <PeerReviewTabPanels
              reflection={reflection}
              readText={readText}
              currentTab={currentTab}
              zenMode={zenMode}
              setZenMode={setZenMode}
              rendition={rendition}
              onRenditionCreated={onRenditionCreated}
              textBookmark={textBookmark}
              answerRef={answerRef}
            />
          </Grid>

          <Grid item xs={3} className={classes.responseContainer}>
            <ScrollBox>
              <Box
                className={clsx(
                  classes.response,
                  currentUser.uid === task.owner &&
                    (task.submission_status === "Submitted" ||
                      task.submission_status === "Graded") &&
                    task.collaboration_status !== "Answered" &&
                    classes.outlinedOnHover
                )}>
                <Box className={classes.header}>
                  {currentUser.uid === task.collaborator_id && `My review`}
                  {currentUser.uid !== task.collaborator_id &&
                    (response?.displayName
                      ? `${response?.displayName}'s review`
                      : `review`)}
                </Box>
                <Box className={classes.userInput}>
                  <RenderEditorContent>{response?.data}</RenderEditorContent>
                </Box>
              </Box>
              <PeerReviewReply reply={reply} setReply={setReply} />
            </ScrollBox>
          </Grid>

          <Grid
            item
            xs={3}
            className={classes.feedbackContainer}
            onClick={() => {
              editorRef?.current && editorRef.current.focus();
            }}>
            <ScrollBox>
              <Box className={classes.feedback}>
                {userRole === "Teacher" ? (
                  <Box className={classes.header}>My feedback</Box>
                ) : (
                  <Box className={classes.header}>Teacher feedback</Box>
                )}

                <Box className={classes.userInput}>
                  {(userRole === "Teacher" &&
                    submissionStatus === "Submitted") ||
                  (userRole === "Teacher" &&
                    submissionStatus === "pendingResponse") ? (
                    <RichTextEditor
                      showWordCount={false}
                      autoFocus={true}
                      subcribeToUnload={true}
                      readOnly={false}
                      onChange={updateEditor}
                      index={index}
                      placeholder={false}
                      comment={feedback?.questions[index]?.comment}
                      wordLimt={false}
                      editorRef={editorRef}
                    />
                  ) : (
                    fetchedFeedback && (
                      <RichTextEditor
                        readOnly={true}
                        comment={fetchedFeedback?.questions[0].comment}
                        wordLimt={false}
                      />
                    )
                  )}
                </Box>
              </Box>
            </ScrollBox>
          </Grid>
        </Grid>
      </>
    );
  }
}
