// Dependencies
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

// Redux dependencies
import { useSelector } from "react-redux";

// Components
import MyAppBar from "./MyAppBar";
import TopBar from "./TopBar";
import Router from "./Router";
import Sidebar from "./Sidebar";
import SubmitTaskModal from "./components/Tasks/TaskAnswer/SubmitTaskModal";
import FlashMessages from "./FlashMessages";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => {
  return {
    header: {
      position: "fixed",
      top: "0",
      width: "100vw",
      transition: "top 0.6s",
      zIndex: theme.zIndex.drawer + 1
    },
    mainContent: {
      display: "flex",
      alignContent: "flex-start",
      height: "100%",
      width: "100%",
      paddingTop: "120px"
      // overflowX: "hidden",
      // overflowY: "auto"
    },
    rtl: {
      direction: "rtl"
    }
  };
});

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Layout() {
  // Hooks
  const classes = useStyles();
  const rtl = useSelector(state => state.user.userProfile.rtl);

  // Ephemeral State

  const [actionBarVisable] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  // Redux State
  const selectedTaskId = useSelector(state => state.task.selectedTaskId);
  const taskAnswers = useSelector(state => state.task.answers);
  const task = useSelector(state => state.task.taskRow);

  // Behavior
  //Render
  return (
    <>
      {task.task_type !== "peerReview" && (
        <SubmitTaskModal
          task={task}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          selectedTaskId={selectedTaskId}
          taskAnswers={taskAnswers}
        />
      )}

      <Box component="header" className={classes.header}>
        <MyAppBar />
        {actionBarVisable && <TopBar setModalVisible={setModalVisible} />}
      </Box>
      <Box
        className={clsx(classes.mainContent)}
        component="main"
        dir={rtl ? "rtl" : "ltr"}
      >
        <Sidebar />

        <Router>
          <ScrollToTop />
        </Router>
      </Box>
      <FlashMessages />
    </>
  );
}
