// Dependencies
import React from "react";

// Redux dependencies

// Components
import PeerReviewReflectionPanel from "./PeerReviewReflectionPanel";
import PeerReviewReaderPanel from "./PeerReviewReaderPanel";

// Material UI

// Styles

export default function PeerReviewTabPanel({
  reflection,
  readText,
  currentTab,
  zenMode,
  setZenMode,
  rendition,
  onRenditionCreated,
  textBookmark,
  answerRef,
  tabPanelRef,
  ...props
}) {
  // Hooks

  // Redux state

  // Ephemeral state

  // Variables

  // Behavior

  //render
  if (zenMode) {
    return null;
  } else if (currentTab === 0) {
    return (
      <PeerReviewReflectionPanel
        reflection={reflection}
        answerRef={answerRef}
      />
    );
  } else {
    return (
      <PeerReviewReaderPanel
        readText={readText}
        rendition={rendition}
        onRenditionCreated={onRenditionCreated}
        textBookmark={textBookmark}
        tabPanelRef={tabPanelRef}
      />
    );
  }
}
