// Dependencies
import React from "react";
import { useIntl } from "react-intl";
import { useDrag } from "react-dnd";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r } from "../../redux/firebaseMiddleware";
import { selectLocation, setQuestions } from "../../redux/grSlice";
import { setLocation } from "../../redux/readerActionsSlice";

// Components
import QuestionTitle from "../SharedComponents/InlineEditTextarea";
import useGetTheme from "../../hooks/useGetTheme";

// Material UI
import DeleteFilledIcon from "@material-ui/icons/Delete";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Link,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    flexBasis: "100%",
    "&:hover ,&:focus-within": {
      "& $deleteBtn": { visibility: "visible" }
    }
  },
  CardContent: {
    paddingBottom: 0
  },
  quoteTitle: {
    minHeight: "36px",
    padding: "0",
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"',
    fontSize: "14px",
    background: "none",
    opacity: "0.7",
    marginBottom: "8px"
  },
  quoteText: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    fontSize: "16px",
    maxHeight: "148px",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    textAlign: "start"
  },
  cardActions: {
    justifyContent: "space-between"
  },
  showQuoteBtn: { padding: "8px" },

  deleteBtn: { visibility: "hidden" }
}));

export function QuestionQuoteCard({
  item,
  question,
  index,
  onDelete,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useGetTheme();

  // Redux State
  const highlights = useSelector(state => state.gr.highlights);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const questions = useSelector(state => state.gr.questions);
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const sourceTextLanguage = useSelector(
    state => state.texts.selectedText?.text_language
  );

  //Variables
  const cardItem = item;
  const ANSWER_ITEM = "GR.Answer";

  // Behavior
  const removeAnswer = (question, quote) => {
    let items = question.answers.filter(a => a.cfi !== quote.cfi);
    let updatedQuestions = questions.map(el => {
      return el.id === question.id ? { ...question, answers: items } : el;
    });

    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );

    onDelete(question, quote);
  };

  const [, drag] = useDrag({
    end(item, monitor) {},
    start(item, monitor) {},
    item: { type: ANSWER_ITEM, id: cardItem.cfi, index, item: cardItem },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const onTitleChange = (question, item, value) => {
    let answers = question.answers.map(el => {
      if (el.cfi === item.cfi) {
        return { ...el, title: value };
      } else return el;
    });

    let updatedQuestions = questions.map(q => {
      if (q.id === question.id) {
        return { ...q, answers: answers };
      } else return q;
    });
    // update redux store first in order to avoide flickering text
    dispatch(setQuestions(updatedQuestions));
    // then update firestore
    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Card ref={drag} className={classes.card} elevation={1}>
        <CardContent className={classes.CardContent}>
          <Typography className={classes.quoteTitle}>
            <QuestionTitle
              text={item.title ? item.title : ""}
              onChange={value => onTitleChange(question, item, value)}
              placeholder={intl.formatMessage({
                id: "card.title",
                defaultMessage: "title"
              })}
            />
          </Typography>
          <Box
            className={classes.quoteText}
            style={{ direction: sourceTextLanguage === "he" ? "rtl" : "ltr" }}>
            {item.text}
          </Box>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Link
            color="secondary"
            component="button"
            className={classes.showQuoteBtn}
            onClick={() => {
              if (grMode === "full") {
                if (stage < 4) {
                  dispatch(setLocation({ ...item, questionId: question.id }));
                } else {
                  dispatch(
                    selectLocation({ ...item, questionId: question.id })
                  );
                }
              } else {
                // grMode is light
                if (stage < 2) {
                  dispatch(setLocation({ ...item, questionId: question.id }));
                } else {
                  dispatch(
                    selectLocation({ ...item, questionId: question.id })
                  );
                }
              }
            }}>
            {intl.formatMessage({
              id: "show.text",
              defaultMessage: "show in text"
            })}
          </Link>

          {stage < 4 && (
            <IconButton
              onClick={e => {
                removeAnswer(question, item);
                e.stopPropagation();
              }}
              className={classes.deleteBtn}
              aria-label="delete quote">
              <DeleteFilledIcon />
            </IconButton>
          )}
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}
