import React, { useRef, useEffect } from "react";
import { InputBase } from "@material-ui/core";

export default function PangeaInputBase(props) {
  const myRef = useRef();
  useEffect(() => {
    myRef.current
      .getElementsByTagName("textarea")[0]
      .setAttribute("dir", "auto");
  }, []);
  return <InputBase {...props} ref={myRef} />;
}
