import React, { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  List,
  Paper,
  ListItem,
  InputBase,
  Typography,
  //Divider,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { updateThemes, getNextId } from "../../redux/firebaseMiddleware";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#616161",
    marginBottom: "12px",
  },
  panelHeader: {
    color: "white",
    width: "100%",
    paddingLeft: "16px",
    textAlign: "left",
  },
  li: {
    // height:'50px',
    cursor: "pointer",
    borderBottom: "1px solid #616161",
    "&:hover": {
      borderBottom: "1px solid #5ec891",
    },
    "& button": {
      position: "absolute",
      right: "0px",
      color: "#5ec891",
      visibility: "hidden",
    },
    "&:hover button": {
      visibility: "visible",
    },
    "&:focus button": {
      visibility: "visible",
    },
  },
  addTheme: {
    justifyContent: "left",
    // borderRadius: '6px',
    //  border:'1px solid #5ec891'
  },
  addThemeLtr: {
    justifyContent: "left",
  },
  addThemeRtl: {
    justifyContent: "right",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fontSize: {
    fontSize: "16px",
  },
  highSpan: {
    // height: '24px',
    margin: "0",
    padding: "0px 0 0px",
    fontSize: "16px",
    //  box-sizing: border-box;
    //align-items: center;
    lineHeight: "24px",
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "32px",
    textTransform: "uppercase",
  },

  white: {
    "&.Mui-disabled": {
      color: "white",
    },
    color: "white",
  },
  fullLine: {
    width: "calc(100% - 24px)",
    minHeight: "20px",
  },

  themeContainer: {
    borderRadius: "4px",
    position: "fixed",
    width: "320px",
    maxHeight: "360px",
    backgroundColor: "#333333",
    zIndex: "1500",
    overflow: "auto",
    //  boxShadow:'0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    // '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px',
  },
}));

function ThemeBox(props) {
  const rtlValue = useSelector((state) => state.user.userProfile.rtl);
  const classes = useStyles();
  const inputRef = useRef();
  const themes = useSelector((state) => state.themes.themes);
  const selectedText = useSelector((state) => state.texts.selectedTextId);

  const [newTheme, setNewtheme] = useState({ addMode: false, name: "" });

  // const defaultColor ='#91fcc2';

  const onChange = (event) => {
    setNewtheme({ addTheme: true, name: event.target.value });
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  });

  const onKeyPress = (ev) => {
    if (ev.key === "Enter") {
      // Do code here
      if (!newTheme.name) {
        setNewtheme({ addTheme: false, name: "" });
      } else {
        props.setTheme && props.setTheme(newTheme);
      }
      ev.preventDefault();
    }
  };

  const renderAddTheme = () => {
    if (!newTheme.addTheme) return "";
    else
      return (
        <ListItem
          tabIndex={0}
          onClick={() => {
            //  console.log("clicked ontb");
            props.setTheme && props.setTheme({ name: newTheme.name });
          }}
          className={classes.li}
        >
          {" "}
          <InputBase
            inputRef={inputRef}
            multiline={true}
            className={clsx(classes.white, classes.fullLine, classes.fontSize)}
            autoFocus={true}
            value={newTheme.name}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </ListItem>
      );
  };

  const dispatch = useDispatch();
  let boxY =
    Number(props.clientRect.y) + 360 > window.innerHeight
      ? { top: "calc(100vh - 360px)" }
      : { top: props.clientRect.y };

  let styleBox = rtlValue
    ? { ...boxY, left: "32px" }
    : { ...boxY, right: "32px" };
  return (
    <Paper elevation={4} className={classes.themeContainer} style={styleBox}>
      <Box className={classes.panelHeader}>
        <Typography
          className={clsx(classes.panelTitle, classes.left)}
          color="textPrimary"
        >
          <FormattedMessage
            id="actionBar.tooltip.themes"
            defaultMessage="THEMES"
          />
        </Typography>
      </Box>
      <Box className={classes.panelHeader}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            if (newTheme.name !== "") {
              dispatch(
                updateThemes({
                  textId: selectedText,
                  themes: [
                    ...themes,
                    { id: getNextId(themes), name: newTheme.value, quotes: [] },
                  ],
                })
              );
            }
            setNewtheme({ addTheme: true, name: "" });
          }}
          className={clsx(classes.addTheme, classes.addThemeLtr)}
        >
          <FormattedMessage id="theme.addTheme" defaultMessage="Add Theme" />
        </Button>
      </Box>

      <List>
        {themes.map((item, index) => (
          <ListItem
            key={item.id}
            onClick={() => props.setTheme && props.setTheme(item)}
            ContainerComponent="li"
            className={classes.li}
          >
            <span
              className={clsx(
                classes.highSpan,
                classes.white,
                classes.fullLine
              )}
            >
              {item.name ? item.name : item.placeholder}
            </span>
          </ListItem>
        ))}
        {renderAddTheme()}
      </List>
    </Paper>
  );
}

export default ThemeBox;
