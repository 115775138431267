// Dependencies
import React, { useState } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

// Redux
import { setAnnotatorMode, setAction } from "../../redux/readerActionsSlice";
import { setThemeMode } from "../../redux/themeSlice";

// Components
import PocBar from "../../components/poc/pocBar";
import ThemesContainer from "../../components/themes/ThemesContainer";
// import CommentBar from "../../components/comments/CommentBar";
import TaskSideBar from "../../components/Tasks/TaskAnswer/TaskSideBar";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Box, Tooltip } from "@material-ui/core";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import LabelImportantOutlinedIcon from "@material-ui/icons/LabelImportantOutlined";
// import InsertCommentIcon from "@material-ui/icons/InsertComment";
import AssignmentIcon from "@material-ui/icons/Assignment";

// Styles
const useStyles = makeStyles(theme => {
  return {
    drawerLtr: {
      left: 0
    },
    drawer: {
      display: "flex",
      background: "black",
      transition: ".3s ease-out",
      flexShrink: 0
    },
    drawerOpen: {
      height: "100%",
      width: 384,
      [theme.breakpoints.up("desktop")]: {
        width: 468
      }
    },
    drawerClosed: {
      width: "48px"
    },

    drawerButton: {
      fontSize: "24px",
      color: theme.palette.grey["400"],
      "&:hover": {
        color: theme.palette.grey["050"]
      }
    },
    selected: { color: "#FFFFFF" },
    blueColor: {
      color: "#168FEE"
    },
    expandedView: {
      width: "100%",
      backgroundColor: "rgb(51, 51, 51)",
      overflow: "auto",
      overflowX: "hidden"
    },
    sideButtons: {
      display: "flex",
      flexDirection: "column"
    }
  };
});

export default function ReaderSidebar({ ...props }) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  let { task_id } = useParams();
  // Ephemeral State

  // Redux State
  const action = useSelector(state => {
    return state.readerActions.persistentActionState.actionBar;
  });
  const selectedText = useSelector(state => state.texts.selectedText);
  const task = useSelector(state => state.task.taskRow);
  const ActiveTaskUrl = useSelector(state => state.texts.activeTask);
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  const selectedTheme = useSelector(state => {
    let filtered = state.themes.themes.filter(
      q => q.id === state.themes.selectedThemeId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });

  const toggleAction = wantedAction => {
    if (wantedAction === action) {
      dispatch(setAction(""));
      if (annotatorMode !== "highlight") {
        dispatch(setAnnotatorMode(""));
      }
    } else {
      if (annotatorMode !== wantedAction) {
        dispatch(setAnnotatorMode(""));
      }
      dispatch(setAction(wantedAction));
      if (wantedAction === "themes") {
        dispatch(setThemeMode(""));
        if (selectedTheme && selectedTheme.id) {
          dispatch(setAnnotatorMode("themes"));
        }
      }
    }
  };

  return (
    <>
      <div
        id="side-bar"
        className={clsx(
          classes.drawer,
          classes.drawerLtr,
          Boolean(action) ? classes.drawerOpen : classes.drawerClosed
        )}
      >
        <Box className={classes.sideButtons}>
          <Box className={classes.guidedReadingBtn}>
            <Tooltip
              title={intl.formatMessage({
                id: "actionBar.tooltip.gr",
                defaultMessage: "Guided reading"
              })}
              arrow
              placement={rtl ? "left" : "right"}
            >
              <IconButton
                onClick={() => toggleAction("poc")}
                className={clsx(
                  classes.drawerButton,
                  action === "poc" && classes.selected
                )}
                aria-current={action === "poc" && "location"}
              >
                <LiveHelpIcon lable="Guided reading" />
              </IconButton>
            </Tooltip>
          </Box>

          <Box className={classes.themesBtn}>
            <Tooltip
              title={intl.formatMessage({
                id: "actionBar.tooltip.themes",
                defaultMessage: "Themes"
              })}
              arrow
              placement={rtl ? "left" : "right"}
            >
              <IconButton
                onClick={() => {
                  toggleAction("themes");
                }}
                className={clsx(
                  classes.drawerButton,
                  classes.drawerButton,
                  action === "themes" && classes.selected
                )}
                aria-current={action === "themes" && "location"}
              >
                <LabelImportantOutlinedIcon aria-label="Themes" />
              </IconButton>
            </Tooltip>
          </Box>
          {/*** DO NOT DELETE THIS BLOCK !
           * Comments icon removed for now - feaure will be added later on
           */}
          {/* <Box className={classes.commentsBtn}>
            <Tooltip
              title={intl.formatMessage({
                id: "actionBar.tooltip.comments",
                defaultMessage: "Comments"
              })}
              arrow
              placement={rtl ? "left" : "right"}
            >
              <IconButton
                onClick={() => toggleAction("comments")}
                className={clsx(
                  classes.drawerButton,
                  classes.drawerButton,
                  action === "comments" && classes.selected
                )}
                aria-current={action === "comments" && "location"}
              >
                <InsertCommentIcon aria-label="Comments" />
              </IconButton>
            </Tooltip>
          </Box> */}
          {ActiveTaskUrl && (
            <>
              <Box className={classes.taskBtn}>
                <Tooltip
                  title={intl.formatMessage({
                    id: "actionBar.tooltip.task",
                    defaultMessage: "Task"
                  })}
                  arrow
                  placement={rtl ? "left" : "right"}
                >
                  <IconButton
                    onClick={() => {
                      // dispatch(setShowAlternativeView(false));

                      toggleAction("task");
                      history.push(ActiveTaskUrl);
                    }}
                    className={clsx(
                      classes.drawerButton,
                      action === "task" && classes.selected,
                      action === "task" && task && classes.blueColor
                    )}
                    aria-current={action === "task" && "location"}
                  >
                    <AssignmentIcon aria-label="Task" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>

        {action === "task" && (
          <Box className={classes.expandedView}>
            <TaskSideBar readText={selectedText} />
          </Box>
        )}

        {action === "poc" && (
          <Box className={classes.expandedView}>
            <PocBar />
          </Box>
        )}

        {action === "themes" && (
          <Box className={classes.expandedView}>
            <ThemesContainer readText={selectedText} />
          </Box>
        )}

        {/* {action === "comments" && (
          <Box className={classes.expandedView}>
            <CommentBar />
          </Box>
        )} */}
        {/* </div> */}
      </div>
    </>
  );
}
