import React, { useEffect, useState } from "react";

import { firebaseFunctions, firebaseApp } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
//import FileUploader from "react-firebase-file-uploader";
import { useHistory } from "react-router-dom";
import {
  //Fab,
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  Select,
  MenuItem,
  InputLabel,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100w",
    height: "100vh",
    // background:'white',
  },
  dialog: {
    backgroundColot: "red",
  },
  tableContainer: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor:'white',
    marginBottom: "24px",
  },
  selectEmpty: {
    width: "80%",
  },
  tableHead: {
    fontWeight: "800",
  },
}));

function CourseTasks(props) {
  const classes = useStyles();

  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    var getAllCourseTasks = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourseTasks"
    );

    // console.log("getting course texts");
    getAllCourseTasks({ course: props.courseId }).then((response) => {
      // console.log("got texts ", response);
      let rows = response.data;
      setTasks(rows);
    });

    // console.log("using effect in course texts");
  }, [props.courseId]);

  const history = useHistory();

  return (
    <Box>
      <Typography>Tasks</Typography>
      <TableContainer
        className={classes.tableContainer}
        // component={Paper}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                ID
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Task Name
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Description
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Deadline
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Accept until
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                All Users
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Submitted Count
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  onClick={(e) => {
                    history.push("adminTaskView/" + row.id);
                  }}
                >
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row"
                  >
                    {row.id}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.cell} align="left"></TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.due_day}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.accept_day}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.task_users}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.submitted_count}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CourseTasks;
