import { createSlice,
    // current 
  } from '@reduxjs/toolkit';

  import {logout} from './userSlice';
  export const coursesSlice = createSlice({
    name: 'comments',
    initialState: {
      courses: [],
      lastUpdate: 0,
     },
     extraReducers:{
      "user/logout":(state,value)=>{
        state.courses = [];
        state.lastUpdate = 0 ;
      },
      "user/setAuth":(state,value)=>{
        state.courses = [];
        state.lastUpdate = 0 ;
      },
     },
    reducers: {
     
      updateCourses: (state,value) => {
        state.courses = value.payload;
        state.lastUpdate = new Date().getTime();
      },
      
    },
  });
  
  export const {  updateCourses,  } = coursesSlice.actions;
   //   openAnnotatorBar  ,closeAnnotatorBar, onBarShown, onBarClosed} = highlightSlice.actions;
  
  
  
  export default coursesSlice.reducer;
  