// Dependencies
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
// Material UI
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography, Paper, Divider } from "@material-ui/core";

import {
  PieSeries,
  Chart,
  Title,
  Legend,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation, Palette, EventTracker } from "@devexpress/dx-react-chart";

// Styles
const styles = (theme) => ({
  titleText: {
    lineHeight: "1",
    marginBottom: "0",
    padding: "24px 0",
    fontWeight: "bold",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  legend: {
    display: "flex",
    justifyContent: "space-around",
  },
  item: {
    width: "100%",
    padding: "24px 0",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    "&:last-child": {
      borderRight: "none",
    },
  },
  chart: {
    padding: "0",
  },
  label: {
    fontSize: "16px !important",
    display: "inline-block",
    textAlign: "left",
    verticalAlign: "middle",
    "& span": {
      fontSize: "16px !important",
    },
  },
  legendContainer: {
    width: "100%",
    // padding: "24px 0",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
});

const Marker = withStyles(styles)(({ color, ...restProps }) => {
  return (
    <svg
      style={{ verticalAlign: "middle" }}
      fill={color}
      width="24"
      height="24"
      {...restProps}
    >
      <circle r={12} cx={12} cy={12} {...restProps} />
    </svg>
  );
});

const Label = withStyles(styles)(({ classes, ...restProps }) => (
  <Legend.Label {...restProps} className={classes.label} />
));

const TitleText = withStyles(styles)(({ classes, ...restProps }) => (
  <Title.Text {...restProps} className={classes.titleText}>
    <Box component="header">
      <Typography variant="h5">{restProps.text}</Typography>
    </Box>
    <Divider />
  </Title.Text>
));

const ChartRoot = withStyles(styles)(({ classes, ...restProps }) => (
  <Chart.Root className={classes.chart} {...restProps} />
));

const RootWithTitle = withStyles(styles)(({ classes, ...restProps }) => (
  <Box className={classes.legendContainer}>
    <Box className={classes.legend} {...restProps} />
  </Box>
));

const Item = withStyles(styles)(({ classes, ...restProps }) => (
  <Box {...restProps} className={classes.item} />
));

export default function TimeManagmentStats({ generalStatsHeight,text,task, submissions, ...props }) {
  // Ephemeral State
  const [timeManagement, setTimeManagement] = useState([]);

  // Helpers
  useEffect(()=>{
    //let participants = submissions.length;
    let deadline = task && moment.tz(task.accept_day || task.due_day, task.timezone).endOf('day').toDate()
    let submitted = submissions.filter((a) => a.submission_date);
    let now = new Date();
    let pending = submissions.filter(
      (a) => !a.submission_date && now < deadline
    ).length;

    let late = submitted.filter(
      (a) => new Date(a.submission_date) > deadline
    ).length;

    let missed = submissions.filter(
      (a) => !a.submission_date && now > deadline
    ).length;

    let ontime = submissions.filter(
      (a) => a.submission_date && new Date(a.submission_date) <= deadline
    ).length;

    if (now > deadline) {
      setTimeManagement([
        { cat: "Submitted", val: ontime, color: "#168fee" },
        { cat: "Late", val: late, color: "#BCD9F1" },
        { cat: "Missed", val: missed, color: "#FF6432" },
        { },
      ]);
    } else
    setTimeManagement([
      { cat: "On time", val: ontime, color: "#168fee" },
      
      { cat: "Pending", val: pending, color: "#BCD9F1" },
    ]);

  },[task, submissions])

  return (
    <Paper elevation={0}>
      <Chart
        rootComponent={ChartRoot}
        height={generalStatsHeight}
        // data={timeManagement}
        data={timeManagement}
      >
        <Palette scheme={timeManagement.map(el=> el.color)} />
        <PieSeries
          valueField="val"
          argumentField="cat"
          outerRadius={0.8}
          innerRadius={0.6}
        />
        <Title text="Time management" textComponent={TitleText} />
        <Animation />
        <Legend
          position="bottom"
          markerComponent={Marker}
          rootComponent={RootWithTitle}
          itemComponent={Item}
          labelComponent={Label}
        />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Paper>
  );
}
