// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";

// Redux dependencies
import { useSelector } from "react-redux";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography, Paper, Divider } from "@material-ui/core";

import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation } from "@devexpress/dx-react-chart";

const styles = (theme) => ({
  titleText: {
    lineHeight: "1",
    padding: "24px 0",
    marginBottom: "0",
    fontWeight: "bold",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  emptyChart: {
    padding: "0",
    "& #top-container": {
      flexDirection: "column !important",
      "&::after": {
        content: `"No grade distribution yet"`,
        fontSize: "20px",
        paddingTop: "36px",
        display: "absolute",
        color: "#D8D8D8",
      },
    },
    "& #center-container": {
      padding: "24px",
    },
  },
  chart: {
    padding: "0",
    "& #center-container": {
      padding: "24px",
    },
  },
  argumentAxisLabel: {
    fontSize: "16px !important",
    transform: "rotate(-90deg)",
  },
  rtlArgumentAxisLabel: {
    fontSize: "16px !important",
    transform: "rotate(90deg)",
  },
  argumentAxisRoot: {
    padding: "20px",
  },
});

const TitleText = withStyles(styles)(({ classes, ...restProps }) => (
  <Title.Text {...restProps} className={classes.titleText}>
    <Box component="header">
      <Typography variant="h5">{restProps.text}</Typography>
    </Box>
    <Divider />
  </Title.Text>
));

const ChartRoot = withStyles(styles)(({ classes, ...restProps }) => (
  <Chart.Root className={classes.chart} {...restProps} />
));

const EmptyChartRoot = withStyles(styles)(({ classes, ...restProps }) => (
  <Chart.Root className={classes.emptyChart} {...restProps} />
));

const ArgumentAxisRoot = withStyles(styles)(({ classes, ...restProps }) => (
  <>
    <Box className={classes.argumentAxisRoot}></Box>
    <ArgumentAxis.Root {...restProps} />
  </>
));

const ArgumentAxisLabel = withStyles(styles)(
  ({ classes, x, y, dy, dx, textAnchor, ...restProps }) => (
    <ArgumentAxis.Label
      // this is because we are rotating the labels
      x={y}
      y={x}
      dx="-2em"
      dy="0.3em"
      textAnchor="end"
      className={classes.argumentAxisLabel}
      {...restProps}
    />
  )
);

const RtlArgumentAxisLabel = withStyles(styles)(
  ({ classes, x, y, dy, dx, textAnchor, ...restProps }) => (
    <ArgumentAxis.Label
      // this is because we are rotating the labels
      x={y}
      y={x}
      dx="-2em"
      dy="0.3em"
      textAnchor="end"
      className={classes.rtlArgumentAxisLabel}
      {...restProps}
    />
  )
);

export default function GradeDistributionStats({
  submissions,
  text,
  task,
  generalStatsHeight,
  ...props
}) {
  // Hooks
  const rtl = useSelector((state) => state.user.userProfile.rtl);

  // Ephemeral State
  const [hist, setHist] = useState([]);

  useEffect(() => {
    let graded = submissions.filter((a) => a.is_checked);
    if (graded.length > 0 && graded[0].totalpoints) {
      let currHist = [
        {
          grade: "0-50",
          val: graded.filter((a) => a.grade < 0.5 * a.totalpoints).length,
        },
        {
          grade: "50-60",
          val: graded.filter(
            (a) =>
              a.grade < 0.6 * a.totalpoints && a.grade >= 0.5 * a.totalpoints
          ).length,
        },
        {
          grade: "60-70",
          val: graded.filter(
            (a) =>
              a.grade < 0.7 * a.totalpoints && a.grade >= 0.6 * a.totalpoints
          ).length,
        },
        {
          grade: "70-80",
          val: graded.filter(
            (a) =>
              a.grade < 0.8 * a.totalpoints && a.grade >= 0.7 * a.totalpoints
          ).length,
        },
        {
          grade: "80-90",
          val: graded.filter(
            (a) =>
              a.grade < 0.9 * a.totalpoints && a.grade >= 0.8 * a.totalpoints
          ).length,
        },
        {
          grade: "90-100",
          val: graded.filter((a) => a.grade >= 0.9 * a.totalpoints).length,
        },
      ];

      setHist(currHist);
    } else setHist([]);
  }, [task, submissions]);

  return (
    <Paper elevation={0}>
      {hist && hist.length > 0 && (
        <Chart
          rootComponent={ChartRoot}
          height={generalStatsHeight}
          data={hist}
        >
          <ArgumentAxis
            showTicks={false}
            showLine={false}
            labelComponent={rtl ? RtlArgumentAxisLabel : ArgumentAxisLabel}
            rootComponent={ArgumentAxisRoot}
          />

          <BarSeries valueField="val" argumentField="grade" color="#168fee" />
          <Title text="Grade distribution" textComponent={TitleText} />
          {/* <Animation /> */}
        </Chart>
      )}
      {(!hist || hist.length === 0 || !hist.length) && (
        <Chart
          rootComponent={EmptyChartRoot}
          height={generalStatsHeight}
          data={[
            { grade: "0-50", val: "1" },
            { grade: "50-60", val: "1" },
            { grade: "60-70", val: "1" },
            { grade: "70-80", val: "1" },
            { grade: "80-90", val: "1" },
            { grade: "90-100", val: "1" },
          ]}
        >
          <ArgumentAxis
            showTicks={false}
            showLine={false}
            labelComponent={rtl ? RtlArgumentAxisLabel : ArgumentAxisLabel}
            rootComponent={ArgumentAxisRoot}
          />

          <BarSeries valueField="val" argumentField="grade" color="#D8D8D8" />
          <Title text="Grade distribution" textComponent={TitleText} />
          {/* <Animation /> */}
        </Chart>
      )}
    </Paper>
  );
}
