import { createSlice } from '@reduxjs/toolkit';



export const linkSlice = createSlice({
  name: 'linking',
  initialState: {
    linkType :'',
    selected:false,
    selectionContext:'',
    currentLink:'',
    linkTarget:'',
    linkContent:null,

  },
  extraReducers: {
   "task/setQuestionIndex":(state,value)=>{
    state.selectionContext='';
    state.linkType='';
    state.currentLink = '';
    state.selectionContext ='';
    state.linkTarget ='';
    state.linkContent = null;
   }
  },
  reducers: {
          setSelection:(state, value)=> {
        state.selected = value.payload.selected;
        state.selectionContext = value.payload.selectionContext;
      },
    setLinkType:(state,value)=>{
        state.linkType = value.payload;
    },
    setLinkContent:(state,value)=>{
      state.linkContent = value.payload;
    },
    setLinkTarget:(state,value)=>{
        state.linkTarget = value.payload;
    },
    setCurrentLink:(state,value)=>{
      state.currentLink = value.payload;
    },
    resetLink:(state,value)=>{
     // console.log("reset link info");
        state.selectionContext='';
        state.currentLink =0;
        state.linkType='';
        state.selectionContext ='';
        state.linkTarget ='';
        state.linkContent = null;
    }
  },
});

export const { setSelection,setLinkContent,setCurrentLink,  setLinkTarget,resetLink,
    setLinkType} = linkSlice.actions;



export default linkSlice.reducer;
