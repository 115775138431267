// Dependencies
import React from "react";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/userSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Select, InputLabel, MenuItem } from "@material-ui/core";

//Styles
const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ProfilePage() {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  //Ephemeral state

  //Redux state
  const userProfile = useSelector((state) => state.user.userProfile);
  const language = userProfile.language;

  //Variables
  const lanaguages = [
    { lang: "en", rtl: false },
    { lang: "he", rtl: true },
  ];

  //Behavior

  // Render
  return (
    <>
      <InputLabel shrink id="language-select-lable">
        Language
      </InputLabel>
      <Select
        labelId="language-select-lable"
        id="language-select"
        value={language}
        displayEmpty
        className={classes.selectEmpty}
      >
        {lanaguages.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                dispatch(
                  setProfile({
                    ...userProfile,
                    language: item.lang,
                    rtl: item.rtl,
                  })
                );
              }}
              value={item.lang}
            >
              {item.lang}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}
