import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { enqueueFlashMessage } from "../../../redux/userSlice";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "center",
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600",
    },
  },
  buttonDiscard: {
    color: "#787877",
  },
  button: { textTransform: "none" },
}));

export default function RouteLeavingGuard(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { navigate } = props;
  // This will disply the brower's default prompt when the user leaves the page via the navigation bar, reloading, or closing a tab
  const alertsDuration = useSelector(
    (state) => state.user.userProfile.alertsDuration
  );

  useEffect(() => {
    const handleUnload = (e) => {
      if (props.isDirty) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [props.isDirty]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previously blocked location with the passes in navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    props.setTask && props.setTask(false);
    props.setIsDirty && props.setIsDirty(false);
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);

    setConfirmedNavigation(true);
  };

  async function handlePassedInStep() {
    props.additionalStep();
    dispatch(
      enqueueFlashMessage({
        message: props.additionalMsg
          ? props.additionalMsg
          : intl.formatMessage({
              id: "task.savedDraft",
              defaultMessage: "Task saved as draft",
            }),
        duration: alertsDuration,
      })
    );
    setModalVisible(false);
    setConfirmedNavigation(true);
  }

  const rtl = useSelector((state) => state.user.userProfile.rtl);

  return (
    <>
      <Prompt when={props.when} message={handleBlockedNavigation} />
      <div>
        <Dialog
          open={modalVisible}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: classes.modal,
            style: {
              direction: rtl ? "rtl" : "ltr",
            },
          }}
        >
          <ClearIcon className={classes.modalCancel} onClick={closeModal} />
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="tasks.create.beforYouLeave"
              defaultMessage="Before you leave"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.msg
                ? props.msg
                : intl.formatMessage({
                    id: "tasks.create.SaveAsDraft",
                    defaultMessage: "Save task as draft?",
                  })}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={handleConfirmNavigationClick}
              className={clsx(classes.button, classes.buttonDiscard)}
            >
              <FormattedMessage id="discard" defaultMessage="Discard" />
            </Button>
            {props.additionalStep && props.additionalStepButton ? (
              <Button
                className={classes.button}
                onClick={handlePassedInStep}
                color="primary"
                autoFocus
              >
                {props.additionalStepButton}
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
