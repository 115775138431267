import { createSlice, current } from "@reduxjs/toolkit";

export const highlightSlice = createSlice({
  name: "highlight",
  initialState: {
    highlights: [],
    underlines: [],
    clientRectangle: {},
    selectedText: 0,
    selectedHighlight: {},
    shouldOpen: false,
    minimal: false,
    shouldClose: false,
    isHover: false
  },
  extraReducers: {
    gotHighlights: (state, value) => {
      // console.log("setHighlights in slice", value);
      state.highlights = value.payload.coll;
      state.selectedText = value.payload.textId;
    }
  },
  reducers: {
    closeAnnotatorBar: state => {
      state.shouldClose = true;
    },
    openAnnotatorBar: (state, value) => {
      const { clientRectangle, selectedText, isHover, minimal } = value.payload;

      state.clientRectangle = clientRectangle;
      state.shouldClose = false;
      state.shouldOpen = true;
      state.minimal = minimal;
      state.selectedText = selectedText;
      state.isHover = isHover;
    },
    onBarShown: state => {
      state.shouldOpen = false;
    },
    onBarClosed: state => {
      state.shouldClose = false;
      state.minimal = false;
    },

    removeHighlight: (state, value) => {
      let newHighlights = current(state.highlights).filter(el => {
        //  console.log(el);
        return (
          el.cfi !== value.payload.cfi || el.textId !== value.payload.textId
        );
      });
      //   console.log("new highlights", newHighlights);
      state.highlights = newHighlights;
    },
    setHighlightColor: (state, value) => {
      let highlightsFiltered = current(state.highlights).filter(el => {
        //console.log("el",el);
        return (
          el.cfi !== value.payload.cfi || el.textId !== value.payload.textId
        );
      });
      //   console.log("highlights =", highlightsFiltered);
      highlightsFiltered.push(value.payload);
      state.highlights = highlightsFiltered;
    }
  }
});

export const { openAnnotatorBar, closeAnnotatorBar, onBarShown, onBarClosed } =
  highlightSlice.actions;

export default highlightSlice.reducer;
