import { createSlice } from '@reduxjs/toolkit';



export const themeSlice = createSlice({
  name: 'themes',
  initialState: {
    themes: [],
    themeMode:0,
    selectedThemeId:false,
  },
  extraReducers: {
    gotThemes : (state, value) => {
        state.themes = value.payload;
    },
  },
  reducers: {
    setThemes : (state, value) => {
      state.themes = value.payload;
  },
          selectTheme:(state, value)=> {
        state.selectedThemeId = value.payload && value.payload.id;
      },
      setThemeMode:(state,value)=>{
        state.themeMode = value.payload;
      }
    
  },
});

export const { setThemes,setThemeMode,
  selectTheme} = themeSlice.actions;



export default themeSlice.reducer;
