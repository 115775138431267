import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
//import { firebaseApp } from "../../firebase";

import { getHighlightColor } from "../../utils/colors";
import {
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  Editor,
  EditorState,
  ContentState,
  RichUtils,
  SelectionState
} from "draft-js";
import "draft-js/dist/Draft.css";

import { Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { resetLink, setSelection, setCurrentLink } from "../../redux/LinkSlice";
import CitationDialog from "./CreateTask/CitationDialog";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: "#ffffff"
  },
  taskDialogTitle: {
    background: "#168FEE"
  },
  regularDialogTitle: {
    background: "#5ec891"
  },
  left: {
    textAlign: "left"
  },
  dialogBtn: {
    color: "#ffffff"
  },
  right: {
    textAlign: "right"
  },

  commentText: {
    fontSize: "20px",
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    width: "100%",
    resize: "none",
    border: "none"
  },

  feedbackHeader: {
    marginTop: "8px",
    marginBottom: "8px"
  },
  wordCounter: {
    textAlign: "right",
    position: "absolute",
    bottom: "16px",
    color: "#616161",
    fontSize: "12px"
  }
}));

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

export const Link = props => {
  const text = useSelector(state => state.texts.selectedText);
  const darkMode = useSelector(state => state.user.userProfile.darkMode);

  const [openDialog, setOpenDialog] = useState(false);

  const { type, cfis, range } = props.contentState
    .getEntity(props.entityKey)
    .getData();

  const rendeHighlightsDialog = () => {
    return (
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={text && (text.link || text.url)}
        location={text && text.defaultLocation}
        highlights={cfis.cfis.map((cfi, i) => {
          let hlColor = cfi.color ? cfi.color : "#04E3FD";
          return {
            cfi: cfi && cfi.cfi ? cfi.cfi : cfi,
            color: getHighlightColor(hlColor, darkMode)
          };
        })}
        openDialog={openDialog}
      />
    );
  };

  const dispatch = useDispatch();

  return (
    <Typography
      component="span"
      onClick={() => {
        if (type === "text") {
          text && setOpenDialog(true);
        }
        if (type === "editor") {
          dispatch(
            setCurrentLink(
              props.contentState.getEntity(props.entityKey).getData()
            )
          );
        }
        if (type === "answer") {
          let element = document.getElementById("answer-text");
          if (element) {
            var sel = document.createRange();
            sel.setStart(element.firstChild, range.start);
            sel.setEnd(element.firstChild, range.end);
            if (window.getSelection().empty) {
              // Chrome
              window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
              // Firefox
              window.getSelection().removeAllRanges();
            }

            window.getSelection().addRange(sel);
          }
        }
      }}
      style={{
        color: "#168FEE",
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: "revert",
        fontSize: "revert"
      }}>
      {props.children}
      {openDialog && rendeHighlightsDialog()}
    </Typography>
  );
};

function getWordCount(editorState) {
  const plainText = editorState.getCurrentContent().getPlainText("");
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray.length : 0;
}

export default function TaskFeedbackComment(props) {
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ]);
  const intl = useIntl();

  const words = intl.formatMessage({ id: "words", defaultMessage: "words" });
  //const currentUser = firebaseApp.auth().currentUser;
  //export default function TabPanel(props) {
  const classes = useStyles();
  const selected = useSelector(state => state.link.selected);
  //  console.log("selected", selected);
  const selectedContext = useSelector(state => state.link.selectionContext);
  const linkType = useSelector(state => state.link.linkType);
  const linkTarget = useSelector(state => state.link.linkTarget);
  const currentLink = useSelector(state => state.link.currentLink);
  const [linkAdded, setLinkAdded] = useState(false);
  //const[changed,setChanged] = useState(false);
  const linkContent = useSelector(state => {
    return state.link.linkContent;
  });
  //  console.log("linkContent - 1", linkContent);
  const dispatch = useDispatch();
  const {
    onChange,
    comment,
    placeholder,
    text,
    index,
    autoFocus,
    readOnly,
    subcribeToUnload,
    componentName
  } = props;
  const componentContext = "feedbackComment" + index;
  const commentRef = useRef();

  const getInitialValue = () => {
    if (comment) {
      try {
        return EditorState.createWithContent(
          convertFromRaw(comment),
          decorator
        );
      } catch (error) {
        return EditorState.createEmpty(decorator);
      }
    } else if (text) {
      return EditorState.createWithContent(ContentState.createFromText(text));
    } else {
      return EditorState.createEmpty(decorator);
    }
  };

  const focus = editorState => {
    if (autoFocus) {
      return EditorState.moveFocusToEnd(editorState);
    } else return editorState;
  };

  const [editorState, setEditorState] = React.useState(
    focus(getInitialValue())
  );

  const onEditorChange = newState => {
    //   setChanged(true);
    const newSelectionState = newState.getSelection();

    if (!readOnly && selected !== !newSelectionState.isCollapsed()) {
      dispatch(
        setSelection({
          selected: !newSelectionState.isCollapsed(),
          selectionContext: componentContext
        })
      );
    }

    /* if (
      currentContent === oldContent ||
      // check if theres a word limit and that the current state is under the word limit
      props.wordLimit === undefined ||
      props.wordLimit <= 0 ||
      getWordCount(newState) <= props.wordLimit
    ) {*/
    setEditorState(newState);
    /*  } else {
      // if theres a wored limit and the word count is above it, return to previus state blocking the user from entering more words
      const currentEditorState = EditorState.undo(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          "delete-character"
        )
      );
      setEditorState(currentEditorState);
    }
        */
    // setEditorState(newState);
    subcribeToUnload &&
      onChange(convertToRaw(newState.getCurrentContent()), index);
  };

  React.useEffect(() => {
    if (currentLink && currentLink.name === componentName) {
      const updateSelection = new SelectionState({
        anchorKey: currentLink.selection.anchorKey,
        startOffset: currentLink.selection.startOffset,
        endOffset: currentLink.selection.endOffset
      });
      let newEditorState = EditorState.acceptSelection(
        editorState,
        updateSelection
      );
      // let newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
      setEditorState(newEditorState);
      dispatch(setCurrentLink(false));
    }
  }, [currentLink, dispatch, componentName, editorState]);

  React.useEffect(() => {
    if (
      !readOnly &&
      selected &&
      componentContext === selectedContext &&
      linkType &&
      linkContent &&
      editorState
    ) {
      // const {editorState, urlValue} = this.state;
      const contentState = editorState.getCurrentContent();
      let linkObjContent = {};
      if (linkTarget === "answer") {
        linkObjContent = { type: "answer", range: linkContent };
      } else if (linkTarget === "editor") {
        linkObjContent = {
          type: "editor",
          selection: linkContent.selection,
          name: linkContent.name
        };
      } else {
        if (linkContent && linkContent.cfis) {
          linkObjContent = { cfis: linkContent, type: "text" };
        }
      }
      if (linkObjContent && linkObjContent.type) {
        const contentStateWithEntity = contentState.createEntity(
          "LINK",
          "MUTABLE",
          linkObjContent
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Apply entity
        let nextEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity
        });

        // Apply selection
        nextEditorState = RichUtils.toggleLink(
          nextEditorState,
          nextEditorState.getSelection(),
          entityKey
        );

        setEditorState(nextEditorState);
        setLinkAdded(true);
      }
    }
  }, [
    selected,
    selectedContext,
    linkType,
    linkTarget,
    linkContent,
    componentContext,
    commentRef,
    editorState,
    readOnly
  ]);

  useEffect(() => {
    if (linkAdded) {
      setLinkAdded(false);
      dispatch(resetLink());
      commentRef &&
        commentRef.current &&
        commentRef.current.focus &&
        commentRef.current.focus();
    }
  }, [dispatch, linkAdded, setLinkAdded]);

  const rtlValue = useSelector(state => state.user.userProfile.rtl);
  const additionalProps = {};
  if (rtlValue && !editorState.getCurrentContent().getPlainText("").length) {
    additionalProps.textDirectionality = "RTL";
  }

  return (
    <>
      <Box>
        {/* { showFeedbackLine && <Typography className={classes.feedbackHeader} variant="body1">{currentUser.uid === task.teacher.uid ? "Your": task.teacher.displayName+'s'} feedback</Typography>} */}
        <Box
          style={{
            minHeight: "96px"
            //     borderBottom:showFeedbackLine? '1px solid rgba(0, 0, 0, .1)':'none', paddingBottom:'4px'
          }}>
          <Editor
            readOnly={readOnly}
            spellCheck={!readOnly}
            onMouseUp={() => {}}
            {...additionalProps}
            onBlur={() => {
              !readOnly &&
                onChange(convertToRaw(editorState.getCurrentContent()), index);
            }}
            placeholder={placeholder}
            editorState={editorState}
            ref={commentRef}
            onChange={onEditorChange}
          />
        </Box>
      </Box>
      {props.showWordCount && (
        <Box className={clsx(classes.wordCounter)} component="div">
          {getWordCount(editorState)}{" "}
          {props.wordLimit > 0 && `/ ${props.wordLimit}`}
          {` ${words}`}
        </Box>
      )}
    </>
  );
}
