import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/remote-config";
import "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGE_qDDivIwcPYqlZYfVzqQjlE5K2fca4",
  authDomain: "pangea-ai.com",
  projectId: "pangea-291110",
  storageBucket: "pangea-291110.appspot.com",
  messagingSenderId: "955244724934",
  appId: "1:955244724934:web:bb1a199712ffd291760dc7",
  measurementId: "G-92D9R6SNET"
};

firebase.initializeApp(firebaseConfig);

//domain issues with cookie
window.dataLayer = window.dataLayer || [];

window.gtag = function () {
  window.dataLayer.push(arguments);
};

window.gtag("config", firebaseConfig.measurementId, {
  cookie_domain: window.location.hostname,
  cookie_flags: "SameSite=None;Secure"
});
firebase.analytics();
export const firebaseApp = firebase;
export const auth = firebase.auth();

export const firebaseFunctions = firebase.app().functions("europe-west3");

// Setup emulators for development

// process.env.NODE_ENV === "development" &&
// firebaseFunctions.useEmulator("localhost",5001);

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
  prompt: "select_account"
});

export const facebookAuthProvider = new firebase.auth.GoogleAuthProvider();

export const firestore = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();
export const recaptchaKey = "6LfvJPQcAAAAAFyaIS_-9kJ9PsLzmK9clcK_g6nd";
//export const env="demo";
export const env = "staging";
remoteConfig.defaultConfig = {
  version: "5"
};

remoteConfig.settings.minimumFetchIntervalMillis = 300000;
