import React, { useEffect, useState } from "react";
import flatten from "flat";
import "./App.css";
import Layout from "./Layout";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { firebaseApp, firestore } from "./firebase";
import { setAuth, defaultProfile, setProfile } from "./redux/userSlice";
import {
  setReaderActionData,
  defaultPersisentActionState
} from "./redux/readerActionsSlice";
import { IntlProvider } from "react-intl";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import useGetTheme from "./hooks/useGetTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

import { useDispatch, useSelector } from "react-redux";

import Hebrew from "./translations/he.js";
import English from "./translations/en";
import { firebaseFunctions } from "./firebase";
import { userNavigated } from "./redux/firebaseMiddleware";
import SignIn from "./components/auth/SignIn";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";

import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { TermsDialog } from "./components/auth/TermsDialog";
import { OfflineDialog } from "./components/SharedComponents/OfflineDialog";

const useStyles = makeStyles(theme => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  }
}));

function App(props) {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.user.userProfile.language);
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useGetTheme();
  const [tokenRedirect, setTokenRedirect] = useState(false);
  const [user, authLoading, error] = useAuthState(firebaseApp.auth());

  const [userProfileDoc, profileLoading, userProfileError] = useDocument(
    firestore.doc("users/" + user?.uid)
  );
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );

  const readerActionsData = useSelector(
    state => state.readerActions.persistentActionState
  );

  let lang;
  if (locale === "en") {
    lang = English;
  } else {
    if (locale === "he") {
      lang = Hebrew;
    }
  }

  const rtl_state = useSelector(state => state.user.userProfile.rtl);
  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl_state && rtl()] });

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#5ec891";
  `;
  const auth = useSelector(state => state.user.auth);

  useEffect(() => {
    if (user) {
      firestore
        .doc("users/" + user.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            let data = doc.data();
            if (!tokenRedirect && location.pathname === "/") {
              history.push(data.location);
            }

            dispatch(setProfile(data));

            if (data?.readerActionData?.highlightColor) {
              // override the default setting with the saved ones in case simething is added to the default
              const readerActionData = {
                ...defaultPersisentActionState,
                ...data.readerActionData
              };
              dispatch(setReaderActionData(readerActionData));
            } else {
              dispatch(setReaderActionData(defaultPersisentActionState));
            }
          } else {
            dispatch(setReaderActionData(defaultPersisentActionState));

            dispatch(setProfile(defaultProfile));
            if (!tokenRedirect && location.pathname === "/") {
              history.push("/library");
            }
          }
        })
        .catch(err => console.log("err fetcfhing user profile", err));
    }
  }, [user]);

  useEffect(() => {
    //save location, selectedCourse
    //save location, selectedCourse
    if (
      user &&
      !location.pathname.includes("logout") &&
      location.pathname !== "/"
    ) {
      firestore
        .doc("users/" + user?.uid)
        .update({ location: location.pathname })
        .catch(err => console.log("Error updating firestore", err));
    }

    if (user) dispatch(userNavigated({ location: location }));
  }, [location, user, dispatch]);

  useEffect(() => {
    if (readerActionsData && readerActionsData.highlightColor && user) {
      try {
        firestore
          .doc("users/" + user?.uid)
          .update({ readerActionData: readerActionsData });
      } catch (err) {
        console.log("error in readerACtion Data update ", err);
      }
    }
  }, [readerActionsData, user]);

  useEffect(() => {
    if (user) {
      // User is signed in, setAuth
      if (auth.uid != user.uid) {
        dispatch(
          setAuth({
            displayName: user.displayName,
            photoURL: user.photoURL,
            uid: user.uid,
            email: user.email
          })
        );
      }
    }
  }, [user, auth]);

  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    let path = sp.get("redirect");
    let course = sp.get("course_id");
    if (token) {
      setTokenRedirect(true);
      firebaseApp
        .auth()
        .signInWithCustomToken(token)
        .then(() => {
          var getMyCourses = firebaseFunctions.httpsCallable(
            "courses-getMyCourses"
          );

          getMyCourses().then(response => {
            let courseDetail = response.data.filter(e => e.id === course);
            if (courseDetail && courseDetail.length && userProfileDoc?.exists) {
              dispatch(
                setProfile({
                  ...userProfileDoc.data(),
                  selectedCourse: courseDetail[0]
                })
              );
            }
            history.push(path);
          });
        });
    } else {
      if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
        //we saved the email in local storage when generated,
        //but if the user used another browser it is not available , so promprt them.
        var savedEmail = window.localStorage.getItem("emailForSignIn");
        if (!savedEmail) {
          savedEmail = window.prompt(
            "Please provide your email for confirmation"
          );
        }
        // The client SDK logs in the user based on link
        firebaseApp
          .auth()
          .signInWithEmailLink(savedEmail, window.location.href)
          .then(result => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch(error => {
            console.log("error", error);
            setAuth(0);
          });
      }
    }
  }, [dispatch, history, location]);

  const renderApp = () => {
    //adding token to if for LTI - sometimes slowness cause the login screen to appear when laoder should
    //still be visible  need to verify this doesn't break anything
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    if (auth && user && readerActionsData && readerActionsData.highlightColor) {
      return (
        <>
          <TermsDialog />
          <Layout />
        </>
      );
    } else if (!user && !token && !authLoading) {
      if (error) console.log("error", error);
      return <SignIn />;
    } else
      return (
        <Box className={classes.center}>
          <ClipLoader
            color="#5ec891"
            loading="true"
            css={override}
            size={150}
          />
        </Box>
      );
  };

  const [isOffline, setIsOffline] = useState(
    window.Offline && window.Offline.state !== "up"
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntlProvider
        locale={locale}
        messages={flatten(lang, {
          safe: true
        })}
      >
        <DndProvider backend={HTML5Backend}>
          <StylesProvider jss={jss}>
            <div className="App" dir={rtl_state ? "rtl" : "ltr"}>
              {!isOffline && renderApp()}
            </div>
            <OfflineDialog isOffline={isOffline} setIsOffline={setIsOffline} />
          </StylesProvider>
        </DndProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default withRouter(App);
