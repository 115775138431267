// Dependencies
import React, { useEffect, useState, useRef } from "react";
import { useLayer } from "react-laag";
import clsx from "clsx";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { onBarShown, onBarClosed } from "../../redux/highlightSlice";
import { setAction, setClearSelection } from "../../redux/readerActionsSlice";
// import { setComment } from "../../redux/commentsSlice";
import { enqueueFlashMessage, undo } from "../../redux/userSlice";
import { getNextId, updateThemes } from "../../redux/firebaseMiddleware";
import { getSelectedQuestion } from "../../redux/grSlice";

// Components
import ReaderAction from "./ReaderAction";
import HighlightColor from "./HighlightColor";
import ThemeBox from "./ThemeBox";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import BorderColor from "@material-ui/icons/BorderColor";
import LabelImportant from "@material-ui/icons/LabelImportant";
// import InsertComment from "@material-ui/icons/InsertComment";
import { Box, Divider } from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  themeContainer: {
    position: "fixed",
    width: "320px",
    maxHeight: "360px",
    backgroundColor: "#333333",
    zIndex: "1500"
  },
  highlightAction: {
    "& :hover": {
      color: "#91fcc2"
    }
  },
  divider: {
    color: "#757575",
    backgroundColor: "#757575",
    margin: 6
  }
}));

export default function ReactReaderActions(props) {
  // Hooks
  let ref = useRef(null);
  let highlightRef = useRef(null);
  const classes = useStyles();

  // Redux State
  const dispatch = useDispatch();

  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  const highlightColor = useSelector(
    state => state.readerActions.persistentActionState.highlightColor
  );
  const clientRectangle = useSelector(
    state => state.highlighter.clientRectangle
  );
  const selectedText = useSelector(state => state.highlighter.selectedText);
  const shouldOpen = useSelector(state => state.highlighter.shouldOpen);
  const minimalFromState = useSelector(state => state.highlighter.minimal);
  const shouldClose = useSelector(state => state.highlighter.shouldClose);
  const isHover = useSelector(state => state.highlighter.isHover);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);

  const selectedQuestion = useSelector(state => {
    if (state.gr.questions?.length) {
      let filtered = state.gr.questions.filter(
        q => q.id === state.gr.selectedQuestionId
      );
      if (filtered && filtered.length) return filtered[0];
      else return false;
    }
  });

  const themes = useSelector(state => state.themes.themes);
  const rtlActual = useSelector(state => state.user.userProfile.rtl);
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral State
  const [labelShow, setLabelShow] = useState(false);
  const [layerOpen, setLayerOpen] = useState(false);
  const [undoData, setUndoData] = useState(null);

  // Variabels
  const minimal = props.minimal || minimalFromState;
  let colors = [
    { color: "#91fcc2", text: "color.Green", hlColor: "#91fcc2" },
    { color: "#93d4d9", text: "color.Cyan", hlColor: "#93d4d9" },
    { color: "#ffa284", text: "color.Orange", hlColor: "#ffa284" },
    { color: "#ff8a8a", text: "color.Red", hlColor: "#ff8a8a" },
    { color: "#ffcb73", text: "color.Yellow", hlColor: "#ffcb73" }
  ];

  //Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoAddTheme = () => {
      if (undoData?.type === "AddThemeQuoteViaPopup") {
        let modifiedThemes = themes.map(theme => {
          if (theme.id === undoData.themeId) {
            let quotes = [
              ...theme.quotes.filter(quote => quote.cfi !== undoData.cfi)
            ];

            return { ...theme, quotes: quotes };
          } else return theme;
        });
        dispatch(
          updateThemes({
            textId: undoData.selectedTextId,
            themes: modifiedThemes
          })
        );
      }
    };

    if (shouldUndo) {
      undoAddTheme();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, undoData, themes]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: "Instance added to theme",
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch]);

  const renderTheme = () => {
    if (labelShow)
      return (
        <ThemeBox
          className={classes.themeContainer}
          clientRect={clientRectangle}
          setTheme={theme => {
            if (theme && theme.id) {
              let modifiedThemes = themes.map(elem => {
                if (elem.id === theme.id) {
                  let quotes = [
                    ...elem.quotes.filter(el => el.cfi !== selectedText.cfi),
                    {
                      cfi: selectedText.cfi,
                      ref: "not avaialble",
                      source: "theme",
                      text: selectedText.text,
                      color: highlightColor
                    }
                  ];
                  dispatch(setClearSelection(true));
                  return { ...elem, quotes: quotes };
                } else return elem;
              });
              dispatch(
                updateThemes({ textId: selectedTextId, themes: modifiedThemes })
              );
            } else {
              dispatch(
                updateThemes({
                  textId: selectedTextId,
                  themes: [
                    ...themes,

                    {
                      name: theme.name,
                      id: getNextId(themes),
                      quotes: [
                        {
                          cfi: selectedText.cfi,
                          ref: "not avaialble",
                          source: "theme",
                          text: selectedText.text,
                          color: highlightColor
                        }
                      ]
                    }
                  ]
                })
              );
            }

            setLabelShow(false);

            setUndoData({
              type: "AddThemeQuoteViaPopup",
              selectedTextId: selectedTextId,
              themeId: theme.id,
              cfi: selectedText.cfi
            });

            dispatch(setClearSelection(true));
          }}
        />
      );
    else return "";
  };

  const renderHoveredToolbar = () => {
    if (isHover) {
      return (
        <React.Fragment>
          {colors.map((item, index) => (
            <HighlightColor
              key={index}
              color={item.color}
              onClick={() => {
                props.setHighlightColor &&
                  props.setHighlightColor(selectedText, item.hlColor);
                setLayerOpen(false);
                setLabelShow(false);
              }}
            />
          ))}
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
        </React.Fragment>
      );
    } else return "";
  };
  const hoverWidth = "307px";

  const { layerProps, renderLayer } = useLayer({
    isOpen: layerOpen,
    trigger: {
      getBounds: () => {
        return clientRectangle;
      },
      getParent: () => {
        return ref.current;
      }
    },
    onOutsideClick: e => {
      setLabelShow(false);
    },

    placement: "top-center",

    triggerOffset: 1
  });

  const getWidth = () => {
    if (minimal) return "50px";
    let defaultWidth = "147px";
    if (rtlActual) {
      defaultWidth = "136px";
    }
    return isHover ? hoverWidth : defaultWidth;
  };

  const rtlValue = rtlActual ? "rtl" : "auto";

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y !== prevPos.y) {
      setLayerOpen(false);
      setLabelShow(false);
    }
  });

  useEffect(() => {
    if (
      shouldOpen &&
      !layerOpen &&
      (!annotatorMode ||
        isHover ||
        (annotatorMode === "poc" && !selectedQuestion))
    ) {
      setLabelShow(false);
      setLayerOpen(true);
      dispatch(onBarShown());
    } else {
      if (shouldClose) {
        setLayerOpen(false);
        setLabelShow(false);

        dispatch(onBarClosed());
      }
    }
  }, [
    shouldOpen,
    layerOpen,
    annotatorMode,
    clientRectangle,
    ref,
    selectedQuestion,
    dispatch,
    shouldClose,
    props,
    isHover
  ]);

  // Render
  return (
    <Box style={{ height: "100%" }} ref={ref}>
      {renderTheme()}

      {props.children}

      {layerOpen &&
        // renderLayer(layerInnerRender())
        renderLayer(
          <Box
            {...layerProps}
            style={{
              ...layerProps.style,
              // width: getWidth(),
              height: "58px",
              display: "flex",
              position: "fixed",
              zIndex: 1500,
              padding: 1
            }}
            onMouseDown={evt => {
              evt.preventDefault();
            }}>
            <Box
              className="triangle"
              style={{
                borderRadius: 4,
                width: "100%",
                height: "48px",
                paddingInline: 8,
                backgroundColor: "#333333",
                display: "flex",
                direction: rtlValue,
                color: "#fafafa",
                zIndex: "100"
              }}>
              {!minimal && renderHoveredToolbar()}
              <ReaderAction
                minimal={minimal}
                ref={highlightRef}
                Icon={BorderColor}
                className={classes.highlightAction}
                onClick={() => {
                  props.removeHighlight &&
                    isHover &&
                    props.removeHighlight(selectedText);
                  props.highlightFunc &&
                    !isHover &&
                    props.highlightFunc(selectedText, highlightColor);
                  setLayerOpen(!layerOpen);
                  setLabelShow(false);
                  dispatch(setClearSelection(true));
                }}
              />

              {!minimal && (
                <ReaderAction
                  className={classes.highlightAction}
                  Icon={LabelImportant}
                  onClick={() => {
                    setLabelShow(!labelShow);
                    setLayerOpen(false);
                  }}
                />
              )}

              {/* {!minimal && ( // ***  Do not delete this is a feature that will come back ***
                <ReaderAction
                  className={classes.highlightAction}
                  Icon={InsertComment}
                  onClick={() => {
                    dispatch(setAction("comments"));
                    dispatch(
                      setComment({ ...selectedText, color: highlightColor })
                    );
                    dispatch(setClearSelection(true));
                    setLayerOpen(false);
                  }}
                />
              )} */}
            </Box>
          </Box>
        )}
    </Box>
  );
}
