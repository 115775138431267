// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { Link, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { remoteConfig } from "./firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateNotificationBedge } from "./redux/firebaseMiddleware";
import { resetNotificationBedge } from "./redux/firebaseMiddleware";

// Components
import "./App.css";
import NotificationsMenu from "./components/menus/NotificationsManu";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import {
  ClickAwayListener,
  Typography,
  Avatar,
  Box,
  Badge,
  AppBar,
  IconButton,
  Button,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem
} from "@material-ui/core";

// Styles
const StyledBadge = withStyles(theme => ({
  badge: {
    right: 10,
    top: 10,
    color: "black",
    fontSize: "16px",
    // verticaly center text
    alignContent: "baseline",
    lineHeight: 1
  }
}))(Badge);

const useStyles = makeStyles(theme => ({
  ltr: {
    textAlign: "left"
  },
  rtl: {
    textAlign: "right"
  },
  heatmapBtn: {
    backgroundColor: "#168fee",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      background: "#1172be"
    }
  },
  pangeaAppBar: {
    flexFlow: "row nowrap",
    alignItems: "center",
    height: "64px",
    padding: "0px 24px",
    backgroundColor: "#333333",
    boxShadow: "none",
    zIndex: 150
  },
  avatarMenuLtr: {
    justifyContent: "right",
    direction: "ltr",
    textAlign: "left"
  },
  menu: {
    display: "inline",
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: "300",
    color: "#e0e0e0",
    textDecoration: "none",
    "&:hover": {
      color: "#FFFFFF"
    }
  },
  avatarMenuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px",
    "&:hover": {}
  },
  menuActive: {
    color: "#FFFFFF"
  },
  avatar: {
    position: "absolute",
    right: "0"
  },
  title: {
    marginInlineEnd: "40px",
    fontSize: "24px",
    color: "white",
    textDecoration: "none",
    // To visualy align with navigation
    marginBottom: "4px"
  },
  avatarFallback: {
    width: "100%",
    height: "100%"
  },
  notificationsIcon: {
    color: "currentColor",
    marginLeft: "auto"
  },

  navigationContainer: {
    flex: "1 1 auto",
    display: "flex",
    "&  a": { textDecoration: "none" }
  },
  appNavigation: {
    display: "flex",
    "& > li": {
      padding: 0,
      marginInlineEnd: "48px"
    }
  },
  userNavigation: {
    flex: "none",
    display: "flex",
    justifyContent: "space-between",
    "& > li": {
      padding: 0,
      marginInlineStart: "16px"
    }
  }
}));

export default function MyAppBar({ ...props }) {
  // TODO: Combine Appbars into one component/folder wrapen with an appbar tag
  // Hooks
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const avatarElRef = useRef();
  const notificationIconsRef = useRef();
  const dispatch = useDispatch();

  const isReader = useRouteMatch({ path: "/reader" });
  const isTasks = useRouteMatch({ path: "/tasks" });
  const isLibrary = useRouteMatch({ path: "/library" });
  const isCommunity = useRouteMatch({ path: "/test" });
  const isContact = useRouteMatch({ path: "/contactUs" });

  // Redux state
  const currentUser = useSelector(state => state.user.auth);
  const originalAuth = useSelector(state => state.user.original_auth);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const notifications = useSelector(state => state.user.notifications);
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );

  // Ephemeral state
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [remoteConfigVersion, setRemoteConfigVersion] = useState(1);
  const [currentVersion, setCurrentVersion] = useState(6);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notificationsBadgeCount, setNotificationsBadgeCount] = useState(0);

  // Variables
  const readerLink =
    selectedTextId && selectedTextId > 0
      ? "/reader/text/" + selectedTextId
      : "/library";

  const reader = intl.formatMessage({
    id: "appBar.reader",
    defaultMessage: "Reader"
  });
  const tasks = intl.formatMessage({
    id: "appBar.tasks",
    defaultMessage: "Tasks"
  });
  const library = intl.formatMessage({
    id: "appBar.library",
    defaultMessage: "Library"
  });
  const community = intl.formatMessage({
    id: "appBar.community",
    defaultMessage: "Community"
  });
  const contactUs = intl.formatMessage({
    id: "appBar.contactUs",
    defaultMessage: "Contact us"
  });

  useEffect(() => {
    try {
      remoteConfig.fetchAndActivate();
      let newVal = remoteConfig.getValue("version");
      if (newVal.asNumber() !== remoteConfigVersion) {
        setRemoteConfigVersion(newVal.asNumber());
      }
    } catch (error) {}
  }, [remoteConfigVersion]);

  useEffect(() => {
    if (notifications) {
      const unreadNotifications = notifications.reduce(
        (accumulator, notification) =>
          notification.includeInCounter ? accumulator + 1 : accumulator,
        0
      );
      setNotificationsBadgeCount(unreadNotifications);
    }
  }, [notifications]);

  useEffect(() => {
    if (notifications) {
      dispatch(
        updateNotificationBedge({
          currentLocation: location.pathname
        })
      );
    }
  }, [notifications, location, dispatch]);

  const renderUpdate = () => {
    return (
      <Dialog
        open={remoteConfigVersion > currentVersion}
        onClose={() => {
          setCurrentVersion(remoteConfigVersion);
        }}
      >
        <DialogContent>
          <Typography>
            <FormattedMessage
              id="upgrade.msg"
              defaultMessage="A new version of Pangea exists, please reload app"
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label={"Reload"}
            className={classes.heatmapBtn}
            onClick={() => {
              window.location.reload(true);
            }}
          >
            <FormattedMessage id="upgrade.reload" defaultMessage="Reload" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderMenu = () => {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <Menu
          anchorEl={avatarElRef.current}
          keepMounted
          classes={{ list: classes.avatarMenuLtr }}
          open={Boolean(menuOpen)}
          onClose={() => setMenuOpen(false)}
          getContentAnchorEl={null}
          disableScrollLock={true}
        >
          <MenuItem
            className={clsx(classes.avatarMenuItem, classes.left)}
            onClick={() => {
              handleProfileClick();
              setMenuOpen(false);
            }}
          >
            <FormattedMessage id="view.profile" defaultMessage="View Profile" />
          </MenuItem>

          <MenuItem
            className={clsx(classes.avatarMenuItem, classes.left)}
            onClick={() => {
              const serializedState = JSON.stringify({
                selectedCourse: false,
                location: "/"
              });
              localStorage.setItem("state.location", serializedState);

              // googleAuthProvider.signOut();
              setMenuOpen(false);
              history.push("/logout");
            }}
          >
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </MenuItem>
        </Menu>
      </ClickAwayListener>
    );
  };

  const handleProfileClick = () => {
    history.push("/profile");
  };

  function toggleNotifications() {
    setIsNotificationsOpen(prevOpen => !prevOpen);
    dispatch(resetNotificationBedge());
  }

  const handleClose = event => {
    if (
      notificationIconsRef.current &&
      notificationIconsRef.current.contains(event.target)
    ) {
      return;
    }

    setIsNotificationsOpen(false);
  };

  // Render
  return (
    <>
      <AppBar
        component="div"
        dir={rtl ? "rtl" : "ltr"}
        className={clsx(
          classes.pangeaAppBar,
          rtl && classes.rtl,
          !rtl && classes.ltr
        )}
        position="static"
      >
        <Link to="/library" className={classes.title}>
          Pangea
        </Link>

        <Box
          aria-label="Pangea App"
          component="nav"
          className={classes.navigationContainer}
        >
          <List className={classes.appNavigation}>
            <ListItem>
              <Link
                title={reader}
                to={readerLink}
                className={clsx(
                  classes.menu,
                  location.pathname === "/" && classes.menuActive,
                  isReader && classes.menuActive
                )}
                aria-current={isReader && "page"}
              >
                {reader}
              </Link>
            </ListItem>

            <ListItem>
              <Link
                title={tasks}
                to={
                  selectedCourse?.id ? "/tasks/" + selectedCourse?.id : "/tasks"
                }
                aria-current={isTasks && "page"}
              >
                <Typography
                  variant="h6"
                  className={clsx(classes.menu, isTasks && classes.menuActive)}
                >
                  {tasks}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                title={library}
                to={
                  selectedCourse?.id
                    ? "/library/" + selectedCourse?.id
                    : "/library"
                }
                aria-current={isLibrary && "page"}
              >
                <Typography
                  variant="h6"
                  className={clsx(
                    classes.menu,
                    isLibrary && classes.menuActive
                  )}
                >
                  {library}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                title={community}
                to="/test"
                aria-current={isCommunity && "page"}
              >
                <Typography
                  variant="h6"
                  className={clsx(
                    classes.menu,
                    isCommunity && classes.menuActive
                  )}
                >
                  {community}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                title={contactUs}
                to="/contactUs"
                aria-current={isContact && "page"}
              >
                <Typography
                  variant="h6"
                  className={clsx(
                    classes.menu,
                    isContact && classes.menuActive
                  )}
                >
                  {contactUs}
                </Typography>
              </Link>
            </ListItem>
          </List>
        </Box>

        <List disablePadding className={classes.userNavigation}>
          <ListItem>
            <IconButton
              aria-label="view notifications"
              ref={notificationIconsRef}
              color="primary"
              className={classes.notificationsIcon}
              onClick={toggleNotifications}
            >
              <StyledBadge
                badgeContent={notificationsBadgeCount}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                color="secondary"
              >
                <NotificationImportantIcon style={{ fontSize: 42 }} />
              </StyledBadge>
            </IconButton>
            <NotificationsMenu
              notificationIconsRef={notificationIconsRef}
              notifications={notifications}
              setIsNotificationsOpen={setIsNotificationsOpen}
              isNotificationsOpen={isNotificationsOpen}
              handleClose={handleClose}
            />
          </ListItem>

          <ListItem>
            <IconButton
              aria-label={`Pangea account: ${currentUser.displayName}`}
              color="primary"
              className={classes.notificationsIcon}
              ref={avatarElRef}
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <Avatar
                classes={{ fallback: classes.avatarFallback }}
                alt="Avatar"
                src={originalAuth === -1 && currentUser && currentUser.photoURL}
              ></Avatar>
            </IconButton>
          </ListItem>
          {renderMenu()}
        </List>
      </AppBar>
      {renderUpdate()}
    </>
  );
}
