import React, {
  useState
  //  useRef
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

import { FormattedMessage } from "react-intl";
import CitationDialog from "./CitationDialog";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },
  dialogHeader: {},
  dialogActions: {
    justifyContent: "left",
    position: "relative"
    //  justifyContent:'flex-start',
  },
  dialogActionsRtl: {
    justifyContent: "right",
    position: "relative"
    //  justifyContent:'flex-start',
  },
  blue: {
    color: "#168FEE"
  },
  dialogBtn: {
    position: "absolute",
    right: "16px",
    color: "#ffffff"
  },
  expandMsg: {
    cursor: "pointer",
    textAlign: "left"
  },
  span: {
    textAlign: "left"
  }
}));

function FindTaskAnswer(props) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <React.Fragment>
      <Typography
        onClick={() => setOpenDialog(true)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            setOpenDialog(true);
          }
        }}
        className={clsx(classes.expandMsg, classes.blue)}
        variant="body1"
        role="button"
        tabIndex="0">
        <FormattedMessage
          id="task.find.instructions"
          defaultMessage="Mark the relevant passages in text"
        />
      </Typography>
      <Typography className={classes.span} variant="body1">
        <FormattedMessage
          id="task.find.details"
          defaultMessage="Pangea will compare your marks with the students' and help you understand how well they did"
        />
      </Typography>
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={props.url}
        location={props.location}
        highlights={props.highlights}
        openDialog={openDialog}
        addHighlight={!props.readOnly && props.addHighlight}
        removeHighlight={!props.readOnly && props.removeHighlight}
      />
    </React.Fragment>
  );
}

export default FindTaskAnswer;
