// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { firebaseFunctions, firebaseApp } from "./firebase";
import { useParams } from "react-router-dom";
import { injectIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { setPangeaText } from "./redux/textSlice";
import { useSelector, useDispatch } from "react-redux";
import { setSubmittedText } from "./redux/grSlice";
import {
  taskSelectors,
  setSelectedTask,
  setSelectedTaskRow
} from "./redux/taskSlice";
import { setProfile } from "./redux/userSlice";
import { setBreadcrumbs, setAction } from "./redux/readerActionsSlice";

// Components
import { GrInstructions } from "./components/guidedReading/GrInstructions";
import CardsManager from "./components/poc/CardsManager";
import SummaryManager from "./components/poc/summary/SummaryManager";
import BookView from "./components/reader/BookView";
import { ThemeCardsManager } from "./components/themes/ThemeCardsManager";
import { ThemeNote } from "./components/themes/ThemeNote";
import { TaskOpenAnswer } from "./components/Tasks/TaskAnswer/TaskOpenAnswer";
import { TaskMultiChoiceAnswer } from "./components/Tasks/TaskAnswer/TaskMultiChoiceAnswer";
import PeerReview from "./components/Tasks/PeerReview/PeerReview";
import PangeaSpinner from "./components/SharedComponents/PangeaSpinner";

import { useGetSideBarWidth } from "./hooks";

//logic from Sbmitting a task
import SubmitTaskModal from "./components/Tasks/TaskAnswer/SubmitTaskModal";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
// Styles
const useStyles = makeStyles(theme => {
  return {
    readerViewContainer: {
      position: "relative",
      flex: 1,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      overflowY: "auto"
    },
    drawerContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center"
    },
    selected: { color: theme.palette.secondary.main },
    dirLtr: {
      direction: "ltr"
    },
    content: {
      position: "relative",
      width: "100%",
      height: "100%"
    },
    contentActionOpen: {
      gridTemplateColumns: "repeat(18,1fr)"
    },
    contentActionClose: {
      gridTemplateColumns: "repeat(18,1fr)"
    },
    bookContainerActionOpen: {
      gridColumn: " 6 / 17 "
    },
    bookContainerActionCommentOpen: {
      gridColumn: " 7 / 17 "
    },

    bookContainerActionFull: {
      gridColumn: " 6 / 19 "
    },
    bookContainerDetails: {
      gridColumn: " 6 / 19 "
    },
    bookContainerActionClose: {
      gridColumn: " 4 / 16 "
    },
    darkMode: {
      backgroundColor: "#303030"
    },
    bookContainer: {
      display: "flex",
      justifyContent: "center",
      height: "100%"
    }
  };
});

const ReaderView = ({ intl, ...props }) => {
  let { task_id, text_id, submission_id } = useParams();
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();

  // Redux state
  const action = useSelector(state => {
    return state.readerActions.persistentActionState.actionBar;
  });
  const showCitationForQuestion = useSelector(
    state => state.task.showCitationForQuestion
  );

  const isLoading = useSelector(state => {
    return state.readerActions.isLoading;
  });
  const lastLocation = useSelector(state => {
    return state.texts.lastLocation;
  });
  const selectedText = useSelector(state => state.texts.selectedText);
  const showAnswers = useSelector(state => state.gr.showAnswers);
  const showHighlights = useSelector(state => state.gr.showHighlights);
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const submittedTextId = useSelector(state => state.gr.submittedTextId);
  const detailedInstructions = useSelector(
    state => state.gr.detailedInstructions
  );
  const submissionStatus = useSelector(
    state => state.task.taskRow.submission_status
  );
  const themeMode = useSelector(state => state.themes.themeMode);
  const selectedTheme = useSelector(state => {
    let filtered = state.themes.themes.filter(
      q => q.id === state.themes.selectedThemeId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const selectedTaskId = useSelector(state => state.task.selectedTaskId);
  const taskAnswers = useSelector(state => state.task.answers);
  const userProfile = useSelector(state => state.user.userProfile);
  const selectedCourse = userProfile.selectedCourse;
  const rtl = userProfile.rtl;
  const darkMode = userProfile.darkMode;

  // Ephemeral state
  const [readText, setReadText] = useState("");
  const [task, setTask] = useState("");
  const [textTask, setTextTask] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const [reflection, setReflection] = useState(null);
  const [response, setResponse] = useState(null);
  const [reply, setReply] = useState(null);
  const [fetchedFeedback, setFetchedFeedback] = useState(null);

  // Variables

  //Behavior
  useEffect(() => {
    if (lastLocation && lastLocation != "") {
      if (task) {
        setReadText({ ...readText, file_location: lastLocation });
      } else {
        dispatch(
          setPangeaText({ ...selectedText, defaultLocation: lastLocation })
        );
      }
    }
  }, [stage, lastLocation]);

  //Set breadcrumbs effect
  const author =
    !task || !task.text_author
      ? selectedText && selectedText.author
      : task.text_author;

  const text_title =
    !task || !task.text_name
      ? selectedText && selectedText.name
      : task.text_name;

  const course =
    !task || !task.course_name
      ? selectedText && selectedText.course_name
      : task.course_name;

  const course_id =
    !task || !task.course_id
      ? selectedText && selectedText.course_id
      : task.course_id;

  useEffect(() => {
    let parts = [];
    task &&
      task.id &&
      parts.push({
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Tasks"
        }),
        url: "/tasks",
        brightText: !!task
      });
    parts.push({
      text: course,
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      brightText: !!task
    });

    task &&
      task.name &&
      parts.push({
        url: "/tasks/" + course_id,
        text: task.name,
        brightText: !!task
      });

    parts.push({
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      text: author + " - " + text_title,
      brightText: !!task
    });
    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: task && task.id,
        showTextMenu: true,
        showSubmitTask: task && action === "task"
      })
    );
  }, [rtl, task, course, course_id, author, text_title]);

  const getText = id => {
    var getTaskById = firebaseFunctions.httpsCallable(
      "courses-getTextByIdWithTasks"
    );
    getTaskById({ id }).then(response => {
      let textRow = response.data;
      setTextTask(textRow);

      firebaseApp
        .storage()
        .ref("courseTexts/" + textRow.course_id)
        .child(textRow.file_url)
        .getDownloadURL()
        .then(url => {
          dispatch(
            setPangeaText({
              ...textRow,
              link: url
              // file_location:location,
            })
          );
        })
        .catch(e => {
          console.log(e);
        });
    });
  };

  useEffect(() => {
    if (task_id > 0) {
      dispatch(setSelectedTask(task_id));

      const getTask = !submission_id
        ? firebaseFunctions.httpsCallable("courses-getTaskById")
        : firebaseFunctions.httpsCallable("courses-getTaskForCollaboration");

      getTask({ task: task_id, submission: submission_id }).then(response => {
        let taskRow = response.data[0];
        if (taskRow?.course_id) {
          dispatch(setAction("task"));
          firebaseApp
            .storage()
            .ref("courseTexts/" + taskRow.course_id)
            .child(taskRow.file_url)
            .getDownloadURL()
            .then(url => {
              if (selectedCourse.id != taskRow.course_id) {
                dispatch(
                  setProfile({
                    ...userProfile,
                    selectedCourse: {
                      id: taskRow.course_id,
                      name: taskRow.course_name,
                      course_role: "student"
                    }
                  })
                );
              }

              setReflection(taskRow?.answers?.answers);
              setFetchedFeedback(taskRow?.feedback);

              setReadText({
                course_id: taskRow.course_id,
                textUrl: taskRow.file_url,
                url: url,
                file_location: taskRow.file_location
              });

              dispatch(
                setPangeaText({
                  ...taskRow,
                  ...taskRow.sq3r,
                  id: taskRow.text_id,
                  url: url,
                  defaultLocation: taskRow.file_location
                })
              );
              setTask(taskRow);
              dispatch(setSelectedTaskRow(taskRow));
            });
        }
      });
    } else if (text_id > 0) {
      dispatch(setSelectedTask(task_id));
      setTask(null);
      if (action === "task") {
        dispatch(setAction(""));
      }
      getText(text_id);
    }
  }, [task_id, dispatch]);

  const undo = () => {
    let funcName = "courses-undoTextSq3rSubmission1";
    let textId = submittedTextId;

    let func = firebaseFunctions.httpsCallable(funcName);
    func({ text_id: textId }).then(response => {
      dispatch(setSubmittedText(false));
    });
  };

  const renderNote = () => {
    return (
      <ThemeNote
        key={selectedTheme.id}
        readText={{
          url:
            (readText && readText.url) || (selectedText && selectedText.link),
          file_location:
            (readText && readText.file_location) ||
            (selectedText && selectedText.file_location)
        }}
        back={() => {}}
      />
    );
  };

  const renderCardsEditView = () => {
    return (
      <ThemeCardsManager
        readText={{
          url:
            (readText && readText.url) || (selectedText && selectedText.link),
          file_location:
            (readText && readText.file_location) ||
            (selectedText && selectedText.file_location)
        }}
        back={() => {}}
      />
    );
  };
  const bookRef = useRef();

  const currentTaskQuestionMode = useSelector(state => {
    if (
      state.task &&
      state.task.taskRow.questions &&
      state.task.taskRow.questions.questions[
        state.task.selectedQuestionIndex
      ] &&
      !state.task.taskRow.questions.questions[state.task.selectedQuestionIndex]
        .includeCitation
    ) {
      return "Answer";
    } else if (
      state.task &&
      state.task.answers[state.task.selectedQuestionIndex] &&
      state.task.answers[state.task.selectedQuestionIndex].mode
    ) {
      return state.task.answers[state.task.selectedQuestionIndex].mode;
    } else {
      return "Citations";
    }
  });

  const currentQuestionIndex = useSelector(
    state => state.task && state.task.selectedQuestionIndex
  );
  const currentQuestion = useSelector(taskSelectors.question);

  const renderTasksMainScreen = () => {
    switch (true) {
      case currentQuestion?.type === 0:
      case currentQuestion?.type === 1 &&
        currentQuestion.includeCitation &&
        showCitationForQuestion:
      case currentQuestion?.type === 2 &&
        currentQuestion.includeCitation &&
        showCitationForQuestion:
        //FIND IN TEXT
        return (
          <BookView
            readOnly={
              action === "task" &&
              task &&
              task.submission_status === "Submitted"
            }
            minimalBar={action === "task" && task}
            task={action === "task" && task}
            readText={task ? readText : selectedText}
            context="gr"
          />
        );
      case currentQuestion?.type === 1:
        return (
          <TaskOpenAnswer
            question={currentQuestion}
            task={task}
            index={currentQuestionIndex}
            key={currentQuestionIndex}
            readText={{
              url:
                (readText && readText.url) ||
                (selectedText && selectedText.link),
              file_location:
                (readText && readText.file_location) ||
                (selectedText && selectedText.file_location)
            }}
            back={() => {}}
          />
        );
      case currentQuestion?.type === 2:
        return (
          <TaskMultiChoiceAnswer
            key={currentQuestionIndex}
            question={currentQuestion}
            index={currentQuestionIndex}
            task={task}
            readText={{
              url:
                (readText && readText.url) ||
                (selectedText && selectedText.link),
              file_location:
                (readText && readText.file_location) ||
                (selectedText && selectedText.file_location)
            }}
            back={() => {}}
          />
        );
      case currentQuestion?.type === 3:
        return (
          <PeerReview
            reflection={reflection}
            setReflection={setReflection}
            response={response}
            setResponse={setResponse}
            reply={reply}
            setReply={setReply}
            readText={readText}
            setReadText={setReadText}
            fetchedFeedback={fetchedFeedback}
            setFetchedFeedback={setFetchedFeedback}
          />
        );
    }
  };

  const renderMainScreen = () => {
    if (task_id && !task) {
      return null;
    }

    if (
      (action === "task" && task && task.id) ||
      (task?.task_type === "peerReview" && action === "" && task && task.id)
    ) {
      return renderTasksMainScreen();
    }

    const themeView =
      action === "themes" &&
      (themeMode === "extended" || themeMode === "note") &&
      selectedTheme &&
      selectedTheme.id;

    return (
      <>
        {/* ??? */}
        {(task || selectedText) &&
          ((grMode === "full" && stage < 3) ||
            (grMode === "light" && !showHighlights && stage === 0) ||
            (grMode === "light" && !showAnswers && stage === 1) ||
            action !== "poc") &&
          (!text_id ||
            text_id < 0 ||
            (readText && readText.id && text_id === readText.id.toString()) ||
            (selectedText &&
              selectedText.id &&
              text_id === selectedText.id.toString())) &&
          !themeView && (
            <BookView
              readOnly={
                action === "task" &&
                task &&
                task.submission_status === "Submitted"
              }
              minimalBar={action === "task" && task}
              task={action === "task" && task}
              readText={task ? readText : selectedText}
            />
          )}

        {/* Theme note/ */}
        {action === "themes" &&
          themeMode === "note" &&
          selectedTheme &&
          selectedTheme.id &&
          renderNote()}

        {/* { Theme extanded view } */}
        {action === "themes" &&
          themeMode === "extended" &&
          selectedTheme &&
          selectedTheme.id &&
          renderCardsEditView()}

        {/* GR Show answer view  */}
        {((grMode === "full" && stage === 3) ||
          (grMode === "light" && stage === 1 && showAnswers)) &&
          action === "poc" && (
            <CardsManager
              context="Answer"
              readText={{
                url:
                  (readText && readText.url) ||
                  (selectedText && selectedText.link),
                file_location:
                  (readText && readText.file_location) ||
                  (selectedText && selectedText.file_location)
              }}
            />
          )}

        {/* GR Show highlight view */}
        {grMode === "light" &&
          stage === 0 &&
          showHighlights &&
          action === "poc" && (
            <CardsManager
              disableDrag
              context="Highlight"
              readText={{
                url:
                  (readText && readText.url) ||
                  (selectedText && selectedText.link),
                file_location:
                  (readText && readText.file_location) ||
                  (selectedText && selectedText.file_location)
              }}
            />
          )}
        {grMode === "full" && stage === 4 && action === "poc" && (
          <SummaryManager />
        )}
        {grMode === "light" && stage === 2 && action === "poc" && (
          <SummaryManager />
        )}
      </>
    );
  };

  const sideBarWidth = useGetSideBarWidth();

  return (
    <div
      className={clsx(classes.readerViewContainer, classes.dirLtr)}
      style={{
        width: `calc(100vw - ${sideBarWidth}px)`
      }}
    >
      <SubmitTaskModal
        task={task}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedTaskId={selectedTaskId}
        taskAnswers={taskAnswers}
      />

      {isLoading ? (
        <PangeaSpinner />
      ) : (
        <>
          {action === "poc" && detailedInstructions && <GrInstructions />}
          {(action !== "poc" || !detailedInstructions) && renderMainScreen()}
        </>
      )}
    </div>
  );
};

export default injectIntl(ReaderView);
