import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { firebaseApp } from "../../../firebase";
import {
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  Editor,
  EditorState,
  ContentState,
  getDefaultKeyBinding,
  RichUtils
} from "draft-js";
import "draft-js/dist/Draft.css";
import { Link, findLinkEntities } from "../TaskFeedbackComment";
import { useIntl } from "react-intl";
import { Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  resetLink,
  setSelection,
  setCurrentLink
} from "../../../redux/LinkSlice";
import { useOnClickOutside } from "../../comments/useOnClickOutside";
const useStyles = makeStyles(theme => ({
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },

  left: {
    textAlign: "left"
  },
  dialogBtn: {
    color: "#ffffff"
  },
  wordCounter: {
    textAlign: "right",
    position: "absolute",
    bottom: "16px",
    color: "#616161",
    fontSize: "12px"
  },
  right: {
    textAlign: "right"
  },

  commentText: {
    fontSize: "20px",
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    width: "100%",
    resize: "none",
    border: "none"
  },

  feedbackHeader: {
    marginTop: "8px",
    marginBottom: "8px"
  }
}));

export default function OpenAnswerLinked(props) {
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ]);

  const currentUser = firebaseApp.auth().currentUser;
  //export default function TabPanel(props) {
  const classes = useStyles();
  const selected = useSelector(state => state.link.selected);
  //  console.log("selected", selected);
  const selectedContext = useSelector(state => state.link.selectionContext);
  const linkType = useSelector(state => state.link.linkType);
  const linkTarget = useSelector(state => state.link.linkTarget);
  const currentLink = useSelector(state => state.link.currentLink);
  //const [appliedStyle, setAppliedStyle] =useState(false);
  const [linkAdded, setLinkAdded] = useState(false);
  //const[changed,setChanged] = useState(false);
  const linkContent = useSelector(state => {
    return state.link.linkContent;
  });
  //  console.log("linkContent - 1", linkContent);
  const dispatch = useDispatch();
  const {
    selectionEvent,
    onChange,
    comment,
    placeholder,
    text,
    index,
    readOnly,
    task,
    showFeedbackLine,
    subcribeToUnload,
    componentName
  } = props;
  const componentContext = "feedbackComment" + index;
  const commentRef = useRef();
  const intl = useIntl();

  const words = intl.formatMessage({ id: "words", defaultMessage: "words" });

  const getInitialValue = () => {
    if (comment) {
      try {
        return EditorState.createWithContent(
          convertFromRaw(comment),
          decorator
        );
      } catch (error) {
        return EditorState.createEmpty(decorator);
      }
    } else if (text) {
      return EditorState.createWithContent(ContentState.createFromText(text));
    } else {
      return EditorState.createEmpty(decorator);
    }
  };

  const [editorState, setEditorState] = React.useState(getInitialValue());

  const onEditorChange = newState => {
    if (readOnly) {
      let currentContent = editorState.getCurrentContent();
      let newContent = newState.getCurrentContent();
      if (currentContent !== newContent) {
        return;
      }

      // console.log("newState.getSelection()", newState.getSelection().isCollapsed());
      if (!newState.getSelection().isCollapsed()) {
        let modifiedState = RichUtils.toggleInlineStyle(newState, "highlight");

        selectionEvent(convertToRaw(modifiedState.getCurrentContent()));
      }
      setEditorState(newState);
      return;
    }
    //   setChanged(true);
    const newSelectionState = newState.getSelection();

    if (!readOnly && selected !== !newSelectionState.isCollapsed()) {
      dispatch(
        setSelection({
          selected: !newSelectionState.isCollapsed(),
          selectionContext: componentContext
        })
      );
    }

    setEditorState(newState);
    subcribeToUnload &&
      onChange(convertToRaw(newState.getCurrentContent()), index);
  };

  React.useEffect(() => {
    //    console.log("currentLink",currentLink);
    if (currentLink && currentLink.name === componentName) {
      //setAppliedStyle(currentLink);
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(currentLink.selection),
          decorator
        )
      );
      dispatch(setCurrentLink(false));
    }
  }, [currentLink, componentName, decorator, dispatch]);

  React.useEffect(() => {
    if (
      !readOnly &&
      selected &&
      componentContext === selectedContext &&
      linkType &&
      linkContent &&
      editorState
    ) {
      // const {editorState, urlValue} = this.state;
      const contentState = editorState.getCurrentContent();
      let linkObjContent = {};
      if (linkTarget === "answer") {
        linkObjContent = { type: "answer", range: linkContent };
      } else if (linkTarget === "editor") {
        linkObjContent = {
          type: "editor",
          name: linkContent.name,
          selection: linkContent.selection
        };
      } else {
        linkObjContent = { cfis: linkContent, type: "text" };
      }

      // console.log("link COntent add", linkContent);
      const contentStateWithEntity = contentState.createEntity(
        "LINK",
        "MUTABLE",
        linkObjContent
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      // Apply entity
      let nextEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity
      });

      // Apply selection
      nextEditorState = RichUtils.toggleLink(
        nextEditorState,
        nextEditorState.getSelection(),
        entityKey
      );

      setEditorState(nextEditorState);
      setLinkAdded(true);
    }
  }, [
    selected,
    selectedContext,
    linkType,
    linkTarget,
    linkContent,
    componentContext,
    commentRef,
    editorState,
    readOnly
  ]);

  const myKeyBindingFn = e => {
    return !readOnly && getDefaultKeyBinding(e);
  };
  const styleMap = {
    highlight: {
      backgroundColor: "#53caf5aa"
    }
  };

  function getWordCount(editorState) {
    const plainText = editorState.getCurrentContent().getPlainText("");
    const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
    const cleanString = plainText.replace(regex, " ").trim(); // replace above characters w/ space
    const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
    return wordArray && wordArray.length;
  }

  const editorRef = useRef();
  useOnClickOutside(editorRef, () => {
    setEditorState(getInitialValue());
  });

  useEffect(() => {
    if (linkAdded) {
      setLinkAdded(false);
      dispatch(resetLink());
      commentRef &&
        commentRef.current &&
        commentRef.current.focus &&
        commentRef.current.focus();
    }
  }, [dispatch, linkAdded, setLinkAdded]);
  return (
    <Box>
      {showFeedbackLine && (
        <Typography className={classes.feedbackHeader} variant="body1">
          {currentUser.uid === task.teacher.uid
            ? "Your"
            : task.teacher.displayName + "s"}{" "}
          feedback
        </Typography>
      )}
      <Box
        ref={editorRef}
        style={{
          borderBottom: showFeedbackLine
            ? "1px solid rgba(0, 0, 0, .1)"
            : "none",
          paddingBottom: "4px"
        }}>
        <Editor
          onMouseUp={() => {
            // console.log("mouse up");
          }}
          customStyleMap={styleMap}
          keyBindingFn={myKeyBindingFn}
          onBlur={() => {
            !readOnly &&
              onChange(convertToRaw(editorState.getCurrentContent()), index);
          }}
          placeholder={placeholder}
          editorState={editorState}
          ref={commentRef}
          onChange={onEditorChange}
        />
      </Box>
      {props.showWordCount && (
        <Box className={clsx(classes.wordCounter)} component="div">
          {getWordCount(editorState)}{" "}
          {props.wordLimit > 0 && `/ ${props.wordLimit}`}
          {` ${words}`}
        </Box>
      )}
    </Box>
  );
}
