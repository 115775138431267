// Dependencies
import React, { useState } from "react";
import { firebaseApp, auth, firebaseFunctions } from "../../firebase";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

// Redux dependencies
import { setOriginalUser, setAuth } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/userSlice";

// Components
import LenguageSelect from "./LanguageSelect";
import AlertsDurationSelect from "./AlertsDurationSelect";

import CoursesAdmin from "./Admin/CoursesAdmin";
import CourseUsers from "./Admin/CourseUsers";
import CourseTexts from "./Admin/CourseTexts";
import CourseTasks from "./Admin/CourseTasks";

import ScrollBox from "../SharedComponents/ScrollBox";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel
} from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  link: {
    cursor: "pointer",
    textTransform: "underline",
    color: "blue"
  },

  left: {
    textAlign: "left"
  },
  profileContainer: {
    // temp style - remove when implamenting grid system
    display: "flex",
    flexBasis: "100%",

    paddingTop: "40px",
    paddingLeft: "40px"
  },
  sideBar: {},
  adminView: {
    flex: "1"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function ProfilePage() {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  //Ephemeral state
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("");

  //Redux state
  const userProfile = useSelector(state => state.user.userProfile);
  const originalAuth = useSelector(state => state.user.original_auth);
  const currentUser = useSelector(state => state.user.auth);
  const darkMode = userProfile.darkMode;
  const language = userProfile.language;

  //Variables
  const lanaguages = [
    { lang: "en", rtl: false },
    { lang: "he", rtl: true }
  ];

  //Behavior
  firebaseApp
    .auth()
    .currentUser.getIdTokenResult(true)
    .then(idTokenResult => {
      // Confirm the user is an Admin.
      if (idTokenResult.claims.role && idTokenResult.claims.role === "admin") {
        // Show admin UI.
        setIsAdmin(true);
      } else {
        // Show regular user UI.
        setIsAdmin(false);
      }
    })
    .catch(error => {});

  // Render
  return (
    <ScrollBox>
      <Box
        className={clsx(
          classes.profileContainer,
          language.rtl && classes.right,
          !language.rtl && classes.left
        )}
      >
        <Box className={clsx(classes.sideBar)}>
          <img
            alt="profile"
            src={originalAuth === -1 && currentUser.photoURL}
            width="100"
          />
          {originalAuth && originalAuth !== -1 && (
            <Typography
              className={classes.link}
              onClick={() => {
                var impersonateFunc = firebaseFunctions.httpsCallable(
                  "adminFunctions-stopImpersonate"
                );
                impersonateFunc({ token: originalAuth }).then(response => {
                  //  console.log("response.data", response.data);
                  auth
                    .signInWithCustomToken(response.data)
                    .then(userCredential => {
                      //    console.log("userCredential", userCredential.user);
                      let user = userCredential.user;
                      dispatch(setOriginalUser(-1));
                      dispatch(
                        setAuth({
                          displayName: user.displayName,
                          photoURL: user.photoURL,
                          uid: user.uid,
                          email: user.email
                        })
                      );

                      history.push("/profile");
                    });
                });
              }}
            >
              Stop impersonation
            </Typography>
          )}

          <Typography>Name</Typography>
          <Typography>
            {originalAuth === -1 ? currentUser.displayName : currentUser.uid}
          </Typography>
          <Typography>Email</Typography>
          <Typography>
            {originalAuth === -1 ? currentUser.email : currentUser.uid}
          </Typography>

          <LenguageSelect />

          <br />
          <FormControlLabel
            control={
              <Switch
                checked={darkMode}
                onChange={e => {
                  dispatch(setProfile({ ...userProfile, darkMode: !darkMode }));
                }}
              />
            }
            label="Dark Mode"
          />
          <AlertsDurationSelect />
        </Box>

        {isAdmin && (
          <Box className={classes.adminView}>
            Admin
            <CoursesAdmin
              courseId={selectedCourse}
              onSelectCourse={courseId => {
                setSelectedCourse(courseId);
              }}
            />
            {selectedCourse && (
              <>
                <CourseUsers courseId={selectedCourse} />
                <CourseTexts courseId={selectedCourse} />
                <CourseTasks courseId={selectedCourse} />
              </>
            )}
          </Box>
        )}
      </Box>
    </ScrollBox>
  );
}
