import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { saveGRState } from "../../redux/firebaseMiddleware";
import {
  Paper,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { setStage, setGrMode } from "../../redux/grSlice";
import MyDefaultPanel from "./MyDefaultPanel";
import { useGetTheme } from "../../hooks";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    background: "#333333",
    display: "flex",
    flexDirection: "column"
  },
  ripple: {
    width: "20px",
    height: "20px"
  },
  menuIcon: {
    color: "#e0e0e0",
    height: 48,
    flexShrink: 0
  },
  stepWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  stepTabs: {
    display: "flex",
    listStyle: "none",
    width: "100%",
    padding: "15px 0px 15px 17px",
    margin: 0
  },
  tabHeader: {
    borderRadius: 50,
    width: 40,
    height: 40,
    fontSize: 16,
    marginRight: theme.spacing(2),
    fontFamily: "monospace",
    minWidth: "unset",
    transition: ".15s ease-out",
    "&:last-of-type": {
      marginRight: 0
    }
  },
  selected: {
    color: "#333333",
    background: theme.palette.secondary.main,
    "&:hover": {
      color: "#333333",
      background: theme.palette.secondary.dark
    }
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px",
    "&:hover": {}
  }
}));

export default function PocBar(props) {
  const classes = useStyles();
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);
  const dispatch = useDispatch();
  const [openQuestions, setOpenQuestions] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuElRef = useRef();

  useEffect(() => {
    if (stage === 4) {
      setOpenQuestions([]);
    }
  }, [stage]);

  useEffect(() => {
    dispatch(saveGRState());
  }, [grMode, stage, selectedQuestionId, dispatch]);

  function ModeMenu() {
    const theme = useGetTheme();

    return (
      <ThemeProvider theme={theme}>
        <Menu
          anchorEl={menuElRef.current}
          keepMounted
          classes={{ list: classes.avatarMenuLtr }}
          open={Boolean(menuOpen)}
          onClose={() => setMenuOpen(false)}
          getContentAnchorEl={null}
          disableScrollLock={true}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setMenuOpen(false);
              dispatch(setGrMode("light"));
              dispatch(setStage({ stage: 0 }));
            }}
          >
            <FormattedMessage id="gr.mode.3" defaultMessage="3 step guide" />
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setMenuOpen(false);
              dispatch(setGrMode("full"));
              dispatch(setStage({ stage: 0 }));
            }}
          >
            <FormattedMessage id="gr.mode.5" defaultMessage="5 step guide" />
          </MenuItem>
        </Menu>
      </ThemeProvider>
    );
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Box className={classes.stepWrapper}>
        <Box value={stage} className={classes.stepTabs}>
          <Button
            variant="outlined"
            color="secondary"
            className={clsx(classes.tabHeader, {
              [classes.selected]: stage === 0
            })}
            onClick={() => dispatch(setStage({ stage: 0 }))}
          >
            1
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            className={clsx(classes.tabHeader, {
              [classes.selected]: stage === 1
            })}
            onClick={() => dispatch(setStage({ stage: 1 }))}
          >
            2
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            className={clsx(classes.tabHeader, {
              [classes.selected]: stage === 2
            })}
            onClick={() => dispatch(setStage({ stage: 2 }))}
          >
            3
          </Button>
          {grMode === "full" && (
            <Button
              variant="outlined"
              color="secondary"
              className={clsx(classes.tabHeader, {
                [classes.selected]: stage === 3
              })}
              onClick={() => dispatch(setStage({ stage: 3 }))}
            >
              4
            </Button>
          )}
          {grMode === "full" && (
            <Button
              variant="outlined"
              color="secondary"
              className={clsx(classes.tabHeader, {
                [classes.selected]: stage === 4
              })}
              onClick={() => dispatch(setStage({ stage: 4 }))}
            >
              5
            </Button>
          )}
        </Box>
        <IconButton
          aria-label="search, coming soon"
          className={clsx(
            classes.menuIcon
            // classes.menuIcon
          )}
          ref={menuElRef}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <ModeMenu />
      </Box>
      <MyDefaultPanel
        openQuestions={openQuestions}
        setOpenQuestions={setOpenQuestions}
        value={stage}
        index={0}
      />
      <MyDefaultPanel
        openQuestions={openQuestions}
        setOpenQuestions={setOpenQuestions}
        value={stage}
        index={1}
      />
      <MyDefaultPanel
        openQuestions={openQuestions}
        setOpenQuestions={setOpenQuestions}
        value={stage}
        index={2}
      />
      <MyDefaultPanel
        openQuestions={openQuestions}
        setOpenQuestions={setOpenQuestions}
        value={stage}
        index={3}
      />
      <MyDefaultPanel
        openQuestions={openQuestions}
        setOpenQuestions={setOpenQuestions}
        value={stage}
        index={4}
      />
    </Paper>
  );
}
