// Dependencies
import React, { useState, useRef, useEffect } from "react";
import { firebaseFunctions } from "../../firebase";
import { useHistory, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import clsx from "clsx";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTextId } from "../../redux/textSlice";
import { setBreadcrumbs } from "../../redux/readerActionsSlice";
// import { setSelectedCourse } from "./redux/userSlice";

// Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import ScrollBox from "../SharedComponents/ScrollBox";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Link,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Chip
} from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    flex: 1,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center"
  },
  pointer: {
    cursor: "pointer"
  },
  libraryContainer: {
    // flex: 1,
    height: "100%",
    width: "83.333333%", // 10 culumns on a 12 column grid
    paddingInline: 40,
    alignItems: "center",
    position: "relative",

    [theme.breakpoints.up("desktop")]: {
      maxWidth: 840,
      marginInline: "auto"
    }
  },
  tableContainer: {
    // width: "80%",
    // marginLeft: "auto",
    // marginRight: "auto",
    // marginBottom: "24px"
  },
  tableHead: {
    fontWeight: "800"
  },
  headerFont: {
    fontSize: "3.75rem"
  },
  link: {
    color: theme.palette.text.primary
  },
  libraryHeader: {
    marginBlock: theme.spacing(7.5)
  },

  cell: {
    marginTop: "16px",
    marginBottom: "16px"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  readerViewContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerLtr: {
    left: 0
  },
  drawer: {
    width: "56px",
    height: "100vh",
    position: "fixed",
    background: "black",
    zIndex: 120,
    top: 0,
    bottom: 0,
    whiteSpace: "nowrap"
  },
  dirLtr: {
    direction: "ltr"
  },
  content: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)",
    display: "grid"
  },
  contentActionOpen: {
    gridTemplateColumns: "repeat(18,1fr)"
  },
  bookContainerActionOpen: {
    gridColumn: " 7 / 17 "
  }
}));

const DraggableComponent = (id, index, isDarkMode) => props => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            isDarkMode
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = onDragEnd => props => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"1"} direction="vertical">
        {provided => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

const getItemStyle = (isDragging, draggableStyle, isDarkMode) => {
  return {
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(!isDragging && { cursor: "pointer" }),
    ...(isDragging && {
      cursor: "grabbing",
      display: "table",
      background: isDarkMode ? "rgb(5,15,15)" : "rgb(235,235,235)"
    })
  };
};

function Library({ ...props }) {
  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { course_id } = useParams();

  // Ephemeral state
  const [rows, setRows] = useState(null);

  // Redux state
  const selectedCourse = useSelector(
    state =>
      state.user.userProfile.selectedCourse?.id == course_id &&
      state.user.userProfile.selectedCourse
  );
  const isDarkMode = useSelector(state => state.user.userProfile.darkMode);
  // Bahavior
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.library",
        defaultMessage: "Library"
      }),
      resetCourse: true
    });

    if (selectedCourse) {
      parts.push({ text: selectedCourse.name, course: selectedCourse });
    }
    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, [selectedCourse, dispatch, intl]);

  React.useEffect(() => {
    let func = course_id
      ? firebaseFunctions.httpsCallable("courses-getReadText")
      : firebaseFunctions.httpsCallable("courses-getAllTexts");

    // func({ course: course?.id })
    func({ course: course_id })
      .then(response => {
        setRows(response.data);
      })
      .catch(err => console.log(err));
  }, [selectedCourse, course_id]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(rows, result.source.index, result.destination.index);

    //call function for reorder:
    let func = firebaseFunctions.httpsCallable("courses-reorderCourseTexts");

    func({ course: selectedCourse.id, texts: items })
      .then(response => {
        // setRows(response.data);
      })
      .catch(err => {
        console.log("eror in reorder", err);
      });

    setRows(items);
  };

  //Render
  return (
    <Box className={classes.container}>
      {!rows ? (
        <PangeaSpinner />
      ) : (
        <ScrollBox flexDirection="column" alignItems="center">
          <Box className={classes.libraryContainer}>
            <Box className={classes.libraryHeader}>
              <Typography className={classes.headerFont} variant="h1">
                {selectedCourse
                  ? selectedCourse.name
                  : intl.formatMessage({
                      id: "appBar.library",
                      defaultMessage: "Library"
                    })}
              </Typography>
            </Box>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}
                    >
                      <FormattedMessage
                        id="library.author"
                        defaultMessage="Author"
                      />
                    </TableCell>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}
                    >
                      <FormattedMessage
                        id="library.title"
                        defaultMessage="Title"
                      />
                    </TableCell>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}
                    >
                      <FormattedMessage
                        id="library.category"
                        defaultMessage="Category"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  component={
                    selectedCourse && selectedCourse.course_role === "Teacher"
                      ? DroppableComponent(onDragEnd)
                      : "tbody"
                  }
                >
                  {rows?.map((row, index) => (
                    <TableRow
                      className={classes.pointer}
                      onClick={e => {
                        dispatch(setSelectedTextId(row.id));
                        //TODO only if action is sq3r
                        history.push("/reader/text/" + row.id);

                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      component={
                        selectedCourse?.course_role === "Teacher"
                          ? DraggableComponent("t" + row.id, index, isDarkMode)
                          : "tr"
                      }
                      key={row.id}
                    >
                      <TableCell className={classes.cell}>
                        <Link
                          className={classes.link}
                          href={"/reader/text/" + row.id}
                        >
                          {row.author}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.cell} align="left">
                        <Link
                          className={classes.link}
                          href={"/reader/text/" + row.id}
                        >
                          {" "}
                          {row.name}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.cell} align="left">
                        {row.categories && row.categories.length
                          ? row.categories.split(",").map((item, index) => {
                              return <Chip key={index} label={item} />;
                            })
                          : " "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ScrollBox>
      )}
    </Box>
  );
}

export default injectIntl(Library);
