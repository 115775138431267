// Dependencies
import React from "react";

// Redux dependencies

// Components

// Material UI
import { Box, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      backgroundImage: 'url("/resources/landingImage20211023.jpg")',
      backgroundSize: "auto 100vh",
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat",
    },
    main: {
      flexBasis: "50%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      whiteSpace: "nowrap",
    },
    loginBox: {
      marginTop: "20%",
      height: "50%",
      display: "flex",
      flexFlow: "column nowrap",
      flexBasis: "50%",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
    },
  };
});

export default function PermissionDenied() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.main}>
        <Box className={classes.loginBox}>
          <Typography component="h1" variant="h3">
            Pangea is in Private Beta
          </Typography>
          <Typography component="p" variant="h5">
            Contact admin to get access
          </Typography>
          <Link Link href="/">
            Back to login
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
