import React from "react";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Box, Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative",
  },
  blueBg: {
    backgroundColor: "#168FEE",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1172be",
    },
  },

  stepper: {
    marginTop: "20px",
    // background: "#e8e8e8",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px",
    textAlign: "right",
  },
  btn: {
    width: "32px",
    height: "32px",
    minWidth: "32px",
    minHeight: "32px",
    borderRadius: "50%",
    marginLeft: "4px",
    marginRight: "4px",
  },
  btnPublish: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  stepLabel: {
    fontSize: "24px",
  },
  stepRtl: {
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "8px",
      paddingRight: "0px",
    },
  },
  stepFirstLtr: {
    paddingLeft: "0px",
  },
  stepFirstRtl: {
    paddingRight: "0px",
  },
  stepLast: {
    paddingRight: "0px",
  },
  stepIcon: {
    width: "36px",
    height: "36px",
  },
  cancelBtn: {
    position: "absolute",
  },
  cancelBtnRtl: {
    left: "0px",
  },
  cancelBtnLtr: {
    right: "0px",
  },
}));

export default function NavigationButtons(props) {
  const classes = useStyles();
  const intl = useIntl();

  const rtl = useSelector((state) => state.user.userProfile.rtl);

  function renderButtons() {
    let backBtn = "";

    if (props.activeStep >= 1) {
      backBtn = (
        <Tooltip
          title={intl.formatMessage({ id: "back", defaultMessage: "Back" })}
          arrow
          placement={rtl ? "right" : "left"}
        >
          <Button
            onClick={() => props.setActiveStep(props.activeStep - 1)}
            className={classes.btn}
            variant="outlined"
          >
            {rtl ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronLeftIcon fontSize="small" />
            )}
          </Button>
        </Tooltip>
      );
    }

    if (props.activeStep === 2) {
      return (
        <Box className={classes.btnContainer}>
          {backBtn}

          <Button
            className={clsx(classes.btnPublish, classes.blueBg)}
            elevation={2}
            onClick={() => {
              props.validateForm() && props.saveTask("Submitted");
            }}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Publish task" id="save.task" />
          </Button>
        </Box>
      );
    }
    return (
      <Box className={classes.btnContainer}>
        {backBtn}
        <Tooltip
          title={intl.formatMessage({
            id: "next",
            defaultMessage: "Next Step",
          })}
          arrow
          placement={rtl ? "left" : "right"}
        >
          <Button
            className={clsx(classes.btn, classes.blueBg)}
            elevation={2}
            onClick={() => {
              props.validateForm() && props.setActiveStep(props.activeStep + 1);
            }}
            variant="contained"
          >
            {rtl ? (
              <ChevronLeftIcon fontSize="small" />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </Button>
        </Tooltip>
      </Box>
    );
  }

  return <>{renderButtons()}</>;
}
