// Dependencies
import React from "react";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";

// Redux dependencies

// Components
import FindTaskAnswer from "./FindTaskAnswer";
import OpenAnswer from "./OpenAnswer";
import CreateMultiQuestion from "./CreateMultiQuestion";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import {
  Box,
  Paper,
  Divider,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  ListItemText
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  tasksHeader: {
    textAlign: "left",
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  stepper: {
    marginTop: "20px",
    // background: "white",
    width: "100%"
  },
  menu: {
    // background: "white",
  },
  paperClass: {
    marginTop: "20px",
    marginBottom: "10px",
    // background: "white",
    borderRadius: "0px",
    paddingBottom: "16px",
    textAlign: "left",

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#168FEE"
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderBottom: "2px solid #168FEE"
      }
    },
    "&:after": {
      borderBottom: "2px solid #168FEE"
    },
    "&:focused::after": {
      borderBottom: "2px solid #168FEE"
    },
    "& .Muilabel-root.Mui-focused": {
      color: "#168FEE"
    }
  },
  menuBg: {
    "& ul": {},
    borderRadius: "8px"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  pntText: {
    lineHeight: "36px"
  },
  pntInput: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "2px",
    paddingLeft: "5px",
    width: "60px",
    borderRadius: "4px",
    border: "1px solid #adadad",
    "&:hover": {
      border: "1px solid #168fee"
    },
    "& input": {
      textAlign: "center"
    }
  },
  selectInput: {
    verticalAlign: "bottom"
  },
  selectContainer: {
    width: "25%",
    verticalAlign: "bottom",
    "& .MuiListItemText-root": {
      marginTop: "3px"
    }
  },
  select: {
    width: "100%",
    paddingLeft: "8px",
    textAlign: "left"
  },
  emptyPad: {
    paddingTop: "1px"
  },
  noPad: {
    padding: "0px",
    marginBottom: "0px",
    lineHeight: "1rem"
  },

  divider: {
    backgroundColor: "#dedede",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  btncontainer: {
    // display: "inline-block",
    // position: "absolute",
    // right: "24px",
  },
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  stepLabel: {
    fontSize: "24px"
  },
  removeQuote: {
    width: "36px",
    height: "36px"
  },
  step: {},
  questionTxt: {
    width: "70%"
  },
  blueBg: {
    backgroundColor: "#168FEE",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1172be"
    }
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    lineHeight: "36px"
  },

  stepIcon: {
    width: "36px",
    height: "36px"
  },
  container: {
    width: "100w",
    height: "100vh"
  },
  dialog: {
    backgroundColot: "red"
  },
  cancelBtn: {
    // position: "absolute",
    // right: "0px",
  },
  tableHead: {
    fontWeight: "800"
  }
}));

function TaskQuestionView({
  question,
  includeCitation,
  type,
  points,
  questionIndex,
  answer,
  options,
  text,
  wordLimit,
  shouldAutoFocus,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Variables
  const questionTypes = [
    {
      id: 0,
      txt: intl.formatMessage({
        id: "task.type.find",
        defaultMessage: "Find in Text"
      })
    },
    {
      id: 1,
      txt: intl.formatMessage({
        id: "task.type.open",
        defaultMessage: "Open Ended Question"
      })
    },
    {
      id: 2,
      txt: intl.formatMessage({
        id: "task.type.multiple",
        defaultMessage: "Multiple Choice"
      })
    }
  ];

  // Behavior
  const renderDetails = () => {
    return (
      <Box>
        {type >= 0 && type === 0 && (
          <React.Fragment>
            <Divider className={classes.divider} />
            <FindTaskAnswer
              readOnly={props.readOnly}
              addHighlight={hl => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options
                    },
                    { ...answer, quotes: [...answer.quotes, hl] },
                    questionIndex
                  );
              }}
              removeHighlight={hl => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options
                    },
                    {
                      ...answer,
                      quotes: answer.quotes.filter(el => el.cfi !== hl.cfi)
                    },
                    questionIndex
                  );
              }}
              highlights={answer && answer.quotes}
              url={text && text.url}
              location={text && text.location}
            />
          </React.Fragment>
        )}
        {type >= 0 && type === 1 && (
          <React.Fragment>
            <Divider className={classes.divider} />
            <OpenAnswer
              readOnly={props.readOnly}
              addHighlight={hl => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options
                    },
                    { ...answer, quotes: [...answer.quotes, hl] },
                    questionIndex
                  );
              }}
              removeHighlight={hl => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options
                    },
                    {
                      ...answer,
                      quotes: answer.quotes.filter(el => el.cfi !== hl.cfi)
                    },
                    questionIndex
                  );
              }}
              answer={answer}
              url={text && text.url}
              location={text && text.location}
              includeCitation={includeCitation}
              setIncludeCitation={value => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      includeCitation: value,
                      type: type,
                      points: points,
                      options: options
                    },
                    answer,
                    questionIndex
                  );
              }}
              highlights={answer && answer.quotes}
              onWordLimit={newLimit => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options,
                      wordLimit: newLimit
                    },
                    { ...answer, wordLimit: newLimit },
                    questionIndex
                  );
              }}
              onConcepts={concepts => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options,
                      wordLimit: wordLimit
                    },
                    { ...answer, concepts: concepts },
                    questionIndex
                  );
              }}
            />
          </React.Fragment>
        )}
        {type >= 0 && type === 2 && (
          <React.Fragment>
            <Divider className={classes.divider} />
            <CreateMultiQuestion
              readOnly={props.readOnly}
              addHighlight={hl => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options
                    },
                    { ...answer, quotes: [...answer.quotes, hl] },
                    questionIndex
                  );
              }}
              removeHighlight={hl => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: options
                    },
                    {
                      ...answer,
                      quotes: answer.quotes.filter(el => el.cfi !== hl.cfi)
                    },
                    questionIndex
                  );
              }}
              answer={answer}
              url={text && text.url}
              location={text && text.location}
              options={options}
              highlights={answer && answer.quotes}
              includeCitation={includeCitation}
              setIncludeCitation={value => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      includeCitation: value,
                      type: type,
                      points: points,
                      options: options
                    },
                    answer,
                    questionIndex
                  );
              }}
              onUpdate={(newOptions, updatedAnswer) => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      type: type,
                      points: points,
                      options: newOptions
                    },
                    { ...answer, shouldSelect: updatedAnswer },
                    questionIndex
                  );
              }}
            />
          </React.Fragment>
        )}
        <Divider className={classes.divider} />
        <Box className={classes.actions}>
          <Box className={classes.pointsCtr}>
            <label htmlFor={`points${questionIndex}`}>
              <Typography
                className={classes.pntText}
                variant="body1"
                component="span">
                <FormattedMessage defaultMessage="Points" id="task.points" />
              </Typography>
            </label>

            <TextField
              id={`points${questionIndex}`}
              value={points}
              className={classes.pntInput}
              onChange={e => {
                props.onUpdate &&
                  props.onUpdate(
                    {
                      question: question,
                      points: e.target.value,
                      type: type,
                      options: options
                    },
                    answer,
                    questionIndex
                  );
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: props.readOnly,
                disableUnderline: true,
                style: { height: "36px" }
              }}
            />
          </Box>
          <Box className={classes.btncontainer}>
            <IconButton
              onClick={props.onDelete}
              className={clsx(classes.removeQuote)}
              aria-label="delete question">
              <DeleteIcon />
            </IconButton>

            {question && question.length > 2 && type >= 0 && (
              <IconButton
                onClick={() => {
                  props.onCopy &&
                    props.onCopy(
                      {
                        question: question,
                        type: type,
                        points: points,
                        options: options,
                        wordLimit: wordLimit
                      },
                      answer
                    );
                }}
                className={clsx(classes.removeQuote)}
                aria-label="duplicate question">
                <CopyIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  // Render
  return (
    <>
      <Typography variant="h6">
        {intl.formatMessage({
          id: "task.question",
          defaultMessage: "Question"
        }) +
          " #" +
          (questionIndex + 1)}
      </Typography>

      <Divider className={classes.divider} />

      <Box className={clsx(classes.inputContainer)}>
        <TextField
          id={`questionFormulation${questionIndex}`}
          InputProps={{
            readOnly: props.readOnly
          }}
          autoFocus={shouldAutoFocus && (!question || !question.length)}
          className={classes.questionTxt}
          multiline={true}
          label={intl.formatMessage({
            id: "task.question.formulation",
            defaultMessage: "Question formulation"
          })}
          value={question}
          onChange={e => {
            props.onUpdate &&
              props.onUpdate(
                {
                  question: e.target.value,
                  type: type,
                  points: points,
                  options: options
                },
                answer,
                questionIndex
              );
          }}
        />
        <TextField
          id={`questionType${questionIndex}`}
          InputProps={{
            readOnly: props.readOnly
          }}
          className={clsx(classes.selectContainer)}
          label={intl.formatMessage({
            id: "task.question.type",
            defaultMessage: "Type of question"
          })}
          select
          value={type}
          dropDownMenuProps={{ classes: { paper: classes.menuBg } }}
          MenuProps={{ classes: { paper: classes.menuBg } }}
          onChange={e => {
            props.onUpdate &&
              props.onUpdate(
                {
                  question: question,
                  includeCitation: false,
                  type: e.target.value,
                  points: points,
                  options: options
                },
                answer,
                questionIndex
              );
          }}>
          {questionTypes.map((item, index) => {
            return (
              <MenuItem
                className={classes.menu}
                key={index}
                onClick={() => {
                  props.onUpdate &&
                    props.onUpdate(
                      {
                        question: question,
                        type: item.id,
                        points: points,
                        options: options
                      },
                      answer,
                      questionIndex
                    );
                }}
                value={item.id}>
                <ListItemText
                  disableTypography={true}
                  className={classes.noPad}>
                  {item.txt}
                </ListItemText>
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      {renderDetails()}
    </>
  );
}

export default TaskQuestionView;
