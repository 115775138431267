// Dependencies
import React from "react";
import { useIntl } from "react-intl";

// Redux dependencies

// Components
import CoursesDropdown from "./CoursesDropdown";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Divider } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => {
  return {
    container: {
      width: 384,
      height: "100%",
      paddingTop: theme.spacing(2),
      display: "flex",
      flexShrink: 0,
      flexDirection: "column",
      backgroundColor: "#333333",
      overflowY: "auto",
      zIndex: "140",
      transition: "top 0.6s",
      [theme.breakpoints.up("desktop")]: {
        width: 468
      }
    },
    header: {
      width: "100%",
      paddingInlineStart: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    title: {
      color: theme.palette.grey["400"],
      letterSpacing: "1px",
      lineHeight: "24px"
    },
    subtitle: {
      color: theme.palette.grey["50"],
      letterSpacing: "0.15px"
    },
    divider: {
      backgroundColor: theme.palette.grey["700"],
      marginTop: theme.spacing(1)
    }
  };
});

export default function CourseSelectorSidebar({ ...props }) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral State

  // Redux State

  // Bahavior

  // Render
  return (
    <Box component="nav" className={classes.container}>
      <Box className={classes.header}>
        <Typography
          className={classes.title}
          color="textPrimary"
          variant="overline"
        >
          {intl.formatMessage({
            id: "courses.classes",
            defaultMessage: "Classes"
          })}
        </Typography>
        <Typography className={classes.subtitle} variant="h6">
          {intl.formatMessage({
            id: "courses.subtitle",
            defaultMessage: "My Classes"
          })}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <CoursesDropdown />
    </Box>
  );
}
