import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {  useIntl } from "react-intl";

import { Stepper, Step, StepLabel } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative",
  },
  blueBg: {
    backgroundColor: "#168FEE",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1172be",
    },
  },

  stepper: {
    marginTop: "20px",
    // background:'#e8e8e8',
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px",
  },
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  stepLabel: {
    fontSize: "24px",
  },
  stepRtl: {
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "8px",
      paddingRight: "0px",
    },
  },
  stepFirstLtr: {
    paddingLeft: "0px",
  },
  stepFirstRtl: {
    paddingRight: "0px",
  },
  stepLast: {
    paddingRight: "0px",
  },
  step: {},
  stepIcon: {
    width: "36px",
    height: "36px",
  },
  cancelBtn: {
    position: "absolute",
  },
  cancelBtnRtl: {
    left: "0px",
  },
  cancelBtnLtr: {
    right: "0px",
  },
}));

export default function TaskStepper(props) {
  const classes = useStyles();
  const intl = useIntl();

  const steps = [
    intl.formatMessage({ id: "task.readings", defaultMessage: "Readings" }),
    intl.formatMessage({ id: "task.questions", defaultMessage: "Questions" }),
    intl.formatMessage({ id: "task.submit", defaultMessage: "Submit" }),
  ];

  return (
    <Stepper className={classes.stepper} activeStep={props.activeStep}>
      {steps.map((label, index) => {
        const stepProps = {};
        const labelProps = {};
        return (
          <Step
            key={index}
            className={clsx(
              classes.step,
              index === 0 && !props.rtl && classes.stepFirstLtr,
              classes.step,
              index === 0 && props.rtl && classes.stepFirstRtl,
              index === steps.length - 1 && classes.stepLast,
              props.rtl && classes.stepRtl
            )}
            {...stepProps}
          >
            <StepLabel
              StepIconProps={{ classes: { root: classes.stepIcon } }}
              classes={{ label: classes.stepLabel }}
              {...labelProps}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
