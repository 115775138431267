import React, { useState, useRef, useEffect } from "react";
//,{ useState, useEffect }
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";

import useOnClickOutside from "../../../hooks/useOnClickOutside";
//import { firebaseFunctions} from '../../firebase';
import CitationDialog from "./CitationDialog";
import {
  Box,
  Link,
  TextField,
  Input,
  InputAdornment,
  IconButton,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox
  //  TextareaAutosize,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  expandMsg: {
    display: "block",
    cursor: "pointer"
  },
  paddedContent: {
    paddingTop: "16px",
    paddingbottom: "16px",
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  wordLmt: {
    // marginLeft: "8px",
    lineHeight: "32px",
    "& input": {
      textAlign: "center"
    }
  },
  concept: {
    boxSizing: "border-box",
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "4px",
    marginBottom: "4px",
    resize: "none",
    border: "none",
    lineHeight: "20px",
    letteSpacing: "0.13px",
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    fontSize: "16px",
    // width:'10em',
    width: "23%",
    fontFamily: theme.typography.fontFamily,
    "&:focus": {
      outline: "none"
    },
    "& ::placeholder": {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#adadad"
    }
  },
  conceptsContainer: {
    position: "relative"
  },

  includeCitation: {
    display: "inline-block",
    width: "75%",
    // color: "#BDBDBD",
    color: theme.palette.text.disabled
  },
  wordLimit: {
    display: "inline-block",
    width: "25%"
    // position:'absolute',
    // display:'inline-block',
    // right:'0px',
    // bottom:'8px',
  },
  conceptContainer: {
    // width:'calc(100% - 170px)',
    width: "100%"
  },
  fakeLink: {
    fontSize: "1rem",
    color: "#168fee",
    "&:hover": {
      textDecoration: "none"
    }
  },
  wordInput: {
    marginLeft: "16px",
    paddingLeft: "5px",
    marginRight: "16px",
    borderRadius: "4px",
    border: "1px solid #adadad",
    "&:hover": {
      border: "1px solid #168fee"
    },
    "& input": {
      textAlign: "center"
    }
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  checkboxChecked: {
    // color: "rgba(0, 0, 0, 0.87)",
    color: theme.palette.text.primary
  },
  citationCheckbox: {
    color: "#BDBDBD"
  }
}));

function OpenAnswer(props) {
  const classes = useStyles();
  const [expand, setExpand] = useState(
    props.answer.concepts && props.answer.concepts.length
  );
  // const [concepts, setConcepts] = useState([]);
  const [newConcept] = useState([]);
  const [editMode, setEditMode] = useState(-1);
  const [editKeywords, setEditKeywords] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [shouldFocus, setShouldFocus] = useState(false);
  const keywordsContainerRef = useRef();
  const intl = useIntl();
  useOnClickOutside(keywordsContainerRef, () => setEditKeywords(false));
  const toggleExpand = () => {
    setExpand(!expand);
    if (!expand) {
      setShouldFocus(true);
    }
    setEditMode(-1);
  };

  const newConceptRef = useRef();
  const wordLimitRef = useRef();
  useEffect(() => {
    if (shouldFocus && newConceptRef && newConceptRef.current) {
      newConceptRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus, newConceptRef]);
  const renderConceptsContainer = () => {
    if (expand) {
      return (
        <Box className={classes.conceptContainer}>
          {props.answer.concepts.map((concept, index) => {
            return (
              <Input
                readOnly={props.readOnly}
                key={index} //othersize deleting a concept in the middle causes problems
                type="text"
                value={concept}
                className={classes.concept}
                autoFocus={!props.readOnly && index === editMode}
                onChange={e => {
                  let items = [...props.answer.concepts];
                  items[index] = e.target.value;
                  props.onConcepts(items);
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    setEditMode(-1);
                    newConceptRef.current.focus();
                  }
                }}
                endAdornment={
                  !props.readOnly && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          let items = [...props.answer.concepts];
                          items.splice(index, 1);
                          props.onConcepts(items);
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                inputProps={{
                  "aria-label": `concept ${index + 1}`
                }}
              />
            );
          })}

          {expand && editKeywords && !props.readOnly && renderNewConcept()}
        </Box>
      );
    }
  };
  const renderNewConcept = () => {
    if (!editKeywords || props.readOnly) {
      return <></>;
    }
    return (
      <TextField
        inputRef={newConceptRef}
        placeholder={
          intl.formatMessage({
            id: "task.concept",
            defaultMessage: "Concept"
          }) +
          " " +
          (props.answer.concepts.length + 1)
        }
        key={props.answer.concepts.length}
        defaultValue={newConcept}
        className={classes.concept}
        onChange={e => {
          setEditMode(props.answer.concepts.length);
          let items = [...props.answer.concepts, e.target.value];
          props.onConcepts(items);
        }}
        aria-label={`concept ${props.answer.concepts.length + 1}`}
      />
    );
  };

  return (
    <>
      <Box>
        <Box
          className={clsx(
            classes.includeCitation,
            props.includeCitation && classes.checkboxChecked
          )}>
          <Typography component="span">
            <FormControlLabel
              control={
                <Checkbox
                  readOnly={props.readOnly}
                  className={classes.citationCheckbox}
                  checked={props.includeCitation}
                  onChange={() => {
                    props.setIncludeCitation(!props.includeCitation);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography>
                  {intl.formatMessage({
                    id: "task.includeCitation",
                    defaultMessage: "Include citation in answer"
                  })}
                </Typography>
              }
            />
          </Typography>
          {props.includeCitation && (
            <Typography component="span">
              <Link
                component="button"
                variant="body2"
                className={clsx(classes.fakeLink)}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenDialog(true);
                }}>
                <FormattedMessage
                  id="task.selectCitation"
                  defaultMessage=" Select citation"
                />
              </Link>
            </Typography>
          )}
        </Box>
        <Box className={classes.wordLimit}>
          <Typography component="span" className={classes.wordLmt}>
            <FormControlLabel
              control={
                <TextField
                  inputRef={wordLimitRef}
                  id="standard-number"
                  className={classes.wordInput}
                  value={
                    props.answer.wordLimit >= 0 ? props.answer.wordLimit : ""
                  }
                  onChange={e => {
                    props.onWordLimit(e.target.value);
                  }}
                  onKeyPress={ev => {
                    if (ev.key === "Enter") {
                      // Do code here
                      wordLimitRef.current.blur();
                    }
                  }}
                  type="number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: props.readOnly,
                    disableUnderline: true,
                    style: { height: "36px", width: "56px" }
                  }}
                />
              }
              label={intl.formatMessage({
                id: "task.wordLimit",
                defaultMessage: "Word Limit"
              })}
            />
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Box
        onClick={() => {
          setEditKeywords(true);
        }}
        ref={keywordsContainerRef}
        className={clsx(classes.conceptsContainer)}>
        <Link
          component="button"
          variant="body2"
          onClick={toggleExpand}
          className={clsx(classes.expandMsg, classes.fakeLink)}>
          <FormattedMessage
            id="task.open.concepts"
            defaultMessage="Define Key Concepts (optional)"
          />
        </Link>
        {renderConceptsContainer()}
      </Box>
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={props.url}
        location={props.location}
        highlights={props.highlights}
        openDialog={openDialog}
        addHighlight={!props.readOnly && props.addHighlight}
        removeHighlight={!props.readOnly && props.removeHighlight}
      />
    </>
  );
}

export default OpenAnswer;
