import React, { useEffect, useState } from "react";

import { firebaseFunctions, firebaseApp } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
//import FileUploader from "react-firebase-file-uploader";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import ChipInput from "material-ui-chip-input";
import Epub from "epubjs/lib/index";
import {
  //Fab,
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Table,
  Select,
  MenuItem,
  InputLabel,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100w",
    height: "100vh",
    // background:'white',
  },
  dialog: {
    backgroundColot: "red",
  },
  tableContainer: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor:'white',
    marginBottom: "24px",
  },
  selectEmpty: {
    width: "80%",
  },
  tableHead: {
    fontWeight: "800",
  },
}));

function CourseTexts(props) {
  const classes = useStyles();

  const [texts, setTexts] = useState([]);
  const [openTexts, setOpenTexts] = React.useState(false);

  const [author, setAuthor] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [link, setLink] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [textLanguage, setTextLanguage] = React.useState("en");
  const [SQ3RQuestionslanguage, setSQ3RQuestionslanguage] =
    React.useState("en");
  const [chapters, setChapters] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [uploadState, setUploadState] = React.useState({
    uploading: false,
    progress: 0,
  });
  const [questions, setQuestions] = React.useState([]);

  var addCourseText = firebaseFunctions.httpsCallable(
    "adminFunctions-addCourseText"
  );

  const languages = ["he", "en"];

  useEffect(() => {
    var getAllCourseTexts = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourseTexts1"
    );

    // console.log("getting course texts");
    getAllCourseTexts({ course: props.courseId }).then((response) => {
      // console.log("got texts ", response);
      let rows = response.data;
      setTexts(rows);
    });

    // console.log("using effect in course texts");
  }, [props.courseId]);

  const handleUploadStart = () => {
    setUploadState({ uploading: true, progress: 0 });
  };

  const handleProgress = (progress) =>
    setUploadState({ ...uploadState, progress: progress });

  const handleUploadError = (error) => {
    setUploadState({ ...uploadState, uploading: false });

    console.error("upload error", error);
  };

  const getAllTocItems = (toc) => {
    /*toc.flatMap( chapter=> {
    if (chapter.subItems && chapter.subItems.length)
    return getAllTocItems(chapter.subItems) 
    return chapterAr;

  })*/
    return toc;
  };
  const handleUploadSuccess = (filename, task) => {
    setUploadState({ progress: 100, uploading: false });
    firebaseApp
      .storage()
      .ref("courseTexts/" + props.courseId)
      .child(task.snapshot.ref.name)
      .getDownloadURL()
      .then((url) => {
        let book = new Epub(url, {});
        book.loaded.navigation.then((toc) => {
          console.log("toc", toc);
          setChapters(getAllTocItems(toc.toc));
        });
      });
    setLink(task.snapshot.ref.name);
  };

  const saveText = () => {
    let text = {
      course: props.courseId,
      name: title,
      author: author,
      text_language: textLanguage,
      SQ3R_questions_language: SQ3RQuestionslanguage,
      questions: questions,
      categories: category,
      file_location: location,
      file_url: link,
    };
    //console.log("saving text", text);
    addCourseText(text).then((response) => {
      //   console.log("created  text val",response );
      if (response.data.length > 0) {
        let newText = { ...text, id: response.data[0].id };
        setTexts([...texts, newText]);
      }
    });
    handleClose();
  };

  const handleClose = () => {
    setUploadState({ uploading: false, progress: 0 });
    setOpenTexts(false);
    setLocation("");
    setAuthor("");
    setTitle("");
    setLink("");
    setCategory("");
    /*setEmail('');
    setName('');
    setRole('Student');*/
  };

  const handleChange = (chips) => {
    setQuestions(chips);
  };

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openTexts}
        PaperProps={{
          style: {
            // backgroundColor: "white",
          },
        }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent className={classes.dialog}>
          <CustomUploadButton
            hidden
            accept="application/epub+zip"
            storageRef={firebaseApp
              .storage()
              .ref("courseTexts/" + props.courseId)}
            metadata={{ name: "book" }}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
            style={{
              backgroundColor: "steelblue",
              color: "white",
              padding: 10,
              borderRadius: 4,
            }}
          >
            {" "}
            Select your epub
          </CustomUploadButton>
          <p>Progress: {uploadState.progress}</p>
          <p>Link:{link}</p>
          <TextField
            onChange={(e) => {
              setAuthor(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="author"
            label="author"
            type="text"
            value={author}
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            autoFocus
            margin="dense"
            id="title"
            label="title"
            type="text"
            fullWidth
          />

          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Location in Book
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={location}
            displayEmpty
            className={classes.selectEmpty}
          >
            {chapters &&
              chapters.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setLocation(item.href);
                    }}
                    value={item.href}
                  >
                    {item.label} - {item.href}
                  </MenuItem>
                );
              })}
          </Select>

          <InputLabel shrink id="text-language-label">
            Text language
          </InputLabel>
          <Select
            labelId="text-language-label"
            id="text-language"
            value={textLanguage}
            displayEmpty
            className={classes.selectEmpty}
          >
            {languages.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setTextLanguage(item);
                  }}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Select>

          <InputLabel shrink id="SQ3R-question-language-label">
            SQ3R question language
          </InputLabel>
          <Select
            labelId="SQ3R-question-language-label"
            id="SQ3R-question-language"
            value={SQ3RQuestionslanguage}
            displayEmpty
            className={classes.selectEmpty}
          >
            {languages.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setSQ3RQuestionslanguage(item);
                  }}
                  value={item}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Select>

          <TextField
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="category"
            label="category"
            type="text"
            value={category}
            fullWidth
          />

          <ChipInput
            defaultValue={questions}
            onChange={(chips) => handleChange(chips)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveText} color="primary">
            Add Text
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box>
      <Typography>Texts</Typography>
      <TableContainer
        className={classes.tableContainer}
        // component={Paper}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Author
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Title
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Category
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Chapters
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {texts.map((row) => {
              return (
                <TableRow key={row.author + row.id}>
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row"
                  >
                    {row.author}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    <a href={row.downloadUrl} download>
                      {row.name}
                    </a>
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.categories.split(",").map((item, index) => {
                      return <Chip key={index} label={item} />;
                    })}
                  </TableCell>
                  <TableCell className={classes.cell} align="left">
                    {row.file_location}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenTexts(true);
        }}
      >
        Add Text
      </Button>
      {renderCreateCourseDialog()}
    </Box>
  );
}

export default CourseTexts;
