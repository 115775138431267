// Dependencies
import React, { useEffect, useRef } from "react";
import { useResizeDetector } from "react-resize-detector";

// Redux dependencies
import { useSelector } from "react-redux";

// Components
import EpubView from "../../reader/EpubView/EpubView";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  tabPanel: {
    fontSize: "22px",
    height: "100%",
    overflow: "auto",
    position: "relative"
  }
}));

export default function PeerReviewReaderPanel({
  readText,
  rendition,
  onRenditionCreated,
  textBookmark,
  tabPanelRef,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const epubContainerRef = useRef(null);

  const { ref, width, height } = useResizeDetector({
    handleHeight: false,
    skipOnMount: true,
    onResize: () => {
      rendition && rendition.resize();
    }
  });

  // Redux state
  const userProfile = useSelector(state => state.user.userProfile);
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const fontSize = useSelector(state => state.user.fontSizeOptions);

  // Ephemeral state

  // Variables

  // Behavior
  useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  //render
  return (
    <Box
      className={classes.tabPanel}
      role="tabpanel"
      id={`tabpanel-${1}`}
      aria-labelledby={`text`}
      ref={ref}
    >
      <EpubView
        fullSize={false}
        fontSize={fontSize[fontSizeValue]}
        epubOptions={{ flow: "scrolled-doc" }}
        darkMode={userProfile.darkMode}
        rtl={rtl}
        url={readText.url}
        location={textBookmark || readText.file_location}
        onRenditionCreated={onRenditionCreated}
        containerRef={epubContainerRef}
        bodyClassName="scrollBox"
      />
    </Box>
  );
}
