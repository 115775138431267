import { createSlice, current } from "@reduxjs/toolkit";

export const grSlice = createSlice({
  name: "gr",
  initialState: {
    questions: [],
    highlights: [],
    expand: true,
    stage: 0,
    submittedTextId: 0,
    selectedLocation: false,
    shownLocation: false,
    status: "active",
    forceHighlight: false,
    detailedInstructions: false,
    seenStages: [],
    textId: undefined,
    movedThroughState: [0],
    selectedQuestionId: false,
    selectedAnswer: false,
    mode: "light",
    showAnswers: false,
    showHighlights: false
  },
  extraReducers: {
    gotSq3r: (state, value) => {
      state.questions = value.payload.questions || [];
      state.highlights = value.payload.highlights;

      if (state.textId != value.payload.textId) {
        state.stage = value.payload.grStage || 0;
        state.mode = value.payload.grMode || "light";
        state.selectedQuestionId = value.payload.grQuestionId || false;
        state.updateOnce = false;
      }
      state.textId = value.payload.textId;
      if (value.payload.status) {
        state.status = value.payload.status;
      }
    },
    "readerActions/setLocation": (state, value) => {
      if (value.payload && "questionId" in value.payload) {
        state.selectedQuestionId = value.payload.questionId;
      }
    }
  },
  reducers: {
    selectLocation: (state, value) => {
      if (value.payload) {
        if ("questionId" in value.payload) {
          state.selectedQuestionId = value.payload.questionId;
        }
        state.selectedLocation = true;
        state.selectedAnswer = value.payload;
        state.stage = state.grMode === "full" ? 2 : 1;
      }
    },
    setDetailedInstructions: (state, value) => {
      state.detailedInstructions = value.payload;
    },
    setForceHighlight: (state, value) => {
      state.forceHighlight = value.payload;
    },
    setGrMode: (state, value) => {
      state.mode = value.payload;
    },
    setShowAnswers: (state, value) => {
      state.showAnswers = value.payload;
    },
    setShowHighlights: (state, value) => {
      state.showHighlights = value.payload;
    },
    setStage: (state, value) => {
      if ("stage" in value.payload) {
        // auto-select question
        if (state.mode === "full") {
          if (
            value.payload.stage >= 3 &&
            state.questions &&
            state.questions[0] &&
            !state.selectedLocation &&
            !current(state.movedThroughState).includes(value.payload.stage)
          ) {
            state.selectedQuestionId = state.questions[0].id;
          }
        } else {
          // gr-mode is 'light'
          if (
            value.payload.stage >= 1 &&
            state.questions &&
            state.questions[0] &&
            !state.selectedLocation &&
            !current(state.movedThroughState).includes(value.payload.stage)
          ) {
            state.selectedQuestionId = state.questions[0].id;
          }
        }

        state.selectedAnswer = false;
        state.stage = value.payload.stage;
        if (!current(state.movedThroughState).includes(value.payload.stage))
          state.movedThroughState.push(value.payload.stage);
      }

      if (
        "lastStage" in value.payload && // TODO: Ofer - investigate this
        !(value.payload.lastStage in state.seenStages)
      ) {
        state.seenStages.push(value.payload.lastStage);
      }
    },
    setExpand: (state, value) => {
      // state.seenStages = state.seenStages.filter(el=> el !== value.payload.stage);
      state.expand = value.payload;
    },
    deleteSelectedAnswer: (state, value) => {
      state.selectedAnswer = false;
    },
    setSubmittedText: (state, value) => {
      state.submittedTextId = value.payload;
    },

    setSelectedQuestion: (state, value) => {
      state.selectedQuestionId = value.payload.id;
    },
    setQuestions: (state, value) => {
      state.questions = value.payload;
    }
  }
});

export const {
  setDetailedInstructions,
  setStage,
  setGrMode,
  setExpand,
  setSubmittedText,
  deleteSelectedAnswer,
  setQuestions,
  setSelectedQuestion,
  setForceHighlight,
  selectLocation,
  setShowAnswers,
  setShowHighlights
} = grSlice.actions;

// Selectors
export const getSelectedQuestion = state => {
  let selectedQuestion = state.gr.questions.find(
    question => question.id === state.gr.selectedQuestionId
  );
  if (selectedQuestion) return selectedQuestion;
  else return false;
};

export default grSlice.reducer;
