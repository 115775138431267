// Dependencies
import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import moment from "moment-timezone";
import { Draggable } from "react-beautiful-dnd";

// Redux Dependencies
import { useSelector } from "react-redux";

//Components
import TaskListItemDetails from "./TaskListItemDetails";
import ToggleTriangle from "../../SharedComponents/ToggleTriangle";

// Material UI
import DeleteFilled from "@material-ui/icons/Delete";
import InsertChart from "@material-ui/icons/InsertChart";
import EditIcon from "@material-ui/icons/Edit";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  task: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  taskHeader: {
    padding: "8px 16px",
  },
  taskSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tooltip: {
    // opacity:'1',
    backgroundColor: "rgba(97, 97, 97, 1);",
  },
  taskName: {
    flexBasis: "33%",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textAlign: "start",
    cursor: "pointer",
  },
  taskDates: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    fontWeight: "100",
    flexShrink: "0",
    "& span:first-child": {
      marginRight: "48px",
    },
  },
  actionContainer: {
    flexShrink: "0",
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  taskStatus: {
    color: theme.palette.text.secondary,
    margin: "0 48px",
  },
}));

export default function TasksListItem({
  taskIndex,
  task,
  deleteTask,
  submissions,
  graders,
  
  course,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const isDarkMode = useSelector((state) => state.user.userProfile.darkMode);

  // Redux State
  const rtl = useSelector((state) => state.user.userProfile.rtl);
  // const openTasks = useSelector((state) => state.user.openTasks);

  // Ephemeral State
  // const [submissions, setSubmissions] = useState([]);

  const [isOpen, setIsOpen] = React.useState(props.singleItemView || false);

  const handleClick = (e) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  const getDateUTC = (str, timezone) => {
    return moment(str)?.tz(timezone)?.format("MMMM D, YYYY");
  };

  const getTzDate = (dateStr, timezone) => {
    return moment.tz(dateStr, timezone).format("MMMM D, YYYY");
  };

  // Display the task's name and the status if aplicable
  function renderTaskName() {
    return (
      <Typography
        // component="button"
        variant="body1"
        className={classes.taskName}
      >
        {task.name}
      </Typography>
    );
  }

  // Display the taks's publish date
  function renderPublishDate() {
    if (
      task.status !== "Draft" &&
      //  open &&
      task.publish_date
    ) {
      return (
        <Typography
          className={classes.taskPublish}
          component="span"
          variant="body1"
        >
          <FormattedMessage id="tasks.published" defaultMessage="Published" />
          {` ${getDateUTC(task.publish_date)}`}
        </Typography>
      );
    }
  }

  // Display the task's deadline
  function renderDueDate() {
    if (
      task.status !== "Draft" &&
      //  open &&
      task.publish_date &&
      task.due_date
    ) {
      return (
        <Typography
          className={classes.taskDeadline}
          component="span"
          variant="body1"
        >
          <FormattedMessage id="tasks.deadline" defaultMessage="Deadline" />{" "}
          {` ${getTzDate(task.due_day, task.timezone)}`}
        </Typography>
      );
    }
  }

  // display the avaliable action icons

  const getItemStyle = (isDragging, draggableStyle, isDarkMode) => {
    return {
      // styles we need to apply on draggables
      ...draggableStyle,

      ...(isDragging && {
        display: "table",
        background: isDarkMode ? "rgb(5,15,15)" : "rgb(235,235,235)",
      }),
    };
  };

  // Render
  return (
    <>
      {props.singleItemView ? (
        <>
          <Box
            className={clsx(classes.task, classes.taskHeader)}
            disableGutters={true}
          >
            <Box disableTypography={true} className={classes.taskSummary}>
              {renderTaskName()}
              {isOpen && (
                <Box className={classes.taskDates}>
                  {renderPublishDate()}
                  {renderDueDate()}
                </Box>
              )}
            </Box>
          </Box>
          <TaskListItemDetails
            taskIndex={taskIndex}
            course={course}
            task={task}
            submissions={submissions}
            graders={graders}
          />
        </>
      ) : (
        <Draggable draggableId={"task_" + task.id} index={taskIndex}>
          {(provided, snapshot) => (
            <>
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                  isDarkMode
                )}
                className={classes.task}
                disableGutters={true}
              >
                <ListItemIcon onClick={handleClick}>
                  {task.status !== "Draft" && (
                    <ToggleTriangle isOpen={isOpen} />
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  className={classes.taskSummary}
                >
                  {renderTaskName()}
                  {isOpen && (
                    <Box className={classes.taskDates}>
                      {renderPublishDate()}
                      {renderDueDate()}
                    </Box>
                  )}
                </ListItemText>
              </ListItem>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <TaskListItemDetails
                  taskIndex={taskIndex}
                  course={course}
                  task={task}
                  submissions={submissions}
                  graders={graders}
                />
              </Collapse>
            </>
          )}
        </Draggable>
      )}
    </>
  );
}
