// Dependencies
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { firebaseFunctions, firebaseApp } from "../../../firebase";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import clsx from "clsx";
// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage } from "../../../redux/userSlice";
// Components
import SelectReading from "./StepSelectReadings";
import AddQuestions from "./StepAddQuestions";
import SubmitTask from "./StepSubmitTask";
import RouteLeavingGuard from "./RouteLeavingGuard";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  SnackbarContent,
  Typography
} from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px"
  },
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    textTransform: "none"
  },
  saveDraftlBtn: {
    textTransform: "none",
    float: "right"
  },
  goBackBtn: {
    position: "absolute",
    top: 0,
    transform: "translateX(-120%)"
  }
}));

function PublishedTaskView({ task, course, ...props }) {
  // Hooks
  const classes = useStyles();
  let history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const rtlValue = useSelector(state => state.user.userProfile.rtl);

  // Ephemeral State
  const [isDirty, setIsDirty] = useState(false);
  const [id, setId] = useState(0);
  const [texts, setTexts] = useState([]);
  const [text, setText] = useState(0);
  const [readOnly, setReadOnly] = useState(true);
  const [showGuard, setShowGuard] = useState(false);
  const [taskType, setTaskType] = useState(task.task_type);
  const [taskTitle, setTaskTitle] = useState(
    task && task.name ? task.name : ""
  );
  const [taskDescription, setTaskDescription] = useState(
    task && task.task_description ? task.task_description : ""
  );
  const [deadline, setDeadline] = useState(null);
  const [acceptUntil, setAcceptUntil] = useState(null);
  const [questions, setQuestions] = useState([
    {
      question: "",
      includeCitation: false,
      type: "",
      points: 0,
      wordLimit: -1,
      options: []
    }
  ]);
  const [answers, setAnswers] = useState([
    { shouldSelect: -1, wordLimit: -1, quotes: [], concepts: [] }
  ]);
  const [draftSave, setDraftSaved] = useState(false);
  //const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  // Variables
  const [message] = useState(
    intl.formatMessage({
      id: "task.saved",
      defaultMessage: "Task saved"
    })
  );

  // Behavior
  useEffect(() => {
    if (task) {
      let getAllTexts = firebaseFunctions.httpsCallable("courses-getTexts1");
      getAllTexts({ course: task.course_id }).then(response => {
        setTexts(response.data);
        if (task.text_id) {
          let texts = response.data.filter(el => el.id === task.text_id);
          if (texts && texts.length) {
            firebaseApp
              .storage()
              .ref("courseTexts/" + task.course_id)
              .child(texts[0].file_url)
              .getDownloadURL()
              .then(url =>
                setText({
                  id: texts[0].id,
                  author: texts[0].author,
                  name: texts[0].name,
                  url: url,
                  location: texts[0].file_location
                })
              );
          }
        }
      });
    }
  }, [task]);

  useEffect(() => {
    props.setIsDirty(false);
  }, []);

  useEffect(() => {
    if (task) {
      if (task.due_date) {
        setDeadline(new Date(task.due_date));
      }

      if (task.accept_date) {
        setAcceptUntil(new Date(task.accept_date));
      }

      if (task.id) {
        setId(task.id);
      }

      if (task.questions) {
        setQuestions(task.questions.questions);
        setAnswers(task.answers.answers);
      }
    }
  }, [task]);

  const saveTask = status => {
    let messages = validateForm();
    if (messages && messages.length) handelFlashMessage(messages);
    else {
      var saveTaskFunction = firebaseFunctions.httpsCallable(
        "courses-saveSubmittedTask"
      );
      let validQuestions = [];

      let validAnswers = [];
      for (var i = 0; i < questions.length; i++) {
        if (
          questions[i] &&
          questions[i].type >= 0 &&
          typeof answers[i] !== "undefined"
        ) {
          validQuestions.push(questions[i]);
          validAnswers.push(answers[i]);
        }
        //Do something
      }
      let editedTask = {
        course: task.course_id,
        questions: validQuestions,
        answers: validAnswers,
        due_day: deadline ? moment(deadline).format("YYYY-MM-DD") : "",
        accept_day: acceptUntil ? moment(acceptUntil).format("YYYY-MM-DD") : "",

        deadline:
          deadline && deadline.toISOString ? deadline.toISOString() : "",
        acceptUntil:
          acceptUntil && acceptUntil.toISOString
            ? acceptUntil.toISOString()
            : "",
        description: taskDescription,
        task_num: 1,
        name: taskTitle,
        text_id: text && text.id ? text.id : null,
        id: id,
        status: status
      };

      saveTaskFunction(editedTask).then(response => {
        setIsDirty(false);
        history.push("/tasks/" + course.id);
      });
    }
  };

  const handelFlashMessage = message => {
    dispatch(
      enqueueFlashMessage({
        message: message,
        duration: 3000,
        severity: "error"
      })
    );
  };

  const setWrapper = setFunc => state => {
    setFunc(state);
    setIsDirty(true);
  };

  const renderEditWarning = () => {
    return (
      <Dialog
        open={showWarning}
        PaperProps={{
          style: {
            direction: rtlValue ? "rtl" : "ltr"
          }
        }}
        onClose={() => setShowWarning(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <FormattedMessage id="task.edit.msg" defaultMessage="Edit task" />
        </DialogTitle>
        <DialogContent className={clsx(classes.dialog, classes.ltr)}>
          <Typography>
            <FormattedMessage
              id="task.edit.warning"
              defaultMessage="Some students have already started working on this task, are you sure you want to edit it?"
            />
          </Typography>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button
            className={clsx(classes.yesBtn, classes.yesBtnLtr)}
            onClick={() => {
              setReadOnlyToFalse();
              setShowWarning(false);
            }}
            color="secondary"
          >
            <FormattedMessage id="gr.confirm.btn" defaultMessage="OK" />
          </Button>
          <Button
            className={clsx(classes.cancelBtn, classes.cancelBtnLtr)}
            onClick={() => setShowWarning(false)}
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const textValidation = intl.formatMessage({
    id: "tasks.create.validation.selectReading",
    defaultMessage: "Select a text"
  });

  const deadlineValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterDeadline",
    defaultMessage: "Set a deadline for this task"
  });
  const deadlineValueValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadLinePast",
    defaultMessage: "The deadline you selected has already passed"
  });
  const deadlineOrderValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadlineOrder",
    defaultMessage:
      "Please make sure that the secound deadline is after the first deadline"
  });
  const titleValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterTitle",
    defaultMessage: "Enter a task title"
  });
  const questionValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterQuestion",
    defaultMessage: "Add a question"
  });
  const formulationValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterFormulation",
    defaultMessage: "Add question formulation to question"
  });
  const questionTypeValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.selectType",
    defaultMessage: `Select question type for question`
  });
  /*const pointsValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.addPoints",
    defaultMessage: "Please add points for question",
  });
  const pointsValueValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.pointsValue",
    defaultMessage: "Change the point value to a positive number in question ",
  });*/
  const optionsValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionsValue",
    defaultMessage:
      "Multiple choice question must have more than one option in question "
  });
  const optionsChoiceValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionChoice",
    defaultMessage:
      "Multiple choice must have an option marked as correct in question "
  });

  console.log("task", task);

  function validateForm() {
    let messages = [];

    if (text === 0) {
      messages.push(textValidation);
    }
    // Check if there is a deadline
    // For standart tasks, check if there is a deadline
    console.log("deadline", deadline);
    console.log("acceptUntil", acceptUntil);
    if (taskType === "standard" && !deadline) {
      messages.push(deadlineValidationMessage);
    } else if (taskType === "peerReview" && (!deadline || !acceptUntil)) {
      messages.push(deadlineValidationMessage);
      // If there is a deadline...
    } else {
      // Check if the deadline's day is today or after
      if (moment().isSameOrAfter(moment(deadline), "day")) {
        messages.push(deadlineValueValidationMessage);
      }
      // Check if the secound deadline's day is today or after
      else if (acceptUntil && moment().isSameOrAfter(moment(deadline), "day")) {
        messages.push(deadlineValueValidationMessage);
      }
      // Check if the first deadline is before the secound deadline
      if (
        acceptUntil &&
        moment(deadline).isSameOrAfter(moment(acceptUntil), "day")
      ) {
        messages.push(deadlineOrderValidationMessage);
      }
    }

    if (!taskTitle) {
      messages.push(titleValidationMessage);
    }
    // Check that there is at least one question
    if (
      taskType !== "peerReview" &&
      questions.length <= 1 &&
      questions[0].question === ""
    ) {
      messages.push(questionValidationMessage);
    } else {
      // if there are questions, check that each have...
      questions.forEach((question, index) => {
        // A question formulation
        if (taskType !== "peerReview" && question.question.trim() === "") {
          messages.push(`${formulationValidationMessage} ${index + 1}`);
        }
        // A type selected
        if (question.type === "") {
          messages.push(`${questionTypeValidationMessage} ${index + 1}`);
        }

        if (question.type === 2) {
          if (
            !question.options ||
            !question.options.length ||
            question.options.length < 2
          ) {
            messages.push(`${optionsValidationMessage} ${index + 1}`);
          }
          if (
            !answers ||
            !answers[index] ||
            answers[index].shouldSelect < 0 ||
            answers[index].shouldSelect >=
              (question.options && question.options.length) ||
            typeof answers[index].shouldSelect === "undefined"
          ) {
            messages.push(`${optionsChoiceValidationMessage} ${index + 1}`);
          }
        }
      });
    }
    return messages;
  }

  const renderBody = () => {
    return (
      <>
        <SelectReading
          course={course}
          readOnly={readOnly}
          task={task}
          texts={texts}
          text={text}
          setText={!readOnly && setWrapper(setText)}
          setTexts={!readOnly && setTexts}
          saveTask={!readOnly && saveTask}
          handelFlashMessage={!readOnly && handelFlashMessage}
          setIsDirty={!readOnly && setIsDirty}
        />

        <AddQuestions
          task={task}
          text={text}
          readOnly={readOnly}
          questions={questions}
          answers={answers}
          taskTitle={taskTitle}
          taskType={taskType}
          setTaskType={setTaskType}
          taskDescription={taskDescription}
          setQuestions={!readOnly && setWrapper(setQuestions)}
          setAnswers={!readOnly && setWrapper(setAnswers)}
          setTaskTitle={!readOnly && setWrapper(setTaskTitle)}
          setTaskDescription={!readOnly && setWrapper(setTaskDescription)}
          saveTask={!readOnly && saveTask}
          handelFlashMessage={!readOnly && handelFlashMessage}
        />
        {showWarning && renderEditWarning()}
        <SubmitTask
          deadline={deadline}
          readOnly={readOnly}
          acceptUntil={acceptUntil}
          setDeadline={!readOnly && setWrapper(setDeadline)}
          setAcceptUntil={!readOnly && setWrapper(setAcceptUntil)}
          handelFlashMessage={!readOnly && handelFlashMessage}
        />
      </>
    );
  };

  //const [openTexts, setOpenTexts] = React.useState(false);
  //var addCourseText = firebaseFunctions.httpsCallable('adminFunctions-addCourseText');
  const setReadOnlyToFalse = () => {
    setReadOnly(false);
    setShowGuard(true);
    props.setIsDirty(true);
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <RouteLeavingGuard
        setIsDirty={setShowGuard}
        isDirty={showGuard}
        selectCourse={props.selectCourse}
        setTask={props.setTask}
        msg={
          isDirty
            ? intl.formatMessage({
                id: "tasks.create.SaveAsDraft",
                defaultMessage: "Save task as draft?"
              })
            : intl.formatMessage({
                id: "tasks.edit.noChange",
                defaultMessage: "No changes to task have been made"
              })
        }
        // Start blocking navigation when text is selected
        when={showGuard}
        // Navigation function provided by react router's useHistory
        navigate={path => history.push(path)}
        // adds a third button which will run a function before navigating
        additionalStep={
          isDirty
            ? () => {
                saveTask("Submitted");
              }
            : false
        }
        additionalMsg={intl.formatMessage({
          id: "task.saved",
          defaultMessage: "Task Saved"
        })}
        additionalStepButton={
          isDirty &&
          intl.formatMessage({
            id: "tasks.edit.SaveTask",
            defaultMessage: "Save task"
          })
        }
      />
      <Box position="relative">
        <Link to={`/tasks/${course.id}`} className={classes.goBackBtn}>
          <IconButton aria-label="go-back" onClick={() => {}}>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography
          className={clsx(classes.tasksHeader, classes.left)}
          variant="h4"
        >
          {taskTitle ? (
            taskTitle
          ) : (
            <FormattedMessage id="new.task" defaultMessage="New Task" />
          )}

          {readOnly ? (
            <Button
              variant="outlined"
              // className={classes.btn}
              className={clsx(classes.saveDraftlBtn)}
              onClick={() => {
                task.submissions.filter(s => s.submission_status !== "Pending")
                  .length > 0
                  ? setShowWarning(true)
                  : setReadOnlyToFalse();
              }}
            >
              <FormattedMessage defaultMessage="Edit task" id="task.edit.msg" />
            </Button>
          ) : (
            <Button
              variant="outlined"
              // className={classes.btn}
              className={clsx(classes.saveDraftlBtn)}
              onClick={() => {
                setShowGuard(false);
                saveTask();
              }}
            >
              <FormattedMessage
                defaultMessage="Save task"
                id="tasks.edit.SaveTask"
              />
            </Button>
          )}
        </Typography>

        {renderBody()}
      </Box>
      <Snackbar
        open={draftSave}
        onClose={() => setDraftSaved(false)}
        autoHideDuration={6000}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: rtlValue ? "left" : "right"
        }}
      >
        <SnackbarContent message={message} />
      </Snackbar>
    </MuiPickersUtilsProvider>
  );
}

export default PublishedTaskView;
