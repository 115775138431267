import React from "react";
//import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  colorBtn: {
    width: "16px",
    height: "16px",
    cursor: "pointer",
    marginTop: "16px",
    marginBottom: "16px",
    marginRight: "8px",
    marginLeft: "8px",
    borderRadius: "8px"
  },

  highlightBtn: {
    "& :hover": {
      color: "#91fcc2"
    }
  }
}));

function HighlightColor({ selected, onClick, style, color }) {
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={classes.colorBtn}
      style={{
        backgroundColor: color
      }}></div>
  );
}

export default HighlightColor;
