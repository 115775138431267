// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";

// Components
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  LinearProgress,
  Chip,
} from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    statContainer: {
      padding: "32px",
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold",
    },
    questionTitle: {
      fontWeight: theme.typography.fontWeightBold,
    },
    multiChoiceContainer: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    citationContainer: {
      flexBasis: "50%",
      padding: "32px",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
    mainStatsContainer: {
      display: "flex",
      flexBasis: "100%",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
      "&.withCitations": {
        flexBasis: "50%",
      },
    },
    answerDistributionContainer: {
      flexBasis: "100%",
      display: "grid",
      gridTemplateColumns: "0.1fr 0.7fr",
      gap: "36px 24px",
      gridAutoFlow: "row",
      padding: "32px",
      "&.withCitations": {
        flexBasis: "50%",
        gridTemplateColumns: "0.15fr 1fr",
        gridTemplateRows: "max-content",
      },
    },

    chipContainer: {
      gridColumn: "1 / 2",
      position: "relative",
    },
    chip: {
      position: "absolute",
      top: "5px",
    },
    optionContainer: {
      gridColumn: "2 / 3",
    },
    optionText: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      fontSize: "20px",
    },
    optionPresantage: {
      fontFamily: `Helvetica, "sans-serif"`,
    },
    statBar: {
      height: "8px",
      backgroundColor: "#D8D8D8",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#168FEE",
      },
    },
    citationsActions: {
      textAlign: "end",
      margin: "0 34px 34px 34px",
    },
  };
});

export default function MultiChoiceStats({
  submissions,
  question,
  index,
  task,
  text,
  ...props
}) {
  // Hooks
  const classes = useStyles();

  // Ephemeral State
  const [withCitations, ] = useState(question.includeCitation);
  const [choiceDistribution, SetChoiceDistribution] = useState(null);
  const [openHeatmap, setOpenHeatmap] = useState(false);
  const [choicesArray, setChoicesArray] = useState([]);
  // Behavior
  let teacherAnswer =
  submissions[0] && submissions[0].answers && submissions[0].answers.answers[index];

  // get an array the indexes of all current submitted choices for this question
  

  useEffect(() => {
    console.log("multi effect");
     if (question && submissions) {

      const calcedChoices = submissions
    .filter((submission) => submission.submission_date)
    .map((submittion) => {
      if (submittion.student_answers) {
        return submittion.student_answers.answers[index].choice;
      }
      else return undefined;
    })
    // filter out submissions were the student didn't answer this question
    .filter((submission) => submission !== undefined);
    setChoicesArray(calcedChoices);
    let choiceDistributionMap = new Map();
    // get the answer options array
    const avaliableOption = question.options;

    // for each avaliable option
    avaliableOption.forEach((option, optionIndex) => {
      // count the occurrences in the student choices array
      const occurrences = choicesArray.reduce((accumulator, currentValue) => {
        return Number(currentValue) === optionIndex
          ? accumulator + 1
          : accumulator;
      }, 0);
      choiceDistributionMap.set(optionIndex, occurrences);
    });

    SetChoiceDistribution(choiceDistributionMap);
     }
  }, [question, submissions]);

  // Helpers

  // normalizing the value for the progress bars
  function normalize(value, min, max) {
    if (value - min === 0 || max - min === 0) {
      return 0;
    } else {
      return (((value - min) * 100) / (max - min)).toFixed(1);
    }
  }
  return (
    <Paper component="section" elevation={0}>
      <HeatMap
       totalCount={submissions.filter((submission) => submission.submission_date).length}
        openDialog={openHeatmap}
        teacherHighlights={teacherAnswer.quotes}
        setOpenDialog={setOpenHeatmap}
        url={text.url}
        location={text.location}
        hlOpacity={
          1     }
        hlColor="#4AA96C"
        highlights={submissions
          .filter(
            (submission) =>
              submission.submission_date && submission.student_answers
          )
          .map((submission) => submission.student_answers.answers[index].quotes)
          .flat()}
        addHighlight={() => {}}
        removeHighlight={() => {}}
      />
      <Box component="header" className={classes.statContainer}>
        <Typography className={clsx(classes.questionTitle)} variant="h5">
          Question #{index + 1} — multiple choice
        </Typography>
        <Typography
          className={clsx(classes.questionSubtitle)}
          variant="h6"
          component="span"
        >
          {question.question}
        </Typography>
      </Box>
      <Divider />
      <Box className={clsx(classes.multiChoiceContainer)}>
        {withCitations && (
          <Box className={classes.citationContainer}>
            <CitationChart
              question={question}
              i={index}
              task={task}
              submissions={submissions}
            />
          </Box>
        )}
        <Box
          className={clsx(
            classes.mainStatsContainer,
            withCitations && "withCitations"
          )}
        >
          <Box
            className={clsx(
              classes.answerDistributionContainer,
              withCitations && "withCitations"
            )}
          >
            {question.options.map((option, optionIndex) => {
              const isCorrect =
                // Convert to num becouse it looks like older entries were saved as strings
                Number(task.answers.answers[index].shouldSelect) ===
                optionIndex;
              return (
                <React.Fragment key={optionIndex}>
                  <Box className={classes.chipContainer}>
                    <Chip
                      // label="Correct"
                      label={isCorrect ? "Correct" : "Incorrect"}
                      //   color="primary"
                      variant={isCorrect ? "default" : "outlined"}
                      style={
                        isCorrect
                          ? {
                              backgroundColor: "#168FEE",
                              color: "#FFFFFF",
                            }
                          : null
                      }
                      className={classes.chip}
                    />
                  </Box>

                  <Box className={classes.optionContainer}>
                    <Box className={classes.optionText}>
                      <Box component="span">{option}</Box>
                      <Box
                        className={classes.optionPresantage}
                        component="span"
                      >
                        {(choiceDistribution &&
                          normalize(
                            choiceDistribution.get(optionIndex),
                            0,
                            choicesArray.length
                          )) ||
                          0}
                        %
                      </Box>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={
                        choiceDistribution &&
                        normalize(
                          choiceDistribution.get(optionIndex),
                          0,
                          choicesArray.length
                        )
                      }
                      className={classes.statBar}
                    />
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
          {withCitations && (
            <Box className={classes.citationsActions}>
              <Button
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#168FEE",
                  color: "#FFFFFF",
                 padding: "4px 8px",
                }}
                className={classes.actionButton}
                onClick={() => setOpenHeatmap(true)}
              >
                Show heatmap
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
