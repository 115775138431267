// Dependencies
import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { firebaseFunctions } from "../../../firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSubmittedStudentResponse } from "../../../redux/taskSlice";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import {
  setSubmitButtonClicked,
  setIsLoading
} from "../../../redux/readerActionsSlice";
import { removeActiveTaskPerText } from "../../../redux/firebaseMiddleware";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog

  responseModal: {
    // position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "center"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  responseModalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },

  button: { textTransform: "none" },
  buttonDiscard: {
    color: "#787877"
  }
}));

export default function PeerReviewResponseModal({
  modalVisible,
  response,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const intl = useIntl();
  //Redux state
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const userDisplayName = useSelector(state => state.user.auth.displayName);
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );
  const task = useSelector(state => state.task.taskRow);
  const ownerId = useSelector(state => state.task.taskRow.owner);
  const owner = useSelector(
    state => state.task.taskRow?.answers?.answers?.displayName
  );
  const teacherName = useSelector(state => state.task.taskRow.teacher_name);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral state

  // Variables

  //Behavior

  const closeModal = () => {
    dispatch(setSubmitButtonClicked(false));
    // props.setModalVisible(false);
  };

  function handleSubmitResponse() {
    dispatch(setIsLoading(true));

    dispatch(setSubmitButtonClicked(false));
    // props.setModalVisible(false);
    const submitResponse = firebaseFunctions.httpsCallable(
      "courses-submitPeerReviewResponse"
    );
    submitResponse({
      task: task.id,
      answers: response,

      // for notification
      owner: ownerId,
      courseId: selectedCourse.id,
      courseName: selectedCourse.name,
      submissionId: task.submission_id,
      studentName: userDisplayName
    }).then(response => {
      dispatch(setSubmittedStudentResponse(task.id));
      dispatch(
        enqueueFlashMessage({
          message: "Review was submitted successfully",
          duration: alertsDuration,
          undoButton: true
        })
      );
      dispatch(removeActiveTaskPerText({ textId: task.text_id }));
      dispatch(setIsLoading(false));
      history.push("/tasks/" + selectedCourse.id);
    });
  }

  // Render
  return (
    <>
      <div>
        <Dialog
          open={modalVisible}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          PaperProps={{
            className: classes.responseModal,
            style: {
              direction: rtl ? "rtl" : "ltr"
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="task.peerReview.modal.title"
              defaultMessage="Submit review"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage
                id="task.peerReview.modal.content"
                defaultMessage={`Your review will be avaliable to ${owner} and ${teacherName}`}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.responseModalActions}>
            <Button
              onClick={closeModal}
              className={clsx(classes.button, classes.buttonDiscard)}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmitResponse}
              color="primary"
              disableElevation
            >
              <FormattedMessage id="task.submit" defaultMessage="Submit" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
