// Dependencies
import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { Switch, Route, useHistory } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";

import {
  setBreadcrumbs,
  setBreadcrumbsClicked
} from "../../redux/readerActionsSlice";
// Components
import TasksManager from "./TaskManager/TaskManager";
import SelectedTaskManager from "./TaskManager/SelectedTaskManager";
import PublishedTaskContainer from "./CreateTask/PublishedTaskContainer";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => {
  return {
    container: {
      height: "100%",
      flex: 1,
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center"
    },
    emptyState: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    emptyStateInner: {
      width: 500,
      textAlign: "center",
      transform: "translateY(-180px)"
    },
    emptyStateTitle: {
      marginBottom: theme.spacing(1.5)
    },
    btn: {
      marginTop: theme.spacing(2)
    }
  };
});

function Tasks() {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  // Redux Selectors

  //Variables

  //Behavior
  // Invoke the undo logic when the Redux undo flag is true
  const breadCrumbsClicked = useSelector(
    state => state.readerActions.breadCrumbsClicked
  );
  useEffect(() => {
    if (breadCrumbsClicked.resetTask) {
      history.push("/tasks");

      dispatch(setBreadcrumbsClicked(false));
    }
  }, [breadCrumbsClicked]);

  //for breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Tasks"
      }),
      url: "/tasks",
      resetCourse: true
    });

    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, []);

  return (
    <Box className={classes.container}>
      <Switch>
        <Route exact path="/tasks/">
          {/* TODO: add empty state / Index */}
          <div></div>
        </Route>

        {/* All course tasks index */}
        <Route exact path="/tasks/:course_id">
          <TasksManager />
        </Route>

        {/* Single task index */}
        <Route exact path="/tasks/course/:course_id/task/:task_id">
          <SelectedTaskManager />
        </Route>

        {/* task index preview / edit */}
        <Route exact path="/tasks/:course_id/edit/:task_id">
          <PublishedTaskContainer />
        </Route>

        {/*  */}
        <Route
          exact
          path="/tasks/course/:course_id/task/:task_id/submission/:submission_id"
        >
          <SelectedTaskManager />
        </Route>
      </Switch>
    </Box>
  );
}

export default Tasks;
