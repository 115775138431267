import React, { useState, useEffect, useCallback } from "react";
import ReactReader from "./ReactReader/ReactReader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

// Fix for v3 branch of epub.js -> needs ePub to by a global var
//const storage = global.localStorage || null;

const useStyles = makeStyles(theme => ({
  bookView: {
    flexGrow: "1",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%"
  },
  bookHeight: {
    maxHeight: "100vh"
  }
}));

function BookView(props) {
  const classes = useStyles();
  const { readText, task, readOnly, rtl, dark, context } = props;

  const selectedTextId = useSelector(state => {
    return state.texts.selectedTextId;
  });

  const onTextSelected = params => {
    props.onTextSelected && props.onTextSelected(params);
  };

  const [rendition, setRendition] = useState(null);
  const renditionCreated = rendition => {
    setRendition(rendition);
  };

  // save book location on unload
  useEffect(() => {
    const unloadHandler = () => {
      if (rendition) {
        let location = rendition.currentLocation();

        if (location && location.start && location.start.cfi) {
          localStorage.setItem(
            "state.text.location." + selectedTextId,
            location.start.cfi
          );
          const serializedState = JSON.stringify({
            selectedTextId: selectedTextId
          });
          localStorage.setItem("state.text", serializedState);
        }
      }
    };

    window.addEventListener("beforeunload", unloadHandler);
    return () => {
      unloadHandler();
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [rendition, selectedTextId]);

  /*const  goToLocation = loc => {
      rendition.display(loc) ;
    }
  
    const refreshBookView = () => {
      rendition.resize(
        containerRef.current.clientWidth);
   
    }*/

  /*  onLocationChanged = location => {
          this.setState(
          {
            location
          },
          () => {
            storage && storage.setItem("epub-location", location);
          }
        );
      };

  render() {
*/
  // const { location } = this.state;
  const selectedText = useSelector(state => {
    return state.texts.selectedText;
  });

  const selectedLink =
    selectedText && selectedText.link
      ? selectedText.link
      : selectedText && selectedText.url;
  return (
    <div className={clsx(classes.bookView)}>
      {((readText && readText.url) || (selectedText && selectedText.link)) && (
        <ReactReader
          minimalBar={props.minimalBar}
          task={task}
          readOnly={readOnly}
          url={task && task.id > 0 && readText ? readText.url : selectedLink}
          title={""}
          location={
            task && task.id > 0 && readText
              ? readText.file_location
              : selectedText.defaultLocation
          }
          renditionCreated={renditionCreated}
          //locationChanged={onLocationChanged}
          onTextSelected={onTextSelected}
        />
      )}
    </div>
  );
}

export default BookView;
