import React, { useEffect, useState } from "react";

import { firebaseFunctions } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  //Fab,
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100w",
    height: "100vh",
    // background: "white",
  },
  dialog: {
    backgroundColot: "red",
  },
  tableContainer: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor: "white",
    marginBottom: "24px",
  },
  selectedCourseRow: {
    backgroundColor: "yellow",
  },
  nonSeledtedCourseRow: {
    // backgroundColor: "white",
  },
  tableHead: {
    fontWeight: "800",
  },
}));

function CoursesAdmin(props) {
  const classes = useStyles();

  const [courses, setCourses] = useState([]);
  const [openCourses, setOpenCourses] = React.useState(false);

  const [courseName, setCourseName] = React.useState("");
  const [year, setYear] = React.useState("");
  const [semester, setSemester] = React.useState("");

  var addCourse = firebaseFunctions.httpsCallable(
    "adminFunctions-createCourse"
  );

  useEffect(() => {
    var getAllCourses = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourses"
    );

    //  console.log("getting courses");
    getAllCourses().then((response) => {
      //console.log("got courses ", response);
      setCourses(response.data);
    });
  }, []);

  const saveCourse = () => {
    addCourse({ name: courseName, year: year, semester: semester }).then(
      (response) => {
        //   console.log("created course retu val",response );
        if (response.data.length > 0)
          setCourses([
            ...courses,
            {
              id: response.data[0].id,
              name: courseName,
              academic_year: year,
              semester: semester,
            },
          ]);
      }
    );
    handleClose();
  };
  const handleClose = () => {
    setOpenCourses(false);
    setYear("");
    setSemester("");
    setCourseName("");
  };

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openCourses}
        PaperProps={{
          style: {
            // backgroundColor: "white",
          },
        }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent className={classes.dialog}>
          <TextField
            onChange={(e) => {
              setCourseName(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="course_name"
            label="name"
            type="text"
            value={courseName}
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setYear(e.target.value);
            }}
            value={year}
            autoFocus
            margin="dense"
            id="academic_year"
            label="academic_year"
            type="text"
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setSemester(e.target.value);
            }}
            value={semester}
            autoFocus
            margin="dense"
            id="semester"
            label="semester"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveCourse} color="primary">
            Create Course
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box>
      <Typography>Courses</Typography>
      <TableContainer
        className={classes.tableContainer}
        // component={Paper}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Id
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Year{" "}
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Semester{" "}
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.tableHead)}>
                Name{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((row) => (
              <TableRow
                onClick={() => {
                  props.onSelectCourse && props.onSelectCourse(row.id);
                }}
                key={row.id}
                className={clsx(
                  classes.courseRow,
                  props.courseId === row.id && classes.selectedCourseRow
                )}
              >
                <TableCell className={classes.cell} align="left">
                  {row.id}
                </TableCell>

                <TableCell className={classes.cell} component="th" scope="row">
                  {row.academic_year}
                </TableCell>
                <TableCell className={classes.cell} align="left">
                  {row.semester}
                </TableCell>
                <TableCell className={classes.cell} align="left">
                  {row.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenCourses(true);
        }}
      >
        Create Course
      </Button>
      {renderCreateCourseDialog()}
    </Box>
  );
}

export default CoursesAdmin;
