import { createSlice, current, createSelector } from "@reduxjs/toolkit";
export const taskSlice = createSlice({
  name: "task",
  initialState: {
    answers: [],
    selectedQuestionIndex: 0,
    showCitationForQuestion: false,
    selectedTaskId: 0,
    updatedTaskId: 0,
    submittedStudentTask: 0,
    submittedStudentReflection: 0,
    submittedStudentResponse: 0,
    submittedStudentReply: 0,
    sentFeedbackTask: 0,
    sentFeedbackTextTask: 0,
    clientRectangle: {},
    clickedMatch: false,
    selectedText: {},
    taskRow: {},
    feedbackTask: {},
    sendFeedback: false,
    validateFeedback: false,
    selectedHighlight: {},
    task_type: "",
    status: ""
  },
  extraReducers: {
    gotAnswers: (state, value) => {
      state.updatedTaskId = value.payload.taskId;
      state.answers = value.payload.answers ? value.payload.answers : [];
      let task = current(state.taskRow);
      if (
        task &&
        task.questions &&
        task.questions.length > state.answers.length
      ) {
        state.answers = task.questions.map(el => {
          return {};
        });
      }
      state.status = value.payload.status;
      state.selectedQuestionIndex = value.payload.selectedQuestion || 0;
    }
  },
  reducers: {
    setSentFeedbackTask: (state, value) => {
      state.sentFeedbackTask = value.payload;
      state.sendFeedback = false;
    },
    setSentFeedbackTextTask: (state, value) => {
      state.sentFeedbackTextTask = value.payload;
      state.sendFeedback = false;
    },
    setSubmittedStudentTask: (state, value) => {
      state.submittedStudentTask = value.payload;
    },
    setSubmittedStudentReflection: (state, value) => {
      state.submittedStudentReflection = value.payload;
    },
    setSubmittedStudentResponse: (state, value) => {
      state.submittedStudentResponse = value.payload;
    },
    setSubmittedStudentReply: (state, value) => {
      state.submittedStudentReply = value.payload;
    },
    setSelectedTask: (state, value) => {
      state.selectedTaskId = value.payload;
      state.selectedQuestionIndex = 0;
    },
    setQuestionIndex: (state, value) => {
      // console.log("setQuestionIndex index", value);
      state.selectedQuestionIndex = value.payload;
    },
    setFeedbackTask: (state, value) => {
      state.feedbackTask = value.payload;
    },
    setSelectedTaskRow: (state, value) => {
      let answers = current(state.answers);
      if (
        !answers ||
        (value.payload &&
          value.payload.questions &&
          value.payload.questions.questions.length > answers.length)
      ) {
        state.answers = value.payload.questions.questions.map(el => {
          return {};
        });
      }
      state.taskRow = value.payload;
    },
    setSelectedText: (state, value) => {
      state.selectedText = value.payload;
    },
    setClickedMatch: (state, value) => {
      state.clickedMatch = value.payload;
    },
    sendFeedback: (state, value) => {
      state.sendFeedback = value.payload;
    },
    validateFeedback: (state, value) => {
      state.validateFeedback = value.payload;
    },
    // This is to override a problem where view is being rendered before got answer is called. Once a solution is implemented in the rendering order we should get rid of this.
    resetAnswers: (state, value) => {
      state.answers = [{}];
    },
    setShowCitationForQuestion: (state, value) => {
      state.showCitationForQuestion = value.payload;
    }
  }
});

export const {
  setSubmittedStudentTask,
  setSubmittedStudentReflection,
  setSubmittedStudentResponse,
  setSubmittedStudentReply,
  setSentFeedbackTask,
  setSentFeedbackTextTask,
  setSelectedTask,
  setClickedMatch,
  setSelectedText,
  setQuestionIndex,
  setSelectedTaskRow,
  sendFeedback,
  validateFeedback,
  setFeedbackTask,
  resetAnswers,
  setShowCitationForQuestion
} = taskSlice.actions;

export const taskSelectors = {
  answer: createSelector(
    state => state.task.answers,
    state => state.task.taskRow.questions.questions,
    state => state.task.selectedQuestionIndex,
    (answers, questions, index) => {
      return answers[index];
    }
  ),
  question: createSelector(
    state => state.task.taskRow.questions,
    state => state.task.selectedQuestionIndex,
    (questions, index) => {
      // selectedQuestionIndex returns before taskRow resulting with a usecase where there requested index does not exsist returning undefined (took me 2 hours to figure that out)
      return questions ? questions.questions[index] : null;
    }
  ),
  questionsForFeedback: createSelector(
    state => state.task.feedbackTask.questions,
    questions => {
      return questions
        ? questions.questions.map((q, i) =>
            Object.assign({}, q, { questionIndex: i })
          ) // Adding questionIndex is needed for the QuestionCarousel component
        : [];
    }
  )
};

export default taskSlice.reducer;
