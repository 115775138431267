import { createSlice } from "@reduxjs/toolkit";
export const defaultPersisentActionState = {
  actionBar: false,
  annotatorMode: false,
  showHighlights: true,
  clearSelection: false,
  highlightColor: "#91fcc2",
  highlightUnderline: false
};

export const readerActionsSlice = createSlice({
  name: "readerActions",
  initialState: {
    breadcrumbs: false,
    breadCrumbsClicked: false,
    submitButtonClicked: false,
    blueBreadcrumbs: false,
    showTextMenu: false,
    showSubmitTask: false,
    showSendFeedback: false,
    showDoneButton: false,
    selectedLocation: false,
    shownLocation: false,
    isLoading: false,
    persistentActionState: false
    // persistentActionState: {
    //   actionBar: false,
    //   annotatorMode: false,
    //   showHighlights: true,
    //   clearSelection: false,
    //   highlightColor: "#91fcc2",
    //   highlightUnderline: false,
    // },
  },

  extraReducers: {
    "gr/setStage": (state, value) => {
      state.shownLocation = false;
    },
    "gr/setSelectedQuestion": (state, value) => {
      state.shownLocation = false;
    },
    "gr/deleteSelectedAnswer": (state, value) => {
      state.shownLocation = false;
    },
    "gr/selectLocation": (state, value) => {
      state.selectedLocation = value.payload;
      if (value.payload) {
        state.shownLocation = value.payload;
      }
    },
    "task/setSelectedTask": (state, value) => {
      state.shownLocation = false;
    },
    "task/setQuestionIndex": (state, value) => {
      state.shownLocation = false;
    },
    "comments/setActiveComment": (state, value) => {
      if (value.payload.ref && value.payload.ref.cfi) {
        state.selectedLocation = value.payload.ref;
      }
    },

    "themes/selectTheme": (state, value) => {
      if (value.payload?.id)
        state.persistentActionState.annotatorMode = "themes";
    },
    gotAnswers: (state, value) => {
      state.shownLocation = false;
    },

    gotThemes: (state, value) => {
      state.shownLocation = false;
    }
  },
  reducers: {
    setClearSelection: (state, value) => {
      state.clearSelection = value.payload;
    },
    setLocation: (state, value) => {
      state.selectedLocation = value.payload;
      if (value.payload) {
        state.shownLocation = value.payload;
      }
    },
    setHighlightColor: (state, value) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.persistentActionState.highlightColor = value.payload;
    },
    setUnderline: (state, value) => {
      state.persistentActionState.highlightUnderline = value.payload;
    },
    setShowHighlights: (state, value) => {
      state.persistentActionState.showHighlights = value.payload;
      state.selectedLocation = false;
      state.shownLocation = false;
    },
    setBreadcrumbs: (state, action) => {
      state.breadCrumbsClicked = false;
      state.breadcrumbs = action.payload.breadcrumbs;
      if (action.payload.blue) {
        state.blueBreadcrumbs = action.payload.blue;
      } else state.blueBreadcrumbs = false;
      if (action.payload.showTextMenu) {
        state.showTextMenu = action.payload.showTextMenu;
      } else state.readerMenu = false;
      if (action.payload.showSendFeedback) {
        state.showSendFeedback = action.payload.showSendFeedback;
      } else state.showSendFeedback = false;
      if (action.payload.showSubmitTask) {
        state.showSubmitTask = action.payload.showSubmitTask;
      } else state.showSubmitTask = false;
      if (action.payload.showDoneButton) {
        state.showDoneButton = action.payload.showDoneButton;
      } else state.showDoneButton = false;
    },
    setAction: (state, action) => {
      try {
        state.persistentActionState.actionBar = action.payload;
        if (state.persistentActionState.actionBar === "poc") {
          state.persistentActionState.annotatorMode = "poc";
        }

        state.selectedLocation = false;
        state.shownLocation = false;
      } catch (err) {
        console.log("error in readerACtionSlice - setAction ", err);
      }
    },

    setBreadcrumbsClicked: (state, value) => {
      state.breadCrumbsClicked = value.payload;
    },
    setAnnotatorMode: (state, mode) => {
      state.persistentActionState.annotatorMode = mode.payload;
      state.selectedLocation = false;
      state.shownLocation = false;
    },
    setReaderActionData: (state, value) => {
      state.persistentActionState = value.payload;
    },
    setSubmitButtonClicked: (state, value) => {
      state.submitButtonClicked = value.payload;
    },
    setIsLoading: (state, value) => {
      state.isLoading = value.payload;
    }
  }
});

export const {
  setBreadcrumbsClicked,
  setReaderActionData,
  setClearSelection,
  setLocation,
  setHighlightColor,
  setUnderline,
  setAction,
  setAnnotatorMode,
  setShowHighlights,
  setBreadcrumbs,
  setShowAlternativeView,
  setSubmitButtonClicked,
  setIsLoading,
  toggleActionBar
} = readerActionsSlice.actions;

export default readerActionsSlice.reducer;
