// Dependencies
import React from "react";

// Redux dependencies
import { useSelector } from "react-redux";

// Components
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import ScrollBox from "../../SharedComponents/ScrollBox";

// Styles
const useStyles = makeStyles(theme => ({
  tabPanel: {
    fontSize: "22px",
    height: "100%",
    paddingBlockStart: theme.spacing(4.5),
    paddingInlineStart: theme.spacing(2),
    "& :not($reflectionHeader)": {
      fontFamily: '"Crimson Pro", "Frank Ruhl Libre"'
    }
  },
  reflectionHeader: {
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px"
  }
}));

export default function PeerReviewReflectionPanel({
  reflection,
  answerRef,
  ...props
}) {
  // Hooks
  const classes = useStyles();

  // Redux state
  const currentUser = useSelector(state => state.user.auth);
  const task = useSelector(state => state.task.taskRow);

  // Ephemeral state

  // Variables

  // Behavior

  //render
  return (
    <ScrollBox ref={answerRef}>
      <Box
        className={classes.tabPanel}
        role="tabpanel"
        id={`tabpanel-${0}`}
        aria-labelledby={`reflection`}>
        <Box className={classes.reflectionHeader}>
          {currentUser.uid === task.owner && `My reflection`}
          {currentUser.uid !== task.owner &&
            (reflection?.displayName
              ? `${reflection?.displayName}'s reflection`
              : `reflection`)}
        </Box>
        <RenderEditorContent>{reflection?.data}</RenderEditorContent>
      </Box>
    </ScrollBox>
  );
}
