import React, { useRef, useState, useEffect } from "react";
import TaskFeedbackComment from "../Tasks/TaskFeedbackComment";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { setAnnotatorMode } from "../../redux/readerActionsSlice";
import { getHighlightColor } from "../../utils/colors";
import { setLinkType, resetLink, setLinkContent } from "../../redux/LinkSlice";
import { useSelector, useDispatch } from "react-redux";
import { updateThemes } from "../../redux/firebaseMiddleware";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Typography } from "@material-ui/core";
import ReactReaderActions from "../annotations/ReactReaderActions";
import {
  openAnnotatorBar,
  closeAnnotatorBar
} from "../../redux/highlightSlice";
import { makeStyles } from "@material-ui/core/styles";
import EpubView from "../reader/EpubView/EpubView";
import { setThemeMode, setThemes } from "../../redux/themeSlice";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LinkIcon from "@material-ui/icons/Link";
import {
  Box,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Tooltip
} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  cardsContainer: {
    position: "relative",
    height: "100%"
  },
  paperContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)"
  },
  paper: {
    gridColumn: " 2 / 11 ",
    marginLeft: "56px",

    height: "max-content",
    position: "relative"
  },
  header: {
    marginTop: "40px",
    marginBottom: "24px",
    textAlign: "left",
    fontSize: "24px",
    // color: "rgba(0,0,0,0.87)",
    paddingLeft: "60px"
  },
  pointsContainer: {
    position: "relative",
    minHeight: "56px",
    padding: "4px"
  },
  feedbackBtns: {
    position: "absolute",
    right: "8px"
  },
  dialogActions: {
    justifyContent: "left"
  },
  dialogActionsRtl: {
    justifyContent: "right"
  },
  dialogBtn: {
    position: "absolute",
    color: "#ffffff",
    right: "16px"
  },
  dialogTitle: {
    background: "#5ec891",
    color: "#ffffff"
  },
  commentContainer: {
    padding: "16px",
    fontSize: "20px",
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"'
  },
  themeView: {
    position: "absolute",
    // background: "#E8E8E8",
    zIndex: 1,
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px"
  }
}));

export function ThemeNote(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const themes = useSelector(state => {
    return state.themes.themes;
  });
  const selectedTextId = useSelector(state => state.texts.selectedTextId);

  const selectedTheme = useSelector(state => {
    let filtered = state.themes.themes.filter(
      q => q.id === state.themes.selectedThemeId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });

  const darkMode = useSelector(state => state.user.userProfile.darkMode);
  const highlightColor = useSelector(
    state => state.readerActions.highlightColor
  );

  const { readText } = props;

  const rtlValue = useSelector(state => state.user.userProfile.rtl);

  const [openDialog, setOpenDialog] = useState(false);
  const epubElemRef = useRef();
  const epubRef = useRef();
  const [rendition, setRendition] = useState(null);
  const intl = useIntl();
  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const selectedFeedbackText = useSelector(state => state.link.selected);

  const fontSize = useSelector(state => state.user.fontSizeOptions);

  const lastLocation = useSelector(state => {
    return state.texts.lastLocation;
  });

  //  console.log("linkContent - 1", linkContent);
  const linkBtnRef = useRef();
  const [linksCfi, setLinksCfi] = useState([]);
  const addHighlightToAnswer = cfi => {
    setLinksCfi([...linksCfi, { cfi: cfi, color: highlightColor }]);
  };
  const removeHighlightFromAnswer = hl => {
    setLinksCfi(linksCfi.filter(el => el !== hl.cfi && el.cfi !== hl.cfi));
  };

  //effect to add highlights
  const [highlightsElements, setHighlightsElements] = useState([]);
  // const highlights = props.highlights;
  const [url, setUrl] = useState(false);

  useEffect(() => {
    if (readText) {
      /* firebaseApp
    .storage()
    .ref("courseTexts/"+props.readText.course_id)
    .child(props.readText.file_url)
    .getDownloadURL()
    .then(url => {
      setUrl(url);
    });
  }
  else if (readText.url ){*/
      setUrl(readText.url);
    }
  }, [readText]);

  useEffect(() => {
    const setExistingElements = items => {
      let toRemove = [];
      highlightsElements &&
        Object.entries(highlightsElements).forEach(([cfi, elem]) => {
          let highlightExist =
            linksCfi && linksCfi.filter(el => el.cfi === cfi);
          if (!highlightExist) {
            toRemove.push(cfi);
            rendition.annotations.remove(cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingHighlights = items => {
      let timeAdding = new Date().getTime();

      // console.log("after conds");
      linksCfi &&
        rendition &&
        rendition.manager &&
        linksCfi.forEach(linkEl => {
          let cfi = linkEl.cfi;
          let range = rendition.getRange(cfi);
          if (range && !(cfi in items)) {
            let addedHighlight = rendition.annotations.highlight(
              cfi,
              {},
              e => {
                let timeClick = new Date().getTime();
                if (timeClick - timeAdding < 500) return;
                let contents = rendition.getContents();

                contents.forEach(content => {
                  if (range) {
                    let pos = rendition.getContents()[0].locationOf(cfi);
                    if (pos.x !== 0 || pos.y !== 0) {
                      let clientRect = range.getBoundingClientRect();
                      const frameBounds =
                        content.document.defaultView.frameElement.getBoundingClientRect();
                      // `target` can be an element or a range
                      let newRect = {
                        x: clientRect.x + frameBounds.x,
                        y: clientRect.y + frameBounds.y,
                        width: clientRect.width,
                        height: clientRect.height,
                        top: clientRect.top + frameBounds.y,
                        left: clientRect.left + frameBounds.x,
                        bottom:
                          clientRect.top + frameBounds.y + clientRect.height,
                        right:
                          clientRect.left + frameBounds.x + clientRect.width
                      };
                      dispatch(
                        openAnnotatorBar({
                          clientRectangle: newRect,
                          selectedText: { cfi: cfi },
                          isHover: true
                        })
                      );
                    }
                  }
                });
              },
              "highlightClass",
              {
                "z-index": 10,
                "mix-blend-mode": "multiply",
                "fill-opacity": 1,
                fill: getHighlightColor(linkEl.color, darkMode)
              }
            );

            items[cfi] = { ...addedHighlight, cfi: cfi };
            //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

            addedHighlight.mark.element.style.cursor = "pointer";
            addedHighlight.mark.element.style.pointerEvents = "visible";
          }
        });
      return items;
    };

    const renderHighlights = () => {
      let items = highlightsElements;

      items = setExistingElements(items);
      items = addMissingHighlights(items);

      setHighlightsElements(items);
    };
    //  console.log("should addd highligh useEffect");
    if (rendition) {
      // console.log("rendition useEffect", rendition.manager);
    }
    if (openDialog === 1) renderHighlights();
  }, [linksCfi, openDialog, rendition, highlightsElements, darkMode, dispatch]);

  const onRenditionCreated = rendition => {
    if (rendition) {
      selectedTheme &&
        selectedTheme.quotes &&
        selectedTheme.quotes.map((quote, i) => {
          let hlStyleObj = {
            "z-index": 10,
            "mix-blend-mode": "multiply",
            "fill-opacity": 1,
            fill: getHighlightColor(quote.color, darkMode)
          };
          try {
            rendition.annotations.highlight(
              quote.cfi,
              {},
              e => {},
              "highlightClass",
              hlStyleObj
            );
          } catch (err) {
            console.log("error in  hl", err);
            console.log("cfi ", quote.cfi);
          }
          return 1;
        });
    }
    setRendition(rendition);
    if (lastLocation && lastLocation !== "") {
      let section = rendition.book.spine.get(lastLocation);
      var visible = section && rendition.manager.views.find(section); // View is already shown, just move to correct location in view
      let target = lastLocation;
      if (target === section.href) {
        target = undefined;
      }
      if (visible && section) {
        let offset = target ? visible.locationOf(target) : visible.offset();
        epubElemRef &&
          epubElemRef.current &&
          epubElemRef.current.scrollTo(0, offset.top);
        //rendition.display(openDialog.cfi);
      }
    }
  };

  const renderTextLinkDialog = () => {
    return (
      <Dialog
        open={Boolean(openDialog)}
        PaperProps={{
          style: {
            direction: rtlValue ? "rtl" : "ltr",

            width: "90%",
            maxWidth: "90%"
          }
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <DialogActions
            className={clsx(
              classes.dialogActions,
              rtlValue && classes.dialogActionsRtl
            )}
          >
            <Typography className={classes.dialogHeader}></Typography>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenDialog(false);
                if (linksCfi.length > 0) {
                  dispatch(setLinkType("text"));
                  // console.log("setLinkContent", linksCfi);
                  dispatch(setLinkContent({ cfis: linksCfi }));
                } else {
                  dispatch(resetLink());
                }
                setLinksCfi([]);

                //setHighlightsElements([]);
                epubRef.current && epubRef.current.book.destroy();
              }}
            >
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <ReactReaderActions
            id="reactReaderActions"
            minimal={true}
            highlightFunc={addHighlightToAnswer}
            removeHighlight={removeHighlightFromAnswer}
          >
            <div id="epubViewContainer" className={classes.epubViewContainer}>
              <EpubView
                fullSize={true}
                ref={epubRef}
                fontSize={fontSize[fontSizeValue]}
                epubOptions={{ flow: "scrolled-doc" }}
                // loadingView={inputRef}
                rtl={rtlValue}
                url={url}
                location={
                  lastLocation && lastLocation !== ""
                    ? lastLocation
                    : readText.file_location
                }
                // locationChanged={locationChanged}
                handleTextSelected={params => {
                  if (openDialog !== 1) return;
                  if (params.shouldClose) {
                    dispatch(closeAnnotatorBar());
                  } else {
                    addHighlightToAnswer(params.selection.cfi);
                    let content = rendition && rendition.getContents()[0];

                    if (
                      content &&
                      content.window &&
                      content.window.getSelection()
                    ) {
                      content.window.getSelection().empty();
                    }
                  }
                }}
                onRenditionCreated={rendition => {
                  onRenditionCreated(rendition);
                }}
              />
            </div>
          </ReactReaderActions>
        </DialogContent>
      </Dialog>
    );
  };

  const saveNote = content => {
    let updatedThemes = themes.map(el => {
      if (el.id === selectedTheme.id) {
        return { ...el, note: content };
      } else return el;
    });
    setThemes(updatedThemes);
    dispatch(updateThemes({ textId: selectedTextId, themes: updatedThemes }));
  };

  return (
    <Box className={classes.themeView}>
      <Box className={classes.header}>
        <Typography variant="h4" className={classes.headerTitle}>
          <IconButton
            className={classes.btn}
            onClick={() => {
              dispatch(setAnnotatorMode("themes"));
              dispatch(setThemeMode(""));
              props.back();
            }}
          >
            {rtlValue ? <ArrowForwardIcon /> : <ArrowBackIcon />}
          </IconButton>{" "}
          <FormattedMessage
            id="actions.themes.notetitle"
            defaultMessage="Theme Note"
          />
        </Typography>
      </Box>
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Box className={classes.commentContainer}>
            <TaskFeedbackComment
              subcribeToUnload={true}
              showFeedbackLine={false}
              readOnly={false}
              autoFocus={true}
              onChange={saveNote}
              index={selectedTheme.id}
              placeholder={intl.formatMessage({
                id: "themes.note.placeholder",
                defaultMessage: "Enter your note here"
              })}
              comment={selectedTheme.note}
            />
          </Box>

          <Divider />
          <Box className={classes.pointsContainer}>
            <Box className={classes.feedbackBtns}>
              <Tooltip
                title={intl.formatMessage({
                  id: "feedback.tooltip.link",
                  defaultMessage: "Add link"
                })}
                arrow
                placement={rtlValue ? "left" : "right"}
              >
                <span>
                  <IconButton
                    ref={linkBtnRef}
                    className={classes.textIcon}
                    disabled={!selectedFeedbackText}
                    onClick={() => {
                      setLinksCfi([]);
                      setOpenDialog(1);
                    }}
                  >
                    <LinkIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: "feedback.tooltip.showText",
                  defaultMessage: "Show text"
                })}
                arrow
                placement={rtlValue ? "left" : "right"}
              >
                <span>
                  <IconButton
                    className={classes.textIcon}
                    onClick={() => setOpenDialog(2)}
                  >
                    <LibraryBooksIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      </Box>
      {openDialog && renderTextLinkDialog()}
    </Box>
  );
}
