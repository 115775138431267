export function getHighlightColor(color, darkMode) {
    let colors =[{color:'#91fcc2',text:'color.Green', hlColorOld:'#00FF7F',hlColor:'#91fcc2', darkMode:'#5ec891'},
    {color:'#93d4d9',text:'color.Cyan', hlColorOld:'#00B9FB', hlColor:'#04E3FD', darkMode:"#77babf"},
    {color:'#ffa284',text:'color.Orange', hlColorOld:'#ffa284', hlColor:'#ffa284'},
    {color:'#ff8a8a',text:'color.Red', hlColorOld:'#DE2939', hlColor:'#ff8a8a'},
    {color:'#ffcb73',text:'color.Yellow', hlColorOld:'#F5BC21', hlColor:'#FFFF00',darkMode:'#f2f21d'}];

    let relEntry = colors.filter(c=> c.color === color || c.hlColor === color || c.hlColorOld === color || c.darkMode === color);
    if (relEntry && relEntry.length) {
        let entry = relEntry[0];
        if (darkMode && entry.darkMode) {
            return entry.darkMode;
        }
        else return entry.hlColor;
    }
 
    return color;

};
