// Dependencies
import React from "react";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/userSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Select, InputLabel, MenuItem } from "@material-ui/core";

//Styles
const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ProfilePage() {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  //Ephemeral state

  //Redux state
  const userProfile = useSelector((state) => state.user.userProfile);

  //Variables
  const durations = [5, 10, 20, 30];

  //Behavior

  // Render
  return (
    <>
      <InputLabel shrink id="alert-duration-select-lable">
        Alerts duration
      </InputLabel>
      <Select
        labelId="alert-duration-select-lable"
        id="alert-duration-select"
        value={userProfile.alertsDuration / 1000} // show in secounds
        displayEmpty
        className={classes.selectEmpty}
      >
        {durations.map((duration, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                dispatch(
                  setProfile({
                    ...userProfile,
                    alertsDuration: duration * 1000, // set in milisecounds
                  })
                );
              }}
              value={duration}
            >
              {`${duration} seconds`}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}
