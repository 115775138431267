// Dependencies
import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import update from "immutability-helper";
import { useIntl, FormattedMessage } from "react-intl";
import ePub from "epubjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r, getNextId } from "../../redux/firebaseMiddleware";
import { setSelectedQuestion, deleteSelectedAnswer } from "../../redux/grSlice";
import { enqueueFlashMessage, undo } from "../../redux/userSlice";
import { setShowAnswers, setShowHighlights } from "../../redux/grSlice";

// Components
import { QuestionQuoteCard } from "./QuestionQuoteCard";
import { QuestionBox } from "./QuestionBox";
import EditingButton from "../EditingButton";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import {
  List,
  ListItem,
  Button,
  IconButton,
  Collapse,
  Box,
  FormControlLabel,
  Switch
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  questionsManager: {
    flex: 1,
    overflowX: "hidden"
  },
  questionContainer: {
    height: "100%"
  },
  panelHeader: {
    color: "white",
    width: "100%",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  spacer: {
    width: theme.spacing(2),
    flexShrink: 0
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  liRtl: {
    "& button": {
      left: "0px"
    }
  },
  liLtr: {
    "& button": {
      right: "0px"
    }
  },
  addThemeRtl: {
    justifyContent: "right"
  },
  addThemeLtr: {
    justifyContent: "left"
  },
  questionIcon: {
    display: "inline-flex",
    transition: "0.3s ease-out",
    alignSelf: "flex-start"
  },
  editmodeLine: {
    paddingLeft: theme.spacing(2),
    minHeight: "40px"
  },
  questionText: {
    color: "#fafafa",
    fontSize: "16px"
  },
  selectedQuestion: {
    color: "black",
    backgroundColor: theme.palette.secondary.main
  },
  listItem: {
    padding: 0,
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    // FIXME: this is a hack
    "&>div": {
      width: "100%"
    }
  },
  rotateChevron: {
    transform: "rotate(90deg)"
  }
}));

export default function QuestionsManager(props) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Redux State
  const questions = useSelector(state => state.gr.questions);
  const highlights = useSelector(state => state.gr.highlights);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedQuestion = useSelector(state => {
    if (state.gr.questions?.length) {
      let filtered = state.gr.questions.filter(
        q => q.id === state.gr.selectedQuestionId
      );
      if (filtered && filtered.length) return filtered[0];
      else return false;
    }
  });
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const showAnswers = useSelector(state => state.gr.showAnswers);
  const showHighlights = useSelector(state => state.gr.showHighlights);
  const selectedAnswer = useSelector(state => state.gr.selectedAnswer);
  const shownLocation = useSelector(state => {
    return state.readerActions.shownLocation;
  });
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  const renderAddBtn =
    (grMode === "full" && stage === 1) ||
    (grMode === "light" && [0, 1].includes(stage));

  // Ephemeral State
  const { openQuestions, setOpenQuestions } = props;
  const [newQuestionMode, setNewQuestionMode] = useState({
    adding: false,
    value: ""
  });
  const [previousSelectedQuestion, setPreviousSelectedQuestion] = useState();
  const [tempQuestions, setTempQuestions] = useState([]);
  const [undoData, setUndoData] = useState(null);
  const rtl = useSelector(state => state.user.userProfile.rtl);

  // Variables
  var EpubCFI = new ePub.CFI();

  // Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoDeleteQuestion = () => {
      if (undoData?.type === "deleteQuestionTheme") {
        let relQuestion = questions.filter(
          el => el.id === undoData.question.id
        );

        if (relQuestion.length) {
          let question = relQuestion[0];
          let items = question.answers
            ? question.answers.filter(a => a.cfi !== undoData.quote.cfi)
            : [];
          let answers = [...items];
          let i = 0;
          let insertAt = answers.length;
          for (i = 0; i < answers.length; i++) {
            if (EpubCFI.compare(answers[i].cfi, undoData.quote.cfi) > 0) {
              insertAt = i;
              break;
            }
          }
          answers.splice(insertAt, 0, undoData.quote);
          let modQ = { ...question, answers: answers };

          dispatch(
            updateSq3r({
              textId: selectedTextId,
              questions: questions.map(el => {
                if (el.id === question.id) return modQ;
                else return el;
              }),
              highlights: highlights
            })
          );
        }
      } else if (undoData?.type === "deleteQuestion") {
        let id = undoData.question.id;
        id = getNextId(questions);

        let question = { ...undoData.question, id: id };
        let newQuestions = [...questions];
        newQuestions.splice(undoData.index, 0, question);

        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: newQuestions,
            highlights: highlights
          })
        );
      }
    };

    if (shouldUndo) {
      undoDeleteQuestion();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [
    shouldUndo,
    dispatch,
    EpubCFI,
    highlights,
    questions,
    selectedTextId,
    undoData
  ]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData?.type === "deleteQuestion") {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "delete.question",
            defaultMessage: "Question deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    } else if (undoData?.type === "deleteQuestionTheme")
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "delete.card",
            defaultMessage: "Card deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
  }, [undoData, dispatch, intl]);

  useEffect(() => {
    setTempQuestions(questions);
  }, [questions]);

  const toggleOpenQuestion = id => {
    if (openQuestions.includes(id)) {
      setOpenQuestions(openQuestions.filter(el => el !== id));
    } else {
      setOpenQuestions([...openQuestions, id]);
    }
  };

  useEffect(() => {
    if (newQuestionMode.addValue && newQuestionMode.shouldAdd) {
      let id = getNextId(questions);

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: [
            ...questions,
            { id: id, question: newQuestionMode.addValue, answers: [] }
          ],
          highlights: highlights
        })
      );
      setNewQuestionMode({
        value: "",
        adding: newQuestionMode.adding,
        addValue: "",
        shouldAdd: false
      });
      setPreviousSelectedQuestion(id);
    }
  }, [newQuestionMode, dispatch, highlights, questions, selectedTextId]);

  useEffect(() => {
    if (!newQuestionMode.adding && previousSelectedQuestion) {
      dispatch(setSelectedQuestion({ id: previousSelectedQuestion }));
      setPreviousSelectedQuestion(0);
    }
  }, [newQuestionMode, previousSelectedQuestion, dispatch]);

  const getAnswers = myAnswers => {
    if (!myAnswers || !myAnswers.filter) return myAnswers;
    if (stage < 3) {
      return myAnswers
        .slice()
        .filter(el => el.cfi)
        .sort(function (a, b) {
          return EpubCFI.compare(a.cfi, b.cfi);
        });
    } else return myAnswers.filter(el => !el.hidden);
  };

  const renderCards = (question, index) => {
    let sortedAnswers =
      !openQuestions.includes(question.id) &&
      question.id === selectedAnswer.questionId
        ? [selectedAnswer]
        : getAnswers(question.answers);
    return (
      <Collapse
        in={
          question.id === selectedAnswer.questionId ||
          openQuestions.includes(question.id)
        }
        timeout="auto"
        unmountOnExit
      >
        <List>
          {sortedAnswers &&
            sortedAnswers.map((item, index) => (
              <ListItem key={index} ContainerComponent="li">
                <QuestionQuoteCard
                  question={question}
                  item={item}
                  index={index}
                  onDelete={(question, quote) => {
                    console.log("this is type 1");
                    setUndoData({
                      type: "deleteQuestionTheme",
                      question,
                      quote
                    });
                    if (quote.cfi === shownLocation.cfi) {
                      dispatch(deleteSelectedAnswer());
                    }
                  }}
                />
              </ListItem>
            ))}
        </List>
      </Collapse>
    );
  };

  //const grid = 8;

  /*const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer

    // change background colour if dragging
    cursor: isDragging ? "grab" : "pointer",

    // styles we need to apply on draggables
    ...draggableStyle,
  });*/

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      tempQuestions,
      result.source.index,
      result.destination.index
    );

    setTempQuestions(items);

    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: items,
        highlights: highlights
      })
    );
  };

  const onDelete = (question, index) => {
    setUndoData({
      type: "deleteQuestion",
      question,
      index
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const addQuestion = () => {
    props.scrollToBottom();
    selectedQuestion &&
      selectedQuestion.id &&
      setPreviousSelectedQuestion(selectedQuestion.id);
    dispatch(setSelectedQuestion({ id: 0 }));

    if (!newQuestionMode.adding) {
      setNewQuestionMode({ adding: true, value: "" });
    } else {
      if (newQuestionMode.value) {
        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: [
              ...questions,
              {
                id: getNextId(questions),
                question: newQuestionMode.value,
                answers: []
              }
            ],
            highlights: highlights
          })
        );

        setNewQuestionMode({ adding: true, value: "" });
      }
    }
  };

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      console.log(newQuestionMode.value);
      if (newQuestionMode.value) {
        let newId = getNextId(questions);
        console.log(newId);
        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: [
              ...questions,
              {
                id: newId,
                question: newQuestionMode.value,
                answers: []
              }
            ],
            highlights: highlights
          })
        );
        setNewQuestionMode({
          adding: true,
          value: "",
          addValue: newQuestionMode.value,
          shouldAdd: true
        });
        setPreviousSelectedQuestion(newId);
        props.scrollToBottom();
      } else {
        setNewQuestionMode({
          adding: false,
          value: "",
          addValue: "",
          shouldAdd: false
        });
      }
      e.preventDefault();
    }
  }

  const renderNewQuestion = () => {
    if (
      ((grMode === "full" && stage === 1) ||
        (grMode === "light" && stage < 2)) &&
      newQuestionMode.adding
    ) {
      return (
        <Box
          className={clsx(
            classes.liLtr,
            classes.editmodeLine,
            classes.selectedQuestion
          )}
        >
          <EditingButton
            editIcon={<React.Fragment />}
            colorClass="black"
            onFocusOut={item => {
              if (item) {
                let newId = getNextId(questions);
                setNewQuestionMode({
                  adding: false,
                  value: "",
                  addValue: item,
                  shouldAdd: true
                });
                dispatch(
                  updateSq3r({
                    textId: selectedTextId,
                    questions: [
                      ...questions,
                      { id: newId, question: item, answers: [] }
                    ],
                    highlights: highlights
                  })
                );
                setPreviousSelectedQuestion(newId);
              } else
                setNewQuestionMode({
                  adding: false,
                  value: "",
                  addValue: "",
                  shouldAdd: false
                });
            }}
            multiline={true}
            key={questions.length}
            text={newQuestionMode.value}
            onChange={e => {
              setNewQuestionMode({ adding: true, value: e.target.value });
            }}
            editingMode={true}
            onKeyPress={handleKeyPress}
          />
        </Box>
      );
    } else return "";
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = questions[dragIndex];
      let updatedQuestions = update(questions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: updatedQuestions,
          highlights: highlights
        })
      );
    },
    [questions, dispatch, highlights, selectedTextId]
  );

  const renderQuestion = (question, index) => {
    //QuestionDropTarget
    return (
      <ListItem className={classes.listItem} key={question.id}>
        <Draggable
          key={question.id}
          isDragDisabled={openQuestions.includes(question.id)}
          draggableId={question.id.toString()}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              style={{ width: "100%" }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              tabIndex="-1"
            >
              <QuestionBox
                onDelete={onDelete}
                key={question.id}
                index={index}
                id={question.id}
                text={question.question}
                question={question}
                moveCard={moveCard}
                icon={
                  (grMode === "full" && [2, 4].includes(stage)) ||
                  (grMode === "light" && [1, 2].includes(stage)) ? (
                    <IconButton
                      onClick={e => {
                        toggleOpenQuestion(question.id);
                        e.stopPropagation();
                      }}
                      className={clsx(
                        classes.questionText,
                        classes.questionIcon,
                        selectedQuestion?.id === question.id &&
                          classes.selectedQuestion,
                        {
                          [classes.rotateChevron]: openQuestions.includes(
                            question.id
                          )
                        }
                      )}
                    >
                      {rtl ? <ArrowLeft /> : <ArrowRight />}
                    </IconButton>
                  ) : (
                    <div className={classes.spacer} />
                  )
                }
              />
            </div>
          )}
        </Draggable>
        {/* TODO: Ofer - think of a way to incapsulate this condition */}
        {((grMode === "full" && [2, 4].includes(stage)) ||
          (grMode === "light" && [1, 2].includes(stage))) &&
          renderCards(question, index)}
      </ListItem>
    );
  };

  // Render
  return (
    <div className={classes.questionsManager}>
      {renderAddBtn && (
        <Box className={clsx(classes.panelHeader, classes.left)}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={addQuestion}
            className={clsx(classes.addTheme, classes.addThemeLtr)}
          >
            <FormattedMessage
              id="gr.addQuestion"
              defaultMessage="Add Question"
            />
          </Button>
          {grMode === "light" && stage === 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={showHighlights}
                  onChange={e => {
                    dispatch(setShowHighlights(!showHighlights));
                  }}
                />
              }
              label={
                <FormattedMessage
                  id="gr.highlightsView"
                  defaultMessage="Highlight view"
                />
              }
            />
          )}
          {grMode === "light" && stage === 1 && (
            <FormControlLabel
              control={
                <Switch
                  checked={showAnswers}
                  onChange={e => {
                    dispatch(setShowAnswers(!showAnswers));
                  }}
                />
              }
              label={
                <FormattedMessage
                  id="gr.answersView"
                  defaultMessage="Answer view"
                />
              }
            />
          )}
        </Box>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.questionContainer}
            >
              {tempQuestions.length > 0 &&
                tempQuestions.map((item, index) => {
                  return (
                    <List key={index} disablePadding>
                      {renderQuestion(item, index)}
                    </List>
                  );
                })}
              {renderNewQuestion()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
