import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { updateSq3r } from "../../../redux/firebaseMiddleware";
import SubmitDialog from "../SubmitDialog";
import { Button, Box, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedQuestion } from "../../../redux/grSlice";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: "16px",
    marginBottom: "10px",
    fontSize: 16
  },
  btn: {
    marginRight: "8px"
    // color: theme.palette.lightText.main
  },
  nextBtn: {
    background: theme.palette.secondary.main,
    color: theme.palette.lightText.main,
    "&:hover": {
      background: theme.palette.secondary.dark
    }
  },
  nextBtnLtr: {
    right: "0px"
  },
  feedbackContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 37
  },
  feedback: {
    marginTop: theme.spacing(5)
  },
  dark: {
    background: "#424242",
    color: "#fff",
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.7)"
    },
    "& textarea": {
      color: "inherit",
      "&::placeholder": {
        color: "rgba(255, 255, 255, 0.7)"
      }
    }
  }
}));

function SummaryActions() {
  const userProfile = useSelector(state => state.user.userProfile);
  const rtl = userProfile.rtl;
  const dispatch = useDispatch();
  const classes = useStyles();
  const highlights = useSelector(state => state.gr.highlights);
  const questions = useSelector(state => {
    return state.gr.questions;
  });
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedQuestion = useSelector(state => {
    let filtered = state.gr?.questions?.filter(
      q => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const [openSubmit, setOpenSubmit] = useState(false);
  const questionIndex = questions.findIndex(
    el => el.id === selectedQuestion.id
  );
  const darkTheme = useSelector(state => state.user.userProfile.darkMode);

  function ownFeedback(score) {
    let modQ = { ...selectedQuestion, ownFeedback: score };
    let updatedQuestions = questions.map(el =>
      el.id === selectedQuestion.id ? modQ : el
    );
    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );
  }

  return (
    <Box className={classes.feedback}>
      <Box className={classes.title}>
        <FormattedMessage
          id="gr.feedback.question"
          defaultMessage="How did I do?"
        />{" "}
      </Box>
      <Box
        className={clsx(classes.feedbackContainer, {
          [classes.darkTheme]: darkTheme
        })}>
        <Box>
          <Button
            onClick={() => ownFeedback(1)}
            className={clsx(classes.btn, {
              [classes.feedbackSelected]: selectedQuestion.ownFeedback === 1
            })}
            variant={
              selectedQuestion.ownFeedback && selectedQuestion.ownFeedback === 1
                ? "contained"
                : "outlined"
            }
            color="secondary">
            <FormattedMessage
              id="exerciseFeedback.1"
              defaultMessage="Awesome"
            />
          </Button>
          <Button
            onClick={() => ownFeedback(0)}
            className={clsx(classes.btn)}
            variant={
              "ownFeedback" in selectedQuestion &&
              selectedQuestion.ownFeedback === 0
                ? "contained"
                : "outlined"
            }
            color="secondary">
            <FormattedMessage
              id="exerciseFeedback.2"
              defaultMessage="Not bad"
            />
          </Button>
          <Button
            onClick={() => ownFeedback(-1)}
            className={clsx(classes.btn, {
              [classes.feedbackSelected]: selectedQuestion.ownFeedback === -1
            })}
            variant={
              selectedQuestion.ownFeedback &&
              selectedQuestion.ownFeedback === -1
                ? "contained"
                : "outlined"
            }
            color="secondary">
            <FormattedMessage
              id="exerciseFeedback.3"
              defaultMessage="Not great"
            />
          </Button>
        </Box>
        {questionIndex < questions.length - 1 && (
          <IconButton
            onClick={() =>
              dispatch(setSelectedQuestion(questions[questionIndex + 1]))
            }
            className={clsx(classes.nextBtn, classes.nextBtnLtr)}
            color="secondary">
            {rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
        {questions[questionIndex].ownFeedback !== undefined &&
          questionIndex === questions.length - 1 && (
            <>
              <Button
                variant="contained"
                className={clsx(classes.nextButton, classes.nextButtonLtr)}
                color="secondary"
                onClick={() => {
                  //console.log("setStage",stage);
                  setOpenSubmit(true);
                  //  dispatch(setAnnotatorMode(''));
                  //  dispatch(setAction(''))
                }}>
                <FormattedMessage
                  id="gr.button.finish"
                  defaultMessage="Finish"
                />
              </Button>
              <SubmitDialog
                open={openSubmit}
                onClose={() => setOpenSubmit(false)}
              />
            </>
          )}
      </Box>
    </Box>
  );
}

export default SummaryActions;
