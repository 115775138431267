import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EmptyState from "./EmptyState";
import AnswerCard from "./AnswerCard";
import update from "immutability-helper";
import { updateSq3r } from "../../redux/firebaseMiddleware";
import { setQuestions } from "../../redux/grSlice";
import CitationDialog from "../Tasks/CreateTask/CitationDialog";
import { Box } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: "#616161",
    marginBottom: "16px",
    marginTop: "16px"
  },

  white: {
    color: "#e0e0e0"
  },
  fullLine: {
    width: "calc(100% - 24px)"
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)"
  },
  container: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    bottom: "0"
  },
  cardContainer: {
    gridColumn: " 2 / 11 ",
    height: "100%",
    position: "relative",
    paddingLeft: "56px",
    paddingRight: "56px"
  },
  answer: {
    marginTop: "16px",
    marginBottomn: "16px",
    position: "relative"
  },
  li: {
    "&:hover": {
      borderBottom: "1px solid #5ec891"
    },
    "& button": {
      position: "absolute",
      color: "#5ec891",
      visibility: "hidden"
    },
    "&:hover button": {
      visibility: "visible"
    }
  },
  menuBtn: {
    color: "white"
  },
  rtl: {
    textAlign: "right"
  },
  ltr: {
    textAlign: "left"
  },

  menu: {
    borderRadius: "4px",
    marginRight: "16px"
  }
}));

export function Card({
  cards,
  dragItemType,
  onTitleChange,
  moveCard,
  hideCard,
  showCard,
  showInText,
  disableDrag
}) {
  const classes = useStyles();
  const visibleCards = cards && cards.filter && cards.filter(el => !el.hidden);
  const hiddenCards = cards && cards.filter && cards.filter(el => el.hidden);
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);

  return cards.length ? (
    <Box className={classes.container}>
      <Box className={classes.gridContainer}>
        <Box className={classes.cardContainer}>
          {visibleCards &&
            visibleCards.map((item, index) => (
              <Box className={classes.answer} key={item.cfi}>
                <AnswerCard
                  disableDrag={disableDrag}
                  showInText={showInText}
                  drag_item={dragItemType}
                  answer={item}
                  onTitleChange={onTitleChange(item)}
                  index={index}
                  moveCard={moveCard}
                  hideAnswer={
                    grMode === "light" && stage === 0
                      ? undefined
                      : () => hideCard(item)
                  }
                />
              </Box>
            ))}
          {hiddenCards &&
            hiddenCards.map((item, index) => (
              <Box className={classes.answer} key={item.cfi}>
                <AnswerCard
                  disableDrag={disableDrag}
                  showInText={showInText}
                  drag_item={dragItemType}
                  answer={item}
                  index={index}
                  onTitleChange={onTitleChange(item)}
                  hideAnswer={() => showCard(item)}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  ) : (
    <EmptyState />
  );
}

export default function CardsManager({
  readText,
  context,
  disableDrag = false
}) {
  const dispatch = useDispatch();

  const DRAG_ITEM_TYPE = `GR.${context}`;

  const highlights = useSelector(state => state.gr.highlights);
  const questions = useSelector(state => {
    return state.gr.questions;
  });
  const selectedTextId = useSelector(state => state.texts.selectedTextId);

  const selectedQuestion = useSelector(state => {
    let filtered = state.gr?.questions?.filter(
      q => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });

  const onAnswerTitleChange = item => newTitle => {
    let updatedQuestions = questions.map(el => {
      if (el.id === selectedQuestion.id) {
        return {
          ...el,
          answers: el.answers.map(ans => {
            if (ans.cfi === item.cfi) {
              return { ...ans, title: newTitle };
            } else return ans;
          })
        };
      } else return el;
    });
    dispatch(setQuestions(updatedQuestions));
    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );
  };

  const updateHighlight = (id, updatePart) => {
    const updatedHighlights = highlights.reduce((acc, highlight) => {
      if (highlight.id === id) {
        acc.push(Object.assign({}, highlight, updatePart));
      } else {
        acc.push(highlight);
      }
      return acc;
    }, []);
    console.log(updatedHighlights);
    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: questions,
        highlights: updatedHighlights
      })
    );
  };

  const onHighlightTitleChange = item => newTitle => {
    updateHighlight(item.id, { title: newTitle });
  };

  const answers = selectedQuestion && selectedQuestion.answers;
  const showAnswer = item => {
    let newAnswer = { ...item, hidden: false };
    let modifiedAnswers = answers.map(el =>
      el.cfi === newAnswer.cfi ? newAnswer : el
    );
    let updatedQuestions = questions.map(el => {
      return el.id === selectedQuestion.id
        ? { ...selectedQuestion, answers: modifiedAnswers }
        : el;
    });
    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );
  };

  const hideAnswer = item => {
    let newAnswer = { ...item, hidden: true };
    let modifiedAnswers = answers.map(el =>
      el.cfi === newAnswer.cfi ? newAnswer : el
    );
    let updatedQuestions = questions.map(el => {
      return el.id === selectedQuestion.id
        ? { ...selectedQuestion, answers: modifiedAnswers }
        : el;
    });
    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );
  };

  const hideHighlight = item => {
    updateHighlight(item.id, { hidden: true });
  };

  const showHighlight = item => {
    updateHighlight(item.id, { hidden: false });
  };

  const visibleAnswers =
    answers && answers.filter && answers.filter(el => !el.hidden);
  const hiddenAnswers =
    answers && answers.filter && answers.filter(el => el.hidden);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = visibleAnswers[dragIndex];

      let updatedAnswers = update(visibleAnswers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });
      let updatedQuestions = questions.map(el => {
        return el.id === selectedQuestion.id
          ? {
              ...selectedQuestion,
              answers: [...updatedAnswers, ...hiddenAnswers]
            }
          : el;
      });
      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: updatedQuestions,
          highlights: highlights
        })
      );
    },
    [
      dispatch,
      hiddenAnswers,
      highlights,
      questions,
      selectedQuestion,
      selectedTextId,
      visibleAnswers
    ]
  );

  const [showCitation, setShowCitation] = useState(false);

  return (
    <>
      <Card
        disableDrag={disableDrag}
        cards={context === "Answer" ? answers : highlights}
        dragItemType={DRAG_ITEM_TYPE}
        onTitleChange={
          context === "Answer" ? onAnswerTitleChange : onHighlightTitleChange
        }
        moveCard={moveCard}
        hideCard={context === "Answer" ? hideAnswer : hideHighlight}
        showInText={answer => {
          setShowCitation(answer);
        }}
        showCard={context === "Answer" ? showAnswer : showHighlight}
      />

      {showCitation && (
        <CitationDialog
          color="green"
          setOpenDialog={setShowCitation}
          url={readText.url}
          location={readText.file_location}
          highlights={[showCitation]}
          openDialog={!!showCitation}
          context={context}
          removeHighlight={() => {
            dispatch(
              updateSq3r({
                textId: selectedTextId,
                questions:
                  context === "Answer"
                    ? questions.map(el => {
                        return el.id === selectedQuestion.id
                          ? {
                              ...el,
                              answers: answers.filter(
                                a => a.cfi !== showCitation.cfi
                              )
                            }
                          : el;
                      })
                    : questions,
                highlights:
                  context === "Answer"
                    ? highlights
                    : highlights.filter(el => el.cfi !== showCitation.cfi)
              })
            );

            setShowCitation(false);
          }}
        />
      )}
    </>
  );
}
