// Dependencies
import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { auth, googleAuthProvider, firebaseApp } from "../../firebase";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

// Redux dependencies
import { useSelector } from "react-redux";

// Components
import SendPasswordReset from "./SendPasswordReset";
import PasswordReset from "./PasswordReset";
import Signup from "./Signup";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  Button,
  Link,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

//Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      backgroundImage: 'url("/resources/landingImage20211023.jpg")',
      backgroundSize: "auto 100vh",
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat",
    },
    backBtn: {
      position: "absolute",
      left: "-48px",
    },
    newAccount: {
      color: theme.palette.secondary.main,
      marginInlineStart: "8px",
    },
    resetLink: {
      right: "0px",
      color: theme.palette.secondary.main,
      marginBlockEnd: "24px",
      alignSelf: "flex-end",
    },

    main: {
      flexBasis: "50%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      // whiteSpace: "nowrap",
      marginBottom: "60px",
    },
    loginBox: {
      marginTop: "160px",
      width: "50%",
      //height: "50%",
      //display: "flex",
      //flexFlow: "column nowrap",
      //flexBasis: "50%",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      position: "relative",
    },
    googleLoginBtn: {
      padding: "18px",
      marginBottom: "36px",
      width: "100%",
      "& .MuiButton-startIcon": {
        // marginInlineEnd: "auto",
      },
    },
    googleLoginBtnText: {
      // marginInlineEnd: "auto",
      fontSize: "16px",
    },
    alternativeSignInText: {
      textTransform: "uppercase",
      fontSize: "12px",
      letterSpacing: "1px",
      marginBottom: "36px",
    },
    emailLoginBox: {
      width: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
    },
    emailLoginInput: {
      marginBlockEnd: "8px",
    },
    emailLoginBtn: {
      paddingBlock: "16px",
      color: "white",
    },
  };
});

export default function SignIn() {
  // Hooks
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();

  // Redux state
  const rtl = useSelector((state) => state.user.userProfile.rtl);

  // Ephemeral state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValidationMessage, setEmailValidationMessage] = useState(null);
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState(null);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSendPasswordReset, setShowSendPasswordReset] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showSignupScreen, setShowSignupScreen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Behavior
  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let action = sp.get("action");
    let code = sp.get("code");
    if (action === "passwordReset" && code) {
      setShowPasswordReset(true);
    }
    if (auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var savedEmail = window.localStorage.getItem("emailForSignIn");
      if (!savedEmail) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        savedEmail = window.prompt(
          "Please provide your email for confirmation"
        );
      }
      // The client SDK will parse the code from the link for you.
      auth
        .signInWithEmailLink(savedEmail, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.log("error", error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, [setShowPasswordReset, location]);

  function loginWithGoogle() {
    auth
      .signInWithPopup(googleAuthProvider)
      .then((credentisl) => {
        console.log("logged in ");
      })
      .catch((error) => {
        console.log("Error creating  token:", error);
        if (
          error.code === "auth/internal-error" &&
          error.message?.includes(
            "needs to be verified before access is granted"
          )
        ) {
          setLoading(false);
          setEmailValidationMessage(
            "Wait for Pangea to contact you to receive access"
          );
        } else setEmailValidationMessage("Can't login, contact Pangea");

        // history.push("/permissionDenied");
      });
  }

  function loginWithPassword() {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((value) => {
        console.log("logged in? ", value);
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setEmailValidationMessage("User for email not found");
        } else if (error.code === "auth/wrong-password") {
          setPasswordValidationMessage("Wrong email/password combination");
        } else if (
          error.code === "auth/internal-error" &&
          error.message?.includes(
            "needs to be verified before access is granted"
          )
        ) {
          setLoading(false);
          setEmailValidationMessage(
            "User not authorized, Pangea will contact you"
          );
        } else setEmailValidationMessage("Can't login, contact Pangea");

        console.log("Error creating  token:", error);
        //history.push("/permissionDenied");
      });
  }

  function loginWithEmail(e) {
    if (validateForm()) {
      console.log("logging in");
      loginWithPassword();
    } else return false;
  }

  function validateForm() {
    if (email === "") {
      setEmailValidationMessage(
        intl.formatMessage({
          id: "signin.validation.emailEmpty",
          defaultMessage: "Please enter an email address",
        })
      );
      return false;
    } else if (!validateEmail(email)) {
      setEmailValidationMessage(
        intl.formatMessage({
          id: "signin.validation.emailInvalid",
          defaultMessage: "Please enter a valid email address",
        })
      );
      return false;
    } else {
      setEmailValidationMessage(null);
      return true;
    }
  }

  function validateEmail(userInput) {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userInput
      )
    ) {
      return true;
    } else return false;
  }

  const renderLoader = () => {
    if (loading) {
      return (
        <Box>
          <ClipLoader color="#5ec891" loading="true" size={150} />
        </Box>
      );
    }
  };

  const renderLoginScreen = () => {
    return (
      <>
        <Typography component="h1" variant="h3" className={classes.header}>
          {intl.formatMessage({
            id: "signin.header",
            defaultMessage: "Sign in to Pangea",
          })}
        </Typography>
        <Button
          onClick={loginWithGoogle}
          className={classes.googleLoginBtn}
          variant="outlined"
          startIcon={
            <img
              alt="Google logo"
              src="/resources/btn_google_light_normal_ios.svg" // FIXME: google logo yellow part is missaligned
              width="24"
              height="24"
            />
          }
        >
          <Typography
            component="span"
            className={classes.googleLoginBtnText}
            variant="h5"
          >
            {intl.formatMessage({
              id: "signin.googleSignin",
              defaultMessage: "Sign in with Google",
            })}
          </Typography>
        </Button>
        <Box className={classes.userPassowrdContainer}>
          <Typography variant="body2" className={classes.alternativeSignInText}>
            {intl.formatMessage({
              id: "signin.emailSignin",
              defaultMessage: "Or use email and password",
            })}
          </Typography>

          {!loading && ( // Hide the login form when showing spinner
            <form
              className={classes.emailLoginBox}
              noValidate
              autoComplete="off"
            >
              <TextField
                required
                fullWidth
                type="email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                label={intl.formatMessage({
                  id: "signin.emailAddress",
                  defaultMessage: "Email:",
                })}
                variant="outlined"
                // variant="standard"
                id="email"
                className={classes.emailLoginInput}
                error={emailValidationMessage && true}
                helperText={emailValidationMessage || " "}
              />

              <TextField
                required
                fullWidth
                type={showPassword ? "text" : "password"}
                defaultValue={password}
                className={classes.emailLoginInput}
                onChange={(e) => setPassword(e.target.value)}
                label={intl.formatMessage({
                  id: "signin.password",
                  defaultMessage: "Password:",
                })}
                variant="outlined"
                // variant="standard"
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={passwordValidationMessage && true}
                helperText={passwordValidationMessage || null}
              />
              <Link
                component="button"
                onClick={() => {
                  setShowSendPasswordReset(true);
                }}
                className={classes.resetLink}
              >
                Reset password
              </Link>
              <Button
                disableElevation
                className={classes.emailLoginBtn}
                color="secondary"
                variant="contained"
                onClick={loginWithEmail}
              >
                {intl.formatMessage({
                  id: "signin.getLink",
                  defaultMessage: "Sign In",
                })}
              </Button>
              <Box>
                New here?
                <Link
                  component="button"
                  onClick={() => {
                    setShowSignupScreen(true);
                  }}
                  className={classes.newAccount}
                >
                  Create an account
                </Link>
              </Box>
            </form>
          )}
        </Box>
        {renderLoader()}
      </>
    );
  };

  const renderBody = () => {
    if (showSendPasswordReset) {
      return pending ? (
        <Typography variant="h4">
          Check your email for the password reset link to Pangea
        </Typography>
      ) : (
        <SendPasswordReset
          pending={pending}
          setPending={setPending}
          back={() => {
            setShowSendPasswordReset(false);
          }}
        />
      );
    }

    if (showPasswordReset) {
      return pending ? (
        <>
          <Typography variant="h4">Password Successfully Reset</Typography>
          <Button
            onClick={() => {
              setShowPasswordReset(false);
              setPending(false);
            }}
            className={classes.resetLink}
            variant="text"
          >
            Back to login
          </Button>
        </>
      ) : (
        <PasswordReset
          pending={pending}
          setPending={setPending}
          back={() => {
            setShowPasswordReset(false);
            setPending(false);
            setLoading(false);
            history.push("/");
          }}
        />
      );
    }

    if (showSignupScreen) {
      return pending ? (
        <Typography variant="h4">
          A Pangea representative will contact you shortly.
        </Typography>
      ) : (
        <Signup
          pending={pending}
          setPending={setPending}
          back={() => setShowSignupScreen(false)}
        />
      );
    }
    // the pending is true only when an login email was sent
    // when not pending, showing the login page

    return renderLoginScreen();
  };

  // Render
  return (
    <Box className={classes.container} dir={rtl ? "rtl" : "ltr"}>
      <Box className={classes.main}>
        <Box className={classes.loginBox}>{renderBody()}</Box>
      </Box>
    </Box>
  );
}
