import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { firebaseFunctions } from "../../firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { setAction } from "../../redux/readerActionsSlice";
import { setSubmittedText } from "../../redux/grSlice";
import { FormattedMessage } from "react-intl";
import { getNextId, updateSq3r } from "../../redux/firebaseMiddleware";

const useStyles = makeStyles((theme) => ({
  scroll: {
    overscrollBehavior: "none",
    "overflow-y": "auto"
  },
  btns: {
    width: "100%",
    marginTop: "40px",
    position: "relative"
  },
  yesBtn: {
    position: "absolute",

    fontWeight: "700"
  },
  yesBtnLtr: {
    left: "36px"
  },
  dialogTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
    direction: "rtl"
  },
  cancelBtn: {
    position: "absolute",
    color: "rgba(0, 0, 0, 0.54)",

    fontWeight: "700"
  },
  cancelBtnLtr: {
    right: "36px"
  },
  panelHeader: {
    color: "white",
    width: "100%",
    textTransform: "uppercase",
    overscrollBehavior: "none",

    position: "relative"
  },
  white: {
    "&.Mui-disabled": {
      color: "white"
    },
    color: "white"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  dialog: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.54)"
  },
  rtl: {
    direction: "rtl"
  },
  ltr: {
    direction: "ltr"
  },
  panelDetails: {}
}));

export default function SubmitDialog({ open, onClose }) {
  //export default function TabPanel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedText = useSelector((state) => state.texts.selectedText);
  const history = useHistory();
  const questions = useSelector((state) => state.gr.questions);
  const highlights = useSelector((state) => state.gr.highlights);

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "white",
          paddingRight: "100px",
          paddingLeft: "100px",
          paddingBottom: "24px"
        }
      }}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title' className={classes.dialogTitle}>
        <FormattedMessage
          id='gr.confirm.msg'
          defaultMessage='Confirm Submission'
        />
      </DialogTitle>
      <DialogContent className={clsx(classes.dialog, classes.ltr)}>
        <Typography>
          <FormattedMessage
            id='gr.should.submit'
            defaultMessage='Is the assignment ready for submission?'
          />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.btns}>
        <Button
          className={clsx(classes.yesBtn, classes.yesBtnLtr)}
          onClick={() => {
            var submitTextTask = firebaseFunctions.httpsCallable(
              "courses-submitTextTask"
            );
            let id = getNextId(highlights);
            let highlightsWithId = highlights.map((hl) => {
              if (hl.id) {
                return hl;
              } else {
                id = id + 1;
                return { ...hl, id: id };
              }
            });
            let textTask = {
              text: selectedText.id,
              submission: {
                textId: selectedText.id,
                questions: questions,
                highlights: highlightsWithId,
                status: "submitted"
              }
            };
            submitTextTask(textTask).then((row) => {
              dispatch(setAction(""));
              dispatch(setSubmittedText(selectedText.id));
              history.push("/tasks");

              dispatch(
                updateSq3r({
                  textId: selectedText.id,
                  questions: questions,
                  highlights: highlights,
                  status: "submitted"
                })
              );
            });
            onClose();
          }}
          color='secondary'
        >
          <FormattedMessage id='gr.confirm.btn' defaultMessage='OK' />
        </Button>
        <Button
          className={clsx(classes.cancelBtn, classes.cancelBtnLtr)}
          onClick={onClose}
        >
          <FormattedMessage id='cancel' defaultMessage='Cancel' />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
