// Dependencies
import React, { useState, useEffect } from "react";
import ePub from "epubjs";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import {
  PieSeries,
  Chart,
  Title,
  Legend,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation, Palette, EventTracker } from "@devexpress/dx-react-chart";

// Styles
const styles = (theme) => ({
  textTitle: {
    flexBasis: "100%",
    marginBottom: "32px",
  },
  legend: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    width: "100%",
    padding: "24px",
    border: "1px solid rgba(0, 0, 0, 0.12)",

    "&:not(:last-child)": {
      borderBottom: "0",
    },
  },
  chart: {
    padding: "0",
    minHeight: "300px",
    height: "100%",
  },
  label: {
    fontSize: "16px !important",
    display: "inline-block",
    textAlign: "left",
    verticalAlign: "middle",
    "& span": {
      fontSize: "16px !important",
    },
  },
  legendContainer: {},
});

const Marker = withStyles(styles)(({ color, ...restProps }) => {
  return (
    <svg
      style={{ verticalAlign: "middle" }}
      fill={color}
      width="24"
      height="24"
      {...restProps}
    >
      <circle r={12} cx={12} cy={12} {...restProps} />
    </svg>
  );
});

const Label = withStyles(styles)(({ classes, ...restProps }) => (
  <Legend.Label {...restProps} className={classes.label} />
));

const TitleText = withStyles(styles)(({ classes, ...restProps }) => (
  <Typography variant="h6" className={classes.textTitle}>
    {restProps.text}
  </Typography>
));

const ChartRoot = withStyles(styles)(({ classes, ...restProps }) => (
  <Chart.Root className={classes.chart} {...restProps} />
));

const RootWithTitle = withStyles(styles)(({ classes, ...restProps }) => (
  <Box className={classes.legendContainer}>
    <Box className={classes.legend} {...restProps} />
  </Box>
));

const Item = withStyles(styles)(({ classes, ...restProps }) => (
  <Box {...restProps} className={classes.item} />
));

export default function CitationChart({
  task,
  submissions,
  text,
  i,
  legendPosition,
  ...props
}) {
  // Hooks

  // Ephemeral State
  const [stats, setStats] = useState([]);
  // Helpers

  const subCompare = (needle, haystack, min_substring_length = 1) => {
    // Search possible substrings from largest to smallest:
    for (var i = needle.length; i >= min_substring_length; i--) {
      for (var j = 0; j <= needle.length - i; j++) {
        var substring = needle.substr(j, i);
        var k = haystack.indexOf(substring);
        if (k != -1) {
          return {
            found: 1,
            substring: substring,
            needleIndex: j,
            haystackIndex: k,
          };
        }
      }
    }
    return {
      found: 0,
    };
  };
  var EpubCFI = new ePub.CFI();
  const calcScore = (question, answer, teacherAnswer) => {
    let tempScore = 0;

    if (!answer.quotes || !answer.quotes.length) return 0;
    if (!teacherAnswer.quotes || !teacherAnswer.quotes.length) return 1;
    let teacherSortedQuotes = [...teacherAnswer.quotes].sort(function (a, b) {
      return EpubCFI.compare(a.cfi, b.cfi);
    });

    let studentSorted = [...answer.quotes].sort(function (a, b) {
      return EpubCFI.compare(a.cfi, b.cfi);
    });

    let studentInd = 0;
    let teacherInd = 0;

    while (
      studentInd < studentSorted.length &&
      teacherInd < teacherSortedQuotes.length
    ) {
      let currentStudentRange = EpubCFI.parse(studentSorted[studentInd].cfi); // EpubCFI.getRange(studentSorted[studentInd].cfi);
      let currentTeacherRange = EpubCFI.parse(
        teacherSortedQuotes[teacherInd].cfi
      );

      const cfiRange =
        "epubcfi(/6/4[chap01ref]!/4[body01]/10[para05],/2/1:1,/3:4)";
      let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
      let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
      let studentEndCfi = cfiParts[0] + cfiParts[2];

      cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
      let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
      let teacherEndCfi = cfiParts[0] + cfiParts[2];
      if (EpubCFI.compare(studentEndCfi, teacherStartCfi) <= 0) {
        studentInd++;
      } else if (EpubCFI.compare(teacherEndCfi, studentStartCfi) <= 0) {
        teacherInd++;
      } else {
        let needle = studentSorted[studentInd].text;
        let haystack = teacherSortedQuotes[teacherInd].text;
        if (haystack.length < needle.length) {
          haystack = needle;
          needle = teacherSortedQuotes[teacherInd].text;
        }
        let retVal = subCompare(needle, haystack);
        if (retVal.found) {
          //console.log("found common substring", retVal.found);
          tempScore += retVal.substring.length;
        }

        if (EpubCFI.compare(studentEndCfi, teacherEndCfi) >= 0) {
          studentInd++;
        } else {
          teacherInd++;
        }
      }
    }
    let totalTeacher = teacherSortedQuotes.reduce(
      (acc, curr) => acc + curr.text.length,
      0
    );

    return (1.0 * tempScore) / totalTeacher;
  };

  useEffect(() => {
    if (submissions) {
      let submittedTasks = submissions.filter((e) => e.submission_date);
      if (submittedTasks.length > 0) {
        let matches = submittedTasks.map(
          (row) =>
            calcScore(
              row.questions.questions[i],
              row.student_answers.answers[i],
              row.answers.answers[i]
            ) * 100
        );

        setStats([
          {
            cat: "Right on the spot",
            val: matches.filter((a) => a > 70).length,
            color: "#168fee",
          },
          {
            cat: "Pretty close",
            val: matches.filter((a) => a > 40 && a < 70).length,
            color: "#BCD9F1",
          },
          {
            cat: "Way off",
            val: matches.filter((a) => a < 40).length,
            color: "#FF6432",
          },
        ]);
      } else {
        setStats([
          {
            cat: "No submissions yet",
            val: 1,
            color: "#D8D8D8",
          },
        ]);
      }
    }
  }, [submissions]);
  return (
    <Chart
      rootComponent={ChartRoot}
      height="100%"
      // data={timeManagement}
      data={stats.filter((s) => s.val > 0)}
    >
     
      <Palette scheme={stats.filter((s) => s.val > 0).map((s) => s.color)} />
      <PieSeries
        valueField="val"
        argumentField="cat"
        outerRadius={1}
        innerRadius={0.75}
      />
      <Title text="Student performance" textComponent={TitleText} />
      <Animation />
      <Legend
        position="left"
        markerComponent={Marker}
        rootComponent={RootWithTitle}
        itemComponent={Item}
        labelComponent={Label}
      />
      <EventTracker />
      <Tooltip />
    </Chart>
  );
}
