// Dependencies
import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import clsx from "clsx";

// Redux dependencies
import { validateFeedback, taskSelectors } from "../../../redux/taskSlice";
import { useSelector, useDispatch } from "react-redux";

// Components
import FeedbackQuestionPanel from "./FeedbackQuestionPanel";
import FeedbackValidationModal from "./FeedbackValidationModal";
import QuestionsCarousel from "../QuestionsCarousel";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Box, Typography, Divider } from "@material-ui/core";

// Styles
const useStyles = makeStyles(() => ({
  actionBgLtr: {
    left: 0
  },
  actionBg: {
    width: "25%",
    height: "100vh",
    position: "fixed",
    background: "#333333",
    zIndex: 120,
    top: "0",
    bottom: 0,
    whiteSpace: "nowrap"
  },
  actionDetailsSidebar: {
    position: "relative",
    background: "#333333"
  },

  actionDetailsSidebarContainer: {
    position: "fixed",
    height: "calc(100vh - 120px)",
    width: "25%",
    display: "flex",
    flexDirection: "column ",
    backgroundColor: "#333333",
    overflowY: "auto",
    zIndex: "140",
    transition: "top 0.6s"
  },
  buttonContainer: {
    textAlign: "right"
  },
  taskBtn: {
    marginTop: "16px",
    marginBottom: "16px",
    fontSize: "13px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#ffffff",
    backgroundColor: "#168fee",
    "&:hover": {
      backgroundColor: "#1172be"
    }
  },
  panelHeader: {
    color: "white",
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    textAlign: "left",
    position: "relative"
  },
  tabs: {
    borderBottom: "1px solid #616161"
  },
  divider: {
    marginTop: "8px",
    backgroundColor: "#616161",
    marginBottom: "8px"
  },
  panelSubtitle: {
    color: "white"
  },
  dialog: {
    backgroundColot: "red"
  },
  indicator: {
    background: "#e0e0e0"
  },
  root: {
    backgroundColor: "#333333"
  },
  panelTaskTitle: {
    fontSize: "20px",
    textTransform: "none"
  },
  panelTaskDesc: {
    fontSize: "16px",
    whiteSpace: "pre-wrap"
  },
  tabHeader: {
    minWidth: "20%",
    color: "#e0e0e0",
    borderBottom: "1px solid #616161",
    "&.MuiTab-textColorSecondary.Mui-selected": {
      color: "#BCD9F1"
    }
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "24px",
    marginTop: "8px",
    textTransform: "uppercase"
  }
}));

function TaskFeedbackSidebar({
  editable,
  task,
  selectedQuestionIndex,
  onQuestionIndex,
  feedback,
  isTeacher,
  onSubmit,
  showMatch
}) {
  // Hooks
  const classes = useStyles();

  // Redux state
  const dispatch = useDispatch();
  const showValidateFeedback = useSelector(
    state => state.task.validateFeedback
  );
  const questions = useSelector(taskSelectors.questionsForFeedback);

  // Ephemeral state
  // const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [validationResult, setValidationResult] = useState({
    noPoints: [],
    invalidPoints: [],
    comment: []
  });

  // Behavior
  const renderQuestion = (question, index) => {
    return (
      <FeedbackQuestionPanel
        showMatch={showMatch}
        isTeacher={isTeacher}
        key={index}
        selectedQuestionIndex={selectedQuestionIndex}
        score={
          feedback &&
          feedback.questions &&
          feedback.questions[index] &&
          feedback.questions[index].match * 100
        }
        concepts={
          feedback &&
          feedback.questions &&
          feedback.questions[index] &&
          feedback.questions[index].concepts
        }
        value={selectedQuestionIndex}
        answer={task.student_answers.answers[index]}
        title={feedback?.questions?.[index]?.title}
        comment={feedback?.questions?.[index]?.comment}
        points={
          feedback?.questions?.[index]?.points >= 0
            ? feedback?.questions?.[index]?.points
            : task.questions.questions[index].points
        }
        isLast={selectedQuestionIndex >= task.questions.questions.length - 1}
        onSubmit={() => {
          // setShowSubmitDialog(true);
        }}
        onNext={() => {
          onQuestionIndex(selectedQuestionIndex + 1);
        }}
        teacherAnswer={
          task.answers && task.answers.answers && task.answers.answers[index]
        }
        question={question}
        questionsTotal={task.questions.questions.length}
        index={index}
      />
    );
  };
  const validateForm = useCallback(() => {
    // Return and object with arrays of invalid answers and citations
    const noPoints = [];
    const invalidPoints = [];
    const comment = [];

    if (feedback) {
      feedback.questions.forEach((question, index) => {
        if (
          question.points === "" ||
          question.points < 0 ||
          Number.isNaN(question.points)
        ) {
          noPoints.push(index);
        } else if (Number(question.question.points) < Number(question.points)) {
          invalidPoints.push(index);
        }

        // the rich text editor returns an array of text lines
        // checkling to see if at least one line isn't empty
        let commentIsValid =
          question.comment &&
          question.comment.blocks &&
          question.comment.blocks.some(element => element.text !== "");

        if (!commentIsValid && (!question.title || question.title === "")) {
          comment.push(index);
        }
      });
      setValidationResult({ noPoints, invalidPoints, comment });

      // if something failed validation - open the dialog
      if (
        noPoints.length > 0 ||
        invalidPoints.length > 0 ||
        comment.length > 0
      ) {
        setShowValidationDialog(true);
      } else {
        onSubmit();
      }
    }
  }, [feedback, onSubmit]);

  useEffect(() => {
    if (showValidateFeedback) {
      validateForm();
      dispatch(validateFeedback(false));
    }
  }, [showValidateFeedback, dispatch, validateForm]);

  useEffect(() => {
    onQuestionIndex(selectedQuestionIndex);
  }, [selectedQuestionIndex]);

  const renderActionBg = () => {
    return <Box className={clsx(classes.actionBg, classes.actionBgLtr)} />;
  };

  const renderActionBar = () => {
    return (
      <Box
        elevation={2}
        className={clsx(
          classes.actionDetailsSidebarContainer,
          classes.actionDetailsSidebarContainerLtr
        )}
      >
        <Paper
          square={true}
          elevation={0}
          className={classes.actionDetailsSidebar}
        >
          <Paper className={classes.root} elevation={0}>
            <Box
              className={clsx(
                classes.panelHeader,
                classes.panelHeaderLtr,
                classes.left
              )}
            >
              <Typography
                className={clsx(classes.panelTitle, classes.left)}
                color="textPrimary"
              >
                <FormattedMessage id="appBar.tasks" defaultMessage="tasks" />
              </Typography>
              <Typography
                className={clsx(
                  classes.panelTaskTitle,
                  classes.panelSubtitle,
                  classes.left
                )}
                color="textPrimary"
              >
                {task && task.name}
              </Typography>

              <Typography
                className={clsx(
                  classes.panelTaskDesc,
                  classes.panelSubtitle,
                  classes.left
                )}
                color="textPrimary"
              >
                {task && task.task_description}
              </Typography>
              {task &&
                selectedQuestionIndex < task.questions.questions.length - 1 && (
                  <Box component="div" className={classes.buttonContainer}>
                    <Button
                      className={classes.taskBtn}
                      onClick={() => {
                        onQuestionIndex(selectedQuestionIndex + 1);
                      }}
                    >
                      <FormattedMessage
                        id="gr.next.question"
                        defaultMessage="Next question"
                      />
                    </Button>
                  </Box>
                )}
              {editable &&
                selectedQuestionIndex ===
                  task.questions.questions.length - 1 && (
                  <Box component="div" className={classes.buttonContainer}>
                    <Button
                      onClick={() => {
                        validateForm();
                        // setShowValidationDialog(true);
                        // handleSubmit();
                        // onSubmit();
                      }}
                      className={classes.taskBtn}
                    >
                      <FormattedMessage
                        id="task.feedback.submit"
                        defaultMessage="Send feedback"
                      />
                    </Button>
                  </Box>
                )}
            </Box>
            <Divider className={classes.divider} />
            {task?.questions && (
              <QuestionsCarousel
                questionsOrder={questions}
                updateTaskFeedbackState={selectedQuestionIndex => {
                  // this code is nessary to support the taskfeedback variation of the data becaouse it was not implemented as part of firesotre middlewere.
                  onQuestionIndex(selectedQuestionIndex);
                }}
              />
            )}

            {task &&
              task.questions.questions.map((question, index) =>
                renderQuestion(question, index)
              )}
          </Paper>
        </Paper>
      </Box>
    );
  };

  // Render
  return (
    <>
      <FeedbackValidationModal
        task={task}
        feedback={feedback}
        showValidationDialog={showValidationDialog}
        setShowValidationDialog={setShowValidationDialog}
        onSubmit={onSubmit}
        validationResult={validationResult}
        // setShowSubmitDialog={setShowSubmitDialog}
        // taskAnswers={taskAnswers}
      />
      {renderActionBg()}
      {renderActionBar()}
    </>
  );
}

TaskFeedbackSidebar.propTypes = {
  task: PropTypes.shape({
    questions: PropTypes.object
  })
};

export default TaskFeedbackSidebar;
