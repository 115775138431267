import React, { useRef, useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { firebaseApp } from "../../../firebase";
import TaskFeedbackComment from "../TaskFeedbackComment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LinkIcon from "@material-ui/icons/Link";
import ePub from "epubjs";
import EpubView from "../../reader/EpubView/EpubView";
import EditIcon from "@material-ui/icons/Edit";
import { getHighlightColor } from "../../../utils/colors";
import {
  Divider,
  Menu,
  MenuItem,
  Box,
  ListItemText,
  IconButton,
  InputAdornment,
  Button,
  Card,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { setClickedMatch } from "../../../redux/taskSlice";
import {
  setLinkType,
  resetLink,
  setLinkContent,
  setLinkTarget,
} from "../../../redux/LinkSlice";
import ReactReaderActions from "../../annotations/ReactReaderActions";
import {
  openAnnotatorBar,
  closeAnnotatorBar,
} from "../../../redux/highlightSlice";
const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    padding: "8px",
    //  maxHeight:'184px',
    textTransform: "none",
    paddingBottom: "24px",
    background: "#e0e0e0",
    "& button": {
      visibility: "hidden",
      color: "black",
      fontSize: "16px",
      position: "absolute",
      bottom: "2px",
    },
    "&:hover": {
      background: "#f5f5f5",
      //  maxHeight:'initial',
      " & button": {
        visibility: "visible",
      },
    },
  },
  questionNum: {
    marginTop: "16px",
    marginBottom: "16px",
    fontSize: "20px",

    paddingRight: "16px",
    paddingLeft: "16px",
  },
  pointsContainer: {
    padding: "16px",
    position: "relative",
    minHeight: "64px",
  },
  feedbackBtns: {
    position: "absolute",
    right: "8px",
  },
  dialogActions: {
    justifyContent: "left",
  },
  dialog: {
    zIndex: 10,
  },
  dialogActionsRtl: {
    justifyContent: "right",
  },
  dialogBtn: {
    position: "absolute",
    color: "#ffffff",
    right: "16px",
  },

  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff",
  },

  points: {
    display: "inline-block",
    border: "1px solid #168fee",
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "64px",
    "& input": {
      textAlign: "center",
    },
  },
  hidden: {
    visibility: "hidden",
    position: "absolute",
    fontSize: "20px",
    maxWidth: "95%",
  },
  epubViewContainer: {
    position: "relative",
  },
  commentText: {
    fontSize: "20px",
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    width: "100%",
    resize: "none",
    border: "none",
  },
  left: {
    textAlign: "left",
  },

  right: {
    textAlign: "right",
  },
  pointsMsg: {
    color: "#168fee",
    display: "inline-block",
    lineHeight: "34px",
  },
  eval: {
    color: "#168fee",
    fontSize: "20px",
    lineHeight: "32px",
    "& ::placeholder": {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#168fee",
      opacity: 1,
    },
    "& button": {
      visibility: "hidden",
    },
    "&:hover button": {
      visibility: "visible",
    },
  },

  adornment: {
    color: "#168fee",
  },
  colorsReader: {
    position: "absolute",
    right: "0px",
    width: "100%",
    zIndex: "2",
    display: "flex",
  },
  matchColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px",
  },
  incorrect: {
    textTransform: "none",
    position: "absolute",
    right: "0px",
    backgroundColor: "#f9413e",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  correct: {
    textTransform: "none",
    position: "absolute",
    right: "0px",
    backgroundColor: "#0eac72",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  textIcon: {
    padding: "4px",
  },
  studentColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px",
  },
  yourColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px",
  },
  feedbackCard: {
    fontSize: "16px",

    // background: "white",
  },
  feedbackBody: {
    padding: "16px",
    position: "relative",
  },
  feedbackHeader: {
    color: "#168fee",
    fontSize: "20px",
    lineHeight: "32px",
    padding: "16px",
  },
  ponits: {
    marginStart: "8px",
  },
}));
export default function FeedbackQuestionContainer(props) {
  const {
    index,
    task,
    feedback,
    updateFeedback,
    readText,
    readOnly,
    showMatch,
  } = props;
  const titleRef = useRef();
  const titleSpanRef = useRef();
  const linkBtnRef = useRef();

  const fontSizeValue = useSelector((state) => state.user.userProfile.fontSize);

  const fontSize = useSelector((state) => state.user.fontSizeOptions);
  const selectedFeedbackText = useSelector((state) => state.link.selected);
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();
  const [editingTitle, setEditingTitle] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const intl = useIntl();
  const clickedMatch = useSelector((state) => state.task.clickedMatch);
  var EpubCFI = new ePub.CFI();
  const darkMode = useSelector((state) => state.user.userProfile.darkMode);

  //Concepts score stuff

  const concepts =
    task.answers &&
    task.answers.answers &&
    task.answers.answers[index] &&
    task.answers.answers[index].concepts;

  const epubElemRef = useRef();

  const makeRangeCfi = (a, b) => {
    const start = EpubCFI.parse(a),
      end = EpubCFI.parse(b);
    const cfi = {
      range: true,
      base: start.base,
      path: {
        steps: [],
        terminal: null,
      },
      start: start.path,
      end: end.path,
    };
    const len = cfi.start.steps.length;
    for (let i = 0; i < len; i++) {
      if (EpubCFI.equalStep(cfi.start.steps[i], cfi.end.steps[i])) {
        if (i === len - 1) {
          // Last step is equal, check terminals
          if (cfi.start.terminal === cfi.end.terminal) {
            // CFI's are equal
            cfi.path.steps.push(cfi.start.steps[i]);
            // Not a range
            cfi.range = false;
          }
        } else cfi.path.steps.push(cfi.start.steps[i]);
      } else break;
    }
    cfi.start.steps = cfi.start.steps.slice(cfi.path.steps.length);
    cfi.end.steps = cfi.end.steps.slice(cfi.path.steps.length);

    return (
      "epubcfi(" +
      EpubCFI.segmentString(cfi.base) +
      "!" +
      EpubCFI.segmentString(cfi.path) +
      "," +
      EpubCFI.segmentString(cfi.start) +
      "," +
      EpubCFI.segmentString(cfi.end) +
      ")"
    );
  };


  const splitHighlights = (highlights )=> {
    //this function split highlights to unique ranges
    let coll = [];
    highlights &&
      highlights
        .filter((h) => h && h.cfi)
        .forEach((hl) => {
          let cfiParts = hl.cfi.split(","); // cfiBase:  cfiParts[0]
          let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
          let endCfi = cfiParts[0] + cfiParts[2];
          coll.push({ cfi: startCfi, val: 1 });
          coll.push({ cfi: endCfi, val: -1 });
        });

    let sortedHl = [...coll].sort(function (a, b) {
      let val = EpubCFI.compare(a.cfi, b.cfi);
      if (val === 0) {
        if (a.val === b.val) return 0;
        return b.val < 0 ? 1 : -1;
      }
      return val;
    });
    
    let current = 0;
    let start = null;
    let hlSplitted =[];
    for (var i =0; i< sortedHl.length ; i++) {
        if (start == null) {
            if (sortedHl[i].val > 0) {
                start = sortedHl[i].cfi;
                current = 1;
            }
        } 
        else {
            if (sortedHl[i].val > 0) {
            
            current++;

          } else {
            current--;
            if (current === 0) {
              hlSplitted.push({cfi:makeRangeCfi(start, sortedHl[i].cfi)});
           
                start = null;
            }
        }
      }
        
    }
    return hlSplitted;
  }

  const getHighlights = (teacheHl, studentHl) => {
    //teacher  04E3FD
    //student FFFF00
    //match 91fcc2

    let highlights = [];
    let splitHl = [];
    if ( teacheHl && teacheHl.length) 
      splitHl = splitHighlights(teacheHl);
    let teacherSortedQuotes =
      teacheHl && teacheHl.length
        ? splitHl.sort(function (a, b) {
            return EpubCFI.compare(a.cfi, b.cfi);
          })
        : [];
    if ( studentHl && studentHl.length) 
      splitHl = splitHighlights(studentHl);
    let studentSorted =
      studentHl && studentHl.length
        ? splitHl.sort(function (a, b) {
            return EpubCFI.compare(a.cfi, b.cfi);
          })
        : [];

    let studentInd = 0;
    let teacherInd = 0;
    let lastAddedCfi = null;
    let passedCfi = null;
    while (
      studentInd < studentSorted.length &&
      teacherInd < teacherSortedQuotes.length
    ) {

      let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
      let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
      let studentEndCfi = cfiParts[0] + cfiParts[2];

      cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
      let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
      let teacherEndCfi = cfiParts[0] + cfiParts[2];
      if (EpubCFI.compare(studentEndCfi, teacherStartCfi) <= 0) {
        passedCfi =studentEndCfi;
        //the student ends before teacher starts, add student highlight
        if (lastAddedCfi !== null) {
          highlights.push({
            cfi: makeRangeCfi(lastAddedCfi.cfi, studentEndCfi),
            color: lastAddedCfi.isStudent ? "#FFFF00" : "#91fcc2",
          });
        } else
          highlights.push({ ...studentSorted[studentInd], color: "#FFFF00" });
        lastAddedCfi = null;
        studentInd++;
      } else if (EpubCFI.compare(teacherEndCfi, studentStartCfi) <= 0) {
        passedCfi =teacherEndCfi;
       
        //teacher highlight ends before student starts, add teacher's highlight
        if (lastAddedCfi !== null) {
          highlights.push({
            cfi: makeRangeCfi(lastAddedCfi.cfi, teacherEndCfi),
            color: lastAddedCfi.isStudent ? "#91fcc2" : "#04E3FD",
          });
        } else
          highlights.push({
            ...teacherSortedQuotes[teacherInd],
            color: "#04E3FD",
          });
        lastAddedCfi = null;
        teacherInd++;
      } else if (lastAddedCfi != null) {
        //we are  immediately after an intersection, add the minimal part 
      //last added cfi was the end of the intersection, find the end of current higlighy
      // which can be either student end or teacher end
        if (lastAddedCfi.isStudent) {
          highlights.push({
            cfi: makeRangeCfi(lastAddedCfi.cfi, teacherStartCfi),
            color:  "#FFFF00" 
          });
          passedCfi =teacherStartCfi;
       

        }
        else {
          highlights.push({
            cfi: makeRangeCfi(lastAddedCfi.cfi, studentStartCfi),
            color:  "#04E3FD" 
          });
        }
        passedCfi =studentStartCfi;
       
       
        lastAddedCfi = null;
      } else {
        let intersectionStart = null;
        if (EpubCFI.compare(studentStartCfi, teacherStartCfi) < 0 && 
        (!passedCfi || EpubCFI.compare(passedCfi, studentStartCfi) <0)) {
          //student before teacher, add student part
          highlights.push({
            cfi: makeRangeCfi(studentStartCfi, teacherStartCfi),
            color: "#FFFF00",
          });
          passedCfi = teacherStartCfi;
          intersectionStart = teacherStartCfi;
        } else if (EpubCFI.compare(studentStartCfi, teacherStartCfi) > 0
       &&  (!passedCfi || EpubCFI.compare(passedCfi, teacherStartCfi) <0)
        ) {
          highlights.push({
            cfi: makeRangeCfi(teacherStartCfi, studentStartCfi),
            color: "#04E3FD",
          });
          intersectionStart = studentStartCfi;
        }
        else intersectionStart = studentStartCfi;

        //enter match
        if (EpubCFI.compare(studentEndCfi, teacherEndCfi) < 0) {
          highlights.push({
            cfi: makeRangeCfi(intersectionStart, studentEndCfi),
            color: "#91fcc2",
          });

          //student ended before teacher
          lastAddedCfi = { cfi: studentEndCfi, isStudent: false };
          passedCfi = studentEndCfi;
          studentInd++;
        } else {
          highlights.push({
            cfi: makeRangeCfi(intersectionStart, teacherEndCfi),
            color: "#91fcc2",
          });
          passedCfi = teacherEndCfi;
          lastAddedCfi = { cfi: teacherEndCfi, isStudent: true };
          teacherInd++;
          if (EpubCFI.compare(studentEndCfi, teacherEndCfi) === 0) 
            studentInd++;
        }
        
      }
    }

    //add remaining students highlights
    while (
      studentInd < studentSorted.length) {
        let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
        let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let studentEndCfi = cfiParts[0] + cfiParts[2];
  
        if (lastAddedCfi !== null) {
          highlights.push({
            cfi: makeRangeCfi(lastAddedCfi.cfi, studentEndCfi),
            color: "#FFFF00" ,
          });
        }
        else {
          highlights.push({
            cfi: makeRangeCfi(studentStartCfi, studentEndCfi),
            color: "#FFFF00" ,
          });
        }
        lastAddedCfi = null;

        studentInd++;
      }


    //add remaining teacher highlights
    while (
      teacherInd < teacherSortedQuotes.length) {
      let  cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
      let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
      let teacherEndCfi = cfiParts[0] + cfiParts[2];
    
        if (lastAddedCfi !== null) {
          highlights.push({
            cfi: makeRangeCfi(lastAddedCfi.cfi, teacherEndCfi),
            color:  "#04E3FD",
          });
        }
        else {
          highlights.push({
            cfi: makeRangeCfi(teacherStartCfi, teacherEndCfi),
            color: "#04E3FD" ,
          });
        }
        lastAddedCfi = null;

        teacherInd++;
      }

    return highlights;
  };

  const onRenditionCreated = (rendition) => {
    setRendition(rendition);

    if (
      rendition &&
      (task.questions.questions[index].type === 0 ||
        task.questions.questions[index].includeCitation)
    ) {
      let teacherHighlights =
       showMatch &&  task.answers && task.answers.answers && task.answers.answers[index]
          ? task.answers.answers[index].quotes
          : [];

      let highlights = getHighlights(
        [...teacherHighlights],
        task &&
          task.student_answers &&
          task.student_answers.answers[index].quotes && [
            ...task.student_answers.answers[index].quotes,
          ]
      );

      highlights.forEach((quote, i) => {
        let hlStyleObj = {
          "z-index": 10,
          //  "mix-blend-mode": "multiply",
          "fill-opacity": 1,
          fill: getHighlightColor(quote.color, darkMode),
        };
        try {
          rendition.annotations.highlight(
            quote.cfi,
            {},
            (e) => {},
            "highlightClass",
            hlStyleObj
          );
        } catch (error) {
          console.log("error in  hl", error);
        }
        return 1;
      });
    }
  };
  const dispatch = useDispatch();
  const rtlValue = useSelector((state) => state.user.userProfile.rtl);
  const [linksCfi, setLinksCfi] = useState([]);
  const addHighlightToAnswer = (cfi) => {
    setLinksCfi([...linksCfi, cfi]);
  };
  const removeHighlightFromAnswer = (hl) => {
    setLinksCfi(linksCfi.filter((el) => el !== hl.cfi));
  };
  const epubRef = useRef();
  const [rendition, setRendition] = useState(null);
  const renderTextLinkDialog = () => {
    return (
      <Dialog
        open={openLinkDialog}
        PaperProps={{
          style: {
            direction: rtlValue ? "rtl" : "ltr",

            // backgroundColor: "white",
            width: "90%",
            maxWidth: "90%",
          },
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <DialogActions
            className={clsx(
              classes.dialogActions,
              rtlValue && classes.dialogActionsRtl
            )}
          >
            <Typography className={classes.dialogHeader}>
              {" "}
              <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
            </Typography>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenLinkDialog(false);
                if (linksCfi.length > 0) {
                  dispatch(setLinkType("text"));
                  // console.log("setLinkContent", linksCfi);
                  dispatch(setLinkContent({ cfis: linksCfi }));
                } else {
                  dispatch(resetLink());
                }
                setLinksCfi([]);

                //setHighlightsElements([]);
              }}
            >
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <ReactReaderActions
            id="reactReaderActions"
            minimal={true}
            highlightFunc={addHighlightToAnswer}
            removeHighlight={removeHighlightFromAnswer}
          >
            <div id="epubViewContainer" className={classes.epubViewContainer}>
              <EpubView
                ref={epubRef}
                fullSize={true}
                fontSize={fontSize[fontSizeValue]}
                epubOptions={{ flow: "scrolled-doc" }}
                // loadingView={inputRef}
                rtl={rtlValue}
                url={readText.url}
                location={readText.file_location}
                // locationChanged={locationChanged}
                handleTextSelected={(params) => {
                  if (params.shouldClose) {
                    dispatch(closeAnnotatorBar());
                  } else {
                    addHighlightToAnswer(params.selection.cfi);
                    let content = rendition && rendition.getContents()[0];

                    if (
                      content &&
                      content.window &&
                      content.window.getSelection()
                    ) {
                      content.window.getSelection().empty();
                    }
                  }
                }}
                onRenditionCreated={(rendition) => {
                  onRenditionCreated(rendition);
                }}
              />
            </div>
          </ReactReaderActions>
        </DialogContent>
      </Dialog>
    );
  };
  //effect to add highlights
  const [highlightsElements, setHighlightsElements] = useState([]);
  // const highlights = props.highlights;

  useEffect(() => {
    const setExistingElements = (items) => {
      let toRemove = [];
      highlightsElements &&
        Object.entries(highlightsElements).forEach(([cfi, elem]) => {
          let highlightExist = linksCfi && linksCfi.includes(cfi);
          if (!highlightExist) {
            toRemove.push(cfi);
            rendition.annotations.remove(cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter((key) => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key],
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingHighlights = (items) => {
      let timeAdding = new Date().getTime();

      // console.log("after conds");
      linksCfi &&
        rendition &&
        rendition.manager &&
        linksCfi.forEach((cfi) => {
          let range = rendition.getRange(cfi);
          if (range && !(cfi in items)) {
            let addedHighlight = rendition.annotations.highlight(
              cfi,
              {},
              (e) => {
                let timeClick = new Date().getTime();
                if (timeClick - timeAdding < 500) return;
                let contents = rendition.getContents();

                contents.forEach((content) => {
                  if (range) {
                    let pos = rendition.getContents()[0].locationOf(cfi);
                    if (pos.x !== 0 || pos.y !== 0) {
                      let clientRect = range.getBoundingClientRect();
                      const frameBounds =
                        content.document.defaultView.frameElement.getBoundingClientRect();
                      // `target` can be an element or a range
                      let newRect = {
                        x: clientRect.x + frameBounds.x,
                        y: clientRect.y + frameBounds.y,
                        width: clientRect.width,
                        height: clientRect.height,
                        top: clientRect.top + frameBounds.y,
                        left: clientRect.left + frameBounds.x,
                        bottom:
                          clientRect.top + frameBounds.y + clientRect.height,
                        right:
                          clientRect.left + frameBounds.x + clientRect.width,
                      };
                      dispatch(
                        openAnnotatorBar({
                          clientRectangle: newRect,
                          selectedText: { cfi: cfi },
                          isHover: true,
                        })
                      );
                    }
                  }
                });
              },
              "highlightClass",
              {
                "z-index": 10,
                "mix-blend-mode": "multiply",
                "fill-opacity": 1,
                fill: getHighlightColor("#ffa284", darkMode),
              }
            );

            items[cfi] = { ...addedHighlight, cfi: cfi };
            //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

            addedHighlight.mark.element.style.cursor = "pointer";
            addedHighlight.mark.element.style.pointerEvents = "visible";
          }
        });
      return items;
    };

    const renderHighlights = () => {
      let items = highlightsElements;

      items = setExistingElements(items);
      items = addMissingHighlights(items);

      setHighlightsElements(items);
    };
    //  console.log("should addd highligh useEffect");
    if (rendition) {
      // console.log("rendition useEffect", rendition.manager);
    }
    if (openLinkDialog) renderHighlights();
  }, [
    linksCfi,
    openDialog,
    rendition,
    highlightsElements,
    dispatch,
    darkMode,
    openLinkDialog,
  ]);

  const currentUser = firebaseApp.auth().currentUser;

  useEffect(() => {
    if (clickedMatch) {
      setOpenDialog(true);
      dispatch(setClickedMatch(false));
    }
  }, [clickedMatch, dispatch, setOpenDialog]);

  const rendeHighlightsDialog = () => {
    return (
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            direction: rtlValue ? "rtl" : "ltr",

            // backgroundColor: "white",
            width: "60%",
            maxWidth: "60%",
          },
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {" "}
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <div id="epubViewContainer" className={classes.epubViewContainer}>
            {showMatch &&
              (task.questions.questions[index].type === 0 ||
                task.questions.questions[index].includeCitation) && (
                <Box className={classes.colorsReader}>
                  <Typography
                    style={{
                      background: getHighlightColor("#04E3FD", darkMode),
                    }}
                    className={classes.yourColor}
                  >
                    {currentUser.uid === task.teacher.uid
                      ? "Your"
                      : task.teacher.displayName + "'s"}{" "}
                    marks in blue
                  </Typography>
                  <Typography
                    style={{
                      background: getHighlightColor("#FFFF00", darkMode),
                    }}
                    className={classes.studentColor}
                  >
                    {currentUser.uid === task.teacher.uid
                      ? task.student_name.split(" ")[0] + "'s"
                      : "Your"}{" "}
                    marks in yellow
                  </Typography>

                  <Typography
                    style={{
                      background: getHighlightColor("#91fcc2", darkMode),
                    }}
                    className={classes.matchColor}
                  >
                    Match in green
                  </Typography>
                </Box>
              )}
            <EpubView
              fullSize={true}
              //  ref={epubRef}
              fontSize={fontSize[fontSizeValue]}
              epubOptions={{ flow: "scrolled-doc" }}
              // loadingView={inputRef}
              rtl={rtlValue}
              url={readText.url}
              location={readText.file_location}
              // locationChanged={locationChanged}
              //  handleTextSelected = {onTextSelected}
              onRenditionCreated={(rendition) => {
                onRenditionCreated(rendition);

                /*if (sortedQuotes.length) {
                  gotoQuote(rendition, sortedQuotes[0].cfi);
                }*/
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderMenu = () => {
    return (
      <Menu
        direction={"auto"}
        anchorEl={linkBtnRef.current}
        keepMounted
        open={Boolean(menuOpen)}
        onClose={() => setMenuOpen(false)}
        getContentAnchorEl={null}
        disableScrollLock={true}
      >
        {task.questions.questions[index].type === 1 && (
          <MenuItem
            onClick={() => {
              // handleProfileClick();
              setMenuOpen(false);
              dispatch(setLinkType("answer"));

              dispatch(setLinkTarget("editor"));
            }}
          >
            <ListItemText>
              <FormattedMessage
                id="task.feedback.link.answer"
                defaultMessage="Link to a passage from student's work"
              />
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            // googleAuthProvider.signOut();
            //firebaseApp.auth().signOut();
            setMenuOpen(false);
            dispatch(setLinkTarget("text"));
            setOpenLinkDialog(true);
          }}
        >
          <ListItemText>
            {" "}
            <FormattedMessage
              id="task.feedback.link.text"
              defaultMessage="Link to task related text"
            />
          </ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  const updateTitle = (title) => {
    let newFeedback = {
      ...feedback,
      questions: feedback.questions.map((q, i) => {
        if (i === index) {
          return { ...q, title: title };
        } else return q;
      }),
    };
    updateFeedback(newFeedback, index);
  };

  const updateComment = (comment, index) => {
    let newFeedbackComment = comment;
    let newFeedback = {
      ...feedback,
      questions: feedback.questions.map((q, i) => {
        if (i === index) {
          return { ...q, comment: newFeedbackComment };
        } else return q;
      }),
    };
    updateFeedback(newFeedback, index);
  };

  const updatePoints = useCallback(
    (points) => {
      let newFeedback = {
        ...feedback,
        questions: feedback.questions.map((q, i) => {
          if (i === index) {
            return { ...q, points: points };
          } else return q;
        }),
      };
      updateFeedback(newFeedback, index);
    },
    [feedback, index, updateFeedback]
  );

  const getMatchScore = (quesion, questionFeedback) => {
    let factored = questionFeedback.match;
    if (questionFeedback.match > 0.7) {
      factored = 1;
    } else if (questionFeedback.match > 0.6) {
      factored = factored * 1.25;
    } else {
      factored = Math.min(0.6, factored * 1.55);
    }
    return Math.ceil(factored * parseFloat(quesion.points));
  };
  const parsedPoints = parseInt(
    feedback.questions[index] && feedback.questions[index].points
  );
  useEffect(() => {
    const calcPoints = () => {
      if (
        task.questions.questions[index] &&
        !task.questions.questions[index].points
      ) {
        return 0;
      }
      let calc = 0;
      if (task.questions.questions[index].type === 0) {
        calc = getMatchScore(
          task.questions.questions[index],
          feedback.questions[index]
        );
      }
      if (task.questions.questions[index].type === 1) {
        let questionPart = Math.ceil(
          feedback.questions[index] &&
            feedback.questions[index].concepts &&
            (feedback.questions[index].concepts.filter((el) => el.found)
              .length /
              concepts.length) *
              task.questions.questions[index].points
        );
        if (
          task.questions.questions[index].includeCitation &&
          task.answers.answers[index].quotes.length
        ) {
          let quotePart = getMatchScore(
            task.questions.questions[index],
            feedback.questions[index]
          );
          calc = 0.5 * quotePart + 0.5 * questionPart;
        } else calc = questionPart;
      }
      if (task.questions.questions[index].type === 2) {
        let questionPart =
          parseInt(feedback.questions[index].correctChoice) ===
          feedback.questions[index].selectedChoice
            ? parseInt(task.questions.questions[index].points)
            : 0;

        if (
          task.questions.questions[index].includeCitation &&
          task.answers.answers[index].quotes.length
        ) {
          let quotePart = getMatchScore(
            task.questions.questions[index],
            feedback.questions[index]
          );
          calc = 0.5 * quotePart + 0.5 * questionPart;
        } else calc = questionPart;
      }
      return calc;
    };
    if (parsedPoints < 0) {
      let setPoints = calcPoints();
      if (setPoints >= 0) {
        updatePoints(calcPoints());
      }
    }
  }, [
    parsedPoints,
    task,
    feedback,
    updatePoints,
    concepts && concepts.length,
    index,
  ]);
  const [titleEditMargin, setTitleEditMargin] = useState(0);

  useEffect(() => {
    let marginVal = "0px";
    marginVal =
      titleSpanRef && titleSpanRef.current
        ? titleSpanRef.current.getBoundingClientRect().width + "px"
        : feedback.questions[index].title.length * 0.7 + "ch";

    if (rtlValue) {
      setTitleEditMargin({ marginRight: marginVal });
    } else {
      setTitleEditMargin({ marginLeft: marginVal });
    }
  }, [feedback, titleSpanRef, index, setTitleEditMargin, rtlValue]);

  const renderPoints = () => {
    if (
      task &&
      task.questions &&
      task.questions.questions &&
      task.questions.questions[index] &&
      task.questions.questions[index].points &&
      Number(task.questions.questions[index].points)
    ) {
      return (
        <>
          <TextField
            className={classes.points}
            id="standard-number"
            type="number"
            InputProps={{ disableUnderline: true, readOnly: readOnly }}
            value={parsedPoints >= 0 ? parsedPoints : ""}
            onChange={(e) => {
              updatePoints(e.target.value);
            }}
            inputProps={{
              "aria-label": `Points out of ${task.questions.questions[index].points}`,
            }}
          />{" "}
          <Typography
            className={classes.pointsMsg}
            variant="body1"
            component="div"
          >
            <FormattedMessage
              defaultMessage="Points out of"
              id="task.points_of"
            />{" "}
            {task.questions.questions[index].points}
          </Typography>
        </>
      );
    }
  };

  const isCommentValid = () => {
    let comment =
      feedback.questions[index] && feedback.questions[index].comment;
    let commentIsValid =
      comment &&
      comment.blocks &&
      comment.blocks.some((element) => element.text !== "");
    return commentIsValid;
  };

  return (
    <Card className={clsx(classes.feedbackCard, classes.left)}>
      <Typography
        variant="h6"
        className={clsx(classes.feedbackHeader, classes.questionLtr)}
      >
        {/* <FormattedMessage id="task.question" defaultMessage="Question" />{" "}
        {index + 1}
        {props.isLast} */}
        <FormattedMessage
          id="task.question.feedback"
          defaultMessage="Question feedback"
        />
      </Typography>
      {(
        !readOnly ||
        isCommentValid() ||
        (feedback.questions[index] && feedback.questions[index].title)) && (
        <Divider />
      )}

      {(
        !readOnly ||
        isCommentValid() ||
        (feedback.questions[index] && feedback.questions[index].title)) && (
        <Box className={classes.feedbackBody}>
          <span ref={titleSpanRef} className={classes.hidden}>
            {!readOnly &&
            (!feedback.questions[index] ||
              feedback.questions[index].title === "")
              ? intl.formatMessage({
                  defaultMessage: "Enter evaluation title",
                  id: "task.title.placeholder",
                })
              : feedback.questions[index] && feedback.questions[index].title}
          </span>

          <TextField
            inputRef={titleRef}
            fullWidth
            InputProps={{
              readOnly: !editingTitle,

              disableUnderline: true,
              startAdornment: !editingTitle && !readOnly && (
                <InputAdornment
                  style={{ ...titleEditMargin, position: "absolute" }}
                  position="start"
                >
                  <IconButton
                    className={classes.adornment}
                    onClick={(e) => {
                      setEditingTitle(true);

                      titleRef.current.focus();
                      if (
                        titleRef.current.setSelectionRange &&
                        feedback.questions[index].title
                      ) {
                        titleRef.current.setSelectionRange(
                          feedback.questions[index].title.length,
                          feedback.questions[index].title.length
                        );
                      }

                      e.stopPropagation();
                    }}
                    aria-label="edit evaluation title"
                  >
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
              className: classes.eval,
            }}
            className={classes.eval}
            label=""
            placeholder={
              readOnly
                ? ""
                : intl.formatMessage({
                    defaultMessage: "Enter evaluation title",
                    id: "task.title.placeholder",
                  })
            }
            value={feedback.questions[index] && feedback.questions[index].title}
            onChange={(e) => {
              updateTitle(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setEditingTitle(false);
              }
            }}
            onBlur={() => setEditingTitle(false)}
          />

          <TaskFeedbackComment
            showFeedbackLine={true}
            task={task}
            readOnly={readOnly}
            key={index}
            index={index}
            onChange={updateComment}
            placeholder={
              readOnly
                ? ""
                : intl
                    .formatMessage({
                      id: "task.feedback.placeholder",
                      defaultMessage: "Let $STUDENT know how well they did",
                    })
                    .replace("$STUDENT", task.student_name)
            }
            comment={
              feedback.questions[index] && feedback.questions[index].comment
            }
          />
        </Box>
      )}
      <Divider />

      <Box className={classes.pointsContainer}>
        <Box className={classes.feedbackBtns}>
          <Tooltip
            title={intl.formatMessage({
              id: "feedback.tooltip.link",
              defaultMessage: "Add link",
            })}
            arrow
            placement="top"
          >
            <span>
              <IconButton
                ref={linkBtnRef}
                className={classes.textIcon}
                disabled={!selectedFeedbackText}
                onClick={() => {
                  setLinksCfi([]);
                  setMenuOpen(true);
                }}
                aria-label="add link"
              >
                <LinkIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: "feedback.tooltip.showText",
              defaultMessage: "Show text",
            })}
            arrow
            placement="top"
          >
            <IconButton
              className={classes.textIcon}
              onClick={() => setOpenDialog(true)}
            >
              <LibraryBooksIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {renderPoints()}
      </Box>
      {openDialog && rendeHighlightsDialog()}
      {openLinkDialog && renderTextLinkDialog()}
      {menuOpen && renderMenu()}
    </Card>
  );
}
