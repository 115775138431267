import React  from 'react';

import ThemesManager from './ThemesManager';
import {  useDispatch } from 'react-redux';

import { selectTheme} from '../../redux/themeSlice';
import {setAnnotatorMode, setLocation} from '../../redux/readerActionsSlice'

export default function ThemesContainer(props) {
   
    const dispatch = useDispatch();

    const localSelectTheme = (theme) => {
      //  console.log("select theme",theme);
        dispatch(selectTheme(theme));
        let mode = "";
        if (theme)
            mode ="themes"
            dispatch(setLocation(false));
        dispatch(setAnnotatorMode(mode));
    }


  return (
   <ThemesManager readText={props.readText} selectTheme={localSelectTheme} />
  )
    

  
}
