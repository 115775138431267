import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import QuestionPanel from "./TaskAnswer/QuestionPanel";
import { useSelector } from "react-redux";
import QuestionsCarousel from "./QuestionsCarousel";
import { setQuestionIndex } from "../../redux/taskSlice.js";

const useStyles = makeStyles(theme => ({
  questionIndexContainer: {
    display: "flex",
    alignItems: "center"
  },
  carouselWrapper: {
    overflow: "hidden",
    flex: 1
  },
  carousel: {
    display: "flex",
    alignItems: "center"
  },
  questionIndex: {
    width: 48,
    height: 48,
    padding: 0,
    flexShrink: 0,
    marginLeft: "calc((25% - 48px) / 2)",
    marginRight: "calc((25% - 48px) / 2)",
    color: "white"
  },
  alignToStart: {
    marginRight: 0,
    marginLeft: theme.spacing(2)
  },
  selected: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  number: {
    fontFamily: "Crimson Pro",
    fontSize: 23,
    height: 38,
    color: "inherit"
  },
  carouselArrow: {
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      opacity: 0.2
    }
  },
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    background: "#333333"
  },
  panelSubtitle: {
    color: "white"
  },
  taskTitle: {
    fontSize: "20px",
    marginBottom: "20px"
  },
  panelHeader: {
    color: "white",
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    textAlign: "left"
  },
  divider: {
    backgroundColor: "#616161",
    margin: "16px 0"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "24px",
    marginTop: "8px",
    textTransform: "uppercase"
  },
  nextButton: {
    marginTop: "16px",
    fontSize: "13px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#ffffff",
    backgroundColor: "#168fee",
    "&:hover": {
      backgroundColor: "#1172be"
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.text.disabled
    }
  },
  questionTitle: {
    fontSize: "0.75rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "20px 0",
    textTransform: "uppercase",
    color: "#BCD9F1"
  }
}));

function TaskSideBarQuestions({ readText }) {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const task = useSelector(state => state.task.taskRow);
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  const [questionsOrder, setQuestionsOrder] = useState([]);

  const question =
    task?.questions?.questions[
      questionsOrder[selectedQuestionIndex]?.questionIndex
    ];

  useEffect(() => {
    if (task?.questions) {
      // if (task.task_type === "peerReview") {
      //   setQuestionsOrder(task.questions);
      // } else {
      // Return an array of objects that brakes questions with citations into two steps
      const questionsToDispay = [];
      let counter = -1;
      task.questions.questions.forEach((question, index) => {
        if (question.includeCitation) {
          questionsToDispay.push({
            questionIndex: index, // The original index
            displayIndex: ++counter, // The index after splitting question steps
            displayLabel: `${index + 1}a`,
            mode: "Citations"
          });
          questionsToDispay.push({
            questionIndex: index,
            displayIndex: ++counter,
            displayLabel: `${index + 1}b`,
            mode: "Answer"
          });
        } else {
          questionsToDispay.push({
            questionIndex: index,
            displayIndex: ++counter,
            displayLabel: `${index + 1}`,
            mode: null
          });
        }
      });

      // setSelected(selectedQuestionIndex);
      setQuestionsOrder(questionsToDispay);
    }
    // }
  }, [task.id]);

  return (
    <>
      <Divider className={classes.divider} />
      <Typography className={classes.questionTitle} variant="h6">
        Questions
      </Typography>
      {questionsOrder.length > 0 && (
        <QuestionsCarousel questionsOrder={questionsOrder} />
      )}
      <Divider className={classes.divider} />
      {question ? (
        <QuestionPanel
          readText={readText}
          question={question}
          task={task}
          // index={questionsOrder[selectedQuestionIndex]?.questionIndex}
          questionsOrder={questionsOrder}
          selected={selectedQuestionIndex}
        />
      ) : null}
    </>
  );
}

TaskSideBarQuestions.propTypes = {
  readText: PropTypes.shape({
    url: PropTypes.string,
    file_location: PropTypes.any
  })
};

export default TaskSideBarQuestions;
