// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { firebaseFunctions } from "../../../firebase";
import { useIntl } from "react-intl";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { updateCourses } from "../../../redux/coursesSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import { setSentFeedbackTask } from "../../../redux/taskSlice";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Components
import TeacherTaskView from "./TeacherTaskView";
import ScrollBox from "../../SharedComponents/ScrollBox";

const useStyles = makeStyles(theme => {
  return {
    container: {
      flex: 1,
      height: "100%",
      width: "83.333333%", // 10 culumns on a 12 column grid
      paddingInline: 40,
      alignItems: "center",
      position: "relative",

      [theme.breakpoints.up("desktop")]: {
        maxWidth: 840,
        marginInline: "auto"
      }
    }
  };
});

function SelectedTaskManager() {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { course_id, task_id } = useParams();
  const [task, setTask] = useState(false);

  const course = useSelector(
    state => state.courses.courses.filter(el => el.id == course_id)?.[0]
  );
  const sentFeedbackTask = useSelector(state => state.task.sentFeedbackTask);
  const shouldUndo = useSelector(state => state.user.undo);

  const intl = useIntl();
  const [refreshToken, setRefreshToken] = React.useState(0);
  const [taskSaved, setTaskSaved] = useState(false);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Behavior
  //Get course and role by id

  useEffect(() => {
    if (task_id) {
      if (!history.location.state?.task) {
        let getAllTasks = firebaseFunctions.httpsCallable("courses-getTasks");
        getAllTasks({ course: course_id }).then(response => {
          const newTask = response.data.filter(t => t.id == task_id)?.[0];

          let submissions = firebaseFunctions.httpsCallable(
            "courses-getTaskSubmissions"
          );
          submissions({ course: course_id }).then(response => {
            const submissions = response.data.filter(t => t.task_id == task_id);
            if (newTask) {
              setTask({ ...newTask, submissions: submissions });
            }
          });
        });
      } else setTask(history.location.state?.task);
    }
  }, [task_id, course_id]);

  useEffect(() => {
    if (!course) {
      //TODO move to some common place
      const makeCancellable = promise => {
        let hasCanceled_ = false;

        const wrappedPromise = new Promise((resolve, reject) => {
          promise.then(
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            error =>
              hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
          );
        });
        return {
          promise: wrappedPromise,
          cancel() {
            hasCanceled_ = true;
          }
        };
      };

      var getMyCourses = firebaseFunctions.httpsCallable(
        "courses-getMyCourses"
      );

      const cancellable = makeCancellable(getMyCourses());

      cancellable.promise.then(response => {
        const classesByYear = response.data.reduce((result, item) => {
          if (item.academic_year in result) {
            result[item.academic_year].push(item);
          } else {
            result[item.academic_year] = [item];
          }
          return result;
        }, {});
        let items = Object.keys(classesByYear).map(function (key) {
          return [key, classesByYear[key]];
        });
        let sorted = items.sort(function (first, second) {
          return second[0].localeCompare(first[0]);
        });
        // Sort the array based on the second element
        dispatch(updateCourses(response.data));
      });

      const cancel = () => {
        cancellable.cancel();
      };
      return cancel;
    }
  }, [course, course_id]);
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoTaskFeedbackSubmission = () => {
      const taskId = sentFeedbackTask;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoTaskFeedbackSubmission"
      );

      firebaseFunction({ task_id: taskId })
        .then(response => {
          dispatch(setSentFeedbackTask(0));
          setRefreshToken(refreshToken + 1);
        })
        .catch(err => console.log("err", err));
    };

    if (shouldUndo) {
      if (sentFeedbackTask > 0) {
        undoTaskFeedbackSubmission();
      }

      dispatch(undo(false));
    }
  }, [shouldUndo]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (taskSaved) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "tasks.published_msg",
            defaultMessage: "Task published"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [taskSaved]);

  useEffect(() => {
    if (sentFeedbackTask) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.feedback_sent",
            defaultMessage: "Feedback sent"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [sentFeedbackTask]);

  const breadCrumbsClicked = useSelector(
    state => state.readerActions.breadCrumbsClicked
  );

  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Tasks"
      }),
      url: "/tasks",
      resetCourse: true
    });

    course && parts.push({ text: course.name, url: "/tasks/" + course.id });

    task &&
      task.name &&
      course &&
      parts.push({
        text: task.name,
        url: "/tasks/course_id/" + course.id + "/task_id/" + task.id
      });

    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course, task]);

  useEffect(() => {
    return () => {
      //avoid showing messages twice
      setTaskSaved(false);
      dispatch(setSentFeedbackTask(0));
    };
  }, []);

  return (
    <ScrollBox alignItems="center">
      <Box className={classes.container}>
        <TeacherTaskView refreshToken={refreshToken} refresh={refreshToken} />
      </Box>
    </ScrollBox>
  );
}

export default SelectedTaskManager;
