import React, {
  useState,
  useEffect
  //  useRef
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { openAnnotatorBar } from "../../../redux/highlightSlice";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import ePub from "epubjs";
import {
  Box,
  //TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  //Divider,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  Typography
} from "@material-ui/core";
import EpubView from "../../reader/EpubView/EpubView";
import { FormattedMessage } from "react-intl";
import Brightness1Icon from "@material-ui/icons/Brightness1";
const useStyles = makeStyles(theme => ({
  dialog: {
    zIndex: 10
  },
  epubViewContainer: {
    paddingRight: "21vw",
    paddingLeft: "15vw",
    width: "100%"
  },
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },
  dialogHeader: {},
  dialogActions: {
    justifyContent: "left",
    position: "relative"
    //  justifyContent:'flex-start',
  },
  legendIcon: {
    minWidth: "24px"
  },
  legendLi: {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  legendContainer: {
    width: "80px",
    height: "270px",
    zIndex: "1",
    position: "absolute",
    right: "80px",
    top: "100px"
  },
  legendText: {
    fontSize: "12px",
    fontFamily: "Helvetica"
  },
  dialogActionsRtl: {
    justifyContent: "right",
    position: "relative"
    //  justifyContent:'flex-start',
  },
  blue: {
    color: "#168FEE"
  },
  dialogBtn: {
    position: "absolute",
    right: "16px",
    color: "#ffffff"
  },
  expandMsg: {
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "24px",
    paddingTop: "8px"
  },
  span: {
    textAlign: "left",
    paddingLeft: "24px",
    paddingBottom: "8px"
  },
  modal: {
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
    width: "90%",
    maxWidth: "90%"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  }
}));

function HeatMap(props) {
  const epubRef = React.useRef(null);
  const {
    openDialog,
    teacherHighlights,
    highlights,
    setOpenDialog,
    url,
    location,
    totalCount
  } = props;

  //maybe need colors for dark mode?
  const colors = [
    "#d7f7e6",
    "#aeefce",
    "#8ae1bb",
    "#5dd6a3",
    "#13cc8c",
    "#ffd863",
    "#ffaf2c",
    "#ff8719",
    "#ff3906",
    "#f80000"
  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const [highlightsElements, setHighlightsElements] = useState([]);
  const [rendition, setRendition] = useState(null);
  var EpubCFI = new ePub.CFI();

  useEffect(() => {
    const makeRangeCfi = (a, b) => {
      const start = EpubCFI.parse(a),
        end = EpubCFI.parse(b);
      const cfi = {
        range: true,
        base: start.base,
        path: {
          steps: [],
          terminal: null
        },
        start: start.path,
        end: end.path
      };
      const len = cfi.start.steps.length;
      for (let i = 0; i < len; i++) {
        if (EpubCFI.equalStep(cfi.start.steps[i], cfi.end.steps[i])) {
          if (i === len - 1) {
            // Last step is equal, check terminals
            if (cfi.start.terminal === cfi.end.terminal) {
              // CFI's are equal
              cfi.path.steps.push(cfi.start.steps[i]);
              // Not a range
              cfi.range = false;
            }
          } else cfi.path.steps.push(cfi.start.steps[i]);
        } else break;
      }
      cfi.start.steps = cfi.start.steps.slice(cfi.path.steps.length);
      cfi.end.steps = cfi.end.steps.slice(cfi.path.steps.length);

      return (
        "epubcfi(" +
        EpubCFI.segmentString(cfi.base) +
        "!" +
        EpubCFI.segmentString(cfi.path) +
        "," +
        EpubCFI.segmentString(cfi.start) +
        "," +
        EpubCFI.segmentString(cfi.end) +
        ")"
      );
    };

    const getColorFromFreq = (frequence, totalCount, colors) => {
      let index = Math.floor(((frequence * 1.0) / totalCount) * 10) - 1;
      if (index < 0) index = 0;
      return colors[index];
    };

    const getRanges = (highlights, totalCount, colors) => {
      let coll = [];
      highlights &&
        highlights
          .filter(h => h && h.cfi)
          .forEach(hl => {
            let cfiParts = hl.cfi.split(","); // cfiBase:  cfiParts[0]
            let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
            let endCfi = cfiParts[0] + cfiParts[2];
            coll.push({ cfi: startCfi, val: 1 });
            coll.push({ cfi: endCfi, val: -1 });
          });

      let sortedHl = [...coll].sort(function (a, b) {
        let val = EpubCFI.compare(a.cfi, b.cfi);
        if (val === 0) {
          if (a.val === b.val) return 0;
          return b.val < 0 ? 1 : -1;
        }
        return val;
      });

      let current = 0;
      let start = null;
      let frequencies = [];
      for (var i = 0; i < sortedHl.length; i++) {
        if (start == null) {
          if (sortedHl[i].val > 0) {
            start = sortedHl[i].cfi;
            current = 1;
          }
        } else {
          frequencies.push({
            start: start,
            end: sortedHl[i].cfi,
            freq: current
          });
          start = sortedHl[i].cfi;
          if (sortedHl[i].val > 0) {
            current++;
          } else {
            current--;
            if (current === 0) {
              start = null;
            }
          }
        }
      }
      console.log("frequencies", frequencies);
      //freq to colors :
      return frequencies.map((el, i) => {
        return {
          cfi: makeRangeCfi(el.start, el.end),
          color: getColorFromFreq(el.freq, totalCount, colors)
        };
      });
    };

    const setExistingElements = (items, ranges) => {
      let toRemove = [];
      highlightsElements &&
        Object.entries(highlightsElements).forEach(([cfi, elem]) => {
          let highlightColl =
            ranges &&
            ranges.filter &&
            ranges.filter(hl => hl && elem.highlight.cfi === hl.cfi);
          if (highlightColl && highlightColl[0]) {
            elem &&
              elem.mark &&
              elem.mark.element &&
              (elem.mark.element.style.fill = highlightColl[0].color);
          } else {
            toRemove.push(elem.highlight.cfi);
            rendition.annotations.remove(elem.highlight.cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };
    const addTeacherHighlights = (items, ranges) => {
      // console.log("after conds");
      let underlineStyleObj = { stroke: "none", "stroke-opacity": 1 };
      ranges &&
        rendition &&
        rendition.manager &&
        ranges.forEach(highlight => {
          if (highlight && highlight.cfi) {
            let range = rendition.getRange(highlight.cfi);
            if (range && !(highlight.cfi in items)) {
              let addedHighlight = rendition.annotations.underline(
                highlight.cfi,
                {},
                e => {},
                "underlineClass",
                underlineStyleObj
              );

              items[highlight.cfi] = {
                ...addedHighlight,
                highlight: highlight
              };
              //This ugly code thickens the underline
              var cDiv = addedHighlight.mark.element.children;
              for (var i = 0; i < cDiv.length; i++) {
                cDiv[i].style.strokeWidth = "2"; //do styling here
              }

              //addedHighlight.mark.element.style.strokeWidth = 2;

              //addedHighlight.mark.element.style.cursor = "pointer";
              //addedHighlight.mark.element.style.pointerEvents = "visible";
            }
          }
        });
      return items;
    };

    const addMissingHighlights = (items, ranges) => {
      let timeAdding = new Date().getTime();

      // console.log("after conds");
      ranges &&
        rendition &&
        rendition.manager &&
        ranges.forEach(highlight => {
          if (highlight && highlight.cfi) {
            let range = rendition.getRange(highlight.cfi);
            if (range && !(highlight.cfi in items)) {
              let addedHighlight = rendition.annotations.highlight(
                highlight.cfi,
                {},
                e => {
                  let timeClick = new Date().getTime();
                  if (timeClick - timeAdding < 500) return;
                  let contents = rendition.getContents();

                  contents.forEach(content => {
                    if (range) {
                      let pos = rendition
                        .getContents()[0]
                        .locationOf(highlight.cfi);
                      if (pos.x !== 0 || pos.y !== 0) {
                        let clientRect = range.getBoundingClientRect();
                        const frameBounds =
                          content.document.defaultView.frameElement.getBoundingClientRect();
                        // `target` can be an element or a range
                        let newRect = {
                          x: clientRect.x + frameBounds.x,
                          y: clientRect.y + frameBounds.y,
                          width: clientRect.width,
                          height: clientRect.height,
                          top: clientRect.top + frameBounds.y,
                          left: clientRect.left + frameBounds.x,
                          bottom:
                            clientRect.top + frameBounds.y + clientRect.height,
                          right:
                            clientRect.left + frameBounds.x + clientRect.width
                        };
                        dispatch(
                          openAnnotatorBar({
                            clientRectangle: newRect,
                            selectedText: highlight,
                            isHover: true
                          })
                        );
                      }
                    }
                  });
                },
                "highlightClass",
                {
                  "z-index": 10,
                  "mix-blend-mode": "multiply",
                  "fill-opacity": 1, // opacityVal,
                  fill: highlight.color
                }
              );

              items[highlight.cfi] = {
                ...addedHighlight,
                highlight: highlight
              };
              //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

              //addedHighlight.mark.element.style.cursor = "pointer";
              //npmaddedHighlight.mark.element.style.pointerEvents = "visible";
            }
          }
        });
      return items;
    };

    const renderHighlights = (ranges, teacherHighlights) => {
      let items = highlightsElements;

      items = setExistingElements(items, ranges);
      items = addMissingHighlights(items, ranges);
      addTeacherHighlights([], teacherHighlights);
      setHighlightsElements(items);
    };

    let ranges = getRanges(highlights, totalCount, colors);

    if (openDialog) renderHighlights(ranges, teacherHighlights);
  }, [
    highlights,
    teacherHighlights,
    openDialog,
    rendition,
    highlightsElements,
    EpubCFI,
    dispatch,
    totalCount,
    colors
  ]);

  //  const [ highlights, setHighlights] = useState([]);

  const onRenditionCreated = rendition => {
    setRendition(rendition);
  };

  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);

  const fontSize = useSelector(state => state.user.fontSizeOptions);

  const rtlValue = useSelector(state => state.user.userProfile.rtl);

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            direction: rtlValue ? "rtl" : "ltr",
            width: "90%",
            maxWidth: "90%",
            height: "100%"
          }
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <DialogActions className={clsx(classes.dialogActions)}>
            <Typography className={classes.dialogHeader}>
              <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
            </Typography>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={e => {
                setOpenDialog(false);

                setHighlightsElements([]);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div id="epubViewContainer" className={classes.epubViewContainer}>
            <EpubView
              ref={epubRef}
              fullSize={true}
              fontSize={fontSize[fontSizeValue]}
              epubOptions={{ flow: "scrolled-doc" }}
              // loadingView={inputRef}
              rtl={rtlValue}
              url={url}
              location={location}
              doNotMove={true}
              // locationChanged={locationChanged}
              onRenditionCreated={onRenditionCreated}
            />
          </div>
          <Box className={classes.legendContainer}>
            <Typography className={classes.legendText}>
              % of students
            </Typography>
            <List>
              {colors.map((color, i) => {
                let min = i * 10 + 1;
                let max = (i + 1) * 10;

                return (
                  <ListItem className={classes.legendLi}>
                    <ListItemIcon className={classes.legendIcon}>
                      <Brightness1Icon style={{ fill: color }} />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.legendText}
                      primary={min + " - " + max}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  return <Box>{renderCreateCourseDialog()}</Box>;
}

export default HeatMap;
