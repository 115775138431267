// Dependencies
import React, { useState } from "react";
import clsx from "clsx";
import { convertFromRaw, EditorState } from "draft-js";
import { stemmer } from "stemmer";
import FlexSearch from "flexsearch";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    statContainer: {
      padding: "32px",
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold",
    },
    OpenQuestionContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 33.33%)",
      gridAutoFlow: "row",
      // Important - it sets height of the pie chart to 2X height of a keyword box
      gridAutoRows: "1fr",
      margin: "-1px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      "&.withCitations": {
        gridTemplateColumns: "repeat(4, 25%)",
      },
      // display: "flex",
      // flexFlow: "row nowrap",
    },
    citationContainer: {
      gridColumn: "1 / 3",
      gridRow: "1 / 3",
      margin: "-1px",
      padding: "32px",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    questionTitle: {
      fontWeight: theme.typography.fontWeightBold,
    },
    stat: {
      margin: "-1px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      "&:nth-child(3)": {
        // borderRight: "none",
      },
      "&.withCitations": {
        // flexBasis: "50%",
        "&:nth-child(3)": {
          // borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        },
        "&:nth-child(2)": {
          // borderRight: "none",
        },
      },
    },

    statTitle: {
      marginBottom: "32px",
    },
    statValuesContainer: {
      display: "flex",
      justifyContent: "space-between",
      overflow: "hidden",
      lineHeight: "initial",
      marginBottom: "6px",
      fontSize: "36px",
      fontFamily: "Helvetica",
    },
    statKey: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "#168FEE",
      "&:hover": {
        overflow: "initial",
      },
    },
    statBar: {
      height: "8px",
      backgroundColor: "#D8D8D8",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#168FEE",
      },
    },
    answers: {
      marginTop: "24px",
      borderRadius: "4px",
      backgroundColor: "#168FEE",
      "& .MuiAccordionSummary-content": {
        order: 2,
      },
    },
    answersHeading: {
      //   fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
      color: "#FFFFFF",
      padding: "0 16px",
    },
    answersDetails: {
      display: "block",
      columnCount: "2",
      columnFill: "balance",
      backgroundColor: theme.palette.background.paper,
      borderRadius: " 0 0 4px 4px",
    },
    answer: {
      marginBottom: "16px",
      breakInside: "avoid",
      pageBreakInside: "avoid",
      cursor: "pointer",
    },
    answerBody: {
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": "6",
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis",
    },
    answerActions: {
      padding: "16px",
      color: "#168FEE",
    },
    citationsActions: {
      textAlign: "end",
      padding: "34px",
    },
  };
});

export default function OpenQuestionStats({
  question,
  index,
  task,
  submissions,
  text,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  //   const generalStatsNumRef = useRef();

  // Redux State
  const rtl = useSelector((state) => state.user.userProfile.rtl);

  // Ephemeral State
  const [withCitations] = useState(question.includeCitation);
  const [openHeatmap, setOpenHeatmap] = useState(false);

  // Variables
  const concepts = submissions[0].answers.answers[index].concepts;
  const checked = submissions.filter((a) => a.is_checked);
  const submittedRows = submissions.filter((a) => a.submission_date);

  let teacherAnswer =
    submissions[0] &&
    submissions[0].answers &&
    submissions[0].answers.answers[index];

  //const studentCount = submissions.length; // All the students
  const participants = submittedRows.length; // Students who submitted the task
  let points = checked.map(
    (row) =>
      row.feedback &&
      row.feedback.questions &&
      row.feedback.questions[index] &&
      row.feedback.questions[index].points
  );
  const sum = points.reduce((a, b) => Number(a) + Number(b), 0);
  const avg = sum / points.length || 0;

  // Helpers
  // normalizing the value for the progress bars
  function normalize(value, min, max) {
    if (value - min === 0 || max - min === 0) {
      return 0;
    } else {
      return (((value - min) * 100) / (max - min)).toFixed(1);
    }
  }

  // index concepts in answers
  const createIndex = (questionIndex, rows) => {
    let flexIndex = new FlexSearch({
      filter: [],

      /**
       * @type {Object<string, string>}
       * @export
       */

      stemmer: function (value) {
        // apply some replacements
        // ...

        return stemmer(value);
      },
      encode: rtl ? false : "balance",
      rtl: rtl,
      split: rtl ? /\s+/ : /\W+/,
    });

    rows.forEach((row, index) => {
      if (
        row.student_answers &&
        row.student_answers.answers &&
        row.student_answers.answers[questionIndex].comment
      ) {
        let text = EditorState.createWithContent(
          convertFromRaw(row.student_answers.answers[questionIndex].comment)
        )
          .getCurrentContent()
          .getPlainText("\u0001");

        flexIndex.add(index, text);
      }
    });

    // changed to an object with the concept as key and result as value, easier to access in the render
    let output = {};
    teacherAnswer &&
      teacherAnswer.concepts.forEach((concept) => {
        let results = flexIndex.search(stemmer(concept));
        let exactResults =  flexIndex.search(concept);
        output[concept] = Math.max( results.length , exactResults.length);
      });
    return output;

    // return teacherAnswer.concepts.map((concept, i) => {
    //   let results = flexIndex.search(stemmer(concept));
    //   return { concept: concept, num: results.length };
    // });
  };
  const conceptResults = createIndex(index, submittedRows);
  console.log("question", question);
  return (
    <>
      <HeatMap
        totalCount={
          submissions.filter((submission) => submission.submission_date).length
        }
        openDialog={openHeatmap}
        teacherHighlights={teacherAnswer.quotes}
        setOpenDialog={setOpenHeatmap}
        url={text.url}
        location={text.location}
        hlOpacity={1}
        hlColor="#4AA96C"
        highlights={submissions
          .filter(
            (submission) =>
              submission.submission_date && submission.student_answers
          )
          .map((submission) => submission.student_answers.answers[index].quotes)
          .flat()}
        addHighlight={() => {}}
        removeHighlight={() => {}}
      />
      <Paper component="section" elevation={0}>
        <Box component="header" className={classes.statContainer}>
          <Typography className={clsx(classes.questionTitle)} variant="h5">
            Question #{index + 1} — open ended
          </Typography>
          <Typography className={clsx(classes.questionSubtitle)} variant="h6">
            {question.question}
          </Typography>
        </Box>
        <Divider />

        <Box
          className={clsx(
            classes.stat,
            classes.OpenQuestionContainer,
            withCitations && "withCitations"
          )}
        >
          {withCitations && (
            <Box className={classes.citationContainer}>
              <CitationChart
                question={question}
                i={index}
                task={task}
                submissions={submissions}
              />
            </Box>
          )}
          {/* <Box
            className={clsx(
              classes.barsContainer,
              withCitations && "withCitations"
            )}
          > */}
          <Box
            className={clsx(
              classes.stat,
              classes.statContainer,
              withCitations && "withCitations"
            )}
          >
            <Typography className={classes.statTitle} variant="h6">
              Grade average
            </Typography>
            <Box className={classes.statValuesContainer}>
              <Box component="span" className={classes.statKey}>
                {normalize(avg, 0, question.points)}
              </Box>
              <Box component="span">{`of ${question.points}`}</Box>
            </Box>
            <LinearProgress
              variant="determinate"
              value={normalize(avg, 0, question.points)}
              className={classes.statBar}
            />
          </Box>
          {concepts.map((concept, index) => {
            return (
              <Box
                key={index}
                className={clsx(
                  classes.stat,
                  classes.statContainer,
                  withCitations && "withCitations"
                )}
              >
                <Typography className={classes.statTitle} variant="h6">
                  Keyword
                </Typography>
                <Box className={classes.statValuesContainer}>
                  <Box component="span" className={classes.statKey}>
                    {concept}
                  </Box>

                  <Box component="span">
                    {`${normalize(conceptResults[concept], 0, participants)}%`}
                  </Box>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={normalize(conceptResults[concept], 0, participants)}
                  className={classes.statBar}
                />
              </Box>
            );
          })}
          {/* </Box> */}
        </Box>

        {withCitations && (
          <Box className={classes.citationsActions}>
            <Button
              color="primary"
              size="small"
              style={{
                backgroundColor: "#168FEE",
                color: "#FFFFFF",
                padding: "4px 8px",
              }}
              className={classes.actionButton}
              onClick={() => setOpenHeatmap(true)}
            >
              Show heatmap
            </Button>
          </Box>
        )}
      </Paper>
      <Accordion
        className={classes.answers}
        elevation={0}
        disabled={
          submissions.filter((s) => s.submission_date).length === 0 && true
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
          aria-controls="details-stats"
          id="panel-header"
        >
          <Typography variant="h5" className={classes.answersHeading}>
            Students’ answers
          </Typography>
        </AccordionSummary>
        <AccordionDetails id="details-stats" className={classes.answersDetails}>
          {submissions
            .filter((s) => s.submission_date)
            .map((s, i) => {
              let studentAnswer = s.student_answers.answers[index];
              let text =
                studentAnswer &&
                studentAnswer.comment &&
                EditorState.createWithContent(
                  convertFromRaw(studentAnswer.comment)
                )
                  .getCurrentContent()
                  .getPlainText("\u0001");
              if (!text) text = "(No answer)";

              return (
                <Tooltip
                  key={i}
                  title="Click to see list"
                  placement="right"
                  arrow
                >
                  <Card
                    onClick={() => {
                      history.push(
                        "/feedback/task/" +
                          s.id +
                          "/submission/" +
                          s.submission_id +
                          "/question_id/" +
                          index
                      );
                    }}
                    className={classes.answer}
                    variant="outlined"
                  >
                    <CardContent>
                      <Typography
                        className={classes.answerBody}
                        variant="body1"
                        component="p"
                      >
                        {text}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.answerActions}>
                      <Typography size="small">{s.student_name}</Typography>
                    </CardActions>
                  </Card>
                </Tooltip>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
