// Dependencies
import React, { useState } from "react";
import clsx from "clsx";

//Components
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Card,
  CardContent,
  Button,
  // LinearProgress,
} from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    statContainer: {
      padding: "32px",
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold",
    },
    questionTitle: {
      fontWeight: theme.typography.fontWeightBold,
    },
    findInTextContainer: {
      display: "flex",
      flexFlow: "row nowrap",
    },
    preformanceContainer: {
      flexBasis: "50%",
      padding: "32px",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },

    citationsContainer: {
      padding: "32px",
      flexBasis: "50%",
      display: "flex",
      flexFlow: "column wrap",
      justifyContent: "space-between",
    },
    citation: {
      cursor: "pointer",
      marginBottom: "32px",
    },
    citationBody: {
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": "10",
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis",
    },
    citationsActions: {
      textAlign: "end",
    },
    actionButton: {
      textTransform: "none",
    },

    // BELLOW IS THE STRUCTURE FOR A FUTURE CONCEPTS STATS FEATURE
    // // The whole box of concepts
    // conceptsContainer: {
    //   padding: "32px",
    //   flexBasis: "50%",
    // },
    // // Each indevidual concept
    // conceptContainer: {
    //   display: "flex",
    //   flexFlow: "row nowrap",
    //   marginBottom: "24px",
    //   "&:last-child": {
    //     marginBottom: "0",
    //   },
    // },

    // conceptPresantage: {
    //   fontFamily: `Helvetica, "sans-serif"`,
    //   fontSize: "36px",
    //   flexBasis: "20%",
    // },
    // barContainer: {
    //   fontSize: "20px",
    //   lineHeight: "1.6",
    // },
    // statBar: {
    //   height: "8px",
    //   backgroundColor: "#D8D8D8",
    //   "& .MuiLinearProgress-barColorPrimary": {
    //     backgroundColor: "#168FEE",
    //   },
    // },
  };
});

export default function FindInTextStats({
  submissions,
  question,
  index,
  task,
  text,
  ...props
}) {
  // Hooks
  const classes = useStyles();

  //Redux State

  // Ephemeral State
  const [openHeatmap, setOpenHeatmap] = useState(false);
  let teacherAnswer =
    submissions[0] &&
    submissions[0].answers &&
    submissions[0].answers.answers[index];

  // Helpers
  return (
    <>
      <Paper component="section" elevation={0}>
        <HeatMap
          totalCount={
            submissions.filter((submission) => submission.submission_date)
              .length
          }
          openDialog={openHeatmap}
          setOpenDialog={setOpenHeatmap}
          teacherHighlights={teacherAnswer.quotes}
          url={text.url}
          location={text.location}
          hlOpacity={1}
          hlColor="#4AA96C"
          highlights={submissions
            .filter(
              (submission) =>
                submission.submission_date && submission.student_answers
            )
            .map(
              (submission) => submission.student_answers.answers[index].quotes
            )
            .flat()}
          addHighlight={() => {}}
          removeHighlight={() => {}}
        />
        <Box component="header" className={classes.statContainer}>
          <Typography className={clsx(classes.questionTitle)} variant="h5">
            Question #{index + 1} — find in text
          </Typography>
          <Typography
            className={clsx(classes.questionSubtitle)}
            variant="h6"
            component="span"
          >
            {question.question}
          </Typography>
        </Box>
        <Divider />
        <Box className={clsx(classes.findInTextContainer)}>
          <Box className={clsx(classes.preformanceContainer)}>
            <CitationChart
              question={question}
              i={index}
              task={task}
              submissions={submissions}
            />
          </Box>
          <Box className={clsx(classes.citationsContainer)}>
            <Card
              onClick={() => {
                console.log("Open Citations");
              }}
              className={classes.citation}
              variant="outlined"
            >
              <CardContent>
                <Typography
                  className={classes.citationBody}
                  variant="body1"
                  component="p"
                >
                  {/* Showing the first citation */}
                  {task.answers.answers[index].quotes[0]?.text}
                </Typography>
              </CardContent>
            </Card>
            <Box className={classes.citationsActions}>
              <Button
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#168FEE",
                  color: "#FFFFFF",
                  padding: "4px 8px",
                }}
                className={classes.actionButton}
                onClick={() => setOpenHeatmap(true)}
              >
                Show heatmap
              </Button>
            </Box>
          </Box>

          {/* BELLOW IS THE STRUCTURE FOR A FUTURE CONCEPTS STATS FEATURE */}
          {/* <Box className={clsx(classes.conceptsContainer)}>
            <Box className={clsx(classes.conceptContainer)}>
              <Box className={classes.conceptPresantage} component="span">
                78%
              </Box>
              <Box className={classes.barContainer}>
                <Box component="span">
                  Included [this concept] in their passages
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={normalize(78, 0, 100)}
                  className={classes.statBar}
                />
              </Box>
            </Box>
            <Box className={clsx(classes.conceptContainer)}>
              <Box className={classes.conceptPresantage} component="span">
                67%
              </Box>
              <Box className={classes.barContainer}>
                <Box component="span">
                  Included [this concept] in their passages
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={normalize(67, 0, 100)}
                  className={classes.statBar}
                />
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Paper>
    </>
  );
}
