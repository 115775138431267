import React, {  useEffect } from "react";
import { logout } from "../../redux/userSlice";
import { firebaseApp } from "../../firebase";
import { useDispatch } from "react-redux";
import {
  useHistory,
} from "react-router-dom";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
const useStyles = makeStyles((theme) => {
  return {
    imgContainer: {
      height: "100vh",
      maxWidth: "calc(100vw - 250px)",
      marginLeft: "auto",
      marginRight: "auto",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage: 'url("/resources/landingImage.jpg")',
    },
    loginContainer: {
      width: "45%",
      height: "100%",
      position: "relative",
      // background:'white',
    },
    loginBox: {
      left: "50%",
      textAlign: "center",
      width: "324px",
      height: "637px",
      position: "absolute",
      top: "50%",
      "-ms-transform": "translate(-50%,-50%)",
      transform: "translate(-50%, -50%)",
    },
    svgStyle: {
      width: "48px",
      height: "48px",
      display: "block",
    },

    loginText: {
      verticalAlign: "middle",
      cursor: "pointer",
      display: "inline-block",
      width: "250px",
      height: "72px",
      color: "black",
      lineHeight: "72px",
      fontSize: "24px",
    },
    btnContainer: {
      cursor: "pointer",
      textAlign: "left",
      fontFamily: "Roboto,arial,sans-serif",
      backgroundColor: "#fff",
      color: "rgba(0,0,0,.54)",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "88px",

      width: "100%",
      height: "72px",
      borderRadius: "8px",
      borderColor: "#979797",
      borderWidth: "1px ",
      borderStyle: "solid ",
      "&:hover": {
        boxShadow: "0 0 3px 3px rgba(66,133,244,.3)",
        transition:
          "background-color .218s, border-color .218s, box-shadow .218s",
      },
    },
    googleIcon: {
      verticalAlign: "middle",
      display: "inline-block",
      textAlign: "left",
    },

    btn: {
      // borderRadius:'8px !important',
      //  borderColor:'#979797 !important',
      boxShadow: "none !important",
      // borderWidth:'1px !important',
      // borderStyle:'solid !important',
    },
  };
});

function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
  firebaseApp.auth().signOut().then(()=>{
    dispatch(logout());
    history.push("/");
  }).catch(err=> {
    console.error(err);
  })

   
  }, [history,dispatch]);

  return (
    <PangeaSpinner />
  );
}

export default Logout;
