// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { firebaseFunctions, firebaseApp } from "./firebase";
import { useHistory } from "react-router-dom";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "./redux/readerActionsSlice";
import { enqueueFlashMessage } from "./redux/userSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, Typography, Button } from "@material-ui/core";
import { ScrollBox } from "./components/SharedComponents";

//Styles
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  inner: {
    width: "50%",
    maxWidth: 600
  },
  header: {
    margin: "60px 0"
  },
  form: {
    flexFlow: "column nowrap",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(4)
    },
    "& .MuiFormHelperText-root": {
      marginInline: "0"
    }
  },
  halfWidth: {
    width: "55%"
  },
  uploadContainer: {
    display: "flex",
    marginBlockEnd: "32px",
    alignItems: "center"
  },
  uploadBtn: {
    marginInlineEnd: "16px"
  }
}));

export default function ConstactUs({ ...props }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const fileUploaderRef = useRef(null);

  // Ephemeral state
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validationMessages, setValidationMessages] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState([]);
  const [fileName, setFileName] = useState(null);

  // Redux State
  const userDisplayName = useSelector(state => state.user.auth.displayName);
  const userEmail = useSelector(state => state.user.auth.email);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // variables

  // Bahavior

  // set breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.contactUs",
        defaultMessage: "Contact us"
      })
    });

    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, [dispatch, intl]);

  // set default values for contact form
  useEffect(() => {
    if (userDisplayName) {
      setFullName(userDisplayName);
    }
  }, [userDisplayName]);

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [userEmail]);

  function handleSubmit() {
    if (validateForm()) {
      const func = firebaseFunctions.httpsCallable("courses-contactPangea");
      func({
        fullName: fullName,
        email: email,
        content: message,
        attachment: downloadUrl
      }).then(response => {
        if (response.data === "success") {
          dispatch(
            enqueueFlashMessage({
              message: intl.formatMessage({
                id: "contactUs.success",
                defaultMessage:
                  "Your message was sent. Thank you for contacting Pangea"
              }),
              duration: alertsDuration
            })
          );
          history.push("/");
        } else {
          dispatch(
            enqueueFlashMessage({
              message: intl.formatMessage({
                id: "contactUs.fail",
                defaultMessage:
                  "There was an problem sending you message. Please try again"
              }),
              severity: "error",
              duration: alertsDuration
            })
          );
        }
      });
    }
  }

  function validateForm() {
    setValidationMessages(null);
    let isValid = true;

    let newValidationMessage = {};

    // Validate name
    if (!fullName || fullName === "") {
      newValidationMessage.fullName = "Please enter your full name";
      isValid = false;
    }

    // Validate email
    if (!email || email === "") {
      newValidationMessage.email = "Please enter an email";
      isValid = false;
    } else if (!validateEmail(email)) {
      newValidationMessage.email = "Please enter a valid email address";
      isValid = false;
    }

    // Validate massage
    if (!message || message === "") {
      newValidationMessage.message = "Please enter a message";
      isValid = false;
    }

    setValidationMessages(newValidationMessage);
    return isValid;
  }

  function validateEmail(userInput) {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userInput
      )
    ) {
      return true;
    } else return false;
  }

  const handleUploadStart = () => {
    setUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = progress => {
    setUploadProgress(progress);
  };
  const handleUploadError = error => {
    setUploading(false);
    console.error(error);
  };

  const handleUploadSuccess = async filename => {
    const result = await firebaseApp
      .storage()
      .ref("contactPangeaAttachments/")
      .child(filename)
      .getDownloadURL();

    setDownloadUrl(result);
    setFileName(filename);
    setUploadProgress(100);
    setUploading(false);
  };

  // Render

  return (
    <ScrollBox className={classes.container}>
      <Box className={classes.inner}>
        <Typography component="h1" variant="h2" className={classes.header}>
          Contact us
        </Typography>
        <form autoComplete="off" className={classes.form}>
          <TextField
            id="fullName"
            className={classes.halfWidth}
            label="Full name"
            value={fullName}
            required
            error={validationMessages?.fullName}
            helperText={validationMessages?.fullName}
            onChange={e => setFullName(e.target.value)}
          />

          <TextField
            id="email"
            className={classes.halfWidth}
            label="E-mail"
            type="email"
            value={email}
            required
            error={validationMessages?.email}
            helperText={validationMessages?.email}
            onChange={e => setEmail(e.target.value)}
          />

          <TextField
            id="message"
            label="Message"
            rows={4}
            value={message}
            variant="outlined"
            multiline
            fullWidth
            required
            error={validationMessages?.message}
            helperText={validationMessages?.message}
            onChange={e => setMessage(e.target.value)}
          />
          <Box className={classes.uploadContainer}>
            <CustomUploadButton
              className={classes.uploadBtn}
              ref={fileUploaderRef}
              hidden
              accept="image/*"
              storageRef={firebaseApp
                .storage()
                .ref("contactPangeaAttachments/")}
              onUploadStart={handleUploadStart}
              onUploadError={handleUploadError}
              onUploadSuccess={handleUploadSuccess}
              onProgress={handleProgress}
            >
              <Button
                disableElevation
                variant="contained"
                color="primary"
                component="span"
                disabled={uploading && true}
              >
                Upload image
              </Button>
            </CustomUploadButton>
            {uploading && <span>{uploadProgress}%</span>}
            {downloadUrl && fileName && <span>{fileName}</span>}
          </Box>

          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleSubmit}
            disabled={uploading && true}
          >
            Submit
          </Button>
        </form>
      </Box>
    </ScrollBox>
  );
}
