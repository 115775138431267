import React from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

import { useSelector, useDispatch } from "react-redux";
import { updateTask } from "../../../redux/firebaseMiddleware";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    position: "relative",
    height: "100%",
  },
  paperContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)",
  },
  paper: {
    gridColumn: " 2 / 11 ",
    marginLeft: "56px",

    height: "max-content",
    position: "relative",
  },
  header: {
    marginTop: "40px",
    marginBottom: "24px",
    textAlign: "left",
    fontSize: "24px",
    color: "rgba(0,0,0,0.87)",
    paddingLeft: "60px",
  },
  pointsContainer: {
    position: "relative",
    minHeight: "56px",
    padding: "4px",
  },
  feedbackBtns: {
    position: "absolute",
    right: "8px",
  },
  dialogActions: {
    justifyContent: "left",
  },
  dialogActionsRtl: {
    justifyContent: "right",
  },
  dialogBtn: {
    position: "absolute",
    color: "#ffffff",
    right: "16px",
  },
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff",
  },
  commentContainer: {
    padding: "16px",
    fontSize: "20px",
  },
  themeView: {
    paddingTop: "64px",
    position: "absolute",
    // background: "#E8E8E8",
    zIndex: 1,
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
  },
  label: { padding: "10px 0" },
  radio: {
    "&$checked": {
      color: "#168fee",
    },
    "&.Mui-checked": {
      color: "#168fee",
    },
  },
  answerHeader: {
    fontSize: "0.75rem",
    marginBottom: "20px",
  },
}));

export function TaskMultiChoiceAnswer(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { index, question, task } = props;

  const taskAnswers = useSelector((state) => {
    let answers = state.task.answers;
    let answerAr = [];
    if (
      task.questions.questions &&
      task.questions.questions.length &&
      (!answers ||
        !answers.length ||
        answers.length !== task.questions.questions.length)
    ) {
      for (var i = 0; i < task.questions.questions.length; i++) {
        answerAr.push({});
      }
      return answerAr;
    } else return answers;
  });
  const updateTaskWithAnswer = (answer) => {
    //  console.log("updater task", answer);
    let newAnswers = [...taskAnswers];

    newAnswers[index] = answer;
    dispatch(
      updateTask({
        taskId: selectedTaskId,
        answers: newAnswers,
        selectedQuestion: index,
      })
    );
  };

  const selectedTaskId = useSelector((state) => state.task.selectedTaskId);
  //console.log("selectedTaskId", selectedTaskId);
  const answer = useSelector((state) => {
    if (
      state.task.answers &&
      typeof state.task.answers[index] !== "undefined"
    ) {
      // console.log("answer is ", state.task.answers[index]);
      return state.task.answers[index];
    } else {
      return { choice: -1, text: "", comment: 0, mode: "answer", quotes: [] };
    }
  });


  return (
    <Box className={classes.themeView}>
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Box className={classes.commentContainer}>
            <Typography className={classes.answerHeader}>
              <FormattedMessage
                id="task.answer.multiple.instructions"
                defaultMessage="Choose the correct answer"
              />
            </Typography>
            <RadioGroup
              name="answer"
              value={answer.choice}
              // className={clsx()}
              onChange={(e) =>
                updateTaskWithAnswer({ ...answer, choice: e.target.value })
              }
            >
              {question.options.map((opt, index) => {
                return (
                  <React.Fragment key={index}>
                    <FormControlLabel
                      className={classes.label}
                      value={index}
                      key={index}
                      control={
                        <Radio
                          className={clsx(classes.radio)}
                          checked={index === parseInt(answer.choice)}
                          disabled={
                            task.submission_status === "Submitted"
                              ? true
                              : false
                          }
                        />
                      }
                      label={opt}
                    />
                    <Divider />
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
