// Dependencies
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Redux
import { useSelector } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Chip } from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => ({
  chip: {
    // marginInline: "-8px",
  },
  chipLink: {
    cursor: "pointer",
  },
  submittedAndPending: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: "transparent",
  },
  missed: {
    backgroundColor: "#f9413e",
    color: "currentColor",
  },
  late: {
    backgroundColor: "#fcb63f",
  },
  pending: {
    border: "1px solid currentColor",
    backgroundColor: "transparent",
  },
  latePending: {
    border: "1px solid #fcb63f",
    backgroundColor: "transparent",
  },
  graded: {
    backgroundColor: "#0eac72",
  },
  sent: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary,
  },
  add: {
    border: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary,
  },
  reviewed: {
    backgroundColor: "#BDBDBD",
    color: "currentCollor",
  },
  chipContainer: {
    display: "flex",
    "& $chip": {
      marginInline: "4px",
    },
    "& $chip:first-of-type": {
      // marginInlineStart: "-8px",
    },
  },
}));

export default function TaskStatusChip({
  //   submission,
  owner,
  due_date,
  submission_date,
  accept_date,
  is_checked,
  userRole,
  submission_status,
  task_type,
  onClick,
  statusFor,
  clickable,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral State
  const [mainLabel, setMainLabel] = useState("");
  const [mainChipStyle, setMainChipStyle] = useState([]);
  const [color, setColor] = useState("");
  const [secondaryChipStyle, setSecondaryChipStyle] = useState(null);
  const [secondaryLabel, setSecondaryLabel] = useState(null);

  // Redux state
  const currentUser = useSelector((state) => state.user.auth.uid);

  // Variables
  const submitted = intl.formatMessage({
    id: "tasks.status.submitted",
    defaultMessage: "Submitted",
  });
  const late = intl.formatMessage({
    id: "tasks.status.late",
    defaultMessage: "Late",
  });
  const sentLate = intl.formatMessage({
    id: "tasks.status.late",
    defaultMessage: "Sent late",
  });

  const missed = intl.formatMessage({
    id: "tasks.status.missed",
    defaultMessage: "Missed",
  });
  const pending = intl.formatMessage({
    id: "tasks.status.pending",
    defaultMessage: "Pending",
  });

  let formattedDueDate = due_date && getDate(due_date).setHours(24);
  let formattedAcceptDate = accept_date && getDate(accept_date).setHours(24);
  let formattedSubmissionDate = submission_date && getDate(submission_date);
  // this is the absolute final submmission date
  let FinalSubmisisonDate = formattedAcceptDate
    ? formattedAcceptDate
    : formattedDueDate;

  const today = new Date();

  // Behavior

  // Set chip for standart tasks
  useEffect(() => {
    if (task_type !== "peerReview") {
      // submitted
      if (
        formattedSubmissionDate &&
        formattedSubmissionDate < (formattedAcceptDate || formattedDueDate)
      ) {
        setColor("secondary");
        setMainLabel(submitted);

        // Missed
      } else if (!formattedSubmissionDate && today > FinalSubmisisonDate) {
        setMainChipStyle(classes.missed);
        setMainLabel(missed);
      }

      // Late submission
      else if (
        userRole === "Teacher" &&
        formattedSubmissionDate &&
        (!formattedAcceptDate ||
          formattedSubmissionDate > formattedAcceptDate) &&
        formattedSubmissionDate > formattedDueDate
      ) {
        setMainChipStyle(classes.late);
        setMainLabel(late);
      }

      // Late Pending
      else if (
        formattedAcceptDate &&
        !formattedSubmissionDate &&
        today > formattedDueDate &&
        today < FinalSubmisisonDate
      ) {
        setMainChipStyle(classes.latePending);
        setMainLabel(pending);
      }
      // Pending
      else {
        setMainChipStyle(classes.pending);
        setMainLabel(pending);
      }
    }
  }, [
    FinalSubmisisonDate,
    formattedAcceptDate,
    formattedDueDate,
    formattedSubmissionDate,
    submission_status,
    task_type,
    today,
    due_date,
    submission_date,
    accept_date,
    is_checked,
    classes,
    late,
    missed,
    pending,
    submitted,
    userRole,
  ]);

  // Set Chip for Peer review - this logic is using the acceptence date as the deadline of the collaborator. This probably should change once the due dates in the DB are refactored and abstracted
  useEffect(() => {
    if (task_type === "peerReview") {
      // Submited reflection but pending response, teacher view only. Reflection was submitted by owner, response wasn't submitted by collaborator - This status was removed for now
      // if (
      //   userRole === "Teacher" &&
      //   submission_status === "pendingResponse" &&
      //   formattedSubmissionDate &&
      //   formattedSubmissionDate < formattedDueDate
      // ) {
      //   setMainChipStyle(classes.submittedAndPending);
      //   setMainLabel(submitted);
      // Submitted and reviews - task was submitted before due date
      if (
        statusFor !== "collaboration" &&
        (currentUser === owner || userRole === "Teacher") &&
        formattedSubmissionDate &&
        formattedSubmissionDate < formattedDueDate &&
        (submission_status === "Submitted" || submission_status === "Graded")
      ) {
        setColor("secondary");
        setMainLabel("Reviewed");

        // Submited - teacher view only. reflection/response was submitted before due date.
        // IMPORTANT: the response chip accept date is passed in as due date
      } else if (
        userRole === "Teacher" &&
        formattedSubmissionDate &&
        formattedSubmissionDate < formattedDueDate
      ) {
        setColor("secondary");
        setMainLabel(submitted);

        // Submitted - task was submitted before due date
      } else if (
        (userRole === "Student" &&
          //Reflection, calculate based on due date
          currentUser === owner &&
          formattedSubmissionDate &&
          formattedSubmissionDate < formattedDueDate) ||
        //Response calculate based on accept date
        (userRole === "Student" &&
          currentUser !== owner &&
          formattedSubmissionDate &&
          formattedSubmissionDate < formattedAcceptDate)
      ) {
        setColor("secondary");
        setMainLabel(submitted);

        // Missed - teacher view only. reflection/response was not submitted and time now is after the due date
        // IMPORTANT: the response chip accept date is passed in as due date
      } else if (
        userRole === "Teacher" &&
        ((!formattedSubmissionDate && today > formattedDueDate) ||
          formattedSubmissionDate > formattedDueDate)
      ) {
        setMainChipStyle(classes.missed);
        setMainLabel(missed);

        // Missed - task was not submitted and time now is after the due date
      } else if (
        //Reflection
        (userRole === "Student" &&
          !formattedSubmissionDate &&
          currentUser === owner &&
          today > formattedDueDate) ||
        //Response
        (userRole === "Student" &&
          !formattedSubmissionDate &&
          currentUser !== owner &&
          today > formattedAcceptDate)
      ) {
        setMainChipStyle(classes.missed);
        setMainLabel(missed);
      } else {
        setMainChipStyle(classes.pending);
        setMainLabel(pending);
      }
    }
  }, [
    currentUser,
    owner,
    FinalSubmisisonDate,
    formattedAcceptDate,
    formattedDueDate,
    formattedSubmissionDate,
    submission_status,
    task_type,
    today,
    due_date,
    submission_date,
    accept_date,
    is_checked,
    classes,
    late,
    missed,
    pending,
    submitted,
    userRole,
    sentLate,
  ]);

  function getDate(str) {
    let b = str.split(/\D/);
    let d = new Date(b[0], b[1] - 1, b[2]);
    return d;
  }

  // Render
  return (
    <Chip
      {...(color !== "" ? { color: color } : {})}
      className={clsx(classes.chip, mainChipStyle)}
      label={mainLabel}
      size="small"
      onClick={onClick}
      clickable={clickable}
    />
  );
}
