// Dependencies
import React, { useRef, useState } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@material-ui/core/styles";
import { useGetTheme } from "../../hooks";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { setThemeMode, selectTheme } from "../../redux/themeSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteFilledIcon from "@material-ui/icons/Delete";
import {
  IconButton,
  ListItemIcon,
  Typography,
  Menu,
  MenuItem
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  left: {
    textAlign: "left"
  },
  editBtn: {
    color: "#5ec891",
    visibility: "hidden",
    alignSelf: "flex-start"
  },
  selectedQuestionEditBtn: {
    color: "black"
  },
  right: {
    textAlign: "right"
  },
  leftDir: {
    textAlign: "left",
    direction: "ltr"
  },
  rightDir: {
    textAlign: "right",
    direction: "rtl"
  },
  listItem: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },
  colorBtnRtl: {
    marginRight: "8px",
    marginLeft: "40px"
  },
  colorBtnLtr: {
    marginRight: "40px",
    marginLeft: "8px"
  },

  barIcon: {
    color: theme.palette.grey[900]
  },

  menu: {},
  menuLtr: {
    justifyContent: "left",
    direction: "ltr",
    textAlign: "left"
  },
  menuRtl: {
    justifyContent: "right",
    direction: "rtl",
    textAlign: "right"
  },

  menuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px",
    "&:hover": {
      backgroundColor: theme.palette.background.hover
    }
  }
}));

export function EditMenuItem(props) {
  const classes = useStyles();
  // Render
  return (
    <MenuItem
      className={clsx(classes.menuItem, classes.left)}
      onClick={props.click}
    >
      {props.icon && (
        <ListItemIcon className={classes.listItem}>{props.icon}</ListItemIcon>
      )}
      <Typography variant="inherit">{props.message}</Typography>
    </MenuItem>
  );
}

export function EditMenu(props) {
  // Hooks
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const classes = useStyles();
  const ref = useRef();
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);

  // Ephemeral State
  const [anchorEl, setAnchorEl] = useState(false);

  // Render
  return (
    <React.Fragment>
      <IconButton
        ref={ref}
        className={clsx(classes.editBtn, {
          [classes.selectedQuestionEditBtn]: props.selected
        })}
        onClick={e => {
          setAnchorEl(ref.current);
          // setMousePos({  X: e.clientX - 2,Y: e.clientY - 4,} );
          e.stopPropagation();
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        direction={rtl ? "rtl" : "auto"}
        onClick={e => {
          setAnchorEl(false);
          e.stopPropagation();
        }}
        //anchorReference="anchorPosition"
        // anchorPosition={mousePos}
        //getContentAnchorEl={()=>{

        // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        //transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        classes={{ list: rtl ? classes.menuRtl : classes.menuLtr }}
        onClose={() => {
          // setMousePos({});
          setAnchorEl(null);
          props.handleClose && props.handleClose();
        }}
        // getContentAnchorEl={null}
        disableScrollLock={true}
        //anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        //transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        {props.children}
      </Menu>
    </React.Fragment>
  );
}

export default function QuestionEditMenu(props) {
  const theme = useGetTheme();

  return (
    <ThemeProvider theme={theme}>
      <EditMenu
        selected={props.selected}
        open={Boolean(props.open)}
        handleClose={props.handleClose}
        anchorPosition={{
          left: props.mouseX ? props.mouseX : 0,
          top: props.mouseY ? props.mouseY : 0
        }}
      >
        <EditMenuItem
          icon={<EditIcon />}
          message={<FormattedMessage id="actions.edit" defaultMessage="Edit" />}
          click={props.onEdit}
        />
        <EditMenuItem
          icon={<DeleteFilledIcon />}
          message={
            <FormattedMessage id="actions.delete" defaultMessage="Delete" />
          }
          click={props.onDelete}
        />
      </EditMenu>
    </ThemeProvider>
  );
}

export function ThemeEditMenu(props) {
  const dispatch = useDispatch();
  const selectedThemeId = useSelector(state => state.themes.selectedThemeId);
  const theme = useGetTheme({ alwase: "light" });

  return (
    <ThemeProvider theme={theme}>
      <EditMenu
        selected={selectedThemeId === props.theme.id}
        anchorPosition={{
          left: props.mouseX ? props.mouseX : 0,
          top: props.mouseY ? props.mouseY : 0
        }}
      >
        <EditMenuItem
          message={<FormattedMessage id="actions.edit" defaultMessage="Edit" />}
          click={e => {
            props.onEdit();
          }}
        />

        <EditMenuItem
          message={
            <FormattedMessage
              id="actions.theme.editNote"
              defaultMessage="Add Note"
            />
          }
          click={e => {
            dispatch(selectTheme(props.theme));
            props.handleClose();
            dispatch(setThemeMode("note"));
          }}
        />
        <EditMenuItem
          message={
            <FormattedMessage
              id="actions.theme.extended"
              defaultMessage="Extended View"
            />
          }
          click={e => {
            dispatch(selectTheme(props.theme));
            props.handleClose();
            dispatch(setThemeMode("extended"));
          }}
        />

        <EditMenuItem
          message={
            <FormattedMessage id="actions.delete" defaultMessage="Delete" />
          }
          click={props.onDelete}
        />
      </EditMenu>
    </ThemeProvider>
  );
}
