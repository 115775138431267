import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  emptyState: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  emptyStateInner: {
    width: 500,
    textAlign: "center",
    transform: "translateY(-180px)"
  },
  emptyStateTitle: {
    marginBottom: theme.spacing(1.5)
  },
  btn: {
    marginTop: theme.spacing(2)
  }
}));

function EmptyState() {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  let imgSrc;
  let title;
  let paragraph;
  // let btnTxt;
  // let btnClick;

  switch (grMode) {
    case "full":
      {
        switch (stage) {
          case 3:
            imgSrc = "logo192.png";
            title = "Focus your reading with answers";
            paragraph =
              "Your answer cards are saved and can be used as an index of the most important parts of the text answers to see them here";
            // btnTxt = "Add Answers";
            // btnClick = () => dispatch(setStage({ stage: 2 }));
            break;
          case 4:
            imgSrc = "logo192.png";
            title = "Test yourself For better results";
            paragraph =
              "Ask questions and find answers in previous steps to be able to test yourself and gain better control and long-term retention of the material";
            // btnTxt = "Add Questions";
            // btnClick = () => {};
            break;
          default:
            break;
        }
      }
      break;
    case "light":
      {
        switch (stage) {
          case 0:
            imgSrc = "logo192.png";
            title = "Use highlights for a better survey";
            paragraph =
              "Highlights let you go over the most important parts of the text and efficiently. Add highlights to see them here";
            // btnTxt = "Add Highlihts";
            // btnClick = () => {};
            break;
          case 1:
            imgSrc = "logo192.png";
            title = "Focus your reading with answers";
            paragraph =
              "Your answer cards are saved and can be used as an index of the most important parts of the text answers to see them here";
            // btnTxt = "Add Answers";
            // btnClick = () => {};
            break;
          case 2:
            imgSrc = "logo192.png";
            title = "Test yourself For better results";
            paragraph =
              "Ask questions and find answers in previous steps to be able to test yourself and gain better control and long-term retention of the material";
            // btnTxt = "Add Questions";
            // btnClick = () => {};
            break;
          default:
            break;
        }
      }
      break;
    default:
      break;
  }

  return (
    <Box className={classes.emptyState}>
      <Box className={classes.emptyStateInner}>
        <img src={`${process.env.PUBLIC_URL}/${imgSrc}`} />
        <Typography variant="h5" className={classes.emptyStateTitle}>
          {title}
        </Typography>
        <Typography>{paragraph}</Typography>
        {/* <Button
          disableElevation
          variant="contained"
          color="secondary"
          className={classes.btn}
          onClick={btnClick}
        >
          {btnTxt}
        </Button> */}
      </Box>
    </Box>
  );
}

export default EmptyState;
