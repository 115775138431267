// TODO: this should be made into a reusable component and moved to the layout level

// Dependencies
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { firebaseFunctions } from "../../../firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSubmittedStudentReflection } from "../../../redux/taskSlice";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import {
  setSubmitButtonClicked,
  setIsLoading
} from "../../../redux/readerActionsSlice";
import { removeActiveTaskPerText } from "../../../redux/firebaseMiddleware";
// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog
  reflectionModal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "start"
  },
  reflectionModalActions: {
    justifyContent: "end",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  button: { textTransform: "none" },
  buttonDiscard: {
    color: "#787877"
  }
}));

export default function PeerReviewReflectionModal({
  modalVisible,
  reflection,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  //Redux state
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const userDisplayName = useSelector(state => state.user.auth.displayName);
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );
  const task = useSelector(state => state.task.taskRow);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral state
  const [openDropdown, setOpenDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [reviewerId, setReviewerId] = useState(null);

  // Variables
  const loading = openDropdown && options.length === 0;

  //Behavior

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    // Get the students list
    let getCourseStudentsNamesAndIds = firebaseFunctions.httpsCallable(
      "courses-getOptionalCollaborators"
    );
    getCourseStudentsNamesAndIds({
      course: selectedCourse.id,
      task: task.id
    }).then(result => {
      if (active) {
        setOptions(result.data);
      }
    });

    return () => {
      active = false;
    };
  }, [selectedCourse.id, loading, task]);

  useEffect(() => {
    if (!openDropdown) {
      setOptions([]);
    }
  }, [openDropdown]);

  const closeModal = () => {
    dispatch(setSubmitButtonClicked(false));
    // props.setModalVisible(false);
  };

  function handleSubmitReflection() {
    dispatch(setIsLoading(true));
    dispatch(setSubmitButtonClicked(false));
    // props.setModalVisible(false);
    const submitReflection = firebaseFunctions.httpsCallable(
      "courses-submitPeerReviewReflection"
    );

    submitReflection({
      task: task.id,
      answers: reflection,
      taskName: task.name,

      // for notification
      reviewer: reviewerId,
      courseId: selectedCourse.id,
      courseName: selectedCourse.name,
      submissionId: task.submission_id,
      studentName: userDisplayName
    }).then(response => {
      dispatch(setSubmittedStudentReflection(task.id));
      dispatch(
        enqueueFlashMessage({
          message: "Reflection was shared successfully",
          duration: alertsDuration,
          undoButton: true
        })
      );
      dispatch(removeActiveTaskPerText({ textId: task.text_id }));
      dispatch(setIsLoading(false));
      history.push("/tasks/" + selectedCourse.id);
    });
  }

  // Render
  return (
    <>
      <div>
        <Dialog
          open={modalVisible}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          PaperProps={{
            className: classes.reflectionModal,
            style: {
              direction: rtl ? "rtl" : "ltr"
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="task.peerReview.modal.title"
              defaultMessage="Share with peer to get a review"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage
                id="task.peerReview.modal.content"
                defaultMessage="Choose peer to share with"
              />
            </DialogContentText>
            <Autocomplete
              id="select-reviewr"
              fullWidth
              onChange={(e, value) => setReviewerId(value?.uid)}
              open={openDropdown}
              onOpen={() => {
                setOpenDropdown(true);
              }}
              onClose={() => {
                setOpenDropdown(false);
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={option => option.name}
              options={options}
              loading={loading}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Peers"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions className={classes.reflectionModalActions}>
            <Button
              onClick={closeModal}
              className={classes.button}
              color="primary"
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              disabled={!reviewerId}
              className={classes.button}
              onClick={handleSubmitReflection}
              color="primary"
              variant="contained"
              disableElevation
            >
              <FormattedMessage id="task.share" defaultMessage="Share" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
