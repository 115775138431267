// Dependencies
import React, { useState, useEffect } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Paper,
  Divider,
  LinearProgress,
} from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    statContainer: {
      padding: "32px",
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold",
      lineHeight: "1",
    },
    statNum: {
      fontSize: "72px",
      lineHeight: "1.2",
      fontFamily: "Helvetica",
      color: "#168FEE",
    },
    statBar: {
      height: "8px",
      backgroundColor: "#D8D8D8",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#168FEE",
      },
    },
  };
});

export default function GeneraStats({
  task,
  text,
  submissions,
  generalStatsNumRef,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  // const generalStatsNumRef = useRef();
  const [participants, setParticipants] = useState(0);
  const [submitted, setSubmitted] = useState(0);
  const [average, setAverage] = useState(0);
  // Helpers
  // normalizing the value for the progress bars
  function normalize(value, min, max) {
    if (value - min === 0 || max - min === 0) {
      return 0;
    } else {
      return Math.round(((value - min) * 100) / (max - min));
    }
  }

  useEffect(() => {
    let rows = submissions;
    setParticipants(rows.length);

    //let deadline = task && new Date(task.accept_date || task.due_date);
    let submittedRows = rows.filter((a) => a.submission_date);
    setSubmitted(submittedRows.length);

    let graded = rows.filter((a) => a.is_checked);
    let sum = graded.map((a) => a.grade).reduce((total, num) => total + num, 0);
    let average = graded.length > 0 ? sum / graded.length : "-";
    let totalPoints = graded.length > 0 ? graded[0].totalpoints : 0;
    if (totalPoints === 0) {
      setAverage("-");
    }

    setAverage(totalPoints > 0 ? Math.round((average / totalPoints) * 100) : 0);
  }, [task, submissions]);
  return (
    <Paper elevation={0} ref={generalStatsNumRef}>
      <Box component="header">
        <Typography variant="h5" className={classes.generalStatsHeader}>
          General
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.statContainer}>
        <Typography variant="h6">Participants</Typography>
        <Typography className={classes.statNum}>{participants}</Typography>
        <LinearProgress
          variant="determinate"
          value={normalize(submitted, 0, participants)}
          className={classes.statBar}
        />
      </Box>

      <Divider />
      <Box className={classes.statContainer}>
        <Typography variant="h6">Grade average</Typography>
        <Typography className={classes.statNum}>{`${average}%`}</Typography>
        <LinearProgress
          variant="determinate"
          value={normalize(average !== "-" ? average : 100, 0, 100)}
          className={classes.statBar}
        />
      </Box>
    </Paper>
  );
}
