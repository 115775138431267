// Dependencies
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
//import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

//Styles
const useStyles = makeStyles((theme) => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "center",
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600",
    },
  },
  buttonDiscard: {
    color: "#787877",
  },
  button: { textTransform: "none" },
}));

export default function FeedbackSubmissionModal({
  grade,
  evalTitle,
  feedbackComment,
  showSubmissionDialog,
  setShowSubmissionDialog,
  openEval,
  setOpenEval,
  onSubmit,
  validationResult,
  submitFeedback,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Redux State
  const rtl = useSelector((state) => state.user.userProfile.rtl);

  // Ephemeral State

  // Behavior
  // useEffect(() => {
  // if (showSubmissionDialog) {
  // generateMessage();
  // }
  // }, [showSubmissionDialog]);

  const closeModal = () => {
    setShowSubmissionDialog(false);
    setOpenEval(true);
  };

  function generateMessage() {
    let output = "";

    // if (Number.isNaN(grade) || grade === "") {
   
    // if (evalTitle === "" && feedbackComment === "") {
    if (!validationResult.comment) {
      if (output !== "") {
        output += intl.formatMessage({
          id: "task.and",
          defaultMessage: ", and ",
        });
      }
      output += intl.formatMessage({
        id: "task.feedback.didntAddComment",
        defaultMessage: "you didn't add overall feedback",
      });
    }
    if (!validationResult.grade) {
      if (output !== "") {
        output += intl.formatMessage({
          id: "task.and",
          defaultMessage: " and ",
        });
      }
      output += intl.formatMessage({
        id: "task.feedback.score",
        defaultMessage: "score",
      });
    }
    if (output !== "") {
      output +=".<br/>"+ intl.formatMessage({
        id: "task.feedback.confirmation",
        defaultMessage: "Do you want to send the feedback?",
      });

      return output.charAt(0).toUpperCase() + output.slice(1);
    }
  }

  return (
    <>
      <div>
        <Dialog
          open={showSubmissionDialog}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: classes.modal,
            style: {
              direction: rtl ? "rtl" : "ltr",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="task.feedback.submit"
              defaultMessage="Send Feedback"
            />
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description"
            className={classes.dialogTxt}
            dangerouslySetInnerHTML={{ __html:  generateMessage()} }
            >
             
            </DialogContentText>
          
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={closeModal}
              className={clsx(classes.buttonDiscard, classes.button)}
            >
              <FormattedMessage id="stepBack" defaultMessage="Back" />
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                submitFeedback();
                setShowSubmissionDialog(false);
              }}
              color="primary"
              autoFocus
            >
              <FormattedMessage
                id="task.feedback.submit"
                defaultMessage="Send feedback"
              />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
