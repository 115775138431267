import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setOriginalUser, setProfile } from "../../../redux/userSlice";
import { auth, firebaseFunctions } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";

import {
  //Fab,
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  InputLabel,
  Select,
  DialogActions,
  DialogContent,
  MenuItem,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    color: "blue",
    textTransform: "underline",
  },
  // backgroundWhite: {
  // background:'white',
  // },
  container: {
    width: "100w",
    height: "100vh",
    // background:'white',
  },
  dialog: {
    backgroundColot: "red",
  },
  tableContainer: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor: "white",
    marginBottom: "24px",
  },
  tableHead: {
    fontWeight: "800",
  },
}));

function CourseUsers(props) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.userProfile);

  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [openUsers, setOpenUsers] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("Student");

  var addCourseUser = firebaseFunctions.httpsCallable(
    "adminFunctions-addCourseUser"
  );

  useEffect(() => {
    var getAllUsers = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourseUsers"
    );

    //   console.log("getting courses users");
    getAllUsers({ course: props.courseId }).then((response) => {
      // console.log("got courses ", response);
      setUsers(response.data);
    });
  }, [props.courseId]);

  const saveUser = () => {
    addCourseUser({
      email: email,
      course: props.courseId,
      name: name,
      role: role,
    }).then((response) => {
      //     console.log("created course retu val",response );
      if (response.data.length > 0)
        setUsers([
          ...users,
          { id: response.data[0].id, name: name, email: email, role: role },
        ]);
    });
    handleClose();
  };

  const handleClose = () => {
    setOpenUsers(false);
    setEmail("");
    setName("");
    setRole("Student");
  };

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openUsers}
        PaperProps={{
          style: {
            // backgroundColor: "white",
          },
        }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent className={classes.dialog}>
          <TextField
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="email"
            label="email"
            type="text"
            value={email}
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            autoFocus
            margin="dense"
            id="name"
            label="name"
            type="text"
            fullWidth
          />
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Role
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={role}
            displayEmpty
            className={classes.selectEmpty}
          >
            {["Student", "Teacher"].map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setRole(item);
                  }}
                  value={item}
                >
                  {" "}
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveUser} color="primary">
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns = [
    { field: "email", headerName: "Email", width: 200 }, // width: 70
    { field: "name", headerName: "Name", width: 150 }, //width: 130
    { field: "role", headerName: "Role", width: 100 }, //width: 130
  ];

  const impColumns = [
    {
      field: "last_active",
      headerName: "Last Active",
      type: "dateTime",
      width: 250,
    },
    {
      field: "course_user",
      headerName: "Impersondate",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <Typography
            className={classes.link}
            onClick={() => {
              dispatch(setProfile({ ...userProfile, selectedCourse: false }));
              var impersonateFunc = firebaseFunctions.httpsCallable(
                "adminFunctions-impersonate"
              );
              impersonateFunc({ uid: params.value }).then((response) => {
                if (response.data && response.data.length) {
                  auth.currentUser
                    .getIdTokenResult(true)
                    .then((idTokenResult) => {
                      dispatch(setOriginalUser(idTokenResult.token));
                      auth
                        .signInWithCustomToken(response.data)
                        .then((userCredential) => {
                          //    console.log("signed in with custom token, impersonating");
                          history.push("/");
                          // ...
                        });
                    });
                }
              });
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
  ];
  //

  const topUsers = [
    "gS7LsuEEP7OYp9ZjSEVOO0HLlIa2",
    "1bjgVIcI8dTpATOIZUEZPo3N8Dw1",
    "LaNTsYWwzyOuYHyeRESRVxvaPhL2",
  ];
  const usersSorted = [
    ...users.filter((u) => topUsers.includes(u.course_user)),
    ...users.filter((u) => !topUsers.includes(u.course_user)),
  ];

  const history = useHistory();
  return (
    <Box>
      <Typography>Users</Typography>
      <div style={{ height: "max-content", width: "90%" }}>
        <DataGrid
          autoHeight={true}
          rows={users}
          columns={columns}
          // className={classes.backgroundWhite}
          pageSize={50}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenUsers(true);
        }}
      >
        Add User
      </Button>

      <Typography>impersonate Users:</Typography>

      <div style={{ height: "max-content", width: "90%" }}>
        <DataGrid
          autoHeight={true}
          rows={usersSorted}
          columns={impColumns}
          className={classes.backgroundWhite}
          pageSize={50}
        />
      </div>

      {renderCreateCourseDialog()}
    </Box>
  );
}

export default CourseUsers;
