import React, { useState, useEffect, useRef, useCallback } from "react";
import ePub from "epubjs"; //,{ EpubCFI }
import { EpubView } from "..";
import defaultStyles from "./style";
import ReactReaderActions from "../../annotations/ReactReaderActions";
import { useResizeDetector } from "react-resize-detector";
import { Box } from "@material-ui/core";

import { getCommentsDict } from "../../../redux/commentsSlice";
import {
  updateHighlights,
  updateTask,
  updateThemes,
  updateSq3r,
  getNextId
} from "../../../redux/firebaseMiddleware";
import {
  setLocation,
  setClearSelection
} from "../../../redux/readerActionsSlice";
import { setLastLocation } from "../../../redux/textSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  openAnnotatorBar,
  closeAnnotatorBar
} from "../../../redux/highlightSlice";
import { setActiveComment } from "../../../redux/commentsSlice";
import { getHighlightColor } from "../../../utils/colors";
import {
  getHighlights,
  shouldShowHighlights
} from "../../../redux/highlightsGetter";
import { isTypedArray } from "lodash";
//import {setLocation} from '../../../redux/readerActionsSlice';
function ReactReader(props) {
  // const epubRef = React.createRef(null);
  const epubContainerRef = useRef(null);

  var EpubCFI = new ePub.CFI();

  const dispatch = useDispatch();
  const highlightColor = useSelector(
    state => state.readerActions.persistentActionState.highlightColor
  );

  const showHighlights = useSelector(shouldShowHighlights);

  //const actionBar = useSelector(state=> state.readerActions.actionBar);
  const clearSelection = useSelector(
    state => state.readerActions.clearSelection
  );
  const action = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const comments = useSelector(getCommentsDict);

  //const activeComment = useSelector(state=> state.comments.activeComment);
  const darkMode = useSelector(state => state.user.userProfile.darkMode);

  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const themes = useSelector(state => state.themes.themes);
  const selectedThemeId = useSelector(state => state.themes.selectedThemeId);
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  const taskAnswers = useSelector(state => state.task.answers);

  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);

  const fontSize = useSelector(state => state.user.fontSizeOptions);

  const shownLocation = useSelector(state => {
    return state.readerActions.shownLocation;
  });
  const [underlineElements, setUnderlineElements] = useState([]);
  const [underlineCommentElements, setUnderlineCommentElements] = useState([]);
  const [rendition, setRendition] = useState(false);
  const themeMode = useSelector(state => state.themes.themeMode);
  const selectedTextId = useSelector(state => {
    return state.texts.selectedTextId;
  });

  const commentRef = useSelector(state => state.comments.commentRef);
  const highlights = useSelector(state =>
    getHighlights(state, props.task, showHighlights)
  );

  const textLocation = useSelector(state => {
    return state.readerActions.selectedLocation;
  });

  const selectedQuestion = useSelector(state => {
    let filtered =
      state.gr.questions &&
      state.gr.questions.filter &&
      state.gr.questions.filter(q => q.id === state.gr.selectedQuestionId);
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });

  const questions = useSelector(state => state.gr.questions);
  const grHighlights = useSelector(state => state.gr.highlights);

  //Effects

  useEffect(() => {
    let cb = (range, shownCfi, highlightColl) => e => {
      let contents = rendition.getContents();

      contents.forEach(content => {
        if (range) {
          let pos = rendition.getContents()[0].locationOf(shownCfi);
          if (pos.x !== 0 || pos.y !== 0 || pos.left) {
            let updatedRange = rendition.getRange(shownCfi);

            let clientRect = updatedRange.getBoundingClientRect();
            const frameBounds =
              content.document.defaultView.frameElement.getBoundingClientRect();
            // `target` can be an element or a range
            let newRect = {
              x: clientRect.x + frameBounds.x,
              y:
                clientRect.y + frameBounds.y > 60
                  ? clientRect.y + frameBounds.y
                  : 60,
              width: clientRect.width,
              height: clientRect.height,
              top:
                clientRect.top + frameBounds.y > 60
                  ? clientRect.top + frameBounds.y
                  : 60,
              left: clientRect.left + frameBounds.x,
              bottom: clientRect.top + frameBounds.y + clientRect.height,
              right: clientRect.left + frameBounds.x + clientRect.width
            };
            if (!props.readOnly) {
              dispatch(
                openAnnotatorBar({
                  clientRectangle: newRect,
                  selectedText: highlightColl[0],
                  isHover: true,
                  minimal: highlightColl[0].minimal
                })
              );
            }
          }
        }
      });
    };

    if (rendition) {
      let items = underlineElements;
      let removed = false;
      let shownCfi = shownLocation && shownLocation.cfi;
      Object.entries(items).forEach(([cfi, elem]) => {
        if (shownCfi != cfi) {
          rendition.annotations.remove(cfi, "underline");

          removed = true;
        }
      });
      items = [];
      if (shownLocation && !(shownCfi in underlineElements)) {
        //putting the underline in timeout so it will be shown above the highlight
        setTimeout(() => {
          let highlightColl = highlights.filter(el => el.cfi === shownCfi);

          if (highlightColl && highlightColl.length) {
            let range = rendition.getRange(shownCfi);

            let addedHighlight = rendition.annotations.underline(
              shownCfi,
              {},
              cb(range, shownCfi, highlightColl),
              undefined,
              { stroke: "none", "z-index": 12 }
            );
            items[shownCfi] = addedHighlight;

            setUnderlineElements(items);
          }
        }, 100);
      } else if (removed) {
        setUnderlineElements([]);
      }
    }
  }, [shownLocation, underlineElements, highlights, rendition]);

  const [highlightsElements, setHighlightsElements] = useState([]);

  //clear selection in book when an action was chosen in reader aciton
  useEffect(() => {
    let content = rendition && rendition.getContents()[0];
    let selection = content && content.window && content.window.getSelection();
    if (selection && selection.empty && clearSelection) {
      selection.empty();
      dispatch(setClearSelection(false));
    }
  }, [rendition, clearSelection]);

  //focus on displayed location
  useEffect(() => {
    if (textLocation && rendition) {
      if (textLocation.cfi) {
        rendition.display(textLocation.cfi);
        //dispatch(setLocation(false));
      }
      // dispatch(setLocation(false));
    }
  }, [textLocation, rendition]);

  const rendManager = rendition && rendition.manager;

  //Effect for comments
  useEffect(() => {
    const setExistingCommentElements = items => {
      let toRemove = [];
      underlineCommentElements &&
        Object.entries(underlineCommentElements).forEach(([cfi, elem]) => {
          let commentEntry = comments[cfi];

          if (commentEntry) {
            // elem && elem.mark && elem.mark.element && (elem.mark.element.style.stroke = commentEntry[0].ref.color);
          } else {
            toRemove.push(cfi);
            rendition.annotations.remove(cfi, "underline");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingComments = items => {
      let timeAdding = new Date().getTime();
      comments &&
        rendition &&
        rendition.manager &&
        Object.entries(comments).forEach(([cfi, comment]) => {
          let range = rendition.getRange(cfi);
          if (range && !(cfi in items)) {
            let hlStyleObj = {
              stroke: "none",
              underlineColor: "#168FEE",
              "z-index": 12,
              "stroke-opacity": 1
            };
            let addedComment = rendition.annotations.underline(
              cfi,
              {},
              e => {
                let timeClick = new Date().getTime();
                if (timeClick - timeAdding < 500) return;
                let contents = rendition.getContents();
                let foundSelection = false;
                contents.forEach(content => {
                  if (
                    content.window &&
                    content.window.getSelection() &&
                    content.window.getSelection().type === "Range"
                  ) {
                    foundSelection = true;
                  }
                });

                if (!foundSelection) {
                  dispatch(setActiveComment(comment));
                }
              },
              "underlineClass",
              hlStyleObj
            );

            items[cfi] = { ...addedComment, comment: comment };
            //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

            addedComment.mark.element.style.cursor = "pointer";
            addedComment.mark.element.style.pointerEvents = "none";
          }
        });
      return items;
    };

    const renderComments = () => {
      let items = underlineCommentElements;

      items = setExistingCommentElements(items);
      //add new highlighyts
      items = addMissingComments(items);

      setUnderlineCommentElements(items);
    };
    renderComments();
  }, [
    comments,
    rendition,
    rendManager,
    annotatorMode,
    underlineCommentElements,
    dispatch
  ]);

  //end effect for comments
  useEffect(() => {
    const setExistingElements = items => {
      let toRemove = [];
      highlightsElements &&
        Object.entries(highlightsElements).forEach(([cfi, elem]) => {
          let highlightColl =
            highlights &&
            highlights.filter &&
            highlights.filter(hl => elem.highlight.cfi === hl.cfi);
          if (highlightColl && highlightColl[0]) {
            elem &&
              elem.mark &&
              elem.mark.element &&
              (elem.mark.element.style.fill = getHighlightColor(
                highlightColl[0].color,
                darkMode
              ));
          } else {
            toRemove.push(elem.highlight.cfi);
            rendition.annotations.remove(elem.highlight.cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingHighlights = items => {
      let timeAdding = new Date().getTime();

      highlights &&
        rendition &&
        rendition.manager &&
        highlights.forEach(highlight => {
          try {
            let range = highlight.cfi && rendition.getRange(highlight.cfi);
            if (range && !(highlight.cfi in items)) {
              let hlStyleObj = {
                "z-index": 10,
                "mix-blend-mode": "multiply",
                "fill-opacity": 0.8,
                fill: getHighlightColor(highlight.color, darkMode)
              };
              let addedHighlight = rendition.annotations.highlight(
                highlight.cfi,
                {},
                e => {
                  let timeClick = new Date().getTime();
                  if (timeClick - timeAdding < 500) return;
                  let contents = rendition.getContents();

                  contents.forEach(content => {
                    if (range) {
                      let pos = rendition
                        .getContents()[0]
                        .locationOf(highlight.cfi);
                      if (pos.x !== 0 || pos.y !== 0 || pos.left) {
                        let updatedRange = rendition.getRange(highlight.cfi);

                        let clientRect = updatedRange.getBoundingClientRect();
                        const frameBounds =
                          content.document.defaultView.frameElement.getBoundingClientRect();
                        // `target` can be an element or a range
                        let newRect = {
                          x: clientRect.x + frameBounds.x,
                          y:
                            clientRect.y + frameBounds.y > 60
                              ? clientRect.y + frameBounds.y
                              : 60,
                          width: clientRect.width,
                          height: clientRect.height,
                          top:
                            clientRect.top + frameBounds.y > 60
                              ? clientRect.top + frameBounds.y
                              : 60,
                          left: clientRect.left + frameBounds.x,
                          bottom:
                            clientRect.top + frameBounds.y + clientRect.height,
                          right:
                            clientRect.left + frameBounds.x + clientRect.width
                        };
                        if (!props.readOnly) {
                          dispatch(
                            openAnnotatorBar({
                              clientRectangle: newRect,
                              selectedText: highlight,
                              isHover: true,
                              minimal: highlight.minimal
                            })
                          );
                        }
                      }
                    }
                  });
                },
                "highlightClass",
                hlStyleObj
              );
              items[highlight.cfi] = {
                ...addedHighlight,
                highlight: highlight
              };
              //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";
            }
          } catch (error) {
            console.log("error adding highlight", error);
          }
        });
      return items;
    };

    const renderHighlights = () => {
      if (
        !showHighlights &&
        annotatorMode !== "poc" &&
        highlightsElements &&
        highlightsElements.length
      ) {
        //hide all highlights in some case
        highlights &&
          highlights.forEach(highlight => {
            rendition.annotations.remove(highlight.cfi, "highlight");
          });
        setHighlightsElements([]);
      } else {
        let items = highlightsElements;

        items = setExistingElements(items);
        //add new highlighyts
        items = addMissingHighlights(items);

        setHighlightsElements(items);
      }
    };
    rendition && rendition.book && renderHighlights();
  }, [
    highlights,
    showHighlights,
    rendition,
    rendManager,
    annotatorMode,
    highlightsElements,
    dispatch,
    darkMode
  ]);

  const addTextToTheme = params => {
    if (!selectedThemeId) {
      return;
    }
    let modifiedThemes = themes.map(elem => {
      if (elem.id === selectedThemeId) {
        let quotes = elem.quotes ? elem.quotes : [];
        let highlight = {
          id: getNextId(quotes),
          cfi: params.cfi,
          source: "theme",
          text: params.text,
          ref: "",
          color: params.color
        };
        let otherThemes = themes.filter(elem => elem.id !== selectedThemeId);
        let newQuotes = [...quotes];
        if (
          newQuotes.filter(quote => quote.cfi === highlight.cfi).length === 0
        ) {
          newQuotes.push(highlight);
        }
        return { ...elem, quotes: newQuotes };
      } else return elem;
    });
    dispatch(updateThemes({ textId: selectedTextId, themes: modifiedThemes }));
  };

  const addTextToQuestion = params => {
    let items = selectedQuestion.answers
      ? selectedQuestion.answers.filter(a => a.cfi !== params.cfi)
      : [];
    let answers = [...items];
    let i = 0;
    let insertAt = answers.length;
    for (i = 0; i < answers.length; i++) {
      if (EpubCFI.compare(answers[i].cfi, params.cfi) > 0) {
        insertAt = i;
        break;
      }
      //Do something
    }
    answers.splice(insertAt, 0, {
      ...params,
      id: getNextId(answers),
      source: "grQuestion"
    });
    let question = { ...selectedQuestion, answers: answers };

    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: questions.map(el => {
          if (el.id === question.id) return question;
          else return el;
        }),
        highlights: grHighlights
      })
    );
  };

  const onTextSelected = params => {
    if (props.readOnly) {
      return;
    }
    if (params.shouldClose) {
      dispatch(closeAnnotatorBar());
    } else {
      if (action === "task" && props.task && props.task.id > 0) {
        let answers = [...taskAnswers];

        if (
          typeof answers[selectedQuestionIndex] === "undefined" ||
          !answers[selectedQuestionIndex].quotes
        ) {
          answers[selectedQuestionIndex] = { quotes: [] };
        }
        answers[selectedQuestionIndex] = {
          ...answers[selectedQuestionIndex],
          quotes: [
            ...answers[selectedQuestionIndex].quotes,
            {
              ...params.selection,
              id: getNextId(answers[selectedQuestionIndex].quotes),
              minimal: true,
              color: "#00B9FB"
            }
          ]
        };
        dispatch(
          updateTask({
            taskId: props.task.id,
            answers: answers,
            selectedQuestion: selectedQuestionIndex
          })
        );
        dispatch(setClearSelection(true));
        return;
      }
      if (annotatorMode === "highlight") {
        addHighlightToBook(params.selection);
        let content = rendition && rendition.getContents()[0];

        dispatch(setClearSelection(true));
      } else if (action === "themes" && annotatorMode === "themes") {
        addTextToTheme({ ...params.selection, color: highlightColor });
        dispatch(setClearSelection(true));
      } else if (action === "poc" && annotatorMode === "poc") {
        if (
          ((grMode === "full" && stage === 2) ||
            (grMode === "light" && stage === 1)) &&
          selectedQuestion
        ) {
          addTextToQuestion({ ...params.selection, color: highlightColor });
          dispatch(setClearSelection(true));
        } else if (stage === 0) {
          let updatedGrHighlights = [
            ...grHighlights.filter(el => el.cfi !== params.selection.cfi)
          ];
          let i = 0;
          let insertAt = updatedGrHighlights.length;
          for (i = 0; i < updatedGrHighlights.length; i++) {
            if (
              EpubCFI.compare(
                updatedGrHighlights[i].cfi,
                params.selection.cfi
              ) > 0
            ) {
              insertAt = i;
              break;
            }
          }
          updatedGrHighlights.splice(insertAt, 0, {
            ...params.selection,
            source: "poc",
            id: getNextId(grHighlights),
            color: highlightColor
          });
          dispatch(
            updateSq3r({
              textId: selectedTextId,
              questions: questions,
              highlights: updatedGrHighlights
            })
          );
          dispatch(setClearSelection(true));
        } else {
          if (params.selection && params.selection.text) {
            dispatch(
              openAnnotatorBar({
                clientRectangle: params.clientRect,
                selectedText: params.selection
              })
            );
          }
        }
      } else if (!annotatorMode) {
        if (params.selection && params.selection.text) {
          dispatch(
            openAnnotatorBar({
              clientRectangle: params.clientRect,
              selectedText: params.selection
            })
          );
        }
      }
    }
  };

  const onRenditionCreated = rendition => {
    setHighlightsElements([]);
    setUnderlineElements([]);
    setUnderlineCommentElements([]);
    setRendition(rendition);
    props.renditionCreated && props.renditionCreated(rendition);
  };

  const addHighlightToBook = (highlight, color, shouldDelete = false) => {
    if (annotatorMode === "poc") {
      let updatedGrHighlights = [
        ...grHighlights.filter(el => el.cfi !== highlight.cfi),
        {
          ...highlight,
          source: "poc",
          color: highlightColor
        }
      ];
      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: questions,
          highlights: updatedGrHighlights
        })
      );
    } else {
      let adjustedHighlightColor = color ? color : highlightColor;

      let adjustedHighlight = {
        ...highlight,
        source: "highlight",
        color: adjustedHighlightColor
      };
      let highlightColl = highlights.filter(
        el => el.source === "highlight" && el.cfi !== highlight.cfi
      );
      if (!shouldDelete) {
        highlightColl.push(adjustedHighlight);
      }

      dispatch(
        updateHighlights({ textId: selectedTextId, highlights: highlightColl })
      );
    }
  };

  const setHighlightColorCb = (highlight, color) => {
    if (highlight.source === "poc" && annotatorMode === "poc") {
      let updatedGrHighlights = [
        ...grHighlights.filter(el => el.cfi !== highlight.cfi),
        {
          ...highlight,
          source: "poc",
          color: color
        }
      ];
      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: questions,
          highlights: updatedGrHighlights
        })
      );
    } else if (highlight.source === "grQuestion" && annotatorMode === "poc") {
      let item = selectedQuestion.answers.filter(
        a => a.cfi === highlight.cfi
      )[0];
      let answers = [
        ...selectedQuestion.answers.filter(a => a.cfi !== highlight.cfi),
        { ...item, color: color }
      ];
      let question = { ...selectedQuestion, answers: answers };

      let updatedQuestions = questions.map(q => {
        if (q.id === selectedQuestion.id) {
          return question;
        } else return q;
      });

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: updatedQuestions,
          highlights: grHighlights
        })
      );
    } else if (annotatorMode === "themes") {
      //get the current selected theme
      const selectedTheme = themes.find(theme => theme.id === selectedThemeId);

      // update the selected qoute with the selected color
      const updatedQuotes = selectedTheme.quotes.map(quote => {
        if (quote.cfi === highlight.cfi) {
          return { ...quote, color: color };
        } else {
          return quote;
        }
      });

      // clone themes, replacing the updated quotes
      const updatedThemes = themes.map(theme => {
        if (theme.id === selectedThemeId) {
          return { ...theme, quotes: updatedQuotes };
        } else {
          return theme;
        }
      });

      // update Redux store
      dispatch(
        updateThemes({
          textId: selectedTextId,
          themes: updatedThemes
        })
      );
    } else addHighlightToBook(highlight, color);
  };

  React.useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  const removeHighlightcb = highlight => {
    const toggleBookHighlight =
      ((grMode === "full" && stage < 2) || (grMode === "light" && stage < 1)) &&
      annotatorMode === "poc";
    const toggleAnswerHighlight =
      ((grMode === "full" && stage === 2) ||
        (grMode === "light" && stage === 1)) &&
      annotatorMode === "poc" &&
      selectedQuestion;

    let filtered = highlights.filter(el => el.cfi === highlight.cfi);
    if (filtered && filtered.length) {
      if (props.task && props.task.id > 0) {
        let answers = [...taskAnswers];
        if (typeof answers[selectedQuestionIndex] === "undefined") {
          answers[selectedQuestionIndex] = [];
        }
        answers[selectedQuestionIndex] = {
          ...answers[selectedQuestionIndex],
          quotes: answers[selectedQuestionIndex].quotes.filter(
            el => el.cfi !== highlight.cfi
          )
        };
        dispatch(
          updateTask({
            taskId: props.task.id,
            answers: answers,
            selectedQuestion: selectedQuestionIndex
          })
        );
      }

      if (toggleBookHighlight) {
        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: questions,
            highlights: grHighlights.filter(el => el.cfi !== highlight.cfi)
          })
        );
      } else if (toggleAnswerHighlight) {
        let answers = selectedQuestion.answers.filter(
          a => a.cfi !== highlight.cfi
        );
        let question = { ...selectedQuestion, answers: answers };

        let updatedQuestions = questions.map(el => {
          if (el.id !== selectedQuestion.id) return el;
          else return question;
        });
        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: updatedQuestions,
            highlights: grHighlights
          })
        );
      }

      if (annotatorMode === "themes") {
        //get the current selected theme
        const selectedTheme = themes.find(
          theme => theme.id === selectedThemeId
        );
        // filter out the selected highlight from the quots array
        const filteredQuotes = highlights.filter(
          el => el.cfi !== highlight.cfi
        );

        // clone themes, leaving out the filltered quote from the selected theme
        const updatedThemes = themes.map(theme => {
          if (theme.id === selectedThemeId) {
            return { ...theme, quotes: filteredQuotes };
          } else {
            return theme;
          }
        });

        // update Redux store
        dispatch(
          updateThemes({
            textId: selectedTextId,
            themes: updatedThemes
          })
        );
      }
      if (highlight.source === "highlight") {
        dispatch(
          updateHighlights({
            textId: selectedTextId,
            highlights: highlights.filter(el => el.cfi !== highlight.cfi)
          })
        );
      }
    }
    //else
    //addHighlightToBook(highlight,"red", true);
  };

  const { loadingView, locationChanged, readOnly } = props;

  //1. resize based on action (because view container size may have changed, epub.js doesn't handle gracefully)
  // useEffect(() => {
  //   const removeAnnotations = (elemenets, elementSetter, annotationType) => {
  //     elemenets &&
  //       Object.entries(elemenets).forEach(([cfi, elem]) => {
  //         rendition.annotations.remove(cfi, annotationType);
  //       });
  //     elementSetter([]);
  //   };
  // if (rendition) {

  //     //clear annotations
  //     removeAnnotations(highlightsElements, setHighlightsElements, "highlight");
  //     removeAnnotations(
  //       underlineCommentElements,
  //       setUnderlineCommentElements,
  //       "underline"
  //     );
  //     removeAnnotations(
  //       underlineElements,
  //       setUnderlineCommentElements,
  //       "underline"
  //     );

  //     rendition.resize();
  // }
  //   // adding rendition to dependency array messes up the rendering
  // }, [action]);

  const onResize = useCallback(() => {
    const removeAnnotations = (elemenets, elementSetter, annotationType) => {
      elemenets &&
        Object.entries(elemenets).forEach(([cfi, elem]) => {
          rendition.annotations.remove(cfi, annotationType);
        });
      elementSetter([]);
    };
    if (rendition) {
      console.log("resizering");
      //clear annotations
      removeAnnotations(highlightsElements, setHighlightsElements, "highlight");
      removeAnnotations(
        underlineCommentElements,
        setUnderlineCommentElements,
        "underline"
      );
      removeAnnotations(
        underlineElements,
        setUnderlineCommentElements,
        "underline"
      );
      rendition.resize();
    }
  }, [
    rendition,
    highlightsElements,
    setHighlightsElements,
    underlineCommentElements,
    setUnderlineCommentElements,
    underlineElements,
    setUnderlineCommentElements
  ]);

  const { width, height, ref } = useResizeDetector({
    handleHeight: false,
    skipOnMount: true,
    onResize
  });

  const rtlValue = useSelector(state => state.user.userProfile.rtl);

  return (
    <Box style={{ height: "100%" }} ref={ref}>
      <ReactReaderActions
        id="reactReaderActions"
        minimal={props.minimalBar}
        highlightFunc={addHighlightToBook}
        removeHighlight={removeHighlightcb}
        setHighlightColor={setHighlightColorCb}
      >
        <EpubView
          // ref={epubRef}
          fullSize={true}
          fontSize={fontSize[fontSizeValue]}
          epubOptions={{ flow: "scrolled-doc" }}
          loadingView={loadingView}
          darkMode={darkMode}
          rtl={rtlValue}
          {...props}
          locationChanged={locationChanged}
          handleTextSelected={onTextSelected}
          onRenditionCreated={onRenditionCreated}
          bodyClassName="gr"
        />
      </ReactReaderActions>
    </Box>
  );
}

export default ReactReader;
