import React, { useEffect, useState } from "react";
import {
    Box,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    DialogActions
  } from "@material-ui/core";
  import "offline-js";

  window.Offline.options = {
    interceptRequests: false,
    requests: false,
    reconnect: {
      // How many seconds should we wait before rechecking.
      initialDelay: 3,
  
      // How long should we wait between retries.
      delay: 5
    }
  };
export function OfflineDialog({isOffline, setIsOffline}) {
      useEffect(() => {
    let stateCheck = () => {
      if (window.Offline.state === "up" && isOffline) {
        setIsOffline(false);
      }
      if (window.Offline.state === "down" && !isOffline) {
        setIsOffline(true);
      }
    };
    window.Offline.on("up", stateCheck);
    window.Offline.on("down", stateCheck);
    stateCheck();
    return () => {
      window.Offline.off("up", stateCheck);
      window.Offline.off("down", stateCheck);
    };
  }, [setIsOffline, isOffline]);

    return (
      <Dialog open={isOffline} onClose={() => {}}>
        <DialogTitle>Connection Error</DialogTitle>
        <DialogContent>
          <Typography>Pangea must have an internet connection,</Typography>
          <Typography>
            lost connection to server, this message will disappear when
            connection is resumed
          </Typography>
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
  

}