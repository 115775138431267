// Dependencies
import React from "react";

// Redux dependencies
import { useSelector } from "react-redux";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "start"
  },

  modalActions: {
    justifyContent: "end",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },

  button: { textTransform: "none" }
}));

export default function PeerReviewModalMissingReviewModal({
  modalVisible,
  setModalVisible,
  ...props
}) {
  //Hooks
  const classes = useStyles();

  //Redux state
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const task = useSelector(state => state.task.taskRow);

  //Ephemeral state

  // Variables

  //Behavior
  const closeModal = () => {
    // dispatch(setSubmitButtonClicked(false));
    props.setModalVisible(false);
  };

  // Render
  if (task.submission_status === "pendingResponse") {
    return (
      <>
        <div>
          <Dialog
            open={modalVisible}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            PaperProps={{
              className: classes.modal,
              style: {
                direction: rtl ? "rtl" : "ltr"
              }
            }}>
            <DialogTitle id="alert-dialog-title">Missing Review</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`${task.collaborator_name} hasn’t submitted their review yet. Once they do you will be able to share your feedback. `}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.modalActions}>
              <Button
                className={classes.button}
                onClick={() => {
                  setModalVisible(false);
                }}
                color="primary"
                variant="contained"
                disableElevation>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  } else return null;
}
