import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getGlobalTheme,
  getGlobalDarkTheme,
  getHebrewTheme,
  getHebrewDarkTheme
} from "../theme";

export default function useGetTheme({ alwase } = {}) {
  const locale = useSelector(state => state.user.userProfile.language);
  const isDarkMode = useSelector(state => state.user.userProfile.darkMode);
  const [theme, setTheme] = useState(getGlobalTheme());

  useEffect(() => {
    switch (alwase) {
      case "light": {
        if (locale === "en") {
          setTheme(getGlobalTheme());
        } else {
          setTheme(getHebrewTheme());
        }
        break;
      }
      case "dark": {
        if (locale === "en") {
          setTheme(getGlobalDarkTheme());
        } else {
          setTheme(getHebrewDarkTheme());
        }
        break;
      }
      default: {
        if (locale === "en" && !isDarkMode) {
          setTheme(getGlobalTheme());
        } else if (locale === "en" && isDarkMode) {
          setTheme(getGlobalDarkTheme());
        } else if (locale === "he" && !isDarkMode) {
          setTheme(getHebrewTheme());
        } else if (locale === "he" && isDarkMode) {
          setTheme(getHebrewDarkTheme());
        } else {
          setTheme(getGlobalTheme());
        }
      }
    }
  }, [locale, isDarkMode]);

  return theme;
}
