// Dependencies
import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { useParams, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDocument } from "react-firebase-hooks/firestore";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  setBreadcrumbs,
  setSubmitButtonClicked,
  setIsLoading
} from "../../../redux/readerActionsSlice";
import {
  setSubmittedStudentReply,
  setSelectedTaskRow
} from "../../../redux/taskSlice";
import { enqueueFlashMessage } from "../../../redux/userSlice";

// Components
import { firebaseFunctions, firestore } from "../../../firebase";
import RichTextEditor from "../../SharedComponents/RichTextEditor";
import PeerReviewTabs from "./PeerReviewTabs";
import PeerReviewTabPanels from "./PeerReviewTabPanels";
import PeerReviewResponseModal from "./PeerReviewResponseModal";
import PeerReviewReply from "./PeerReviewReply";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import ScrollBox from "../../SharedComponents/ScrollBox";
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";

// Material UI
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tooltip, IconButton, Grid } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  tabs: {
    width: "100%",
    position: "sticky",
    top: "0px",
    zIndex: 2
  },
  grid: {
    height: "calc(100% - 50px)", //substruct tabs
    columnGap: 16,
    flexWrap: "nowrap"
  },

  textContainer: {
    position: "Relative",
    paddingInlineStart: theme.spacing(5)
  },

  responsesContainer: {
    // paddingBlock: "24px",
    // paddingInlineEnd: "16px",
    height: "100%"
  },

  responseContainerExpanded: {
    margin: "0 auto",
    paddingInline: "0"
  },

  editorActive: {
    paddingBlock: theme.spacing(3),
    paddingInlineEnd: theme.spacing(5),
    height: "100%",
    "& $responseHeader": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.main,
      paddingBlockStart: theme.spacing(1.5),
      paddingInline: theme.spacing(3),
      height: theme.spacing(9)
    },
    "& $userInput": {
      height: `calc(100% - ${theme.spacing(9)}px)`
    }
  },

  responses: {
    color: theme.palette.text.primary,
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    lineHeight: "2"
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`
  },

  responseHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    paddingBlock: theme.spacing(1.5),
    paddingBlockStart: theme.spacing(4)
    // paddingInline: theme.spacing(3),
    // height: theme.spacing(9),
    // borderBottom: "1px solid",
    // borderBottomColor: theme.palette.grey.main
  },

  responseFooter: {
    marginBlockEnd: "12px"
  },

  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px"
    // height: `calc(100% - ${theme.spacing(9)}px)`
  },

  userInputCollapsed: {
    fontSize: "16px"
  },

  userInputExpanded: {
    fontSize: "22px"
  }
}));

export default function PeerReviewResponse({
  reflection,
  response,
  setResponse,
  reply,
  setReply,
  readText,
  currentTab,
  zenMode,
  setZenMode,
  rendition,
  onRenditionCreated,
  saveTextLocation,
  saveCurrentTabLocation,
  textBookmark,
  reflectionRef,
  setCurrentTab,
  answerBookmark,
  answerRef,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const editorRef = useRef();
  const { submission_id } = useParams();

  // Redux state
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const currentUser = useSelector(state => state.user.auth);
  const selectedText = useSelector(state => state.texts.selectedText);
  const task = useSelector(state => state.task.taskRow);
  const feedbackTask = useSelector(state => state.task.feedbackTask);
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );

  const submitButtonClicked = useSelector(
    state => state.readerActions.submitButtonClicked
  );

  const wordLimit = useSelector(
    state => state.task.taskRow.questions?.questions[0]?.wordLimit
  );
  const submissionStatus = useSelector(
    state => state.task.taskRow.submission_status
  );
  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const fontSize = useSelector(state => state.user.fontSizeOptions);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // Ephemeral state
  const [modalVisible, setModalVisible] = useState(false); // Modal for Missing Review

  const [taskAnswers, answersLoading, answersError] = useDocument(
    firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`)
  );
  // Variables
  const isActive = submissionStatus === "pendingResponse";
  const editorContent = response?.data || null;

  let formattedAcceptDate =
    feedbackTask?.accept_date &&
    getDate(feedbackTask?.accept_date).setHours(24);

  const today = new Date();

  function getDate(str) {
    let b = str.split(/\D/);
    let d = new Date(b[0], b[1] - 1, b[2]);
    return d;
  }
  // Behavior
  useEffect(() => {
    // try to fetch stored answer from firebase or initialize answer
    if (isActive) {
      const emptyAnswer = {
        displayName: currentUser.displayName,
        data: {}
      };

      if (!answersLoading && !taskAnswers.exists) {
        setResponse(emptyAnswer);
      } else if (!answersLoading && taskAnswers.exists) {
        let data = taskAnswers.data();

        if (data.response) setResponse(data.response);
        // user might have a reflection saved in the doc
        else setResponse(emptyAnswer);
      }
    }
  }, [currentUser, task, answersLoading]);

  // set breadcrumbs
  const author =
    !task || !task.text_author
      ? selectedText && selectedText.author
      : task.text_author;

  const text_title =
    !task || !task.text_name
      ? selectedText && selectedText.name
      : task.text_name;

  const course =
    !task || !task.course_name
      ? selectedText && selectedText.course_name
      : task.course_name;

  const course_id =
    !task || !task.course_id
      ? selectedText && selectedText.course_id
      : task.course_id;

  const taskName =
    selectedCourse.course_role === "Teacher" || currentUser.uid === task.owner
      ? task.name
      : task.collaboration_name;

  useEffect(() => {
    let parts = [];
    task &&
      task.id &&
      parts.push({
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Tasks"
        }),
        url: "/tasks",
        brightText: !!task
      });
    parts.push({
      text: course,
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      brightText: !!task
    });

    taskName &&
      parts.push({
        text: taskName,
        brightText: !!task
      });

    parts.push({
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      text: author + " - " + text_title,
      brightText: !!task
    });

    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: task && task.id,
        showTextMenu: true,

        showSendFeedback:
          submissionStatus === "Submitted" ||
          submissionStatus === "Graded" ||
          (isActive && formattedAcceptDate < today),

        showSubmitTask:
          (submissionStatus !== "Graded" &&
            selectedCourse.course_role.toLowerCase() === "student") ||
          (isActive &&
            selectedCourse.course_role === "Teacher" &&
            formattedAcceptDate > today)
      })
    );
  }, [
    rtl,
    task,
    course,
    course_id,
    author,
    text_title,
    submissionStatus,
    taskName
  ]);

  useEffect(() => {
    if (
      (submissionStatus === "Submitted" || submissionStatus === "Graded") &&
      reply &&
      submitButtonClicked
    ) {
      dispatch(setIsLoading(true));
      const submitReply = firebaseFunctions.httpsCallable(
        "courses-submitPeerReviewReply"
      );
      submitReply({
        task: task.id,
        reply: reply,
        collaboration: task.collaboration_id,

        // for notification
        reviewer: task.collaborator_id,
        courseId: selectedCourse.id,
        courseName: selectedCourse.name,
        submissionId: task.submission_id,
        studentName: currentUser.displayName
      }).then(response => {
        dispatch(setSubmittedStudentReply(task.submission_id));

        dispatch(
          enqueueFlashMessage({
            message: "Reply was sent successfully",
            duration: alertsDuration,
            undoButton: true
          })
        );
      });
      // );
      dispatch(setSubmitButtonClicked(false));
      history.push("/tasks/" + selectedCourse.id);
      dispatch(setIsLoading(false));
    }
  }, [
    currentUser,
    reply,
    selectedCourse,
    task,
    submissionStatus,
    submitButtonClicked
  ]);

  function saveResponse(content) {
    // update ephemeral state
    setResponse({ displayName: currentUser.displayName, data: content });

    // Update the task's status to active
    if (task.collaboration_status === "Pending") {
      // Update the store - otherwise this function will keep calling the DB untill the task is fetched again
      dispatch(setSelectedTaskRow({ ...task, collaboration_status: "Active" }));
      // Update the DB
      const updateTaskStatus =
        firebaseFunctions.httpsCallable("courses-setStatus");
      updateTaskStatus({
        updateStatusFor: "Collaboration",
        task: task.id
      });
    }

    // save the reflection on firestore to prevent it from getting lost on refrash.
    try {
      if (!taskAnswers?.exists) {
        firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`).set({
          response: {
            displayName: currentUser.displayName,
            data: { content }
          }
        });
      } else {
        firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`).update({
          response: { displayName: currentUser.displayName, data: content }
        });
      }
    } catch (err) {
      console.log("error in saveResponse update ", err);
    }
  }

  const toggleZenMode = () => {
    // if (zenMode === false) saveTextLocation();
    if (zenMode === false) saveCurrentTabLocation();
    setZenMode(!zenMode);
  };

  useEffect(() => {
    if (
      submission_id == task.submission_id &&
      selectedCourse.course_role === "Teacher" &&
      isActive &&
      formattedAcceptDate > today
    )
      setModalVisible(true);
  }, [
    selectedCourse.course_role,
    submissionStatus,
    submission_id,
    task.submission_id
  ]);

  React.useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  //render

  if (!reflection && !response) {
    return <PangeaSpinner />;
  } else {
    return (
      <>
        <PeerReviewResponseModal
          modalVisible={submitButtonClicked}
          response={response}
        />

        <Box className={classes.tabs}>
          <PeerReviewTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            answerBookmark={answerBookmark}
            zenMode={zenMode}
            saveCurrentTabLocation={saveCurrentTabLocation}
          />
        </Box>

        <Grid
          container
          direction="row"
          alignContent="flex-start"
          classes={{ container: classes.grid }}>
          {!zenMode && (
            <Grid item xs={8} className={classes.textContainer}>
              <PeerReviewTabPanels
                reflection={reflection}
                readText={readText}
                currentTab={currentTab}
                zenMode={zenMode}
                setZenMode={setZenMode}
                rendition={rendition}
                onRenditionCreated={onRenditionCreated}
                textBookmark={textBookmark}
                answerRef={answerRef}
              />
            </Grid>
          )}

          <Grid
            item
            xs={zenMode ? 8 : 4}
            className={isActive && classes.editorActive}
            classes={{
              item: zenMode && classes.responseContainerExpanded
            }}>
            {/* <ScrollBox> */}
            <Box
              className={clsx(
                classes.responsesContainer,
                zenMode
                  ? classes.responseContainerExpanded
                  : classes.reflectionContainerCollapsed
              )}>
              <Box
                className={clsx(
                  classes.responses,
                  isActive &&
                    currentUser.uid === task.collaborator_id &&
                    classes.outlined
                )}
                onClick={() => {
                  editorRef?.current && editorRef.current.focus();
                }}>
                {currentUser.uid === task.collaborator_id && isActive && (
                  <>
                    <Box className={classes.responseHeader}>
                      {currentUser.uid === task.collaborator_id && `My review`}
                      {currentUser.uid !== task.collaborator_id &&
                        (response?.displayName
                          ? `${response?.displayName}'s review`
                          : `review`)}
                      <Tooltip
                        title={zenMode ? "Exit full screen" : "Full screen"}
                        placement={rtl ? "left" : "right"}
                        arrow>
                        <IconButton
                          aria-label={
                            zenMode ? "Exit full screen" : "Full screen"
                          }
                          onClick={toggleZenMode}>
                          {zenMode ? (
                            <FullscreenExitIcon />
                          ) : (
                            <FullscreenIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      className={clsx(
                        classes.userInput,
                        zenMode
                          ? classes.userInputExpanded
                          : classes.userInputCollapsed
                      )}>
                      {editorContent && (
                        <RichTextEditor
                          subcribeToUnload={true}
                          readOnly={
                            false
                            // currentUser.uid !== task.collaborator_id ||
                            // submissionStatus !== "pendingResponse"
                          }
                          onChange={saveResponse}
                          index={0}
                          placeholder={false}
                          comment={editorContent}
                          showWordCount={
                            currentUser.uid === task.collaborator_id && isActive
                          }
                          wordLimit={wordLimit}
                          // autoFocus={true}
                          editorRef={editorRef}
                        />
                      )}
                    </Box>
                  </>
                )}

                {!isActive && (
                  <ScrollBox>
                    <Box className={classes.responseHeader}>
                      {currentUser.uid === task.collaborator_id && `My review`}
                      {currentUser.uid !== task.collaborator_id &&
                        (response?.displayName
                          ? `${response?.displayName}'s review`
                          : `review`)}
                    </Box>

                    <Box className={classes.userInput}>
                      <RenderEditorContent>
                        {response?.data}
                      </RenderEditorContent>
                    </Box>
                    <PeerReviewReply reply={reply} setReply={setReply} />
                  </ScrollBox>
                )}
              </Box>
            </Box>
            {/* </Box> */}
            {/* </ScrollBox> */}
          </Grid>
        </Grid>
      </>
    );
  }
}
