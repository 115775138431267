// Dependencies
import React, { useState, useEffect } from "react";
import { firebaseFunctions } from "../../../firebase";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import {
  setSubmittedStudentTask,
  setSubmittedStudentReflection,
  setSubmittedStudentResponse,
  setSubmittedStudentReply,
  setSentFeedbackTask,
  setSentFeedbackTextTask
} from "../../../redux/taskSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { updateCourses } from "../../../redux/coursesSlice";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Components
import TasksTeacherView from "../../Tasks/TaskManager/TasksTeacherView";
import TasksStudentView from "../../Tasks/TaskManager/TasksStudentView";
import ScrollBox from "../../SharedComponents/ScrollBox";

const useStyles = makeStyles(theme => {
  return {
    container: {
      flex: 1,
      height: "100%",
      width: "83.333333%", // 10 culumns on a 12 column grid
      paddingInline: 40,
      alignItems: "center",
      position: "relative",

      [theme.breakpoints.up("desktop")]: {
        maxWidth: 840,
        marginInline: "auto"
      }
    }
  };
});

export default function TaskManager(props) {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { course_id } = useParams();

  const historyTaskSaved = history.location.state?.taskSaved;

  const [refreshToken, setRefreshToken] = React.useState(0);
  const [taskSaved, setTaskSaved] = useState(false);

  const intl = useIntl();
  //Redux
  const course = useSelector(
    state => state.courses.courses.filter(el => el.id == course_id)?.[0]
  );
  const sentFeedbackTask = useSelector(state => state.task.sentFeedbackTask);
  const sentFeedbackTextTask = useSelector(
    state => state.task.sentFeedbackTextTask
  );
  const shouldUndo = useSelector(state => state.user.undo);
  const submittedStudentTask = useSelector(
    state => state.task.submittedStudentTask
  );
  const submittedStudentReflection = useSelector(
    state => state.task.submittedStudentReflection
  );
  const submittedStudentResponse = useSelector(
    state => state.task.submittedStudentResponse
  );
  const submittedStudentReply = useSelector(
    state => state.task.submittedStudentReply
  );
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Behavior
  //Get course and role by id
  useEffect(() => {
    if (historyTaskSaved) {
      setTaskSaved(historyTaskSaved);
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "tasks.published_msg",
            defaultMessage: "Task published"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [historyTaskSaved]);

  useEffect(() => {
    if (!course) {
      const makeCancellable = promise => {
        let hasCanceled_ = false;

        const wrappedPromise = new Promise((resolve, reject) => {
          promise.then(
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            error =>
              hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
          );
        });
        return {
          promise: wrappedPromise,
          cancel() {
            hasCanceled_ = true;
          }
        };
      };

      var getMyCourses = firebaseFunctions.httpsCallable(
        "courses-getMyCourses"
      );

      const cancellable = makeCancellable(getMyCourses());

      cancellable.promise.then(response => {
        // Sort the array based on the second element
        dispatch(updateCourses(response.data));
      });

      const cancel = () => {
        cancellable.cancel();
      };
      return cancel;
    }
  }, [course, course_id]);

  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoTaskSubmission = () => {
      const taskId = taskSaved;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoTaskSubmission"
      );

      firebaseFunction({ task_id: taskId }).then(response => {
        setTaskSaved(false);
        setRefreshToken(refreshToken + 1);
      });
    };

    const undoStudentTaskSubmission = () => {
      const taskId = submittedStudentTask;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoStudentTaskSubmissions"
      );

      firebaseFunction({
        task_id: taskId
      }).then(response => {
        dispatch(setSubmittedStudentTask(0));
        setRefreshToken(refreshToken + 1);
        history.push("/reader/task/" + taskId);
      });
    };

    const undoSubmitPeerReviewReflection = () => {
      const taskId = submittedStudentReflection;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoSubmitPeerReviewReflection"
      );

      firebaseFunction({
        task_id: taskId
      }).then(response => {
        dispatch(setSubmittedStudentReflection(0));
        setRefreshToken(refreshToken + 1);
        history.push(`/reader/task/${taskId}/`);
      });
    };

    const undoSubmitPeerReviewResponse = () => {
      const taskId = submittedStudentResponse;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoSubmitPeerReviewResponse"
      );

      firebaseFunction({
        task_id: taskId
      }).then(response => {
        dispatch(setSubmittedStudentResponse(0));
        setRefreshToken(refreshToken + 1);
        history.push(`/reader/task/${taskId}/submission/${response.data}`);
      });
    };

    const undoSubmitPeerReviewReply = () => {
      const submission_id = submittedStudentReply;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoSubmitPeerReviewReply"
      );

      firebaseFunction({
        submission_id: submission_id
      }).then(response => {
        dispatch(setSubmittedStudentResponse(0));
        setRefreshToken(refreshToken + 1);
        history.push(
          `/reader/task/${response.data}/submission/${submission_id}`
        );
      });
    };

    const undoTaskFeedbackSubmission = () => {
      const taskId = sentFeedbackTask;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoTaskFeedbackSubmission"
      );

      firebaseFunction({ task_id: taskId })
        .then(response => {
          dispatch(setSentFeedbackTask(0));
          setRefreshToken(refreshToken + 1);
        })
        .catch(err => console.log("err", err));
    };

    const undoTextTaskFeedbackSubmission = () => {
      const taskId = sentFeedbackTextTask;
      const firebaseFunction = firebaseFunctions.httpsCallable(
        "courses-undoTextTaskFeedbackSubmission"
      );

      firebaseFunction({ submission_id: taskId })
        .then(response => {
          dispatch(setSentFeedbackTextTask(0));
          setRefreshToken(refreshToken + 1);
        })
        .catch(err => console.log("err", err));
    };

    if (shouldUndo) {
      if (taskSaved > 0) {
        undoTaskSubmission();
      } else if (submittedStudentTask > 0) {
        undoStudentTaskSubmission();
      } else if (submittedStudentReflection > 0) {
        undoSubmitPeerReviewReflection();
      } else if (submittedStudentResponse > 0) {
        undoSubmitPeerReviewResponse();
      } else if (submittedStudentReply > 0) {
        undoSubmitPeerReviewReply();
      } else if (sentFeedbackTask > 0) {
        undoTaskFeedbackSubmission();
      } else if (sentFeedbackTextTask > 0) {
        undoTextTaskFeedbackSubmission();
      }
      dispatch(undo(false));
    }
  }, [shouldUndo]);

  // Show flash message when there is undo data

  useEffect(() => {
    if (submittedStudentTask > 0) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.submission_msg",
            defaultMessage: "Task submitted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [submittedStudentTask]);

  useEffect(() => {
    if (sentFeedbackTask) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.feedback_sent",
            defaultMessage: "Feedback sent"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [sentFeedbackTask]);

  useEffect(() => {
    if (sentFeedbackTextTask > 0) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.feedback_sent",
            defaultMessage: "Feedback sent"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [sentFeedbackTextTask]);

  //for breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Tasks"
      }),
      url: "/tasks",
      resetCourse: true
    });

    course && parts.push({ text: course.name, course: course });

    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course]);

  useEffect(() => {
    return () => {
      //avoid showing messages twice
      dispatch(setSubmittedStudentTask(0));
      dispatch(setSubmittedStudentReflection(0));
      dispatch(setSubmittedStudentResponse(0));
      dispatch(setSubmittedStudentReply(0));
      dispatch(setSentFeedbackTask(0));
    };
  }, []);

  return (
    <ScrollBox alignItems="center">
      <Box className={classes.container}>
        {course && course.course_role.toLowerCase() === "teacher" && (
          <TasksTeacherView course={course} refresh={refreshToken} />
        )}

        {course && course.course_role.toLowerCase() === "student" && (
          <TasksStudentView
            course={course}
            refreshToken={refreshToken}
            refresh={refreshToken}
          />
        )}
      </Box>
    </ScrollBox>
  );
}
