// Dependencies
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";

import { useDocument } from "react-firebase-hooks/firestore";
import { useIntl } from "react-intl";
import { useResizeDetector } from "react-resize-detector";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  // setShowDoneButton,
  setBreadcrumbs
} from "../../../redux/readerActionsSlice";
import { setSelectedTaskRow } from "../../../redux/taskSlice";

// Components
import { firestore, firebaseFunctions } from "../../../firebase";
import RichTextEditor from "../../SharedComponents/RichTextEditor";
import EpubView from "../../reader/EpubView/EpubView";
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";

// import BookView from "../../../reader/BookView";
import PeerReviewReflectionModal from "./PeerReviewReflectionModal";

// Material UI
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tooltip, IconButton, Grid } from "@material-ui/core";
import ScrollBox from "../../SharedComponents/ScrollBox";

// Styles
const useStyles = makeStyles(theme => ({
  grid: {
    height: "100%",
    columnGap: 16,
    flexWrap: "nowrap"
  },
  textContainer: {
    position: "Relative",
    paddingInlineStart: theme.spacing(5)
  },

  reflectionContainer: {
    height: "100%",
    "& $reflectionHeader": {
      paddingBlockStart: theme.spacing(4.5)
    }
  },

  reflectionContainerExpanded: {
    margin: "0 auto",
    paddingInline: "0"
  },

  editorActive: {
    paddingInlineEnd: theme.spacing(5),
    paddingBlock: theme.spacing(3),
    height: "100%",
    "& $reflectionHeader": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.main,
      paddingInline: theme.spacing(3),
      height: theme.spacing(9),
      paddingBlockStart: theme.spacing(1.5)
    }
  },

  reflection: {
    color: theme.palette.text.primary,
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    lineHeight: "2"
  },

  outlined: {
    border: `1px solid ${theme.palette.divider}`
  },

  reflectionHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    paddingBlockEnd: theme.spacing(1.5)
  },

  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px",
    height: `calc(100% - ${theme.spacing(9)}px)`
  },

  userInputCollapsed: {
    fontSize: "16px"
  },

  userInputExpanded: {
    fontSize: "22px"
  }
}));
export default function PeerReviewReflection({
  reflection,
  setReflection,
  reply,
  setReply,
  readText,
  setCurrentTab,
  rendition,
  onRenditionCreated,
  saveTextLocation,
  textBookmark,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const editorRef = useRef();
  const intl = useIntl();

  const { ref, width, height } = useResizeDetector({
    handleHeight: false,
    skipOnMount: true,
    onResize: () => {
      rendition && rendition.resize();
    }
  });

  // Redux state
  const currentUser = useSelector(state => state.user.auth);
  const userProfile = useSelector(state => state.user.userProfile);
  const task = useSelector(state => state.task.taskRow);
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const selectedText = useSelector(state => state.texts.selectedText);
  const wordLimit = useSelector(
    state => state.task.taskRow.questions?.questions[0]?.wordLimit
  );
  const submissionStatus = useSelector(
    state => state.task.taskRow.submission_status
  );
  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const fontSize = useSelector(state => state.user.fontSizeOptions);

  const submitButtonClicked = useSelector(state => {
    return state.readerActions.submitButtonClicked;
  });

  // Ephemeral state
  const [zenMode, setZenMode] = useState(false); // Zen mode expand the text editor to full screen

  // Firestore
  const [taskAnswers, answersLoading, answersError] = useDocument(
    firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`)
  );

  // Variables
  const isActive =
    task.submission_status === "Pending" || task.submission_status === "Active";

  // Behavior
  // set breadcrumbs
  const author =
    !task || !task.text_author
      ? selectedText && selectedText.author
      : task.text_author;

  const text_title =
    !task || !task.text_name
      ? selectedText && selectedText.name
      : task.text_name;

  const course =
    !task || !task.course_name
      ? selectedText && selectedText.course_name
      : task.course_name;

  const course_id =
    !task || !task.course_id
      ? selectedText && selectedText.course_id
      : task.course_id;

  const taskName =
    userProfile.selectedCourse.course_role === "Teacher" ||
    currentUser.uid === task.owner
      ? task.name
      : task.collaboration_name;

  useEffect(() => {
    let parts = [];
    task &&
      task.id &&
      parts.push({
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Tasks"
        }),
        url: "/tasks",
        brightText: !!task
      });
    parts.push({
      text: course,
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      brightText: !!task
    });

    taskName &&
      parts.push({
        text: taskName,
        brightText: !!task
      });

    parts.push({
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      text: author + " - " + text_title,
      brightText: !!task
    });

    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: task && task.id,
        showTextMenu: true,
        showSubmitTask: true
      })
    );
  }, [
    rtl,
    task,
    course,
    course_id,
    author,
    text_title,
    submissionStatus,
    taskName
  ]);

  useEffect(() => {
    // try to fetch stored answer from firebase or initialize answer
    if (submissionStatus === "Pending" || submissionStatus === "Active") {
      const emptyAnswer = {
        displayName: currentUser.displayName,
        data: {}
      };

      if (!answersLoading && !taskAnswers.exists) {
        setReflection(emptyAnswer);
      } else if (!answersLoading && taskAnswers.exists) {
        let data = taskAnswers.data();

        if (data.reflection) setReflection(data.reflection);
        // user might have a reply saved in the doc
        else setReflection(emptyAnswer);
      }
    }
  }, [currentUser, task, answersLoading]);

  function saveReflection(content) {
    // update ephemeral state

    setReflection({ displayName: currentUser.displayName, data: content });
    // Update the task's status to active
    if (task.submission_status === "Pending") {
      // Update the store - otherwise this function will keep calling the DB untill the task is fetched again
      dispatch(setSelectedTaskRow({ ...task, collaboration_status: "Active" }));
      const updateTaskStatus =
        firebaseFunctions.httpsCallable("courses-setStatus");
      updateTaskStatus({
        task: task.id
      });
    }

    // save to user firestore to prevent from getting lost on refrash.
    try {
      if (!taskAnswers?.exists) {
        firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`).set({
          reflection: {
            displayName: currentUser.displayName,
            data: { content }
          }
        });
      } else {
        firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`).update({
          reflection: { displayName: currentUser.displayName, data: content }
        });
      }
    } catch (err) {
      console.log("error in saveReflection update ", err);
    }
  }

  const handleClick = () => {
    if (zenMode === false) saveTextLocation();
    setZenMode(!zenMode);
  };

  useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  useEffect(() => {
    rendition && rendition.resize();
  }, [width]);

  // render
  return (
    <>
      <PeerReviewReflectionModal
        modalVisible={submitButtonClicked}
        reflection={reflection}
      />
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        classes={{ container: classes.grid }}
      >
        {!zenMode && (
          <Grid item xs={8} className={classes.textContainer} ref={ref}>
            <EpubView
              fullSize={false}
              fontSize={fontSize[fontSizeValue]}
              epubOptions={{ flow: "scrolled-doc" }}
              darkMode={userProfile.darkMode}
              rtl={rtl}
              url={readText.url}
              location={textBookmark || readText.file_location}
              {...props}
              onRenditionCreated={onRenditionCreated}
              bodyClassName="scrollBox"
            />
          </Grid>
        )}

        <Grid
          item
          xs={zenMode ? 8 : 4}
          className={clsx(
            classes.reflectionContainer,
            isActive && classes.editorActive
          )}
          classes={{
            item: zenMode && classes.reflectionContainerExpanded
          }}
        >
          <Box
            className={clsx(classes.reflection, isActive && classes.outlined)}
            onClick={() => {
              editorRef?.current && editorRef.current.focus();
            }}
          >
            {currentUser.uid === task.owner && isActive && (
              // Show editor if the current user is the owner of the task and the reflection wasnt submitted yet
              <>
                <Box className={classes.reflectionHeader}>
                  <Box>My reflection</Box>
                  <Tooltip
                    title={zenMode ? "Exit full screen" : "Full screen"}
                    placement={rtl ? "left" : "right"}
                    arrow
                  >
                    <IconButton
                      aria-label={zenMode ? "Exit full screen" : "Full screen"}
                      onClick={handleClick}
                    >
                      {zenMode ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  className={clsx(
                    classes.userInput,
                    zenMode
                      ? classes.userInputExpanded
                      : classes.userInputCollapsed
                  )}
                >
                  {reflection && (
                    <RichTextEditor
                      showWordCount={true}
                      subcribeToUnload={true}
                      readOnly={false}
                      onChange={saveReflection}
                      index={0}
                      placeholder={false}
                      comment={reflection?.data}
                      wordLimit={wordLimit}
                      // autoFocus={true}
                      editorRef={editorRef}
                    />
                  )}
                </Box>
              </>
            )}

            {reflection && !isActive && (
              <ScrollBox>
                <Box className={classes.reflectionHeader}>
                  <Box>My reflection</Box>
                </Box>
                <Box className={classes.userInput}>
                  <RenderEditorContent>{reflection?.data}</RenderEditorContent>
                </Box>
              </ScrollBox>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
