// Dependencies
import React, { useState } from "react";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
// import moment from "moment";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  setHighlightColor,
  setShowHighlights,
  setAnnotatorMode
} from "../../redux/readerActionsSlice";
import { setForceHighlight } from "../../redux/grSlice";
import { setProfile } from "../../redux/userSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import BorderColor from "@material-ui/icons/BorderColor";
import SearchIcon from "@material-ui/icons/Search";
import Brightness2Icon from "@material-ui/icons/Brightness2";
import Brightness2OutlinedIcon from "@material-ui/icons/Brightness2Outlined";
import VoiceIcon from "@material-ui/icons/SettingsVoice";
import SizeIcon from "@material-ui/icons/FormatSize";
import HeartIcon from "@material-ui/icons/FavoriteBorder";
import DoneIcon from "@material-ui/icons/Done";
import UnderlineIcon from "@material-ui/icons/FormatUnderlined";

import {
  // Badge,
  // Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  leftDir: {
    textAlign: "left",
    direction: "ltr"
  },
  rightDir: {
    textAlign: "right",
    direction: "rtl"
  },
  listItem: {
    width: "24px",
    height: "24px",
    cursor: "pointer",

    marginRight: "8px",
    marginLeft: "8px"
  },
  colorBtnRtl: {
    marginRight: "8px",
    marginLeft: "40px"
  },
  colorBtnLtr: {
    marginRight: "40px",
    marginLeft: "8px"
  },
  selectedColorBtn: {
    border: "2px solid black"
  },
  colorBtn: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    marginTop: "8px",
    marginBottom: "8px",

    borderRadius: "12px"
  },
  barIcon: {
    color: theme.palette.grey[900]
  },
  actionBox: {
    display: "inline-block"
  },
  actionBoxLtr: {
    right: "40px"
  },
  actionBoxRtl: {
    left: "40px"
  },
  menu: {},
  menuLtr: {
    justifyContent: "left",
    direction: "ltr",
    textAlign: "left"
  },
  menuRtl: {
    justifyContent: "right",
    direction: "rtl",
    textAlign: "right"
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px"
  },
  notificationContainer: {
    width: "360px"
  },
  notificationHeader: {
    display: "flex",
    justifyContent: "space-between"
  },

  notificationContent: {},
  courseName: {
    fontWeight: "bold"
  },
  time: {
    color: theme.palette.text.secondary
  },
  notification: {}
}));

export default function HeaderToolbar({
  selected,
  onClick,
  style,
  color,
  textMenu,
  ...props
}) {
  // Hooks
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  // const notificationIconsRef = useRef();
  const userProfile = useSelector(state => state.user.userProfile);

  // Redux state
  const isDarkMode = userProfile.darkMode;
  const stage = useSelector(state => state.gr.stage);
  const fontSize = userProfile.fontSize;
  const showHighlights = useSelector(
    state =>
      // {
      // if (state.readerActions.annotatorMode === "poc" && state.gr.stage >= 2)
      // return state.gr.forceHighlight;
      // else return
      state.readerActions.persistentActionState.showHighlights
    // }
  );
  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  const action = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const highlightColor = useSelector(
    state => state.readerActions.persistentActionState.highlightColor
  );
  // const notifications = useSelector((state) => state.user.notifications);
  // Ephemeral state
  const [highlightEl, setHighlightEl] = useState(null);
  // const [openNotifications, setOpenNotifications] = useState(false);

  // Variables
  let colors = [
    {
      color: "#91fcc2",
      text: "colors.green",
      default: "Green",
      hlColor: "#91fcc2"
    },
    {
      color: "#93d4d9",
      text: "colors.cyan",
      default: "Cyan",
      hlColor: "#93d4d9"
    },
    {
      color: "#ffa284",
      text: "colors.orange",
      default: "Orange",
      hlColor: "#ffa284"
    },
    {
      color: "#ff8a8a",
      text: "colors.red",
      default: "Red",
      hlColor: "#ff8a8a"
    },
    {
      color: "#ffcb73",
      text: "colors.yellow",
      default: "Yellow",
      hlColor: "#ffcb73"
    }
  ];

  // Behavior
  const handleClick = event => {
    setHighlightEl(event.currentTarget);
  };

  const handleClose = () => {
    setHighlightEl(null);
  };

  // Render
  return (
    <div
      className={clsx(classes.actionBox, classes.actionBoxLtr)}
      direction={"auto"}
    >
      {textMenu && (
        <Tooltip
          title={intl.formatMessage({
            id: "menu.tooltip.comingSoon",
            defaultMessage: "Coming soon"
          })}
          arrow
          placement={"bottom"}
        >
          <IconButton
            aria-label="search, coming soon"
            className={clsx(
              classes.barIcon,
              color === "white" && classes.lightIcon
            )}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      )}
      {textMenu && (
        <Tooltip
          title={intl.formatMessage({
            id: "menu.tooltip.highlighter",
            defaultMessage: "Highlighter options"
          })}
          arrow
          placement={"bottom"}
        >
          <IconButton
            aria-label="Highlighter options"
            onClick={handleClick}
            className={clsx(
              classes.barIcon,
              color === "white" && classes.lightIcon
            )}
          >
            <BorderColor />
          </IconButton>
        </Tooltip>
      )}

      {/* START  */}
      <Menu
        direction={"auto"}
        anchorEl={highlightEl}
        keepMounted
        classes={{ list: classes.menuLtr }}
        open={Boolean(highlightEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {action !== "poc" && (
          <>
            <MenuItem
              className={clsx(classes.menuItem, classes.left)}
              onClick={() => {
                // if (
                //   annotatorMode &&
                //   annotatorMode !== "highlight" &&
                //   annotatorMode !== ""
                // ) {
                // } else {
                dispatch(
                  setAnnotatorMode(
                    annotatorMode === "highlight" ? "" : "highlight"
                  )
                );
                handleClose();
                // }
              }}
            >
              <ListItemIcon
                className={classes.listItem}
                role="switch" //TODO: change this element to a checkbox
                aria-checked={annotatorMode === "highlight"}
                aria-labelledby="highlightModeLabel"
              >
                {annotatorMode === "highlight" && <DoneIcon />}
              </ListItemIcon>

              <Typography variant="inherit" id="highlightModeLabel">
                <FormattedMessage
                  id="highlight.highlightMode"
                  defaultMessage="Highlight Mode"
                />
              </Typography>
            </MenuItem>

            <MenuItem
              className={clsx(classes.menuItem, classes.left)}
              onClick={() => {
                if (annotatorMode === "poc" && stage >= 2) {
                  dispatch(setForceHighlight(!showHighlights));
                } else {
                  dispatch(setShowHighlights(!showHighlights));
                }
                handleClose();
              }}
              role="switch" //TODO: change this element to a checkbox
              aria-checked={showHighlights}
              aria-label="Show highlights"
            >
              <ListItemIcon
                className={classes.listItem}
                role="switch" //TODO: change this element to a checkbox
                aria-checked={showHighlights}
                aria-label="Show highlights"
              >
                {showHighlights && <DoneIcon />}
              </ListItemIcon>
              <Typography variant="inherit" id="showHighlightsLabel">
                <FormattedMessage
                  id="highlight.showMode"
                  defaultMessage="Show highlights"
                />
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}
        {colors.map((item, index) => {
          return (
            <MenuItem
              key={index}
              className={clsx(classes.menuItem, classes.left)}
              onClick={() => {
                dispatch(setHighlightColor(item.hlColor));
                handleClose();
              }}
            >
              <ListItemIcon>
                <div
                  className={clsx(
                    classes.colorBtn,
                    classes.colorBtnLtr,
                    highlightColor === item.hlColor && classes.selectedColorBtn
                  )}
                  style={{
                    backgroundColor: item.color
                  }}
                  role="checkbox" //FIXME: This should really be a radiogrop
                  aria-checked={highlightColor === item.Color}
                  aria-labelledby={`${item.text}Lable`}
                />
              </ListItemIcon>
              <Typography variant="inherit" id={`${item.text}Lable`} noWrap>
                <FormattedMessage
                  id={item.text}
                  defaultMessage={item.default}
                />
              </Typography>
            </MenuItem>
          );
        })}
        {/* DO NOT REMOVE THIS FEATURE IS DISABLED UNTILL FURTHER NOTICE   */}
        {/* <Divider />
        <MenuItem className={clsx(classes.menuItem, classes.left)}>
          <ListItemIcon className={classes.listItem}>
            <UnderlineIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            <FormattedMessage
              id="highlight.underline"
              defaultMessage="Underline"
            />
          </Typography>
        </MenuItem> */}
      </Menu>

      {/* END  */}

      {textMenu && (
        <Tooltip
          title={intl.formatMessage({
            id: "menu.tooltip.comingSoon",
            defaultMessage: "Coming soon"
          })}
          arrow
          placement={"bottom"}
        >
          <IconButton
            aria-label="voice, coming soon"
            className={clsx(
              classes.barIcon,
              color === "white" && classes.lightIcon
            )}
          >
            <VoiceIcon />
          </IconButton>
        </Tooltip>
      )}
      {textMenu && (
        <Tooltip
          title={intl.formatMessage({
            id: "menu.tooltip.fontSize",
            defaultMessage: "Adjust font size"
          })}
          arrow
          placement={"bottom"}
        >
          <IconButton
            aria-label="Adjust font size"
            onClick={() => {
              let fontSizeInt = parseInt(fontSize);
              let nextFontSize = 5;
              if (fontSizeInt < 8) nextFontSize = fontSizeInt + 1;
              dispatch(
                setProfile({
                  ...userProfile,
                  fontSize: nextFontSize.toString()
                })
              );
            }}
            className={clsx(
              classes.barIcon,
              color === "white" && classes.lightIcon
            )}
          >
            <SizeIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={intl.formatMessage({
          id: "menu.tooltip.darkMode",
          defaultMessage: "Toggle dark mode"
        })}
        arrow
        placement={"bottom"}
      >
        <IconButton
          aria-label="Toggle dark mode"
          className={clsx(
            classes.barIcon,
            color === "white" && classes.lightIcon
          )}
          style={{ transform: "rotate(120deg)" }}
          onClick={() => {
            dispatch(setProfile({ ...userProfile, darkMode: !isDarkMode }));
          }}
        >
          {isDarkMode ? <Brightness2Icon /> : <Brightness2OutlinedIcon />}
        </IconButton>
      </Tooltip>

      {textMenu && (
        <Tooltip
          title={intl.formatMessage({
            id: "menu.tooltip.comingSoon",
            defaultMessage: "Coming soon"
          })}
          arrow
          placement={"bottom"}
        >
          <IconButton
            aria-label="hart, coming soon"
            className={clsx(
              classes.barIcon,
              color === "white" && classes.lightIcon
            )}
          >
            <HeartIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
