import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useGetTheme() {
  const [sideBarWidth, setSideBarWidth] = useState(48);
  const actionBar = useSelector(state => {
    return state.readerActions.persistentActionState.actionBar;
  });

  useEffect(() => {
    const $sideBarEl = document.getElementById("side-bar");
    $sideBarEl.addEventListener("transitionend", () => {
      setSideBarWidth($sideBarEl.clientWidth);
    });
  }, [actionBar]);

  return sideBarWidth;
}
