import { configureStore , getDefaultMiddleware} from '@reduxjs/toolkit';
import {
  getFirebase,
  actionTypes as rrfActionTypes
} from 'react-redux-firebase';
import { constants as rfConstants, getFirestore } from 'redux-firestore';
import readerActionsReducer from './readerActionsSlice';
import highlightReducer from './highlightSlice';
import themeReducer from './themeSlice';
import grReducer from './grSlice';
import linkSlice from './LinkSlice';
import taskSlice from './taskSlice';
import commentReducer from './commentsSlice';
import textReducer from './textSlice';
import  userReducer from './userSlice';
import coursesSlice from './coursesSlice';
import firebaseMiddleware from './firebaseMiddleware';
const extraArgument = {
  getFirebase,
  getFirestore
}

const middleware = [
  firebaseMiddleware, ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        // just ignore every redux-firebase and react-redux-firebase action type
        ...Object.keys(rfConstants.actionTypes).map(
          type => `${rfConstants.actionsPrefix}/${type}`
        ),
        ...Object.keys(rrfActionTypes).map(
          type => `@@reactReduxFirebase/${type}`
        )
      ],
      ignoredPaths: ['firebase', 'firestore']
    },
    thunk: {
      extraArgument
    }
  })
]

export default configureStore({
  reducer: {
    readerActions: readerActionsReducer,
    highlighter:highlightReducer,
    themes:themeReducer,
    gr:grReducer,
    texts:textReducer,
    task:taskSlice,
    user:userReducer,
    link:linkSlice,
    comments: commentReducer,
    courses: coursesSlice,
  },
  middleware
});
