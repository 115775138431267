import React from "react";

// Redux Dependencies
import { useSelector } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowDown from "@material-ui/icons/ArrowDropDown";

// Styles
const useStyles = makeStyles(theme => ({
  button: {
    color: "currentcolor"
  }
}));

// Reusable toggle triangle button fir dropdowns and acordion UI elements
// The component use the RTL redux state to decide which way the closed triangle points
// Requierd Props:  isOpen (boollean), decides the open/closed state of the triangle
// Optional Props:  onClick (function), executed when clicked
//                  Size (string, 'small' | 'medium')
//                  light (boolean), true overrides the theme, always showing a light arrow

export default function ToggleTriangle({
  isOpen,
  onClick,
  size = "medium",
  ...props
}) {
  const classes = useStyles();
  // Redux State
  const rtl = useSelector(state => state.user.userProfile.rtl);

  //Style

  return (
    <IconButton
      className={classes.button}
      onClick={onClick}
      size={size}
      // aria-label={isOpen ? "collapse" : "expand"}
    >
      {isOpen && <ArrowDown />}
      {!isOpen && (rtl ? <ArrowLeft /> : <ArrowRight />)}
    </IconButton>
  );
}
