import React from 'react';

const PlainTextExample = () => {
  

const classes ={};

const renderAligned = () => {

    return (
      <div className={classes.root}>
       coming soon!

      
       
      </div>
  
  );
}

return renderAligned();
}


export default PlainTextExample