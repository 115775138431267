// Dependencies
import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { useParams } from "react-router-dom";

// Components
import GeneralStats from "./GeneralStats";
import TimeManagmentStats from "./TImeManagmentStats";
import GradeDistributionStats from "./GradeDistributionStats";
import OpenQuestionStats from "./OpenQuestionStats";
import MultiChoiceStats from "./MultiChoiceStats";
import FindInTextStats from "./FindInTextStats";
import StudentEvaluation from "./StudentEvaluation";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { firebaseFunctions, firebaseApp } from "../../../firebase";
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    // FIXME: Temp fix for the background color, shold be removed once the background is set on a global level
    tempContainer: {
      backgroundColor: theme.palette.app.background.main,
      width: "100%",
      minHeight: "calc(100vh - 120px)",
      height: "fit-content",
    },
    container: {
      width: "82%",
      margin: "0 auto",
    },
    pageTitle: {
      marginBottom: "24px",
    },
    pageSubTitle: {
      marginBottom: "80px",
    },
    main: {
      display: "grid",
      // this will have to change once the background color is sorted out
      paddingBottom: "48px",
      gridTemplateColumns: "0.65fr 1.1fr 1.1fr",
      gap: "24px 24px",
      gridAutoFlow: "row",
    },
    general: {
      gridColumn: "1 / 2",
      gridRow: "1",
    },
    timeManagment: {
      gridColumn: "2 / 3",
      gridRow: "1",
    },
    grades: {
      gridColumn: "3 / 4",
      gridRow: "1",
    },
    question: {
      gridColumn: "1 / 4",
    },

    generalStats: {
      textAlign: "center",
      "& h6": { margin: "0" },
    },
    statContainer: {
      padding: "32px",
    },
    questionTitle: {
      fontWeight: "bold",
      marginBottom: "12px",
    },
  };
});

export default function TaskStatsView() {
  // Hooks
  const classes = useStyles();
  const generalStatsNumRef = useRef();
  const [generalStatsHeight, setGeneralStatsHeight] = useState(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  let { task_id } = useParams();
  // Ephemeral State
  const [task, setTask] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [text, setText] = useState({});

  useEffect(() => {
    if (generalStatsNumRef) {
      setGeneralStatsHeight(generalStatsNumRef.current.offsetHeight);
    }
  }, [generalStatsNumRef]);

  useEffect(() => {
    var getTaskById = firebaseFunctions.httpsCallable("courses-getTaskStats");

    getTaskById({ task_id: task_id }).then((response) => {
      let taskRows = response.data;
      setSubmissions(taskRows);
      let taskRow = taskRows && taskRows.length && taskRows[0];
      if (taskRow) {
        firebaseApp
          .storage()
          .ref("courseTexts/" + taskRow.course_id)
          .child(taskRow.file_url)
          .getDownloadURL()
          .then((url) => {
            dispatch(
              setText({
                url: url,
                location: taskRow.file_location,
              })
            );
          });
        setTask(taskRows[0]);
      }
    });
  }, [task_id]);

  //breadcrumbs effect
  useEffect(() => {
    let parts = [];
    parts.push({
      url: "/tasks",
      text: intl.formatMessage({ id: "appBar.tasks", defaultMessage: "Tasks" }),
    });
    task &&
      task.course_name &&
      parts.push({ url: "/tasks", text: task.course_name });
    task &&
      task.name &&
      parts.push({
        url: "/tasks/task/" + task.id + "/submission/" + 0,
        text: task.name,
      });
    task && parts.push({ url: "/tasks", text: "Statistics" });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
    return () => {};
  }, [task]);

  const renderQuestion = (question, index) => {
    if (question.type === 0) {
      return (
        <Box key={index} className={classes.question}>
          <FindInTextStats
            submissions={submissions}
            question={question}
            index={index}
            task={task}
            text={text}
          />
        </Box>
      );
    }
    if (question.type === 1) {
      //open
      return (
        <Box key={index} className={classes.question}>
          <OpenQuestionStats
            submissions={submissions}
            question={question}
            index={index}
            task={task}
            text={text}
          />
        </Box>
      );
    }
    if (question.type === 2) {
      //multi
      return (
        <Box key={index} className={classes.question}>
          <MultiChoiceStats
            submissions={submissions}
            question={question}
            index={index}
            task={task}
            text={text}
          />
        </Box>
      );
    }
  };

  return (
    <Box className={classes.tempContainer}>
      <Box className={classes.container}>
        <Box component="header">
          <Typography
            className={clsx(classes.left, classes.pageTitle)}
            variant="h2"
          >
            Task Statistics
          </Typography>
          {task && (
            <Typography
              className={clsx(classes.left, classes.pageSubTitle)}
              variant="body1"
            >
              {task.course_name} / {task.name} / {task.author} - {task.title} /{" "}
              {new Date(task.publish_date).toLocaleDateString()} -{" "}
              {new Date(task.due_date).toLocaleDateString()}{" "}
            </Typography>
          )}
        </Box>
        <Box className={classes.main}>
          <Box className={clsx(classes.general, classes.generalStats)}>
            <GeneralStats
              submissions={submissions}
              text={text}
              task={task}
              generalStatsNumRef={generalStatsNumRef}
            />
          </Box>

          <Box className={clsx(classes.timeManagment, classes.generalStats)}>
            <TimeManagmentStats
              submissions={submissions}
              text={text}
              task={task}
              generalStatsHeight={generalStatsHeight}
            />
          </Box>

          <Box className={clsx(classes.grades, classes.generalStats)}>
            <GradeDistributionStats
              submissions={submissions}
              text={text}
              task={task}
              generalStatsHeight={generalStatsHeight}
            />
          </Box>
          {task &&
            task.questions.questions &&
            task.questions.questions.map((question, i) => {
              return renderQuestion(question, i);
            })}

          <StudentEvaluation submissions={submissions} />
        </Box>
      </Box>
    </Box>
  );
}
