// Dependencies
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firebaseFunctions, firestore } from "../../../firebase";
import { useIntl } from "react-intl";

// Redux Dependencies
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import TasksListItem from "./TasksListItem";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => ({
  taskContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "48px",
    marginBottom: "24px"
  }
}));

export default function TeacherTaskView({ refreshToken, ...props }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Redux State
  // const sentFeedbackTask = useSelector((state) => state.task.sentFeedbackTask);

  // Ephemeral State
  const [submissions, setSubmissions] = useState([]);
  const { course_id, task_id } = useParams();
  const [course, setCourse] = useState(false);
  const [courses, setCourses] = useState(false);
  const [taskGraders, setTaskGraders] = useState(false);

  // Behavior
  useEffect(() => {
    var getMyCourses = firebaseFunctions.httpsCallable("courses-getMyCourses");
    getMyCourses().then((response) => {
      setCourses(response.data);
    });
  }, []);

  useEffect(() => {
    let unsubscribe = false;
    var getAllSubmissions = firebaseFunctions.httpsCallable(
      "courses-getTaskSubmissionsById"
    );
    getAllSubmissions({ task: task_id }).then((response) => {
      setSubmissions(response.data);
      if (response.data && response.data.length) {
        let docId = "taskGraders/" + response.data[0].course_id + "/graders/";

        unsubscribe = firestore.collection(docId).onSnapshot(
          (snapshot) => {
            let graders = {};
            snapshot.forEach((doc) => {
              graders[doc.id] = doc.data();
            });

            setTaskGraders(graders);
          },
          (error) => {
            console.log("fetchFirebaseError tasks", error);
          }
        );
      }
    });

    return function cleanup() {
      unsubscribe && unsubscribe();
    };
  }, [task_id, refreshToken]);

  useEffect(() => {
    if (course && submissions && submissions.length) {
      let task = submissions[0];
      let parts = [];
      parts.push({
        url: "/tasks",
        resetCourse: true,
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Tasks"
        })
      });
      task &&
        task.course_name &&
        parts.push({
          url: `/tasks/${course_id}`,
          text: task.course_name,
          course: course
        });
      task && parts.push({ text: task.name });
      dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
    }
  }, [submissions, course, dispatch, intl, course_id]);

  useEffect(() => {
    if (submissions && courses && submissions.length && courses.length) {
      let filteredCourses = courses.filter(
        (el) => el.id === submissions[0].course_id
      );
      if (filteredCourses && filteredCourses.length) {
        setCourse(filteredCourses[0]);
      }
    }
  }, [submissions, courses]);

  // Render
  return (
    <Box className={classes.taskContainer} key={task_id}>
      {course && submissions && submissions.length && (
        <TasksListItem
          course={course}
          graders={taskGraders}
          openTasks={[submissions[0].task_id]}
          toggleTask={false}
          task={{
            ...submissions[0],
            answers: submissions[0].task_answers,
            id: submissions[0].task_id
          }}
          submissions={submissions}
          singleItemView={true}
        />
      )}
    </Box>
  );
}
