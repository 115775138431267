import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

// Redux Dependencies

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
  },
}));

// Reusable loading spinner to help keeping consistency across the app
// IMPORTANT: the spinner will be centered in the nearest relatively position parent

// Requierd Props: None
// Optional Props:  size (integer)

export default function PangeaSpinner({ size, ...props }) {
  const classes = useStyles();
  // Redux State

  return (
    <Box className={classes.container}>
      <ClipLoader color="#5ec891" loading="true" size={size || 150} />
    </Box>
  );
}
