// Dependencies
import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { firebaseFunctions, recaptchaKey } from "../../firebase";

import { useIntl } from "react-intl";

import ReCAPTCHA from "react-google-recaptcha-enterprise";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Button,
  Link,
} from "@material-ui/core";

//Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      backgroundImage: 'url("/resources/landingImage20211023.jpg")',
      backgroundSize: "auto 100vh",
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat",
    },
    backBtn: {
      position: "absolute",
      left: "-48px",
    },
    newAccount: {
      color: theme.palette.secondary.main,
      marginInlineStart: "8px",
    },
    termsLink:{
      color:"#D8D8D8",
      marginInlineStart: "8px",
      cursor:"pointer",
      textDecoration:"underline",
    },
    resetLink: {
      right: "0px",
      color: theme.palette.secondary.main,
      alignSelf: "flex-end",
    },

    main: {
      flexBasis: "50%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      whiteSpace: "nowrap",
      marginBottom: "60px",
    },
    loginBox: {
      marginTop: "160px",
      //height: "50%",
      //display: "flex",
      //flexFlow: "column nowrap",
      //flexBasis: "50%",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      position: "relative",
    },
    googleLoginBtn: {
      padding: "12px",
      marginBottom: "40px",
      width: "100%",
      "& .MuiButton-startIcon": {
        marginInlineEnd: "auto",
      },
    },
    googleLoginBtnText: {
      marginInlineEnd: "auto",
      fontSize: "16px",
    },
    captchaContainer: {
      display: "flex",
      justifyContent: "center",
    },
    emailLoginBox: {
      width: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
    },
    emailLoginBtn: {
      paddingInline: "24px",
      color: "white",
      marginInlineStart: "auto",
    },
    actionsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    termsBox:{
      color:"#D8D8D8",
      alignItems: "center"
    },
  }

    
});

export default function Signup({ pending, setPending, back }) {
  const classes = useStyles();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState(null);
  const [verifiedRecaptcha, setVerifiedRecaptcha] = useState(false);
  const [nameValidationMessage, setNameValidationMessage] = useState(null);

  const renderLoader = () => {
    if (loading) {
      return (
        <Box>
          <ClipLoader color="#5ec891" loading="true" size={150} />
        </Box>
      );
    }
  };

  const validateEmail = () => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return true;
    } else {
      setEmailValidationMessage(
        intl.formatMessage({
          id: "signin.validation.emailInvalid",
          defaultMessage: "Please enter a valid email address",
        })
      );
      return false;
    }
  };

  const validatePassword = () => {
    let valid = password && password.length > 5;
    if (!valid) {
      setPasswordValidationMessage(
        intl.formatMessage({
          id: "signin.validation.passwordInvalid",
          defaultMessage: "Please enter a password of at least 6 characters",
        })
      );
    }
    return valid;
  };

  const validateName = () => {
    let valid = name && name.length > 2;
    if (!valid) {
      setNameValidationMessage("Invalid name");
    }
    return valid;
  };

  const signup = () => {
    if (validateName() && validateEmail() && validatePassword()) {
      setLoading(true);

      let createUser = firebaseFunctions.httpsCallable("users-createUser");
      createUser({ email: email, name: name, password: password })
        .then((response) => {
          console.log("response is ", response);
          if (response.data) {
            setLoading(false);
            setPending(true);
            console.log("user created");
          } else {
            setLoading(false);
            setEmailValidationMessage("Can't create user");
          }
        })
        .catch((err) => {
          console.log("error in signup", err);
          setLoading(false);
          setEmailValidationMessage("Can't create user");
        });
    }
  };

  return (
    <>
      <Typography component="h1" variant="h3" className={classes.header}>
        {intl.formatMessage({
          id: "signup.header",
          defaultMessage: "Create an account",
        })}
      </Typography>
      <Box className={classes.userPassowrdContainer}>
        {!loading && ( // Hide the login form when showing spinner
          <form className={classes.emailLoginBox} noValidate autoComplete="off">
            <TextField
              required
              fullWidth
              type="email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              label={intl.formatMessage({
                id: "signin.emailAddress",
                defaultMessage: "Email:",
              })}
              variant="outlined"
              id="email"
              className={classes.emailLoginInput}
              error={emailValidationMessage && true}
              helperText={emailValidationMessage || " "}
            />
            <TextField
              required
              fullWidth
              type="text"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              label={intl.formatMessage({
                id: "signin.name",
                defaultMessage: "Your name:",
              })}
              variant="outlined"
              id="name"
              className={classes.emailLoginInput}
              error={nameValidationMessage && true}
              helperText={nameValidationMessage || " "}
            />
            <TextField
              id="standard-adornment-password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
              error={passwordValidationMessage && true}
              label={intl.formatMessage({
                id: "signin.password",
                defaultMessage: "Password:",
              })}
              helperText={passwordValidationMessage || " "}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box className={classes.captchaContainer}>
              <ReCAPTCHA
                sitekey={recaptchaKey}
                onChange={(value) => {
                  console.log("value", value);
                  setVerifiedRecaptcha(true);
                }}
              />
            </Box>

            <Box className={classes.actionsContainer}>
              Already have an account?
              <Link
                component="button"
                onClick={() => {
                  back();
                }}
                className={classes.newAccount}
                variant="text"
              >
                Sign in
              </Link>
              <Button
                disableElevation
                onClick={signup}
                className={classes.emailLoginBtn}
                color="secondary"
                variant="contained"
              >
                {intl.formatMessage({
                  id: "resetPassword.action",
                  defaultMessage: "Next",
                })}
              </Button>
            </Box>
            <Box className={classes.termsBox}>
              By signing up you agree to the <Link
              target="_blank"
              rel="noopener"
              href="/terms.html"
                
                className={classes.termsLink}
                variant="text"
              >terms and conditions</Link> and <Link
                target="_blank"
                rel="noopener"
                  href="/privacy.html"
              
                className={classes.termsLink}
                variant="text"
              >privacy policy</Link>
              </Box>
          </form>
        )}
      </Box>
      {renderLoader()}
    </>
  );
}
