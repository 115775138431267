export function getHighlights(state, task, showHighlights) {
  let grMode = state.gr.mode;
  if (state.readerActions.persistentActionState.actionBar === "comments") {
    if (state.comments.commentRef && state.comments.commentRef.cfi) {
      return [{ ...state.comments.commentRef, color: "#168fee" }];
    }
    if (state.comments.activeComment && state.comments.activeComment.ref) {
      return [{ ...state.comments.activeComment.ref, color: "#168fee" }];
    } else return [];
  }
  if (
    state.readerActions.persistentActionState.actionBar === "task" &&
    task &&
    task.id > 0
  ) {
    let highlights =
      state.task.answers &&
      state.task.answers.length >= state.task.selectedQuestionIndex &&
      state.task.answers[state.task.selectedQuestionIndex] &&
      state.task.answers[state.task.selectedQuestionIndex].quotes
        ? state.task.answers[state.task.selectedQuestionIndex].quotes
        : [];
    return highlights;
  }
  //console.log("highlight selector");
  if (state.readerActions.persistentActionState.annotatorMode === "poc") {
    let summaryHighlights =
      state.gr.forceHighlight && state.gr.highlights ? state.gr.highlights : [];
    let selectedText = state.readerActions.selectedLocation
      ? [state.readerActions.selectedLocation]
      : [];
    if (
      (grMode === "full" && state.gr.stage < 2) ||
      (grMode === "light" && state.gr.stage === 0)
    ) {
      return (
        // state.readerActions.persistentActionState.showHighlights && // Should always show highlights in GR more
        state.gr.highlights ? [...state.gr.highlights] : []
      );
    }
    if (grMode === "full" && state.gr.stage >= 3) {
      return [...summaryHighlights, ...selectedText];
    }

    let selectedQuestion = state.gr.questions.filter(
      el => el.id === state.gr.selectedQuestionId
    );
    if (
      !selectedQuestion ||
      !selectedQuestion.length ||
      selectedQuestion.length <= 0 ||
      !selectedQuestion[0].answers
    )
      return summaryHighlights;
    else return [...summaryHighlights].concat(selectedQuestion[0].answers);
  } else if (
    state.readerActions.persistentActionState.annotatorMode === "themes"
  ) {
    if (state.themes.selectedThemeId) {
      let themesFiltered = state.themes.themes.filter(
        el => el.id === state.themes.selectedThemeId
      );
      let selTheme =
        themesFiltered && themesFiltered.length ? themesFiltered[0] : 0;
      return selTheme ? selTheme.quotes : [];
    } else return [];
  } else {
    return showHighlights ? state.highlighter.highlights : [];
    // else return [];
  }
}

export const shouldShowHighlights = state => {
  if (
    state.readerActions.persistentActionState.annotatorMode === "poc" &&
    state.gr.stage >= 3
  )
    return state.gr.forceHighlights;
  else return state.readerActions.persistentActionState.showHighlights;
};
