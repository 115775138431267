// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

// Redux Dependencies
import { setAction } from "../../../redux/readerActionsSlice";
import { setActiveTaskPerText } from "../../../redux/firebaseMiddleware";
//Components
import TaskStatusChip from "./TaskStatusChip";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Chip
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "60px 0"
  },
  chip: { margin: "-8px" },

  active: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  pending: {
    backgroundColor: "transparent",
    border: "1px solid black"
  },
  delivered: {
    backgroundColor: "#0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  new: {
    border: "1px solid black"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  createBtn: {
    borderRadius: "4px",
    valign: "bottom",
    position: "absolute",
    bottom: "0px",
    right: "0px"
  },
  container: {
    width: "100w",
    height: "100vh"
  },
  clickableTask: {
    cursor: "pointer",
    "&:focus": {
      backgroundColor: theme.palette.grey[200]
    }
  },
  newTask: {
    fontWeight: "800"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "24px"
  }
}));

export default function TasksStudentView({ course, refresh, ...props }) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // Ephemeral State
  const [tasks, setTasks] = useState(null);
  const [texts, setTexts] = useState(null);

  // Redux State
  const currentUser = useSelector(state => state.user.auth.uid);

  // Behavior
  useEffect(() => {
    if (!course) {
      setTasks([]);
    } else {
      const getAllTasks = firebaseFunctions.httpsCallable(
        "courses-getStudentTasks"
      );

      getAllTasks({ course: course.id }).then(response => {
        setTasks(response.data);
      });
      const getAllTexts = firebaseFunctions.httpsCallable(
        "courses-getStudentTexts"
      );

      getAllTexts({ course: course.id }).then(response => {
        setTexts(response.data);
      });
    }
  }, [course, refresh]);

  const getDate = (str, timezone) => {
    let b = str.split(/\D/);
    let d = new Date(b[0], b[1] - 1, b[2]);
    return d;
  };

  const isNew = task => {
    let now = new Date();
    // If this is the owener
    if (currentUser === task.owner) {
      let due_date = task.due_date && getDate(task.due_date);
      due_date && due_date.setHours(24);
      return now < due_date && task.submission_status === "Pending";
      // If this is the collaborator
    } else {
      let accept_date = task.accept_date && getDate(task.accept_date);
      accept_date && accept_date.setHours(24);
      // Notice: task.submission_status here is actualy the collaboration status which gets a diffrent name by the SQL statment
      return now < accept_date && task.submission_status === "Pending";
    }
  };

  function renderTextTable() {
    return (
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="library.title" defaultMessage="Title" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="tasks.status" defaultMessage="Status" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {texts.map((text, index) => (
              <TableRow
                key={index}
                onClick={() => {
                  if (text.submission_date) {
                    history.push(
                      "/feedback/text/" +
                        text.text_id +
                        "/submission/" +
                        text.id
                    );
                  } else {
                    history.push("/reader/text/" + text.text_id);
                  }
                }}
              >
                <TableCell
                  className={clsx(classes.cell)}
                  component="th"
                  scope="row"
                >
                  {text.author} - {text.name}
                </TableCell>
                <TableCell className={clsx(classes.cell)} align={"left"}>
                  {text.submission_date && (
                    <Chip
                      className={clsx(classes.submitted, classes.chip)}
                      label={intl.formatMessage({
                        id: "tasks.status.submitted",
                        defaultMessage: "Submitted"
                      })}
                      size="small"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const getDateFormatted = (dateStr, timezone) => {
    let date = dateStr && getDate(dateStr);
    return date && date.toLocaleDateString && date.toLocaleDateString();
  };

  function renderTaskTable(tasks) {
    if (tasks <= 0) {
      return (
        <Typography variant="h6">
          There are currently no tasks assigned for this course
        </Typography>
      );
    }
    return (
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="tasks.task" defaultMessage="Task" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="tasks.status" defaultMessage="Status" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage
                    id="tasks.submission_time"
                    defaultMessage="Submitted"
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage
                    id="tasks.deadline"
                    defaultMessage="Deadline"
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="tasks.grade" defaultMessage="Grade" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => (
              <TableRow
                className={clsx(
                  isNew(task) && classes.newTask,
                  classes.clickableTask
                )}
                key={index}
                onClick={() => {
                  dispatch(setAction("task"));
                  // Direct to peerReview collaboration
                  if (
                    (currentUser !== task.owner && !task.is_checked) ||
                    (!task.is_checked &&
                      task.task_type === "peerReview" &&
                      task.submission_status === "Submitted")
                  ) {
                    dispatch(
                      setActiveTaskPerText({
                        textId: task.text_id,
                        taskUrl: `/reader/task/${task.id}/submission/${task.submission_id}`
                      })
                    );

                    history.push(
                      `/reader/task/${task.id}/submission/${task.submission_id}`
                    );

                    // Direct to teacher's feedback
                  } else if (task.is_checked) {
                    history.push(
                      "/feedback/task/" +
                        task.id +
                        "/submission/" +
                        task.submission_id
                    );
                    // Direct to task
                  } else {
                    dispatch(
                      setActiveTaskPerText({
                        textId: task.text_id,
                        taskUrl: `/reader/task/${task.id}`
                      })
                    );
                    history.push(`/reader/task/${task.id}`);
                  }
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    dispatch(setAction("task"));
                    if (task.is_checked) {
                      history.push(
                        "/feedback/task/" +
                          task.id +
                          "/submission/" +
                          task.submission_id
                      );
                    } else {
                      history.push("/reader/task/" + task.id);
                    }
                  }
                }}
                role="link"
                tabIndex="0"
              >
                <TableCell
                  className={clsx(classes.cell, isNew(task) && classes.newTask)}
                  component="th"
                  scope="row"
                >
                  {task.name}
                </TableCell>
                <TableCell
                  className={clsx(classes.cell, isNew(task) && classes.newTask)}
                  align="left"
                >
                  <TaskStatusChip {...task} userRole={course.course_role} />
                </TableCell>
                <TableCell
                  className={clsx(classes.cell, isNew(task) && classes.newTask)}
                  align="left"
                >
                  {getDateFormatted(task.submission_date, task.timezone)}
                </TableCell>
                <TableCell
                  className={clsx(classes.cell, isNew(task) && classes.newTask)}
                  align="left"
                >
                  {currentUser === task.owner
                    ? getDateFormatted(task.due_day, task.timezone)
                    : getDateFormatted(task.accept_day, task.timezone)}
                </TableCell>
                <TableCell
                  className={clsx(
                    classes.cell,
                    isNew(task) && classes.newTask,
                    classes.link
                  )}
                  align="left"
                >
                  <Typography className={classes.pointer}>
                    {task.totalpoints
                      ? "" +
                        (task.grade ? task.grade : "") +
                        "/" +
                        task.totalpoints
                      : task.is_checked &&
                        intl.formatMessage({
                          id: "tasks.see.feedback",
                          defaultMessage: "See feedback"
                        })}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  // render
  if (!tasks) return <PangeaSpinner />;
  else
    return (
      <>
        <Box className={classes.tasksHeader}>
          <Typography component="h1" variant="h4">
            {course.name}
          </Typography>
        </Box>

        {texts?.length > 0 && renderTextTable()}

        {renderTaskTable(tasks)}
      </>
    );
}
