// Dependencies
import React, { useState, useEffect } from "react";
import { firebaseFunctions } from "../../../firebase";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
// Redux
import { useSelector, useDispatch } from "react-redux";
import PublishedTaskView from "./PublishedTaskView";
import { updateCourses } from "../../../redux/coursesSlice";

import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

//Components
import ScrollBox from "../../SharedComponents/ScrollBox";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => {
  return {
    container: {
      flex: 1,
      height: "100%",
      width: "83.333333%", // 10 culumns on a 12 column grid
      paddingInline: 40,
      alignItems: "center",
      position: "relative",

      [theme.breakpoints.up("desktop")]: {
        maxWidth: 840,
        marginInline: "auto"
      }
    }
  };
});

export default function PublishedTaskContainer() {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { course_id, task_id } = useParams();
  const classes = useStyles();

  // Redux state
  const courses = useSelector(state => state.courses.courses);

  // Ephemeral state
  const [task, setTask] = useState(null);
  const [course, setCourse] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  //Variables

  useEffect(() => {
    if (task_id) {
      if (!history.location.state?.task) {
        let getAllTasks = firebaseFunctions.httpsCallable("courses-getTasks");
        getAllTasks({ course: course_id }).then(response => {
          const newTask = response.data.filter(t => t.id == task_id)?.[0];

          let submissions = firebaseFunctions.httpsCallable(
            "courses-getTaskSubmissions"
          );
          submissions({ course: course_id }).then(response => {
            const submissions = response.data.filter(t => t.task_id == task_id);
            if (newTask) {
              setTask({ ...newTask, submissions: submissions });
            }
          });
        });
      } else setTask(history.location.state?.task);
    }
  }, [task_id, course_id]);

  useEffect(() => {
    if (!courses?.length) {
      var getMyCourses = firebaseFunctions.httpsCallable(
        "courses-getMyCourses"
      );
      getMyCourses().then(response => {
        dispatch(updateCourses(response.data));
      });
    }
  }, [course_id, courses]);

  useEffect(() => {
    console.log("setting course");
    const c = courses.filter(el => el.id == course_id)?.[0];

    setCourse(c);
  }, [course_id, courses]);

  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Tasks"
      }),
      url: "/tasks",
      resetCourse: true
    });

    course && parts.push({ text: course.name, url: "/tasks/" + course.id });

    task &&
      task.name &&
      course &&
      parts.push({
        text: task.name,
        url: "/tasks/course/" + course.id + "/task/" + task.id + "/submission/0"
      });

    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course, task]);

  if (task && course) {
    return (
      <ScrollBox alignItems="center">
        <Box className={classes.container}>
          <PublishedTaskView
            course={course}
            task={task}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
          />
        </Box>
      </ScrollBox>
    );
  } else return null;
}
