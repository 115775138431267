// Dependencies
import React, { useEffect } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

// Redux dependencies
import { useSelector } from "react-redux";

// Components
import ReaderView from "./ReaderView";
import PocFeedback from "./components/poc/PocFeedback";
import TaskFeedback from "./components/Tasks/TaskFeedback/taskFeedback";
import TaskStatsView from "./components/Tasks/Stats/TaskStatsView";
import TasksAdminView from "./components/Tasks/Admin/TasksAdminView";
import TaskEvaluation from "./components/Tasks/Admin/TaskEvaluation";

import CreateTaskScreen from "./components/Tasks/CreateTask/CreateTaskScreen";

import Library from "./components/Library/Library";
import ContactUs from "./ContactUs";
import PermissionDenied from "./components/auth/PermissionDenied";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import Tasks from "./components/Tasks/Tasks";
import Logout from "./components/auth/Logout";
import PlainTextExample from "./poc";
import Notifications from "./Notifications";
export function ScrollToTopOnMount() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default function Router({ ...props }) {
  // Redux state
  const userRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );

  //Render
  return (
    <Switch>
      <Route exact path="/">
        <Library />
      </Route>
      <Route exact path="/reader/text/:text_id">
        <ReaderView />
      </Route>
      <Route exact path="/feedback/text/:text_id/submission/:submission_id">
        <ScrollToTopOnMount />
        <PocFeedback />
      </Route>
      <Route exact path="/feedback/task/:task_id/submission/:submission_id">
        <ScrollToTopOnMount />
        <TaskFeedback />
      </Route>
      <Route
        exact
        path="/feedback/task/:task_id/submission/:submission_id/question_id/:question_id"
      >
        <ScrollToTopOnMount />
        <TaskFeedback />
      </Route>

      <Route exact path="/stats/task/:task_id">
        <ScrollToTopOnMount />
        <TaskStatsView />
      </Route>
      <Route exact path="/reader/task/:task_id">
        <ReaderView />
      </Route>
      <Route exact path="/reader/task/:task_id/submission/:submission_id">
        <ReaderView />
      </Route>

      <Route
        exact
        path="/tasks/:course_id/new/:task_id"
        render={({ match }) =>
          userRole === "Teacher" ? (
            <>
              <ScrollToTopOnMount />
              <CreateTaskScreen />
            </>
          ) : (
            <Redirect to={`/tasks/${match.params.course_id}`} />
          )
        }
      ></Route>

      <Route
        exact
        path="/tasks/:course_id/new"
        render={({ match }) =>
          userRole === "Teacher" ? (
            <>
              <ScrollToTopOnMount />
              <CreateTaskScreen />
            </>
          ) : (
            <Redirect to={`/tasks/${match.params.course_id}`} />
          )
        }
      ></Route>
      <Route path="/tasks">
        <ScrollToTopOnMount />
        <Tasks />
      </Route>
      <Route exact path="/adminEvaluation/:submission_id">
        <ScrollToTopOnMount />
        <TaskEvaluation />
      </Route>
      <Route exact path="/adminTaskView/:task_id">
        <ScrollToTopOnMount />
        <TasksAdminView />
      </Route>

      <Route exact path="/test" component={PlainTextExample} />
      <Route exact path="/library" component={Library} />
      <Route exact path="/library/:course_id" component={Library} />
      <Route exact path="/contactUs" component={ContactUs} />
      <Route exact path="/profile">
        <ProfilePage />
      </Route>
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/permissionDenied" component={PermissionDenied} />
    </Switch>
  );
}
