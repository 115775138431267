// Dependencies
import React from "react";
import clsx from "clsx";
// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setAction } from "../../../redux/readerActionsSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Typography, IconButton } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: "#616161",
    margin: "16px 0"
  },
  container: {
    paddingInline: "24px",
    paddingBlockStart: "12px",
    paddingBlockEnd: "48px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": { color: "white" }
  },
  title: {
    color: "white",
    fontSize: "20px",
    marginBottom: "16px"
  },
  subtitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase"
  },
  instructions: {
    color: "white",
    fontStyle: "normal",
    whiteSpace: "pre-wrap"
  },
  body: {
    flexGrow: 1,
    padding: "24px"
  },
  editorContainer: {
    height: "100%"
  },
  editor: {
    height: "100%",
    paddingInline: "24px",
    paddingBlock: "16px",
    border: "1px solid #616161",
    borderRadius: "4px",
    color: "white",
    lineHeight: "2"
  },
  editorHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    fontSize: "12px",
    "& button": { color: "white" }
  },

  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px"
  }
}));

export default function PeerReviewSidebar({ ...props }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state
  const task = useSelector(state => state.task.taskRow);

  // Ephemeral state

  // Variables

  // Behavior

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.subtitle} color="textPrimary">
          Peer review task
        </Typography>
        <IconButton
          aria-label="close sidebar"
          size="small"
          onClick={() => {
            dispatch(setAction(""));
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Typography className={clsx(classes.title)} color="textPrimary">
        {task.name}
      </Typography>

      <Typography className={classes.instructions} color="textPrimary">
        {task.task_description}
      </Typography>
    </Box>
  );
}
