// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useIntl, FormattedMessage } from "react-intl";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { setAnnotatorMode } from "../../redux/readerActionsSlice";
import { updateThemes, getNextId } from "../../redux/firebaseMiddleware";
import { setThemes } from "../../redux/themeSlice";
import { enqueueFlashMessage, undo } from "../../redux/userSlice";

//Components
import { ThemePanelBox } from "./ThemePanelBox";
import EditingButton from "../EditingButton";
import ThemeQuotesList from "./ThemeQuotesList";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  Typography,
  List,
  ListItem
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  themeContainer: {
    height: "calc(100vh - 120px)",
    overscrollBehavior: "none",
    "overflow-y": "auto",

    paddingBottom: "80px",
    marginBottom: "40px"
  },
  addingLine: {
    backgroundColor: "#5ec891",
    color: "black"
  },
  divider: {
    backgroundColor: "#616161",
    marginBottom: "12px",
    marginTop: "8px"
  },
  panelHeaderRtl: {
    paddingRight: "16px"
  },
  panelHeaderLtr: {
    paddingLeft: "16px"
  },
  panelHeader: {
    color: "white",
    width: "100%",
    marginBottom: "16px"
  },
  fullHeight: {
    height: "max-content"
  },
  li: {
    boxSizing: "border-box",
    paddingInline: "16px",
    paddingBlock: 0,
    display: "flex",
    fontSize: "16px",
    lineHeight: "24px",
    position: "relative",
    color: "#e0e0e0",
    borderBottom: "1px solid transparent",
    "&:hover": {
      borderBottom: "1px solid #5ec891"
    },

    "&:hover button": {
      visibility: "visible"
    }
  },
  liLtr: {
    textAlign: "left",
    "& button": {
      right: "0px"
    }
  },
  liRtl: {
    textAlign: "right",
    "& button": {
      left: "0px"
    }
  },
  addThemeLtr: {
    justifyContent: "left"
  },

  addThemeRtl: {
    justifyContent: "right"
  },

  addTheme: {
    textTransform: "none"
  },
  themeText: {
    color: "#fafafa",
    fontSize: "16px"
  },
  selectedQuestion: {
    color: "black"
  },
  questionIcon: {
    width: "24px",
    height: "24px",
    padding: "0px",
    display: "inline-flex"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "24px",
    marginTop: "8px",
    textTransform: "uppercase"
  },
  panelSubtitle: {
    color: "#fafafa",
    fontSize: "20px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.15px",
    lineHeight: "32px"
  },
  listItem: {
    padding: 0,
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    // FIXME: this is a hack
    "&>div": {
      width: "100%"
    }
  }
}));

export default function ThemesManager({ readText, selectTheme, ...props }) {
  //Hooks
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();

  // Redux State
  const themes = useSelector(state => state.themes.themes);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral State
  const [openThemes, setOpenThemes] = React.useState([]);
  const [editingMode, setEditingMode] = useState(0);
  const [newThemeMode, setNewThemeMode] = useState({
    adding: false,
    value: ""
  });
  const [undoData, setUndoData] = useState(null);

  //Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoDeleteTheme = () => {
      if (undoData?.type === "themeDropped") {
        dispatch(
          updateThemes({
            textId: undoData.textId,
            themes: undoData.themes
          })
        );
      }
    };

    if (shouldUndo) {
      undoDeleteTheme();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, undoData]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "themes.delete_msg",
            defaultMessage: "Theme deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch, intl]);

  useEffect(() => {
    if (newThemeMode.addValue && newThemeMode.shouldAdd) {
      let themeId = getNextId(themes);
      dispatch(
        updateThemes({
          textId: selectedTextId,
          themes: [
            ...themes,
            { id: themeId, name: newThemeMode.addValue, quotes: [] }
          ]
        })
      );
      setNewThemeMode({
        value: "",
        adding: newThemeMode.adding,
        addValue: "",
        shouldAdd: false
      });

      dispatch(setAnnotatorMode("themes"));
      dispatch(selectTheme({ id: themeId }));
    }
  }, [newThemeMode, dispatch, selectTheme, selectedTextId, themes]);

  const toggleOpenTheme = id => {
    if (openThemes.includes(id)) {
      setOpenThemes(openThemes.filter(el => el !== id));
    } else {
      setOpenThemes([...openThemes, id]);
    }
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === "themes") {
      const reorderedThemes = reorder(
        themes,
        result.source.index,
        result.destination.index
      );
      dispatch(setThemes(reorderedThemes));
      dispatch(
        updateThemes({ textId: selectedTextId, themes: reorderedThemes })
      );
    } else {
      const updatedIndex = parseInt(result.type, 10);
      let quote = themes[updatedIndex].quotes[result.source.index];
      let updatedTheme = themes[result.destination.index];
      let foundCfi = updatedTheme.quotes.filter(el => el.cfi === quote.cfi);
      if (!foundCfi || !foundCfi.length) {
        const updatedThemes = themes.map((theme, index) => {
          if (theme.id === updatedTheme.id) {
            return { ...updatedTheme, quotes: [...updatedTheme.quotes, quote] };
          } else return theme;
        });
        //Cloning becouse the qoutes property is read-only
        dispatch(setThemes(updatedThemes));
        dispatch(
          updateThemes({ textId: selectedTextId, themes: updatedThemes })
        );
      }
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const addTheme = () => {
    //props?.scrollToBottom();
    //  panelRef.current.scrollTop = panelRef.current.scrollHeight;

    if (newThemeMode.adding) {
      if (newThemeMode.value) {
        let newId = getNextId(themes);
        dispatch(
          updateThemes({
            textId: selectedTextId,
            themes: [
              ...themes,
              { name: newThemeMode.value, id: newId, quotes: [] }
            ]
          })
        );
        dispatch(selectTheme({ id: newId }));
        dispatch(setAnnotatorMode("themes"));
        setNewThemeMode({ adding: true, value: "" });
      }
    } else {
      if (themes.length > 0 && themes[0].placeholder && !themes[0].name) {
        setEditingMode(themes[0].id);
      } else {
        setNewThemeMode({ adding: true, value: "" });
      }
    }
  };

  const renderNewTheme = () => {
    if (newThemeMode.adding) {
      return (
        <ListItem
          className={clsx(
            classes.li,
            classes.liLtr,
            classes.editmodeLine,
            classes.addingLine
          )}
        >
          <EditingButton
            editIcon={<React.Fragment />}
            colorClass={newThemeMode && newThemeMode.value && "black"}
            onFocusOut={item => {
              if (item) {
                let newId = getNextId(themes);
                dispatch(
                  updateThemes({
                    textId: selectedTextId,
                    themes: [...themes, { id: newId, name: item }]
                  })
                );
                dispatch(selectTheme({ id: newId }));
                dispatch(setAnnotatorMode("themes"));
              }
              setNewThemeMode({ adding: false, value: "" });
            }}
            editingMode={true}
            multiline={true}
            key={"edit_" + themes.length}
            text={newThemeMode.value}
            onChange={ev => {
              setNewThemeMode({ adding: true, value: ev.target.value });
            }}
            onKeyPress={ev => {
              if (ev.key === "Enter") {
                // Do code here
                if (newThemeMode.value) {
                  setNewThemeMode({
                    adding: false,
                    value: "",
                    addValue: newThemeMode.value,
                    shouldAdd: true
                  });
                } else {
                  setNewThemeMode({
                    adding: false,
                    value: "",
                    addValue: "",
                    shouldAdd: false
                  });
                }
                ev.preventDefault();
              } else {
                dispatch(selectTheme(false));
              }
            }}
          />
        </ListItem>
      );
    } else return "";
  };

  const renderTheme = (item, index) => {
    return (
      <ListItem className={classes.listItem} key={item.id}>
        <Draggable draggableId={`theme-${item.id}`} index={index}>
          {(provided, snapshot) => (
            <>
              <div
                // className={classes.listItem}
                ref={provided.innerRef}
                {...{ ...provided.draggableProps }}
                {...provided.dragHandleProps}
                tabIndex="-1"
              >
                <ThemePanelBox
                  onDelete={e => {
                    setUndoData({
                      type: "themeDropped",
                      themes,
                      textId: selectedTextId
                    });
                    dispatch(
                      updateThemes({
                        textId: selectedTextId,
                        themes: themes.filter(el => el.id !== item.id)
                      })
                    );
                  }}
                  theme={item}
                  id={item.id}
                  index={index}
                  key={index}
                  isEditing={editingMode === item.id}
                  changedPlaceholder={() => {
                    setNewThemeMode({
                      value: "",
                      adding: true,
                      addValue: "",
                      shouldAdd: false
                    });
                  }}
                  onDoneEdit={() => {
                    setEditingMode(0);
                  }}
                  openThemes={openThemes}
                  toggleOpenTheme={toggleOpenTheme}
                />
              </div>
              <ThemeQuotesList
                readText={readText}
                theme={item}
                themeIndex={index}
                selectTheme={selectTheme}
                openThemes={openThemes}
              />
            </>
          )}
        </Draggable>
      </ListItem>
    );
  };

  // Render
  return (
    <>
      <Box
        className={clsx(
          classes.panelHeader,
          classes.panelHeaderLtr,
          classes.left
        )}
      >
        <Typography
          className={clsx(classes.panelTitle, classes.left)}
          color="textPrimary"
        >
          <FormattedMessage
            id="actionBar.tooltip.themes"
            defaultMessage="THEMES"
          />
        </Typography>
        <Typography
          className={clsx(classes.panelSubtitle, classes.left)}
          color="textPrimary"
        >
          <FormattedMessage id="theme.subtitle" defaultMessage="My Themes" />
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box
        className={clsx(
          classes.panelHeader,
          classes.panelHeaderLtr,
          classes.left
        )}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={addTheme}
          className={clsx(classes.addTheme, classes.addThemeLtr)}
        >
          <FormattedMessage id="theme.addTheme" defaultMessage="Add Theme" />
        </Button>
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="themes">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.fullHeight}
            >
              {themes.map((item, index) => renderTheme(item, index))}
              {renderNewTheme()}

              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
