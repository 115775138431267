import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CitationDialog from "./CitationDialog";
import {
  Box,
  Link,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import EditChoice from "./EditChoice";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  editBtn: {
    color: theme.palette.grey["400"],
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  blue: {
    "&$checked": {
      color: "#168fee",
    },
    "&.Mui-checked": {
      color: "#168fee",
    },
  },
  includeCitation: {
    display: "inline-block",
    width: "75%",
    // color: "#BDBDBD",
    color: theme.palette.text.disabled,
  },
  fakeLink: {
    fontSize: "1rem",
    color: "#168fee",
    "&:hover": {
      textDecoration: "none",
    },
  },
  txtInput: {
    width: "100%",
    "& ::placeholder": {
      color: "#168fee",
    },
  },
  paddedContent: {
    paddingTop: "16px",
    paddingbottom: "16px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  checkboxChecked: {
    // color: "rgba(0, 0, 0, 0.87)",
    color: theme.palette.text.primary,
  },
}));

function CreateMultiQuestion(props) {
  const classes = useStyles();

  const changeAnswer = (index, e) => {
    let items = props.options;

    items[index] = e.target.value;
    props.onUpdate(items, props.answer.shouldSelect);
  };

  const deleteItem = (index) => {
    let items = [...props.options];
    items.splice(index, 1);
    setEditingMode(false);
    let newShouldSelect = -1;
    if (props.answer.shouldSelect > index) {
      newShouldSelect = props.answer.shouldSelect - 1;
    }
    if (props.answer.shouldSelect < index) {
      newShouldSelect = props.answer.shouldSelect;
    }
    props.onUpdate([...items], newShouldSelect);
  };

  const possiblyDeleteAnswer = (e, index) => {
    if (e.key === "Enter") {
      setEditingMode(false);
      if (e.target.value === "") {
        let items = [...props.options];
        items.splice(index, 1);
        props.onUpdate(
          items,
          props.answer.shouldSelect > index
            ? props.answer.shouldSelect - 1
            : props.answer.shouldSelect
        );
      } else {
        if (editingMode === props.options.length - 1) {
          if (newInputRef && newInputRef.current) {
            newInputRef.current.focus();
          }
        }
      }
    }
  };

  const [editingMode, setEditingMode] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const choicesRef = React.useRef();
  const newInputRef = React.useRef();
  const intl = useIntl();

  const renderNewOption = () => {
    if (props.readOnly) return null;
    else
      return (
        <FormControlLabel
          key={props.options.length + 1}
          id="addNewOption"
          className={classes.input}
          control={<Radio className={classes.blue} checked={false} />}
          label={
            <TextField
              id="addOption"
              className={classes.txtInput}
              value={""}
              inputRef={newInputRef}
              multiline={true}
              text={""}
              placeholder={intl.formatMessage({
                id: "task.addOption",
                defaultMessage: "Add new option",
              })}
              onChange={(e) => {
                props.onUpdate(
                  [...props.options, e.target.value],
                  props.answer.shouldSelect
                );
                setEditingMode(props.options.length);
                e.preventDefault();
              }}
            />
          }
        />
      );
  };
  //console.log("{props.answer.shouldSelect",props.answer.shouldSelect);
  return (
    <Box ref={choicesRef}>
      <Box
        className={clsx(
          classes.paddedContent,
          classes.includeCitationContainer
        )}
      >
        <Box className={classes.includeCitation}>
          <Typography component="span">
            <FormControlLabel
              control={
                <Checkbox
                  readonly={props.readOnly}
                  checked={props.includeCitation}
                  onChange={() => {
                    props.setIncludeCitation(!props.includeCitation);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography
                  className={clsx(
                    props.includeCitation && classes.checkboxChecked
                  )}
                >
                  {intl.formatMessage({
                    id: "task.includeCitation",
                    defaultMessage: "Include citation in answer",
                  })}
                </Typography>
              }
            />
          </Typography>
          {props.includeCitation && (
            <Typography component="span">
              <Link
                component="button"
                variant="body2"
                className={clsx(classes.fakeLink)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenDialog(true);
                }}
              >
                <FormattedMessage
                  id="task.selectCitation"
                  defaultMessage=" Select citation"
                />
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.paddedContent}>
        <Typography className={classes.expandMsg} variant="body1">
          <FormattedMessage
            defaultMessage="Type answer options and mark the correct answer"
            id="task.create.multiple.instructions"
          />
        </Typography>
        <RadioGroup name="options" value={props.answer.shouldSelect}>
          {props.options.map((item, index) => {
            return (
              <EditChoice
                readOnly={props.readOnly}
                editingMode={editingMode}
                key={index} //using just index as key
                //won't refresh items when  deleting options in the middle causes problems
                deleteItem={deleteItem}
                setEditingMode={(index) => {
                  setEditingMode(index);
                }}
                onUpdateChoice={(index) => {
                  props.onUpdate(props.options, index);
                }}
                changeAnswer={(e) => {
                  changeAnswer(index, e);
                }}
                possiblyDeleteAnswer={possiblyDeleteAnswer}
                shouldSelect={props.answer.shouldSelect}
                item={item}
                index={index}
              />
            );
          })}

          {renderNewOption()}
        </RadioGroup>
        <CitationDialog
          setOpenDialog={setOpenDialog}
          url={props.url}
          location={props.location}
          highlights={props.highlights}
          openDialog={openDialog}
          addHighlight={!props.readOnly && props.addHighlight}
          removeHighlight={!props.readOnly && props.removeHighlight}
        />
      </Box>
    </Box>
  );
}

export default CreateMultiQuestion;
