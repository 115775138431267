// Dependencies
import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { firebaseFunctions, firebaseApp, recaptchaKey } from "../../firebase";
import { auth, googleAuthProvider } from "../../firebase";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// Redux dependencies

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, 
        Input, 
        TextField,

        InputAdornment,
        Typography, 
        IconButton,
        Button } from "@material-ui/core";


//Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      backgroundImage: 'url("/resources/landingImage20211023.jpg")',
      backgroundSize: "auto 100vh",
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat",
    },
    backBtn:{
     position:'absolute',
     left:'-48px',
    },
    newAccount:{
      color:theme.palette.secondary.main,
    },
    resetLink:{
      right:'0px',
      color:theme.palette.secondary.main,
      alignSelf:"flex-end",
    },
  
    main: {
      flexBasis: "50%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      whiteSpace: "nowrap",
      marginBottom:"60px",
    },
    loginBox: {
      marginTop: "160px",
      //height: "50%",
      //display: "flex",
      //flexFlow: "column nowrap",
      //flexBasis: "50%",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      position:'relative',
    },
    googleLoginBtn: {
      padding: "12px",
      marginBottom:'40px',
      width: "100%",
      "& .MuiButton-startIcon": {
        marginInlineEnd: "auto",
      },
    },
    googleLoginBtnText: {
      marginInlineEnd: "auto",
      fontSize: "16px",
    },
    emailLoginBox: {
      width: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
    },
    emailLoginInput: {
      marginBlockEnd: "8px",
    },
  };
});

export default function PasswordReset({pending, setPending, back}) {
  // Hooks
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  // Ephemeral state

   const [loading, setLoading] = useState(false);
   const [password, setPassword] = useState("");
   const [passwordValidationMessage, setPasswordValidationMessage]= useState(null);
   const [showPassword, setShowPassword] = useState(false);

   const resetPassword = ()=>{
    if (validatePassword()) {
      let sp = new URLSearchParams(location.search);
      let code = sp.get("code");
      setLoading(true);
      firebaseApp.auth().confirmPasswordReset(code,password).then(val=> {
        setPending(true);
        setLoading(false);
      }).catch(err=> {
        setLoading(false);
        console.log("err", err);
        if (err.code === "auth/expired-action-code") {
          setPasswordValidationMessage(intl.formatMessage({
              id: "signin.validation.passwordExpired",
              defaultMessage: "Reset Link Expired",
            }));
        }
        if (err.code === "auth/expired-action-code") {
          setPasswordValidationMessage(intl.formatMessage({
              id: "signin.validation.passwordResetExpired",
              defaultMessage: "Reset Link Expired",
            }));
        }
        if (err.code === "auth/invalid-action-code") {
          setPasswordValidationMessage(intl.formatMessage({
              id: "signin.validation.passwordResetInvalid",
              defaultMessage: "Invalid reset link",
            }));
        }
        if (err.code === "auth/user-disabled" || 
              err.code === "auth/user-not-found") {
          setPasswordValidationMessage(intl.formatMessage({
              id: "signin.validation.userNotFound",
              defaultMessage: "User not found",
            }));
        }

        if (err.code === "auth/weak-password") {
    setPasswordValidationMessage(intl.formatMessage({
        id: "signin.validation.weakPassword",
        defaultMessage: "Weak Password",
      }));
        }

      });

    }
   }
   function validatePassword() {
    let valid =  password && password.length > 5;
    if (!valid) {
      setPasswordValidationMessage(intl.formatMessage({
        id: "signin.validation.passwordInvalid",
        defaultMessage: "Please enter a password of at least 6 characters",
      }));
    }
    return valid;
  }
  
  const renderLoader = () => {
    if (loading) {
      return (
        <Box>
          <ClipLoader color="#5ec891" loading="true" size={150} />
        </Box>
      );
    }
  };

      return (
        <>
         
        <Typography
          component="h1"
          variant="h3"
          className={classes.header}
        >
          
          {intl.formatMessage({
            id: "resetPassword.header",
            defaultMessage: "Reset Password",
          })}
        </Typography>
        
          
        <Box className={classes.userPassowrdContainer}>
        {!loading && ( // Hide the login form when showing spinner
          <form
            className={classes.emailLoginBox}
            noValidate
            autoComplete="off"
            
            >
            <TextField
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordValidationMessage && true}
            helperText={passwordValidationMessage || " "}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=> {setShowPassword(!showPassword)}}
                  onMouseDown={(e)=> {e.preventDefault()}}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
          
            }}
  
            />
  
  <Button
            disableElevation
           
            className={classes.emailLoginBtn}
            color="secondary"
            variant="contained"
           onClick={
             resetPassword
           }
          >
            {intl.formatMessage({
              id: "resetPassword.action",
              defaultMessage: "Confirm",
            })}
          </Button>
            </form> )}

       {!loading && (     <Button 
onClick={()=>{
 back()
}}
className={classes.resetLink} variant="text">
 Back to login
  </Button>
       )}
            </Box>
            {renderLoader()}
            </> 
        );
  }