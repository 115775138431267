// Dependencies
import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useDocument } from "react-firebase-hooks/firestore";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import { firestore } from "../../../firebase";
import RichTextEditor from "../../SharedComponents/RichTextEditor";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Link } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  replyHeader: {
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    letterSpacing: "1px",
    textTransform: "uppercase",
    paddingInlineStart: theme.spacing(2.5)
  },
  replyFooter: {
    marginBlockEnd: "12px"
  },
  replyBtn: {
    color: theme.palette.text.blue,
    fontSize: "12px",
    marginBlockEnd: "8px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    "&:hover": {
      textDecoration: "none"
    }
  },
  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px",
    marginBlockEnd: "12px"
  },
  hide: {
    display: "none"
  }
}));

export default function PeerReviewReply({ reply, setReply, ...props }) {
  // Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const editorRef = useRef();

  // Redux state
  const currentUser = useSelector(state => state.user.auth);
  const task = useSelector(state => state.task.taskRow);
  const breadcrumbs = useSelector(state => state.readerActions.breadcrumbs);

  // Ephemeral state
  const [showReply, setShowReply] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  // Firestore
  const [taskAnswers, answersLoading, answersError] = useDocument(
    firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`)
  );
  // Variables

  // Behavior
  useEffect(() => {
    // try to fetch stored answer from firebase or initialize answer
    if (task.collaboration_status && task.collaboration_status !== "Answered") {
      const emptyAnswer = {
        displayName: currentUser.displayName,
        data: {}
      };

      if (!answersLoading && !taskAnswers?.exists) {
        setReply(emptyAnswer);
      } else if (!answersLoading && taskAnswers?.exists) {
        let data = taskAnswers.data();

        if (setReply && data.reply) setReply(data.reply);
        // user might have a reflection saved in the doc
        else setReply(emptyAnswer);
      }
    }
  }, [currentUser, task, answersLoading, setReply, taskAnswers]);

  useEffect(() => {
    if (
      currentUser.uid === task.owner &&
      task.collaboration_status !== "Answered"
    ) {
      dispatch(
        setBreadcrumbs({
          breadcrumbs: breadcrumbs,
          blue: true,
          showTextMenu: false,
          showSubmitTask: showSubmitButton
        })
      );
    }
  }, [
    dispatch,
    intl,
    showSubmitButton,
    currentUser.uid,
    task.collaboration_status,
    breadcrumbs,
    task.owner
  ]);

  useEffect(() => {
    if (task.collaboration_status === "Answered") {
      setShowSubmitButton(false);
      setShowReply(true);
    }
  }, [task.collaboration_status]);

  function saveReply(content) {
    // update ephemeral state
    setReply({ displayName: currentUser.displayName, data: content });
    // save user input in firestore to prevent it from getting lost on refrash.
    try {
      if (!taskAnswers?.exists) {
        firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`).set({
          reply: {
            displayName: currentUser.displayName,
            data: { content }
          }
        });
      } else {
        firestore.doc(`tasks/${currentUser.uid}/task/${task.id}`).update({
          reply: { displayName: currentUser.displayName, data: content }
        });
      }
    } catch (err) {
      console.log("error in saveReflection update ", err);
    }
  }

  if (
    (currentUser.uid === task.owner &&
      task.collaboration_status === "Submitted") ||
    (reply && task.collaboration_status === "Answered")
  ) {
    return (
      <>
        <Box className={classes.replyFooter}>
          <Link
            className={clsx(classes.replyBtn, showReply && classes.hide)}
            component="button"
            variant="body2"
            onClick={() => {
              setShowReply(true);
              editorRef?.current && editorRef.current.focus();
            }}>
            Reply
          </Link>

          <Box
            className={clsx(classes.replyHeader, !showReply && classes.hide)}>
            Reply
          </Box>
          <Box className={clsx(classes.userInput, !showReply && classes.hide)}>
            {reply && (
              <RichTextEditor
                hasContentOnBlur={setShowReply}
                hasContentOnChange={
                  task.collaboration_status !== "Answered" &&
                  setShowSubmitButton
                }
                hasContent={setShowReply}
                showWordCount={task.collaboration_status !== "Answered"}
                // wordLimit={wordLimit}
                subcribeToUnload={false}
                readOnly={
                  currentUser.uid !== task.owner ||
                  task.collaboration_status === "Answered"
                }
                onChange={saveReply}
                index={0}
                placeholder={false}
                comment={reply?.data}
                editorRef={editorRef}
              />
            )}
          </Box>
        </Box>
      </>
    );
  } else return null;
}
