import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Box, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  setShowCitationForQuestion,
  taskSelectors
} from "../../redux/taskSlice.js";
import { updateTask } from "../../redux/firebaseMiddleware";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    display: "flex",
    alignItems: "center"
  },
  indexContainer: {
    overflow: "hidden",
    flex: 1
  },
  carousel: {
    display: "flex",
    alignItems: "center"
  },
  carouselItem: {
    width: 48,
    height: 48,
    padding: 0,
    flexShrink: 0,
    marginLeft: "calc((25% - 48px) / 2)",
    marginRight: "calc((25% - 48px) / 2)",
    color: "white"
  },
  alignToStart: {
    marginRight: 0,
    marginLeft: theme.spacing(2)
  },
  selected: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  number: {
    fontFamily: "Crimson Pro",
    fontSize: 23,
    height: 38,
    color: "inherit"
  },
  carouselArrow: {
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      opacity: 0.2
    }
  }
}));
function QuestionsCarousel({ questionsOrder = [], updateTaskFeedbackState }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const taskAnswers = useSelector(state => state.task.answers);
  const selectedTaskId = useSelector(state => state.task.selectedTaskId);
  const question = useSelector(taskSelectors.question);
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const { submission_id } = useParams();

  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  const [displayIndex, setDisplayIndex] = useState(() => {
    getDisplayIndex();
  });
  const [lastVisibleIndex, setLastVisibleIndex] = useState(
    Math.max(Math.min(selectedQuestionIndex + 3, questionsOrder.length - 1), 3)
  );

  function getDisplayIndex(currentDisplayIndex) {
    const q = questionsOrder.filter(
      q => q.questionIndex === selectedQuestionIndex
    );
    const displayIndexes = q.map(_q => _q.displayIndex);
    if (currentDisplayIndex && displayIndexes.includes(currentDisplayIndex)) {
      return currentDisplayIndex;
    }
    return q[0] ? q[0].displayIndex : 0;
  }

  useEffect(() => {
    return () => {
      dispatch(setShowCitationForQuestion(false));
    };
  }, [dispatch]);

  useEffect(() => {
    setDisplayIndex(d => getDisplayIndex(d));
  }, [questionsOrder, selectedQuestionIndex]);

  useEffect(() => {
    // this is hack due to a race condition see commoment in taskSlice selectors.taskSelectors
    setLastVisibleIndex(
      Math.max(Math.min(displayIndex + 3, questionsOrder.length - 1), 3)
    );
  }, [displayIndex]);

  useEffect(() => {
    try {
      if (!questionsOrder[displayIndex]) return;
      // There is a racing condition beween questionsOrder and displayIndex
      dispatch(
        setShowCitationForQuestion(
          questionsOrder[displayIndex].mode === "Citations"
        )
      );
    } catch (e) {
      console.error(e);
    }
  }, [displayIndex]);

  function updateAnswerMode(index) {
    if (submission_id >= 0) {
      // this Carousel is in context of a teacher feedback.
      updateTaskFeedbackState(index);
    } else {
      // This Carousel is in context of a studant.
      dispatch(
        updateTask({
          taskId: selectedTaskId,
          answers: taskAnswers,
          selectedQuestion: index
        })
      );
    }
  }

  function BackwardBtn() {
    return (
      <IconButton
        className={clsx(classes.carouselArrow)}
        disabled={lastVisibleIndex - 3 <= 0}
        onClick={() => {
          setLastVisibleIndex(Math.max(lastVisibleIndex - 4, 3));
        }}
      >
        {rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    );
  }

  function ForwardBtn() {
    return (
      <IconButton
        className={clsx(classes.carouselArrow)}
        disabled={lastVisibleIndex >= questionsOrder.length - 1}
        onClick={() =>
          setLastVisibleIndex(
            Math.min(lastVisibleIndex + 4, questionsOrder.length - 1)
          )
        }
      >
        {rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    );
  }

  return (
    <Box className={classes.carouselContainer}>
      {questionsOrder.length > 4 && <BackwardBtn />}
      <Box className={classes.indexContainer}>
        <motion.div
          className={classes.carousel}
          animate={{
            x: `${rtl ? "+" : "-"}${(lastVisibleIndex - 3) * 25}%`
          }}
          transition={{ type: "spring", bounce: 0.25 }}
        >
          {questionsOrder.map((item, i) => (
            <IconButton
              key={i}
              className={clsx(classes.carouselItem, {
                [classes.selected]: displayIndex === i,
                [classes.alignToStart]: questionsOrder.length <= 4
              })}
              onClick={() => {
                // console.log(question);
                // console.log(item);
                setDisplayIndex(
                  item.displayIndex === undefined ? i : item.displayIndex
                );
                updateAnswerMode(
                  typeof item.questionIndex === undefined
                    ? i
                    : item.questionIndex
                );
                // }
              }}
            >
              <Typography className={classes.number}>{i + 1}</Typography>
            </IconButton>
          ))}
        </motion.div>
      </Box>
      {questionsOrder.length > 4 && <ForwardBtn />}
    </Box>
  );
}

QuestionsCarousel.propTypes = {
  questionsOrder: PropTypes.arrayOf(
    PropTypes.shape({
      displayIndex: PropTypes.number,
      questionIndex: PropTypes.number,
      mode: PropTypes.any
    })
  ),
  updateTaskFeedbackState: PropTypes.func
};

export default QuestionsCarousel;
