// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import ePub from "epubjs";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSelectedQuestion } from "../../redux/grSlice";
import { updateSq3r } from "../../redux/firebaseMiddleware";
import { enqueueFlashMessage, undo } from "../../redux/userSlice";

// Components
import QuestionEditMenu from "../menus/QuestionEditMenu";
import EditingButton from "../EditingButton";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  li: {
    display: "flex",
    fontSize: "16px",
    lineHeight: "24px",
    position: "relative",
    color: "#e0e0e0",
    "&:hover button, &:focus-within button": {
      visibility: "visible"
    }
  },
  liLtr: {
    textAlign: "left",
    "& button": {
      right: "0px"
    }
  },
  liRtl: {
    textAlign: "right",
    "& button": {
      left: "0px"
    }
  },
  questionText: {
    fontSize: "16px",
    color: "#fafafa",
    flex: 1,
    textAlign: "initial",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  selectedQuestionText: {
    color: "black"
  },
  selectedQuestionCell: {
    backgroundColor: "#5ec891"
  },
  questionOver: {
    color: "#5ec891"
  }
}));

export const QuestionBox = props => {
  const { id, text, icon } = props; //index, moveCard

  // Hooks
  const intl = useIntl();
  const EpubCFI = new ePub.CFI();
  const inputRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state
  const highlights = useSelector(state => state.gr.highlights);
  const questions = useSelector(state => state.gr.questions);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedQuestion = useSelector(state => {
    let filtered = state.gr?.questions?.filter(
      q => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const shouldUndo = useSelector(state => state.user.undo);
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // Ephemeral state
  const [mousePos, setMousePos] = useState({});
  const [editingMode, setEditingMode] = useState(0);
  const [undoData, setUndoData] = useState(null);

  // Variables
  const ANSWER_ITEM = "GR.Answer";

  // Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoThemeCopy = () => {
      if (undoData?.type === "answerDropped") {
        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: questions.map(el => {
              if (el.id === undoData.questionId) {
                return {
                  ...el,
                  answers: el.answers.filter(el => el.cfi !== undoData.quoteCfi)
                };
              } else return el;
            }),
            highlights: highlights
          })
        );
      }
    };

    if (shouldUndo) {
      undoThemeCopy();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, highlights, questions, selectedTextId, undoData]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "sq3r.answer_dropped",
            defaultMessage: "Card copied to question"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch, intl]);

  useEffect(() => {
    if (editingMode && inputRef.current) {
      inputRef.current.focus();
      if (inputRef.current.setSelectionRange) {
        //  tempRef.current.setSelectionRange(text.length, text.length);
      }
    }
  }, [editingMode, inputRef]);

  const handleQuestionChange = item => event => {
    let updatedQuestions = questions.map(el => {
      if (el.id === item.id) {
        return { ...el, question: event.target.value };
      } else return el;
    });

    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );
  };

  const renderEditControl = () => {
    return (
      <EditingButton
        rows={2}
        multiline={true}
        text={props.question.question}
        colorClass={selectedQuestion.id === props.question.id ? "black" : false}
        onChange={handleQuestionChange(props.question)}
        editingMode={props.question.id === editingMode}
        editIcon={<span />}
        onFocusOut={value => {
          if (!value) {
            dispatch(
              updateSq3r({
                textId: selectedTextId,
                questions: questions.filter(el => el.id !== props.question.id),
                highlights: highlights
              })
            );
          }
          setEditingMode(0);
        }}
        onKeyPress={ev => {
          if (ev.key === "Enter") {
            // Do code here
            setEditingMode(0);
            if (!ev.target.value) {
              dispatch(
                updateSq3r({
                  textId: selectedTextId,
                  questions: questions.filter(
                    el => el.id !== props.question.id
                  ),
                  highlights: highlights
                })
              );
            } else dispatch(setSelectedQuestion({ id: props.question.id }));

            ev.preventDefault();
          }
        }}
      />
    );
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ANSWER_ITEM,
    drop: (item, monitor) => {
      let items = props.question.answers
        ? props.question.answers.filter(a => a.cfi !== item.item.cfi)
        : [];
      let answers = [...items];
      let i = 0;
      let insertAt = answers.length;
      for (i = 0; i < answers.length; i++) {
        if (EpubCFI.compare(answers[i].cfi, item.item.cfi) > 0) {
          insertAt = i;
          break;
        }
      }

      answers.splice(insertAt, 0, { ...item.item, source: "grQuestion" });
      let question = { ...props.question, answers: answers };

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: questions.map(el => {
            if (el.id === question.id) return question;
            else return el;
          }),
          highlights: highlights
        })
      );
      setUndoData({
        type: "answerDropped",
        questionId: question.id,
        quoteCfi: item.item.cfi
      });
    },
    canDrop: (item, monitor) => {
      let retVal =
        !props.question.answers ||
        props.question.answers.filter(el => el.cfi === item.item.cfi).length <=
          0;
      return retVal;
    },
    collect: (monitor, collectProps) => {
      let obj = {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
      return obj;
    }
  });

  // Render
  return (
    <Box
      ref={drop}
      className={clsx(
        classes.li,
        classes.liLtr,
        isOver && canDrop && classes.questionOver,
        {
          [classes.selectedQuestionCell]:
            selectedQuestion.id === id || editingMode
        }
      )}
      onClick={() => {
        if (editingMode !== id && selectedQuestion.id !== id) {
          dispatch(setSelectedQuestion(props.question));
        } else if (selectedQuestion.id === id)
          dispatch(setSelectedQuestion(false));
      }}
      role="button"
      tabIndex="0"
      aria-pressed={selectedQuestion.id === id && "true"}>
      {icon}
      {editingMode === id ? (
        renderEditControl()
      ) : (
        <Typography
          dir="auto"
          className={clsx(
            classes.questionText,
            isOver && canDrop && classes.questionOver,
            {
              [classes.selectedQuestionText]:
                selectedQuestion.id === id || editingMode
            }
          )}>
          {text}
        </Typography>
      )}
      <QuestionEditMenu
        selected={selectedQuestion.id === id}
        open={"X" in mousePos}
        handleClose={() => {
          setMousePos({});
        }}
        onDelete={e => {
          dispatch(
            updateSq3r({
              textId: selectedTextId,
              questions: questions.filter(el => el.id !== props.question.id),
              highlights: highlights
            })
          );
          setMousePos({});
          props.onDelete && props.onDelete(props.question, props.index);
        }}
        onEdit={e => {
          dispatch(setSelectedQuestion({ id: props.question.id }));
          setEditingMode(props.question.id);
          setMousePos({});
        }}
        mouseX={mousePos ? mousePos.X : null}
        mouseY={mousePos ? mousePos.Y : null}
      />
    </Box>
  );
};
